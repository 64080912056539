
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

import { ProductCategoryType } from '@/shared/api/rest/models';
import RequiredAmountTable from '@/shared/components/RequiredAmountTable.vue';
import SidebarContentWrapper from '@/shared/components/SidebarContentWrapper.vue';
import SidebarHeadline from '@/shared/components/SidebarHeadline.vue';
import SidebarSubHeadline from '@/shared/components/SidebarSubHeadline.vue';
import ResetButton from '@/shared/components/buttons/ResetButton.vue';
import BaseInputNumber from '@/shared/components/form/BaseInputNumber.vue';
import FormFieldDropdown from '@/shared/components/form/FormFieldDropdown.vue';
import FormFieldInput from '@/shared/components/form/FormFieldInput.vue';
import FormSlider from '@/shared/components/form/FormSlider.vue';
import { DropdownItem } from '@/shared/components/form/formFieldDropdownTypes';

import NutrientAmountSelectionContainer from '../container/NutrientAmountSelectionContainer.vue';
import ProductsDropdownContainer from '../container/ProductsDropdownContainer.vue';
import { Zone } from '../store/baseWorkflowStore/types/Zone';
import { Calculation, FertilizationZoneDosage } from './store/types';

type StrategyDropdownItem = DropdownItem & {
  disabled?: boolean;
};

type StrategyDropdownItemsPerCategory = {
  items: StrategyDropdownItem[];
};

export default defineComponent({
  name: 'SidebarCreateApplicationMapFertilization',
  components: {
    BaseInputNumber,
    SidebarContentWrapper,
    SidebarHeadline,
    SidebarSubHeadline,
    RequiredAmountTable,
    FormFieldDropdown,
    FormFieldInput,
    ResetButton,
    FormSlider,
    ProductsDropdownContainer,
    NutrientAmountSelectionContainer,
  },
  props: {
    workflowKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      mineralFertilizerDropdownTooltipTitle: this.$t(
        'Durch das Produkt werden die Inhaltsstoffe (N, P₂O₅, K₂O) definiert. Inhaltsstoffe in Prozent und Menge in kg/ha können aber auch manuell eingegeben werden.',
      ),
      productCategoryType: ProductCategoryType.MINERAL_FERTILIZER,
      strategies: [
        { id: 'quality', name: this.$t('Qualitätsdüngung (3. Gabe)') },
        { id: 'balancing', name: this.$t('Ausgleichsdüngung (1. Gabe)') },
        { id: 'manual', name: this.$t('manuelle Dosierung'), disabled: true },
      ] as StrategyDropdownItem[],
      minZoneDifference: 0,
      maxZoneDifference: 20,
      initialZoneDifference: 0,
    };
  },
  computed: {
    ...mapState({
      paginationStep(state): number {
        // @ts-ignore
        return state.precisionFarming.applicationMaps[this.workflowKey].paginationStep;
      },
      calculation(state, getters): Calculation {
        return getters[`precisionFarming/applicationMaps/${this.workflowKey}/calculation`];
      },
      zones(state, getters): Zone[] {
        return getters[`precisionFarming/applicationMaps/${this.workflowKey}/zones`];
      },
      zoneDosage(state, getters): FertilizationZoneDosage[] | null {
        return getters[`precisionFarming/applicationMaps/${this.workflowKey}/zoneDosage`];
      },
    }),
    averageDosage(): number | null {
      if (this.calculation.manualDosage != null) {
        return null;
      }
      return this.calculation.averageDosage;
    },
    n: {
      get(): number {
        return this.calculation.n * 100;
      },
      set(value: number) {
        this.$store.dispatch(`precisionFarming/applicationMaps/${this.workflowKey}/setCalculationField`, {
          key: 'n',
          value: value / 100,
        });
      },
    },
    p: {
      get(): number {
        return this.calculation.p * 100;
      },
      set(value: number) {
        this.$store.dispatch(`precisionFarming/applicationMaps/${this.workflowKey}/setCalculationField`, {
          key: 'p',
          value: value / 100,
        });
      },
    },
    k: {
      get(): number {
        return this.calculation.k * 100;
      },
      set(value: number) {
        this.$store.dispatch(`precisionFarming/applicationMaps/${this.workflowKey}/setCalculationField`, {
          key: 'k',
          value: value / 100,
        });
      },
    },
    zoneDifference(): number | null {
      if (this.calculation.manualDosage != null) {
        return null;
      }
      return this.calculation.zoneDifference * 100;
    },
    strategiesToDropdownItems(): StrategyDropdownItemsPerCategory[] {
      return [{ items: this.strategies }];
    },
    strategyValueForDropdown(): StrategyDropdownItem | undefined {
      if (this.calculation.manualDosage != null) {
        return this.strategies.find((item) => item.id === 'manual');
      }
      return this.strategies.find((item) => item.id === this.calculation.strategy);
    },
    fertilizerAmountTotal(): number {
      if (this.zoneDosage == null) {
        return 0;
      }
      return this.zoneDosage.reduce(
        (sum: number, zone: FertilizationZoneDosage, index: number) => sum + zone.dosage * this.zones[index].size,
        0,
      );
    },
    averageDosageCalculated(): number {
      return this.fertilizerAmountTotal / this.zones.reduce((sum: number, zone) => sum + zone.size, 0);
    },
    zoneSizesTotal(): number {
      return this.zones.reduce((total: number, zone) => total + zone.size, 0);
    },
  },
  created() {
    if (this.zoneDifference) {
      this.initialZoneDifference = this.zoneDifference;
    }
  },
  watch: {
    paginationStep(step: number): void {
      if (step !== 3 || this.calculation.averageDosage != null) {
        return;
      }
      this.$nextTick(() => {
        // @ts-ignore
        this.$refs.averageDosage.$el.focus();
      });
    },
  },
  methods: {
    updateCalculation(key: string, index: number, value: number): void {
      this.$store.dispatch(`precisionFarming/applicationMaps/${this.workflowKey}/setManualDosage`, {
        key,
        index,
        value,
      });
    },
    updateAverageDosage(value: string): void {
      const numberValue = parseFloat(value);

      if (Number.isNaN(numberValue)) {
        this.setAverageDosage(null);
      } else {
        this.setAverageDosage(numberValue);
      }
    },
    setAverageDosage(value: number | null): void {
      this.$store.commit(`precisionFarming/applicationMaps/${this.workflowKey}/setCalculationField`, {
        key: 'averageDosage',
        value,
      });
      this.resetManualDosage();
    },
    setStrategy(value: DropdownItem): void {
      this.$store.commit(`precisionFarming/applicationMaps/${this.workflowKey}/setCalculationField`, {
        key: 'strategy',
        value: value.id,
      });
      if (value.id !== 'manual') {
        this.resetManualDosage();
      }
    },
    setZoneDifference(value: number): void {
      this.$store.commit(`precisionFarming/applicationMaps/${this.workflowKey}/setCalculationField`, {
        key: 'zoneDifference',
        value: value / 100,
      });
      this.resetManualDosage();
    },
    resetManualDosage() {
      this.$store.commit(`precisionFarming/applicationMaps/${this.workflowKey}/setManualDosage`, null);
    },
    resetZoneDifference(): void {
      this.setZoneDifference(this.initialZoneDifference);
    },
  },
});
