
import { defineComponent } from 'vue';

import { Violation } from '@/activities/ruleCheck/types';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

import TableFooterRuleCheckStatus from '../../components/table/TableFooterRuleCheckStatus.vue';
import TableDataActivityService from '../../services/TableDataActivityService';
import { RuleCheckStatusTypeLoading } from '../../types';

export default defineComponent({
  name: 'TableFooterRuleCheckStatusContainer',
  components: { TableFooterRuleCheckStatus },
  computed: {
    loading(): boolean {
      return this.$store.state.activities.ruleCheck.activityRuleViolations.fetching;
    },
    violations(): Violation[] {
      return this.$store.getters['activities/ruleCheck/violations']();
    },
    status(): RuleCheckStatusTypeLoading {
      if (this.loading) {
        return 'LOADING';
      }

      return TableDataActivityService.getRuleCheckStatusType(this.violations);
    },
    featureRuleCheckEnabled(): boolean {
      return this.$store.getters.currentCompaniesHaveFeatureEnabled(availableFeatures.FEATURE_RULE_CHECK);
    },
    featureRuleCheckVisible(): boolean {
      return this.$store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_RULE_CHECK);
    },
  },
  methods: {
    onClick(): void {
      const firstActivityId = this.violations?.[0]?.activityId;
      if (firstActivityId) {
        this.$store.commit('activities/ruleCheck/showRuleViolationsModal', firstActivityId);
      }
    },
  },
});
