export enum WorkflowKey {
  SEEDING = 'seeding',
  FERTILIZATION = 'fertilization',
  FERTILIZATION_CEREALS = 'fertilizationCereals',
}
export enum WorkflowKeyForRoutes {
  SEEDING = 'seeding',
  FERTILIZATION = 'fertilization',
  FERTILIZATION_CEREALS = 'fertilization-cereals',
}
