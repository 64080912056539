var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.clickOutside),expression:"clickOutside"}],ref:"dropdown",staticClass:"dropdown bg-transparent",class:{
    'float-right': _vm.overflowRight,
    'dropdown--table-editor': _vm.variant === 'table-editor',
    'dropdown--form-field': _vm.variant === 'form-field',
    'dropdown--form-field-lg': _vm.variant === 'form-field-lg',
  }},[_c('div',{staticClass:"dropdown__search",class:{
      'dropdown__search--open': _vm.isOpen,
      'dropdown__search--required': _vm.required && _vm.items.selected.id == null,
      'bg-white': _vm.variant === 'table-editor',
      'dropdown__search--form-field': _vm.variant === 'form-field',
      'dropdown__search--form-field-lg': _vm.variant === 'form-field-lg',
      'is-valid': _vm.state === true,
      'is-invalid': _vm.state === false,
    }},[_c('label',{style:(_vm.styleFormField(_vm.iconCount))},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],ref:"search",attrs:{"type":"text","placeholder":_vm.internalPlaceholder,"disabled":_vm.disabled},domProps:{"value":(_vm.search)},on:{"input":[function($event){if($event.target.composing)return;_vm.search=$event.target.value},_vm.open],"blur":_vm.blur,"click":(event) => _vm.open(event, true),"keydown":_vm.keydown,"keyup":_vm.keyup}})]),_c('FontAwesomeIcon',{staticClass:"dropdown__search-icon dropdown__search-icon--caret-down",attrs:{"icon":"caret-down"},on:{"click":_vm.open}}),_c('FontAwesomeIcon',{staticClass:"dropdown__search-icon dropdown__search-icon--search",attrs:{"icon":"search"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"dropdown__select-wrapper bg-white",class:{
      'dropdown__select-wrapper--top': _vm.overflowBottom,
      'dropdown__select-wrapper--small-modal': _vm.variantVariation === 'small-modal',
      'dropdown__select-wrapper--form-field': _vm.variant === 'form-field',
      'dropdown__select-wrapper--form-field-lg': _vm.variant === 'form-field-lg',
    },style:(_vm.selectWrapperStyle)},[_c('div',{ref:"select",staticClass:"dropdown__select",on:{"&scroll":function($event){return _vm.onScroll.apply(null, arguments)}}},[_c('div',{staticClass:"dropdown__item-wrapper",style:({ height: _vm.itemWrapperHeight })},[(_vm.items.loading)?_c('div',{staticClass:"dropdown__select-loading text-primary"},[_c('FontAwesomeIcon',{attrs:{"icon":"circle-notch","spin":""}})],1):_vm._e(),(_vm.comparableItemValue != null)?_c('div',{staticClass:"dropdown__item dropdown__item--comparable-item"},[(_vm.comparableItemCategory != null)?_c('div',{staticClass:"dropdown__comparable-item-category"},[_vm._v(" "+_vm._s(_vm.comparableItemCategory)+" ")]):_vm._e(),_c('div',{staticClass:"dropdown__comparable-item-value"},[_vm._v(" "+_vm._s(_vm.comparableItemValue)+" ")])]):_vm._e(),_vm._l((_vm.visibleOptions),function(option){return _c('div',{key:option.key,staticClass:"dropdown__item",class:{
            'dropdown__item--category': option.isCategory,
            'bg-lightest': option.isCategory,
            'dropdown__item--option': option.isOption,
            'dropdown__item--option-focused': option.key === _vm.focused,
            'dropdown__item--option-selected': option.id === _vm.items.selected.id,
            'dropdown__item--option-disabled': option.disabled,
          },style:({ top: option.top }),on:{"click":function($event){return _vm.select(option.key)}}},[(option.isOption)?_c('BaseSearchTerm',{attrs:{"haystack":option.name,"needle":_vm.filter}}):_vm._e(),(_vm.colorCoded)?_c('ColorBadge',{attrs:{"color":option.id}}):_vm._e(),(option.isCategory)?_c('span',[_vm._v(" "+_vm._s(option.name)+" ")]):_vm._e()],1)})],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }