import { Module } from 'vuex';

import { RootState } from '@/store/types';

import fertilization from '../fertilization/store';
import fertilizationCereals from '../fertilizationCereals/store';
import seeding from '../seeding/store';
import { ApplicationMapsState } from './types';

const vuexModule: Module<ApplicationMapsState, RootState> = {
  namespaced: true,
  modules: {
    fertilization,
    seeding,
    fertilizationCereals,
  },
  actions: {
    resetAll({ dispatch }) {
      dispatch('fertilization/reset');
      dispatch('seeding/reset');
      dispatch('fertilizationCereals/reset');
    },
  },
};

export default vuexModule;
