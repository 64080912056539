<template>
  <ThePageSkeleton
    :page-feature-title="$router.currentRoute.meta.label"
    :page-feature-enabled="currentCompaniesHaveBasicFeature.enabled"
    :page-feature-visible="currentCompaniesHaveBasicFeature.visible"
  >
    <template #contentbar>
      <TheContentbar>
        <template #right="{ contentbarLarge }">
          <SearchBar class="ml-4" :type="contentbarLarge ? 'large' : ''" :searchFilter.sync="filterString" />
          <ProcessOrderSwitch class="ml-4 mr-3" :slim="!contentbarLarge" />
          <CompanySwitch class="ml-1" :slim="!contentbarLarge" />
        </template>
      </TheContentbar>
    </template>
    <template #content>
      <div class="card-container">
        <Card
          v-for="(workflow, index) in workflowsComputed"
          :key="`card-${index}`"
          :headline="workflow.name"
          :subTitle="workflow.subTitle"
          :description="workflow.description"
          :primaryButtonLabel="$t('Karte erstellen')"
          :imageCompanyLogoUrl="workflow.companyLogoUrl"
          @onPrimaryClick="$router.push({ name: `application-maps/workflows/${workflow.key}` })"
          :secondaryButtonLabel="workflow.onSecondaryClick ? $t('Learn more') : null"
          @onSecondaryClick="workflow.onSecondaryClick ? workflow.onSecondaryClick() : null"
        />
      </div>
    </template>
  </ThePageSkeleton>
</template>

<script>
import { mapGetters } from 'vuex';

import FarmdokLogo from '@/assets/images/FARMDOK_Logo.svg';
import TerrazoLogo from '@/assets/images/Terrazo_green_black.svg';
import TheContentbar from '@/layout/components/TheContentbar.vue';
import ThePageSkeleton from '@/layout/components/ThePageSkeleton.vue';
import CompanySwitch from '@/shared/components/CompanySwitch.vue';
import ProcessOrderSwitch from '@/shared/components/ProcessOrderSwitch.vue';
import SearchBar from '@/shared/components/SearchBar.vue';
import Card from '@/shared/components/cards/Card.vue';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

export default {
  name: 'PageApplicationMapsUseCaseSelection',
  components: {
    ThePageSkeleton,
    TheContentbar,
    CompanySwitch,
    SearchBar,
    ProcessOrderSwitch,
    Card,
  },

  data() {
    return {
      filterString: '',
      workflows: [
        {
          name: this.$t('Saatbau Mais Aussaatkarte'),
          subTitle: this.$t('AUSSAAT'),
          description: this.$t('Applikationskarten für Saatbau Mais Aussaat.'),
          key: 'seeding',
          companyLogoUrl:
            'https://develop.farmdok.com/assets/img/contracting/saatbau_Logo_Sondervariante_Kompakt_72dpi_RGB_144.png',
          availabilityFeature: availableFeatures.FEATURE_APPLICATION_MAPS_SEEDING_SAATBAU_MAIZE,
        },
        {
          name: `Terrazo ${this.$t('Düngekarte für Getreide')}`,
          subTitle: this.$t('DÜNGUNG'),
          description: this.$t(
            'Applikationskarten für Ausgleichs- oder Qualitätsdüngung von Getreide. Ein Projekt von Josephinum Research.',
          ),
          key: 'fertilization-cereals',
          companyLogoUrl: TerrazoLogo,
          availabilityFeature: availableFeatures.FEATURE_APPLICATION_MAPS_TERRAZO,
          onSecondaryClick: () => {
            window.open(
              'https://www.josephinum.at/forschung-und-pruefung/agrartechnik/projekte/terrazo.html',
              '_blank',
            );
          },
        },
        {
          name: this.$t('Düngekarte für alle Kulturen'),
          subTitle: this.$t('DÜNGUNG'),
          description: this.$t(
            'Applikationskarten für Ausgleichs- oder Qualitätsdüngung. Zonierung mittels Satelliten oder importierten Daten.',
          ),
          key: 'fertilization',
          companyLogoUrl: FarmdokLogo,
          availabilityFeature: availableFeatures.FEATURE_APPLICATION_MAPS_TERRAZO,
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      currentCompaniesHaveFeature: 'currentCompaniesHaveFeature',
    }),
    currentCompaniesHaveBasicFeature() {
      return this.currentCompaniesHaveFeature(availableFeatures.FEATURE_APPLICATION_MAPS_FERTILIZATION_FD_BASIC);
    },

    workflowsComputed() {
      const workflowsByFeature = this.filterWorkflowsByAvailabilityFeature(this.workflows);
      return this.filterWorkflowsBySearch(workflowsByFeature);
    },
  },

  methods: {
    filterWorkflowsByAvailabilityFeature(workflows) {
      return workflows.filter((workflow) => {
        if (!workflow.availabilityFeature) {
          return false;
        }
        if (
          this.currentCompaniesHaveFeature(workflow.availabilityFeature).enabled &&
          this.currentCompaniesHaveFeature(workflow.availabilityFeature).visible
        ) {
          return true;
        }
        return false;
      });
    },

    filterWorkflowsBySearch(workflows) {
      if (typeof this.filterString !== 'string' || this.filterString.length === 0) {
        return workflows;
      }
      return workflows.filter((workflow) => {
        if (workflow.name.toLowerCase().includes(this.filterString.toLowerCase())) {
          return true;
        }
        if (
          typeof workflow.subTitle === 'string' &&
          workflow.subTitle.toLowerCase().includes(this.filterString.toLowerCase())
        ) {
          return true;
        }
        if (
          typeof workflow.description === 'string' &&
          workflow.description.toLowerCase().includes(this.filterString.toLowerCase())
        ) {
          return true;
        }
        return false;
      });
    },
  },
};
</script>

<style scoped>
.card-container {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  height: auto;
  max-height: 100%;
  padding: 12px;
  overflow-y: auto;
}
</style>
