<template>
  <ModalWrapper ref="modal" :okTitle="$t('Zurücksetzen')" :cancelTitle="$t('Weiter')">
    <!-- eslint-disable max-len -->
    <p>
      {{ contentText }}
    </p>
    <!-- eslint-enable max-len -->
  </ModalWrapper>
</template>

<script>
import ModalWrapper from '@/shared/components/ModalWrapper.vue';

export default {
  components: { ModalWrapper },
  name: 'ModalSeedrateConfirmation',
  props: {
    thresholdExceeded: {
      type: String,
      default: 'upper',
      validator: (value) => ['upper', 'lower'].includes(value),
    },
  },

  computed: {
    contentText() {
      let contentText = '';
      if (this.thresholdExceeded === 'upper') {
        contentText += this.$t(
          'Achtung, die Saatstärke ist in einer oder mehreren Zonen beträchtlich höher als üblich.',
        );
      } else {
        contentText += this.$t(
          'Achtung, die Saatstärke ist in einer oder mehreren Zonen beträchtlich niedriger als üblich.',
        );
      }
      return `${contentText} ${this.$t(
        'Möchtest Du mit diesen Werten fortsetzten oder die Einstellungen auf die empfohlenen Werte zurücksetzen?',
      )}`;
    },
  },
  methods: {
    async show() {
      return this.$refs.modal.show();
    },
  },
};
</script>
