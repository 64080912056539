import { render, staticRenderFns } from "./PestDetails.vue?vue&type=template&id=4adcc75b&scoped=true&"
import script from "./PestDetails.vue?vue&type=script&lang=ts&"
export * from "./PestDetails.vue?vue&type=script&lang=ts&"
import style0 from "./PestDetails.vue?vue&type=style&index=0&id=4adcc75b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4adcc75b",
  null
  
)

export default component.exports