
import { PropType, defineComponent } from 'vue';

import { ViolationNotChecked } from '../types';

export default defineComponent({
  name: 'ModalContentViolationNotChecked',
  props: {
    violation: {
      type: Object as PropType<ViolationNotChecked>,
      required: true,
    },
  },
});
