
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons';
import { PropType, defineComponent } from 'vue';

import { Company } from '@/auth/store/types';
import cropsToDropdownItems from '@/products/crops/mixins/containers/cropsToDropdownItems';
import { Field } from '@/shared/api/rest/models';
import FormFieldDropdown from '@/shared/components/form/FormFieldDropdown.vue';
import FormFieldInput from '@/shared/components/form/FormFieldInput.vue';
import FormFieldSelect from '@/shared/components/form/FormFieldSelect.vue';
import FormFieldSet from '@/shared/components/form/FormFieldSet.vue';
import varietiesToDropdownItems from '@/varieties/mixins/containers/varietiesToDropdownItems';

import { customer as columnCustomer } from '../handsontable/columns/columns';
import dropdownItemsForFields, {
  STORE_KEY_AMA_CODES,
  STORE_KEY_CATCH_CROP_VARIANTS,
  STORE_KEY_GW_AREAS,
  STORE_KEY_KIND_OF_USE_TYPES,
} from '../mixins/containers/dropdownItemsForFields';
import fieldSizeDecimals from '../mixins/fieldSizeDecimals';

library.add(faPlusCircle);

export type DropdownItemValue = {
  name: string | undefined;
  id: String;
  items: any;
};

export default defineComponent({
  name: 'FormFieldSetCreateFields',
  components: { FormFieldDropdown, FormFieldInput, FormFieldSet, FormFieldSelect },
  mixins: [cropsToDropdownItems, varietiesToDropdownItems, dropdownItemsForFields, fieldSizeDecimals],
  props: {
    fields: {
      type: Array as PropType<Field[]>,
      default: () => [],
    },
    columns: {
      type: Array as PropType<any>,
      default: null,
      validator(columns) {
        if (columns == null) {
          return true;
        }
        if (!Array.isArray(columns)) {
          return false;
        }
        return columns.every(
          (column) => column != null && typeof column.key === 'string' && typeof column.visible === 'boolean',
        );
      },
    },
    missingName: {
      type: Boolean,
      default: false,
    },
    fetching: {
      type: Boolean,
      default: false,
    },
    currentFieldId: {
      type: String,
      default: null,
    },
    missingCompany: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showAdditionalData: false,
      companyId: null,
      customerId: 'own',
      missingCompanyState: false,
      missingNameState: false,
    };
  },
  mounted() {
    this.missingCompanyState = this.missingCompany;
    this.missingNameState = this.missingName;
  },
  watch: {
    missingName(val) {
      this.missingNameState = val;
    },
    missingCompany(val) {
      this.missingCompanyState = val;
    },
  },
  computed: {
    columnsByKey(): any {
      if (this.columns == null) {
        return {};
      }
      return (this.columns as any).reduce(
        (columnsByKey: any, column: any) => ({
          ...columnsByKey,
          [column.key]: column,
        }),
        {},
      );
    },
    useCustomers(): boolean {
      if (this.customers == null) {
        return false;
      }
      return (
        this.columnsByKey[columnCustomer.key] != null &&
        this.columnsByKey[columnCustomer.key].visible &&
        Object.keys(this.customers).length > 0
      );
    },
    customersToDropdownItems(): {
      name?: string | undefined;
      id: string;
      items: { id: string; name: string | undefined }[];
      sort: boolean;
    }[] {
      return [
        {
          id: 'own-company',
          items: [{ id: 'own', name: this.$t('Eigener Betrieb') }],
          sort: false,
        },
        {
          name: this.$t('Kunden'),
          id: 'customers',
          items: Object.values(this.customers),
          sort: true,
        },
      ];
    },
    gwAreaDropdownItems(): DropdownItemValue {
      return this.dropdownItemsForFieldsGetItems(STORE_KEY_GW_AREAS);
    },
    catchcropDropdownItems(): DropdownItemValue {
      return this.dropdownItemsForFieldsGetItems(STORE_KEY_CATCH_CROP_VARIANTS);
    },
    kindOfUseDropdownItems(): DropdownItemValue {
      return this.dropdownItemsForFieldsGetItems(STORE_KEY_KIND_OF_USE_TYPES);
    },
    amaCodeDropdownItems(): DropdownItemValue {
      return this.dropdownItemsForFieldsGetItems(STORE_KEY_AMA_CODES);
    },
    companies(): Company[] {
      return this.$store.state.auth.currentCompanies;
    },
    companyOptions(): any[] {
      return [
        { value: null, text: this.$t('Betrieb wählen'), disabled: true },
        ...this.companies.map((company: Company) => ({ value: company.id, text: company.name })),
      ];
    },
  },
  methods: {
    updateFieldName(value: string) {
      if (value) {
        this.missingNameState = false;
      }
    },
    onCompanyChanged(companyId: string) {
      if (companyId) {
        this.missingCompanyState = false;
      }
      this.$emit('update:selectedCompanyId', companyId);
    },
    copyCropToAllFields(field: Field) {
      const newFields = (this.fields as Field[]).map((currentField) => ({
        ...currentField,
        cropId: field.cropId,
      }));
      this.$emit('update:newFields', newFields);
    },
    updateFormFieldCollapsed(field: Field, collapsed: boolean) {
      if (!collapsed) {
        this.$emit('update:currentFieldId', field.id);
      } else if (collapsed && this.currentFieldId === field.id) {
        this.$emit('update:currentFieldId', null);
      }
    },
    customerValueForDropdown(field: Field) {
      let customerId = null;
      if (field['fieldGroup.customerId'] != null) {
        customerId = field['fieldGroup.customerId'];
      }

      if (customerId) {
        if ((this.customers as any)[customerId] != null) {
          return {
            id: customerId,
            name: (this.customers[customerId] as any).name,
          };
        }
        if (customerId === 'own') {
          return { id: 'own', name: this.$t('Eigener Betrieb') };
        }
      }

      return { id: null, name: '' };
    },
    cropValueForDropdown(field: Field) {
      if (field.cropId != null && this.crops[field.cropId] != null) {
        return { id: field.cropId, name: this.crops[field.cropId].name };
      }
      if (Object.prototype.hasOwnProperty.call(field, 'cropId')) {
        return { id: null, name: '' };
      }
      return { id: null, name: '' };
    },
    varietyValueForDropdown(field: Field) {
      if (field.varietyId != null && this.varieties[field.varietyId] != null) {
        return { id: field.varietyId, name: this.varieties[field.varietyId].name };
      }
      if (Object.prototype.hasOwnProperty.call(field, 'varietyId')) {
        return { id: null, name: '' };
      }
      return { id: null, name: '' };
    },
    preCropValueForDropdown(field: Field) {
      if (field.preCropId != null && this.crops[field.preCropId] != null) {
        return { id: field.preCropId, name: this.crops[field.preCropId].name };
      }
      if (Object.prototype.hasOwnProperty.call(field, 'preCropId')) {
        return { id: null, name: '' };
      }
      return { id: null, name: '' };
    },
    catchcropValueForDropdown(field: Field) {
      if (field.catchcropVariant != null && this.catchCropVariantsById[field.catchcropVariant] != null) {
        return this.catchCropVariantsById[field.catchcropVariant];
      }
      return { id: null, name: '' };
    },
    kindOfUseValueForDropdown(field: Field) {
      if (field != null && this.kindOfUseTypesById[field.kindofuse] != null) {
        return this.kindOfUseTypesById[field.kindofuse];
      }
      return { id: null, name: '' };
    },

    amaCodeValueForDropdown(field: Field) {
      if (field.amaCode != null && this.amaCodesById[field.amaCode] != null) {
        return this.amaCodesById[field.amaCode];
      }
      return { id: null, name: '' };
    },
    gwAreaValueForDropdown(field: Field) {
      if (field.gwArea != null && this.gwAreasById[field.gwArea] != null) {
        return this.gwAreasById[field.gwArea];
      }
      return { id: null, name: '' };
    },
    fieldSetHeadline(field: Field) {
      if (typeof field.name === 'string' && field.name.length > 0) {
        return field.name;
      }
      return field.placeholderName;
    },
    getFormFieldValue(key: string, field: Field) {
      if ((field as any)[key] != null) {
        return (field as any)[key];
      }
      return null;
    },
    setFormFieldValue(key: string, value: string, index: number) {
      const fields: Field[] = [...this.fields];
      (fields[index] as any)[key] = value;
      this.$emit('update:newFields', fields);
    },
    roundFieldSize(index: number) {
      if ((this.fields[index] as Field).fieldSize == null) {
        return;
      }

      const fields: Field[] = [...this.fields];
      fields[index] = {
        ...fields[index],
        fieldSize: +this.fieldSizeDecimals(`${fields[index].fieldSize}`),
      };
      this.$emit('update:newFields', fields);
    },
    onOpenDropdown({ action, ref }: { action: string; ref: string }) {
      if (typeof action === 'string') {
        this.$store.dispatch(action);
      }

      /**
       * WORKAROUND
       * Currently the Dropdown is placed absolute in place and therefore is cut off if the container has overflow: hidden.
       * Until the opened dropdown is placed outside the container (or refactored into BDropdown) scroll until the full dropdown is visible.
       */
      this.$emit('openDropdown', this.$refs[ref]);
    },
  },
});
