<template lang="html">
  <div class="navigation">
    <Pagination :pages="stepCount" :current="currentPageIndex" />
    <div class="controls">
      <ButtonWithArrow variant="white" type="left" class="mr-1" @click="goToStep(-1)">
        {{ paginationStep === 1 ? $t('Abbrechen') : $t('Zurück') }}
      </ButtonWithArrow>
      <ButtonWithArrow
        variant="primary"
        type="right"
        class="buttonWithArrowRight"
        :disabled="!paginationNextEnabled"
        @click="onButtonNext"
        :tooltipOptions="tooltipOptions"
        data-cy="navigation-next-btn"
      >
        {{ paginationStep === stepCount ? $t('Abschließen') : $t('Weiter') }}
      </ButtonWithArrow>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Pagination from '@/shared/components/Pagination.vue';
import ButtonWithArrow from '@/shared/components/buttons/ButtonWithArrow.vue';

export default {
  name: 'navigation',
  props: {
    workflowKey: {
      type: String,
      required: true,
    },
    stepCount: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      pages: 4,
    };
  },
  components: { ButtonWithArrow, Pagination },
  computed: {
    ...mapState({
      paginationStep(state) {
        return state.precisionFarming.applicationMaps[this.workflowKey].paginationStep;
      },
      paginationNextEnabled(state, getters) {
        return getters[`precisionFarming/applicationMaps/${this.workflowKey}/paginationNextEnabled`];
      },
      paginationNextDisabledReason(state, getters) {
        return getters[`precisionFarming/applicationMaps/${this.workflowKey}/paginationNextDisabledReason`];
      },
    }),
    currentPageIndex() {
      return this.paginationStep - 1;
    },
    tooltipOptions() {
      return {
        title: this.paginationNextDisabledReason,
        disabled: !this.paginationNextDisabledReason,
      };
    },
  },
  methods: {
    goToStep(step) {
      const nextStep = this.paginationStep + step;
      if (nextStep < 1) {
        this.$store.commit(`precisionFarming/applicationMaps/${this.workflowKey}/reset`);
        this.$router.push({ name: 'application-maps' });
        return;
      }
      this.$store.dispatch(
        `precisionFarming/applicationMaps/${this.workflowKey}/setPaginationStep`,
        this.paginationStep + step,
      );
    },
    stepsCompleted() {
      this.$router.push({ name: 'application-maps' });
      this.$store.dispatch(`precisionFarming/applicationMaps/${this.workflowKey}/reset`);
    },

    nextStep() {
      if (this.paginationStep < this.stepCount) {
        this.goToStep(1);
      } else {
        this.stepsCompleted();
      }
    },

    onButtonNext() {
      if (this.$listeners && this.$listeners.onNavigationNext) {
        this.$emit('onNavigationNext', () => {
          this.nextStep();
        });
      } else {
        this.nextStep();
      }
    },
  },
};
</script>

<style lang="css" scoped>
.navigation {
  padding: 13px 20px 13px;
}

.controls {
  display: flex;
}

.buttonWithArrowRight {
  margin-right: 1rem;
}

@media (max-width: 767px) {
  .controls {
    flex-direction: column;
  }

  .buttonWithArrowRight {
    margin-right: 0rem;
  }
}

.controls > * {
  flex-grow: 1;
  flex-basis: 0;
}

.btn__inner {
  position: relative;
}

.btn__inner--icon-right {
  margin-left: -0.5em;
}

.btn__inner--icon-left {
  margin-right: -0.5em;
}

.btn .btn__icon {
  position: absolute;
  left: 100%;
  top: 50%;
  margin-left: 0.8em;
  transform: translateY(-50%);
}

.btn .btn__icon--left {
  left: auto;
  right: 100%;
  margin-left: 0;
  margin-right: 0.8em;
}
</style>
