<template>
  <div class="loading text-primary">
    <FontAwesomeIcon icon="circle-notch" spin />
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';

library.add(faCircleNotch);

export default {
  name: 'Loading',
};
</script>

<style scoped>
.loading {
  padding: 15px;
  font-size: 40px;
  text-align: center;
}
</style>
