<template>
  <div
    ref="dropdown"
    class="dropdown bg-transparent"
    :class="{
      'float-right': overflowRight,
      'dropdown--table-editor': variant === 'table-editor',
      'dropdown--form-field': variant === 'form-field',
      'dropdown--form-field-lg': variant === 'form-field-lg',
    }"
    v-click-outside="clickOutside"
  >
    <div
      class="dropdown__search"
      :class="{
        'dropdown__search--open': isOpen,
        'dropdown__search--required': required && items.selected.id == null,
        'bg-white': variant === 'table-editor',
        'dropdown__search--form-field': variant === 'form-field',
        'dropdown__search--form-field-lg': variant === 'form-field-lg',
        'is-valid': state === true,
        'is-invalid': state === false,
      }"
    >
      <label :style="styleFormField(iconCount)">
        <input
          ref="search"
          v-model="search"
          type="text"
          :placeholder="internalPlaceholder"
          :disabled="disabled"
          @input="open"
          @blur="blur"
          @click="(event) => open(event, true)"
          @keydown="keydown"
          @keyup="keyup"
        />
      </label>
      <FontAwesomeIcon
        icon="caret-down"
        class="dropdown__search-icon dropdown__search-icon--caret-down"
        @click="open"
      />
      <FontAwesomeIcon icon="search" class="dropdown__search-icon dropdown__search-icon--search" />
    </div>
    <div
      v-show="isOpen"
      class="dropdown__select-wrapper bg-white"
      :class="{
        'dropdown__select-wrapper--top': overflowBottom,
        'dropdown__select-wrapper--small-modal': variantVariation === 'small-modal',
        'dropdown__select-wrapper--form-field': variant === 'form-field',
        'dropdown__select-wrapper--form-field-lg': variant === 'form-field-lg',
      }"
      :style="selectWrapperStyle"
    >
      <div class="dropdown__select" ref="select" @scroll.passive="onScroll">
        <div class="dropdown__item-wrapper" :style="{ height: itemWrapperHeight }">
          <div v-if="items.loading" class="dropdown__select-loading text-primary">
            <FontAwesomeIcon icon="circle-notch" spin />
          </div>
          <div v-if="comparableItemValue != null" class="dropdown__item dropdown__item--comparable-item">
            <div v-if="comparableItemCategory != null" class="dropdown__comparable-item-category">
              {{ comparableItemCategory }}
            </div>
            <div class="dropdown__comparable-item-value">
              {{ comparableItemValue }}
            </div>
          </div>
          <div
            v-for="option in visibleOptions"
            :key="option.key"
            class="dropdown__item"
            :class="{
              'dropdown__item--category': option.isCategory,
              'bg-lightest': option.isCategory,
              'dropdown__item--option': option.isOption,
              'dropdown__item--option-focused': option.key === focused,
              'dropdown__item--option-selected': option.id === items.selected.id,
              'dropdown__item--option-disabled': option.disabled,
            }"
            :style="{ top: option.top }"
            @click="select(option.key)"
          >
            <BaseSearchTerm v-if="option.isOption" :haystack="option.name" :needle="filter" />
            <ColorBadge v-if="colorCoded" :color="option.id" />
            <span v-if="option.isCategory">
              {{ option.name }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import quickSort from '@charlesstover/quicksort';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCaretDown, faCircleNotch, faSearch } from '@fortawesome/pro-solid-svg-icons';
import throttle from 'lodash.throttle';

import { KEY_CODES } from '../constants';
import BaseSearchTerm from './BaseSearchTerm.vue';
import ColorBadge from './form/ColorBadge.vue';

library.add(faCircleNotch, faSearch, faCaretDown);

export default {
  name: 'Dropdown',
  components: { ColorBadge, BaseSearchTerm },
  props: {
    items: {
      type: Object,
      validator(items) {
        if (!Array.isArray(items.data)) {
          return false;
        }
        if (typeof items.loading !== 'boolean') {
          return false;
        }
        if (items.selected == null) {
          return false;
        }
        return true;
      },
      default() {
        return this.dropdownItems;
      },
    },
    variant: {
      type: String,
      validator: (variant) => ['table-editor', 'form-field', 'form-field-lg'].includes(variant),
      default() {
        return this.dropdownVariant;
      },
    },
    variantVariation: {
      type: String,
      validator: (variant) => ['small-modal'].includes(variant),
    },
    various: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default() {
        return this.$t('Tippen um zu suchen');
      },
    },
    required: {
      type: Boolean,
      default: () => false,
    },
    boundary: {
      type: [Window, HTMLElement, String, Function],
      default() {
        return this.dropdownBoundary;
      },
    },
    iconCount: {
      type: Number,
      default: 0,
    },
    state: {
      type: Boolean,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    comparableItem: {
      type: [String, Object],
      default() {
        return this.dropdownComparableItem;
      },
    },
    colorCoded: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      overflowRight: false,
      overflowBottom: false,

      selectScrollTop: 0,
      isOpen: false,
      focused: null,
      search: '',
      filter: null,
    };
  },
  inject: {
    dropdownItems: {
      default: {
        data: [],
        loading: true,
        selected: {
          id: null,
          name: '',
          value: undefined,
        },
      },
    },
    dropdownVariant: {
      default: 'form-field',
    },
    dropdownBoundary: {
      default: window,
    },
    dropdownComparableItem: {
      type: Object,
      default: null,
    },
  },
  computed: {
    comparableItemCategory() {
      if (this.comparableItem == null || typeof this.comparableItem === 'string') {
        return null;
      }
      return this.comparableItem.category;
    },
    comparableItemValue() {
      if (this.comparableItem == null || typeof this.comparableItem === 'string') {
        return this.comparableItem;
      }
      return this.comparableItem.value;
    },
    comparableItemHeight() {
      if (this.comparableItemValue == null) {
        return 0;
      }
      if (this.comparableItemCategory == null) {
        return 36;
      }
      return 36 + 24;
    },
    itemWrapperHeight() {
      if (this.items.loading) {
        return '36px';
      }
      const height = this.options.length * 36 + this.comparableItemHeight;
      return `${height}px`;
    },
    selectWrapperStyle() {
      if (this.overflowBottom) {
        let top = 42;
        this.options.some((option) => {
          if (option.isOption) {
            top += 36;
          } else if (option.isCategory) {
            top += 24;
          }
          if (top > 282) {
            top = 282;
            return true;
          }
          return false;
        });
        top *= -1;
        return {
          marginTop: `${top}px`,
        };
      }
      return null;
    },
    options() {
      if (this.items.loading) {
        return [];
      }

      const options = [];
      this.items.data.forEach((category) => {
        let { items } = category;
        if (this.filter != null) {
          items = category.items.filter((item) => item.name.toLowerCase().includes(this.filter));
        }
        if (items.length < 1) {
          return;
        }
        if ((this.items.data.length > 1 || this.comparableItemValue != null) && category.name != null) {
          const top = options.length * 36 + 12 + this.comparableItemHeight;
          options.push({
            key: options.length,
            isCategory: true,
            name: category.name,
            top: `${top}px`,
          });
        }
        if (category.sort) {
          items = quickSort(items, (a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            return 0;
          });
        }
        items.forEach((item) => {
          const top = options.length * 36 + this.comparableItemHeight;
          const option = {
            key: options.length,
            isOption: true,
            name: item.name,
            id: item.id,
            value: item.value,
            top: `${top}px`,
            disabled: !!item.disabled,
          };
          options.push(option);
        });
      });
      return options;
    },
    visibleOptions() {
      const visibleOptions = [];
      let currentPos = 0;
      this.options.some((option) => {
        if (currentPos > this.selectScrollTop + 500) {
          return true;
        }
        if (currentPos < this.selectScrollTop && option.isCategory) {
          const top = this.selectScrollTop + this.comparableItemHeight;
          visibleOptions.push({
            ...option,
            top: `${top}px`,
          });
        } else if (currentPos > this.selectScrollTop - 500) {
          visibleOptions.push(option);
        }
        currentPos += 36;
        return false;
      });
      return visibleOptions;
    },
    internalPlaceholder() {
      if (this.various) {
        return this.$t('(verschiedene)');
      }
      return this.placeholder;
    },
  },
  watch: {
    options() {
      if (!this.isOpen) {
        if (this.items.selected.name == null) {
          this.search = '';
        } else {
          this.search = this.items.selected.name;
        }
      }
      if (this.filter == null && this.items.selected.id != null) {
        this.options.some((option) => {
          if (this.items.selected.id === option.id) {
            this.focused = option.key;
            requestAnimationFrame(() => {
              this.moveFocusedIntoView();
            });
            return true;
          }
          return false;
        });
        return;
      }
      if (this.focused != null || this.filter == null) {
        return;
      }
      this.options.some((option) => {
        if (!option.isOption) {
          return false;
        }
        this.focused = option.key;
        requestAnimationFrame(() => {
          this.moveFocusedIntoView();
        });
        return true;
      });
    },
  },
  created() {
    if (this.items.selected.name == null) {
      this.search = '';
    } else {
      this.search = this.items.selected.name;
    }
  },
  methods: {
    styleFormField(iconCount) {
      if (iconCount > 0) {
        return { paddingRight: `calc(${iconCount} * (16px + 1.2rem) - 22px)` };
      }
      return null;
    },
    onScroll() {
      this.selectScrollTop = this.$refs.select.scrollTop;
    },
    blur() {
      if (this.variant === 'table-editor') {
        return;
      }
      // do not immediately close as this would prevent the click event on a selected item
      setTimeout(this.close, 200);
    },
    open(event, clearSearchField = false) {
      if (this.isOpen) {
        return;
      }
      this.overflowRight = false;
      this.options.some((option, key) => {
        if (this.items.selected.id == null && option.isOption) {
          this.focused = key;
          return true;
        }
        if (this.items.selected.id === option.id) {
          this.focused = key;
          return true;
        }
        return false;
      });
      this.filter = null;
      this.$refs.search.focus();
      requestAnimationFrame(() => {
        this.$refs.select.scrollTop = 0;
        this.moveFocusedIntoView();
        this.checkPosition();
      });
      if (clearSearchField || this.variant === 'table-editor') {
        this.search = '';
      }
      this.isOpen = true;
      this.$emit('open');
    },
    close() {
      if (!this.open) {
        return;
      }
      this.overflowRight = false;
      this.focused = null;
      this.filter = null;
      if (this.items.selected.name == null) {
        this.search = '';
      } else {
        this.search = this.items.selected.name;
      }
      this.isOpen = false;
      this.$emit('close');
    },
    select(key) {
      if (!this.options[key].isOption || this.options[key].disabled) {
        return;
      }
      const selected = {
        id: this.options[key].id,
        name: this.options[key].name,
        value: this.options[key].value,
      };
      this.$emit('select', selected);
      this.$nextTick(() => {
        this.close();
      });
    },
    keydown(event) {
      event.stopPropagation();
      if (!this.isOpen && event.key !== KEY_CODES.ESCAPE) {
        if ([KEY_CODES.ARROW_DOWN, KEY_CODES.ARROW_UP, KEY_CODES.ENTER].includes(event.key)) {
          this.open();
        }
        return;
      }
      if (event.key === KEY_CODES.ENTER) {
        if (this.focused != null) {
          this.select(this.focused);
        }
      } else if (event.key === KEY_CODES.ESCAPE) {
        this.close();
      } else if (event.key === KEY_CODES.ARROW_DOWN) {
        event.preventDefault();
        let found = false;
        if (this.focused == null) {
          found = true;
        } else {
          this.options.some((option) => {
            if (found && option.isOption) {
              this.focused = option.key;
              found = false;
              return true;
            }
            if (option.key === this.focused) {
              found = true;
            }
            return false;
          });
        }
        if (found) {
          this.options.some((option) => {
            if (option.isOption) {
              this.focused = option.key;
              return true;
            }
            return false;
          });
        }
        this.moveFocusedIntoView();
      } else if (event.key === KEY_CODES.ARROW_UP) {
        event.preventDefault();
        let previous = null;
        if (this.options[this.options.length - 1].isOption) {
          previous = this.options[this.options.length - 1].key;
        }
        if (this.focused == null) {
          this.focused = previous;
          this.moveFocusedIntoView();
          return;
        }
        this.options.some((option) => {
          if (option.key === this.focused) {
            this.focused = previous;
            return true;
          }
          if (option.isOption) {
            previous = option.key;
          }
          return false;
        });
        this.moveFocusedIntoView();
      }
    },
    keyup(event) {
      if (
        [
          KEY_CODES.ENTER,
          KEY_CODES.ESCAPE,
          KEY_CODES.ARROW_UP,
          KEY_CODES.ARROW_DOWN,
          KEY_CODES.ARROW_LEFT,
          KEY_CODES.ARROW_RIGHT,
        ].includes(event.key)
      ) {
        return;
      }
      this.updateFilter();
    },
    updateFilter: throttle(function updateFilter() {
      let filter = this.search.trim().toLowerCase();
      if (filter.length === 0) {
        filter = null;
      }
      if (this.filter !== filter) {
        this.focused = null;
        this.filter = filter;
      }
    }, 300),
    moveFocusedIntoView() {
      if (this.focused == null) {
        return;
      }
      const { scrollTop } = this.$refs.select;
      const selectHeight = this.$refs.select.clientHeight;
      if (scrollTop > this.focused * 36) {
        this.$refs.select.scrollTop = this.focused * 36 - 24;
      } else if (scrollTop < (this.focused + 1) * 36 - selectHeight) {
        this.$refs.select.scrollTop = Math.max((this.focused + 1) * 36 - selectHeight, 0);
      }
    },
    checkPosition() {
      const viewportOffset = this.$refs.dropdown.getBoundingClientRect();
      let boundaryRight = window.innerWidth - 20;
      let boundaryBottom = window.innerHeight - 20;

      let { boundary } = this;
      if (typeof boundary === 'function') {
        boundary = boundary();
      }
      if (boundary instanceof HTMLElement) {
        boundaryRight = boundary.getBoundingClientRect().right;
        boundaryBottom = boundary.getBoundingClientRect().bottom;
      } else if (typeof boundary === 'string') {
        boundaryRight = document.querySelector(`#${boundary}`).getBoundingClientRect().right;
        boundaryBottom = document.querySelector(`#${boundary}`).getBoundingClientRect().bottom;
      }

      this.overflowRight = viewportOffset.left + this.$refs.dropdown.clientWidth > boundaryRight;
      // don't open to the top if not enough room on top
      this.overflowBottom =
        viewportOffset.top + this.$refs.dropdown.clientHeight + this.$refs.select.clientHeight > boundaryBottom &&
        viewportOffset.top > 450;
    },
    clickOutside() {
      // in handsontable editor the open function gets called on mousedown (before click)
      // therefore the clickoutside event is triggered after open is called
      if (this.variant === 'table-editor') {
        return;
      }
      this.close();
    },
  },
};
</script>

<style scoped>
.dropdown {
  --height: auto;
  --padding-x: 0.75rem;
  --padding-y: 0.375rem;
  --background-color--hover: var(--lightest);
}

.dropdown--hover-white,
.form-control--hover-white {
  --background-color--hover: var(--white);
}

.dropdown--table-editor {
  min-width: 580px;
  --height: 42px;
  --padding-x: 12px;
  --padding-y: 0;
  --background-color--hover: transparent;
}

.dropdown--form-field,
.dropdown--form-field-lg {
  line-height: 1.5;
}

.dropdown--form-field-lg {
  height: calc(1.5em + 1.5rem + 4px);
  --padding-x: 1.5rem;
  --padding-y: 0.75rem;
}

.dropdown * {
  box-sizing: border-box;
}

.dropdown__search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--height);
  padding: var(--padding-y) var(--padding-x);
  border: 2px solid transparent;
  border-radius: 2px;
  box-sizing: border-box;
  min-height: 30px;
  transition: background-color 0.1s ease-in-out, border-color 0.1s ease-in-out;
}

.dropdown__search--form-field,
.dropdown__search--form-field-lg {
  border-width: 1px;
  border-radius: 4px;
  border-color: var(--medium);
  background: transparent;
}

.dropdown__search--form-field.dropdown__search--open,
.dropdown__search--form-field-lg.dropdown__search--open,
.dropdown__search--form-field.dropdown__search--open.is-valid,
.dropdown__search--form-field-lg.dropdown__search--open.is-valid {
  border-color: var(--primary);
  box-shadow: 0 0 0 1px var(--primary);
}

.dropdown__search--form-field.is-valid,
.dropdown__search--form-field-lg.is-valid {
  border-color: var(--primary_darker);
}

.dropdown__search--form-field.dropdown__search--open.is-invalid,
.dropdown__search--form-field-lg.dropdown__search--open.is-invalid {
  border-color: var(--danger_dark);
  box-shadow: 0 0 0 1px var(--danger_dark);
}

.dropdown__search--form-field.is-invalid,
.dropdown__search--form-field-lg.is-invalid {
  border-color: var(--danger_dark);
}

.dropdown__search.dropdown__search--form-field:hover,
.dropdown__search.dropdown__search--form-field-lg:hover {
  background-color: transparent;
}

.dropdown__search--form-field:hover:not(.dropdown__search--open),
.dropdown__search--form-field-lg:hover:not(.dropdown__search--open) {
  border-color: var(--black);
}

.dropdown__search--required {
  padding-left: calc(1.5em + var(--padding-y) * 2);
}

.dropdown__search:hover {
  background-color: var(--background-color--hover);
}

.dropdown__search--open {
  border-color: var(--primary);
}

.dropdown__search label {
  display: block;
  margin: 0;
  flex: 1;
}

.dropdown__search input {
  width: 100%;
  border: none;
  outline: 0;
  padding: 0;
  background-color: transparent;
}

.dropdown__search input::placeholder {
  font-style: italic;
}

.dropdown__search-icon--caret-down {
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}

.dropdown__search-icon--search {
  display: none;
}

.dropdown__search--open .dropdown__search-icon--search {
  display: inline-block;
}

.dropdown__search--open .dropdown__search-icon--caret-down {
  display: none;
}

.dropdown__select-wrapper {
  position: absolute;
  min-width: 100%;
  z-index: 10;
  overflow: hidden;
  border-radius: 2px;
  box-shadow: 0 25px 50px rgba(0, 0, 50, 0.1), 0 8px 20px rgba(0, 0, 50, 0.15), 0 5px 7px rgba(0, 0, 0, 0.05);
}

.dropdown__select-wrapper--form-field:not(.dropdown__select-wrapper--top) {
  margin-top: 1px;
}

.dropdown__select-wrapper--small-modal {
  position: fixed !important;
  min-width: 250px !important;
}

.dropdown__select-wrapper--top {
  overflow: hidden;
  border-radius: 2px;
  box-shadow: 0 -25px 50px rgba(0, 0, 50, 0.1), 0 -8px 20px rgba(0, 0, 50, 0.15), 0 -5px 7px rgba(0, 0, 0, 0.05);
}

.dropdown__select {
  overflow-y: auto;
  max-height: 240px;
}

.dropdown__item-wrapper {
  position: relative;
}

.dropdown__select-loading {
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 var(--padding-x);
}

.dropdown__item {
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 var(--padding-x);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown__item--category {
  height: 24px;
  box-shadow: 0 1px 0 #e5e5e5;
  font-size: 12px;
  font-weight: 600;
  z-index: 1;
}

.dropdown__item--option {
  justify-content: space-between;
  height: 36px;
  cursor: pointer;
}

.dropdown__item--option:hover {
  background-color: var(--secondary_light);
}

.dropdown__item--option-focused {
  background-color: var(--secondary_light);
}

.dropdown__item--option-selected {
  color: var(--secondary);
}

.dropdown__item--option-disabled {
  color: var(--gray_600);
  cursor: default;
}

.dropdown__item--option-disabled.dropdown__item--option-focused,
.dropdown__item--option-disabled:hover {
  background-color: var(--lightest);
}

.dropdown__item--comparable-item {
  position: sticky;
  top: 0;
  height: calc(36px + 24px);
  padding: 0;
  flex-direction: column;
  align-items: stretch;
  z-index: 55;
  background: var(--secondary_light);
  font-style: italic;
  font-weight: 600;
}

.dropdown__comparable-item-category {
  display: flex;
  align-items: center;
  height: 24px;
  padding: 0 var(--padding-x);
  background: var(--lightest);
  box-shadow: 0 1px 0 #e5e5e5;
  font-size: 12px;
  font-weight: 600;
  z-index: 1;
}

.dropdown__comparable-item-value {
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 var(--padding-x);
}
</style>
