
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

import SidebarContentWrapper from '@/shared/components/SidebarContentWrapper.vue';
import SidebarHeadline from '@/shared/components/SidebarHeadline.vue';
import SidebarSubHeadline from '@/shared/components/SidebarSubHeadline.vue';
import Tabs from '@/shared/components/Tabs.vue';
import { Tab } from '@/shared/components/tabsTypes';

import FormSelectSatelliteIndex from '../components/FormSelectSatelliteIndex.vue';
import FormUploadZoneFiles from '../components/FormUploadZoneFiles.vue';
import { ZONE_GENERATION_MODE_SATELLITE, ZONE_GENERATION_MODE_UPLOAD } from '../store/baseWorkflowStore/common';
import ResultTableContainer from './containers/sidebarZones/ResultTableContainer.vue';

export default defineComponent({
  name: 'SidebarZones',
  components: {
    SidebarContentWrapper,
    SidebarHeadline,
    SidebarSubHeadline,
    Tabs,
    FormSelectSatelliteIndex,
    FormUploadZoneFiles,
    ResultTableContainer,
  },
  props: {
    workflowKey: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState({
      zoneGenerationMode(state: any) {
        return state.precisionFarming.applicationMaps[this.workflowKey].zoneGenerationMode;
      },
      calculation(state: any, getters: any) {
        return getters[`precisionFarming/applicationMaps/${this.workflowKey}/calculation`];
      },
    }),
    buttonSatellites(): Tab {
      return {
        type: 'button',
        label: this.$t('Satellit') || 'Satellit',
        active: this.zoneGenerationMode === ZONE_GENERATION_MODE_SATELLITE,
        onClick: () => {
          this.$store.commit(
            `precisionFarming/applicationMaps/${this.workflowKey}/setZoneGenerationMode`,
            ZONE_GENERATION_MODE_SATELLITE,
          );
        },
      };
    },
    buttonUpload(): Tab {
      return {
        type: 'button',
        label: this.$t('Upload') || 'Upload',
        active: this.zoneGenerationMode === ZONE_GENERATION_MODE_UPLOAD,
        onClick: () => {
          this.$store.commit(
            `precisionFarming/applicationMaps/${this.workflowKey}/setZoneGenerationMode`,
            ZONE_GENERATION_MODE_UPLOAD,
          );
        },
      };
    },
    tabs() {
      return [this.buttonSatellites, this.buttonUpload];
    },
  },
});
