<template>
  <BaseFormField
    :id="id"
    :required="required"
    :label="label"
    :variant="variant"
    :type="type"
    :state="state"
    :stateMessage="stateMessage"
    :description="description"
  >
    <template #default="{ id, iconCount, showPassword }">
      <BFormInput
        class="form-field-input__input"
        :class="{
          [`form-field-input__input--${variant}`]: true,
          'form-field-input__input--with-icon': required === true || typeof state === 'boolean',
        }"
        :id="id"
        :type="type === 'password' && showPassword ? 'text' : type"
        :autocomplete="autocomplete"
        :name="name"
        :required="required"
        :placeholder="placeholderComputed"
        :disabled="disabled"
        :value="valueComputed"
        :number="type === 'number'"
        :lazy="lazy"
        :state="state"
        :style="style(iconCount)"
        :step="step"
        :max="max"
        :min="min"
        :lazy-formatter="lazyFormatter"
        :formatter="formatter"
        @input="input"
        @change="change"
        @update="update"
        @blur="blur"
        @focus="focus"
        @keypress="keypress"
      />
    </template>
  </BaseFormField>
</template>

<script>
import { BFormInput } from 'bootstrap-vue';

import BaseFormField from './BaseFormField.vue';

/**
 * Use this component for text/number input fields. Ideally placed within a FieldSet.
 *
 * @category Shared
 * @subcategory Molecules
 * @component
 * @example
 * <div style="padding: 10px;">
 *   <FormFieldInput
 *     value="Some text"
 *     label="Hello World"
 *   />
 *   <FormFieldInput
 *     label="Hello World"
 *     placeholder="Enter some text"
 *   />
 *   <FormFieldInput
 *     value="This is disabled"
 *     label="Hello World"
 *     disabled
 *   />
 *   <FormFieldInput
 *     value="This is valid"
 *     label="Hello World"
 *     :state="true"
 *   />
 *   <FormFieldInput
 *     value="This is the vertical layout"
 *     label="Hello World"
 *     variant="vertical"
 *   />
 *   <FormFieldInput
 *     value="And vertical large"
 *     label="Hello World"
 *     variant="vertical-lg"
 *   />
 * </div>
 */
export default {
  name: 'FormFieldInput',
  components: { BaseFormField, BFormInput },
  model: {
    prop: 'value',
    event: 'update',
  },
  props: {
    /**
     * Id set for input. If not set a unique id is generated as bootstrap places the label next to the input.
     */
    id: {
      type: String,
      default: null,
    },
    /**
     * The type of input to render. Supported types are text, number, email, password, tel., time
     */
    type: {
      type: String,
      default: 'text',
      validator: (value) => ['text', 'number', 'email', 'password', 'tel', 'time'].includes(value),
    },
    /**
     * Sets the 'autocomplete' attribute value on the form control.
     */
    autocomplete: {
      type: String,
      default: null,
    },
    /**
     * V-model.
     */
    value: {
      type: [String, Number],
      default: null,
    },
    /**
     * Adds the `required` attribute to the form control.
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * Label rendered above or to the left, depending on the variant.
     *
     * If variant is 'no-label' the label can be omitted.
     */
    label: {
      type: String,
      default: null,
    },
    /**
     * Name attribute of the input.
     */
    name: {
      type: String,
      default: null,
    },
    /**
     * Placeholder text of input.
     */
    placeholder: {
      type: String,
      default: null,
    },
    /**
     * Disable input.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Supported types:
     *  <table style="width:30%;">
     *    <tr><td>'horizontal'</td><td>Label to the left.</td></tr>
     *    <tr><td>'vertical'</td><td>Label above. Increased margin bottom.</td></tr>
     *    <tr><td>'vertical-lg'</td><td>Label above. Increases margin bottom and input paddings.</td></tr>
     *    <tr><td>'no-label'</td><td>No label. Size like the non '-lg' variants.</td></tr>
     * </table>
     */
    variant: {
      type: String,
      default: 'horizontal',
      validator: (value) => ['horizontal', 'vertical', 'vertical-lg', 'no-label', 'no-label-sm'].includes(value),
    },
    /**
     * If set to `true` or `false` the input will be highlighted with colors and icons, depending on the state.<br>
     * Default `null`.
     */
    state: {
      type: Boolean,
      default: null,
    },
    /**
     * If set to `true` the following props will be overwritten:
     *   - value with `null`
     *   - placeholder with `'(verschiedene)'`
     */
    various: {
      type: Boolean,
      default: false,
    },
    /**
     * Only allowed for type 'number'.
     */
    step: {
      type: [String, Number],
      default: undefined,
    },
    number: {
      type: Boolean,
      default: null,
    },
    lazyFormatter: {
      type: Boolean,
      default: false,
    },
    formatter: {
      type: Function,
      default: null,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    stateMessage: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: '',
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
  },
  computed: {
    valueComputed() {
      if (this.various) {
        return null;
      }
      return this.value;
    },
    placeholderComputed() {
      if (this.various) {
        return this.$t('(verschiedene)');
      }
      return this.placeholder;
    },
  },
  methods: {
    style(iconCount) {
      let additionalPadding = 0;
      if (this.variant === 'vertical-lg') {
        additionalPadding = '10px';
      }
      if (iconCount > 0) {
        return { paddingRight: `calc(${iconCount} * (16px + 1.2rem) + ${additionalPadding})` };
      }
      return null;
    },
    /**
     * Input event triggered by user interaction and emitted after the v-model is updated.<br>
     *
     * @event FormFieldInput#input
     * @type {String} value
     */
    input(value) {
      this.$emit('input', value);
    },
    /**
     * Change event triggered by user interaction and emitted after the v-model is updated.
     *
     * @event FormFieldInput#change
     * @type {String} value
     */
    change(value) {
      this.$emit('change', value);
    },
    /**
     * Emitted to update the v-model.
     *
     * @event FormFieldInput#update
     * @type {String} value
     */
    update(value) {
      if (this.type === 'number' && value === '') {
        this.$emit('update', null);
        return;
      }

      this.$emit('update', value);
    },
    /**
     * Emitted when the input field loses focus.
     *
     * @event FormFieldInput#blur
     */
    blur() {
      this.$emit('blur');
    },
    focus() {
      this.$emit('focus');
    },
    /**
     * Emitted when the input field receives a keypress event.
     *
     * @event FormFieldInput#keypress
     */
    keypress(value) {
      this.$emit('keypress', value);
    },
  },
};
</script>

<style scoped>
.form-field-input__input--vertical-lg {
  height: calc(1.5em + 1.5rem + 4px);
  padding: 0.75rem 1.5rem;
}

.form-field-input__input--no-label-sm {
  text-align: center;
  height: calc(1em + 1rem + 2px);
  font-size: 12px;
}

.form-field-input__input {
  border-color: var(--medium);
}

.form-field-input__input:focus,
.form-field-input__input:focus.is-valid {
  border-color: var(--primary);
  box-shadow: 0 0 0 1px var(--primary);
}

.form-field-input__input:focus.is-invalid {
  border-color: var(--danger);
  box-shadow: 0 0 0 1px var(--danger);
}

.form-field-input__input:hover {
  background-color: transparent;
}

.form-field-input__input:disabled,
.form-field-input__input:disabled:hover:not(.is-invalid):not(.is-valid):not(:focus) {
  color: var(--gray_600);
  border-color: var(--medium);
  background-color: transparent;
}

.form-field-input__input:hover:not(.is-invalid):not(.is-valid):not(:focus):not(:disabled) {
  border-color: var(--black);
}

.form-field-input__input.is-valid {
  border-color: var(--primary_darker);
}

.form-field-input__input.is-invalid {
  border-color: var(--danger_dark);
}

.form-field-input__input:hover.is-valid:not(:focus):not(:disabled) {
  box-shadow: 0 0 0 1px var(--primary_medium);
}

.form-field-input__input:hover.is-invalid:not(:focus):not(:disabled) {
  box-shadow: 0 0 0 1px var(--danger_medium);
}
</style>
