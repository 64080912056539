/* tslint:disable */
/* eslint-disable */
/**
 * Farmdok REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.43
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ActivitiesGpsLog200Response
 */
export interface ActivitiesGpsLog200Response {
    /**
     * 
     * @type {string}
     * @memberof ActivitiesGpsLog200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivitiesGpsLog200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof ActivitiesGpsLog200Response
     */
    'developerPayload'?: string;
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof ActivitiesGpsLog200Response
     */
    'pageKey'?: string;
    /**
     * 
     * @type {FeatureCollection}
     * @memberof ActivitiesGpsLog200Response
     */
    'data'?: FeatureCollection;
}
/**
 * 
 * @export
 * @interface ActivitiesGpsLog200ResponseAllOf
 */
export interface ActivitiesGpsLog200ResponseAllOf {
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof ActivitiesGpsLog200ResponseAllOf
     */
    'pageKey'?: string;
    /**
     * 
     * @type {FeatureCollection}
     * @memberof ActivitiesGpsLog200ResponseAllOf
     */
    'data'?: FeatureCollection;
}
/**
 * 
 * @export
 * @interface ActivitiesGpsTrack200Response
 */
export interface ActivitiesGpsTrack200Response {
    /**
     * 
     * @type {string}
     * @memberof ActivitiesGpsTrack200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivitiesGpsTrack200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof ActivitiesGpsTrack200Response
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {MultiLineString}
     * @memberof ActivitiesGpsTrack200Response
     */
    'data'?: MultiLineString;
}
/**
 * 
 * @export
 * @interface ActivitiesGpsTrack200ResponseAllOf
 */
export interface ActivitiesGpsTrack200ResponseAllOf {
    /**
     * 
     * @type {MultiLineString}
     * @memberof ActivitiesGpsTrack200ResponseAllOf
     */
    'data'?: MultiLineString;
}
/**
 * 
 * @export
 * @interface Activity
 */
export interface Activity {
    /**
     * Unique id of this object.
     * @type {string}
     * @memberof Activity
     */
    'id': string;
    /**
     * Timestamp when this object was deleted, null if object is not deleted.
     * @type {number}
     * @memberof Activity
     */
    'deleted'?: number | null;
    /**
     * Last change timestamp, updated automatically by the backend when object is changed.
     * @type {number}
     * @memberof Activity
     */
    'tstamp'?: number;
    /**
     * Unique ID of the user who processed this activity.
     * @type {string}
     * @memberof Activity
     */
    'userId'?: string | null;
    /**
     * Unique ID of the processorder this activity belongs to.
     * @type {string}
     * @memberof Activity
     */
    'processOrderId': string;
    /**
     * Unique ID of the field this activity was performed on. Must be in the process order referenced by `processOrderId`.
     * @type {string}
     * @memberof Activity
     */
    'fieldId'?: string | null;
    /**
     * Optional user supplied comment. In certain cases system generated comments are appended at the end of this, delimitated by a `|`.
     * @type {string}
     * @memberof Activity
     */
    'userComment'?: string | null;
    /**
     * Date/time when the activity started, unix timestamp.
     * @type {number}
     * @memberof Activity
     */
    'timeStart'?: number | null;
    /**
     * Date/time when the activity ended, unix timestamp.
     * @type {number}
     * @memberof Activity
     */
    'timeFinish'?: number | null;
    /**
     * Date/time when the activity was originally created, unix timestamp.
     * @type {number}
     * @memberof Activity
     */
    'creationDate'?: number | null;
    /**
     * Files referenced by this activity.
     * @type {Array<ActivityFile>}
     * @memberof Activity
     */
    'files'?: Array<ActivityFile> | null;
    /**
     * Unique ID of the track for this activity.
     * @type {string}
     * @memberof Activity
     */
    'trackId'?: string | null;
    /**
     * Unique ID of the activity-type. Must belong to the same company as the process order `processOrderId` and field `fieldId`. The category of the rough activity-type must be `user-allowed`.
     * @type {string}
     * @memberof Activity
     */
    'activityTypeId': string;
    /**
     * Processed area in ha.
     * @type {number}
     * @memberof Activity
     */
    'processedArea'?: number | null;
    /**
     * Working width in meters.
     * @type {number}
     * @memberof Activity
     */
    'workingWidth'?: number | null;
    /**
     * Working-time in seconds, fractional seconds allowed.
     * @type {number}
     * @memberof Activity
     */
    'workingTime'?: number | null;
    /**
     * Driving-time in seconds, fractional seconds allowed.
     * @type {number}
     * @memberof Activity
     */
    'drivingTime'?: number | null;
    /**
     * Setup-time in seconds, fractional seconds allowed.
     * @type {number}
     * @memberof Activity
     */
    'setupTime'?: number | null;
    /**
     * Pause-time in seconds, fractional seconds allowed.
     * @type {number}
     * @memberof Activity
     */
    'pauseTime'?: number | null;
    /**
     * True if this activity is a copy. Either the user manually copied it or postprocessing created a copy because of a conflicting change. Can only be set when the copy is created, cannot be changed later.
     * @type {boolean}
     * @memberof Activity
     */
    'isCopy': boolean;
    /**
     * 
     * @type {ProcessingState}
     * @memberof Activity
     */
    'processingState'?: ProcessingState | null;
    /**
     * 
     * @type {boolean}
     * @memberof Activity
     */
    'completed': boolean;
    /**
     * Products used in this activity. The products must either be global products of the same region as the company that the field/processorder belongs to or a product of that company. Additional region specific rules like matching SGD Version might apply.
     * @type {Array<ActivityProduct>}
     * @memberof Activity
     */
    'products': Array<ActivityProduct>;
    /**
     * Equipment used in this activity. The equipment must belong to the same company as the field/processorder.
     * @type {Array<ActivityEquipment>}
     * @memberof Activity
     */
    'equipment': Array<ActivityEquipment>;
}
/**
 * 
 * @export
 * @interface ActivityBase
 */
export interface ActivityBase {
    /**
     * Unique id of this object.
     * @type {string}
     * @memberof ActivityBase
     */
    'id': string;
    /**
     * Timestamp when this object was deleted, null if object is not deleted.
     * @type {number}
     * @memberof ActivityBase
     */
    'deleted'?: number | null;
    /**
     * Last change timestamp, updated automatically by the backend when object is changed.
     * @type {number}
     * @memberof ActivityBase
     */
    'tstamp'?: number;
    /**
     * Unique ID of the user who processed this activity.
     * @type {string}
     * @memberof ActivityBase
     */
    'userId'?: string | null;
    /**
     * Unique ID of the processorder this activity belongs to.
     * @type {string}
     * @memberof ActivityBase
     */
    'processOrderId': string;
    /**
     * Unique ID of the field this activity was performed on. Must be in the process order referenced by `processOrderId`.
     * @type {string}
     * @memberof ActivityBase
     */
    'fieldId'?: string | null;
    /**
     * Optional user supplied comment. In certain cases system generated comments are appended at the end of this, delimitated by a `|`.
     * @type {string}
     * @memberof ActivityBase
     */
    'userComment'?: string | null;
    /**
     * Date/time when the activity started, unix timestamp.
     * @type {number}
     * @memberof ActivityBase
     */
    'timeStart'?: number | null;
    /**
     * Date/time when the activity ended, unix timestamp.
     * @type {number}
     * @memberof ActivityBase
     */
    'timeFinish'?: number | null;
    /**
     * Date/time when the activity was originally created, unix timestamp.
     * @type {number}
     * @memberof ActivityBase
     */
    'creationDate'?: number | null;
    /**
     * Files referenced by this activity.
     * @type {Array<ActivityFile>}
     * @memberof ActivityBase
     */
    'files'?: Array<ActivityFile> | null;
    /**
     * Unique ID of the track for this activity.
     * @type {string}
     * @memberof ActivityBase
     */
    'trackId'?: string | null;
    /**
     * Unique ID of the activity-type. Must belong to the same company as the process order `processOrderId` and field `fieldId`. The category of the rough activity-type must be `user-allowed`.
     * @type {string}
     * @memberof ActivityBase
     */
    'activityTypeId'?: string;
    /**
     * Processed area in ha.
     * @type {number}
     * @memberof ActivityBase
     */
    'processedArea'?: number | null;
    /**
     * Working width in meters.
     * @type {number}
     * @memberof ActivityBase
     */
    'workingWidth'?: number | null;
    /**
     * Working-time in seconds, fractional seconds allowed.
     * @type {number}
     * @memberof ActivityBase
     */
    'workingTime'?: number | null;
    /**
     * Driving-time in seconds, fractional seconds allowed.
     * @type {number}
     * @memberof ActivityBase
     */
    'drivingTime'?: number | null;
    /**
     * Setup-time in seconds, fractional seconds allowed.
     * @type {number}
     * @memberof ActivityBase
     */
    'setupTime'?: number | null;
    /**
     * Pause-time in seconds, fractional seconds allowed.
     * @type {number}
     * @memberof ActivityBase
     */
    'pauseTime'?: number | null;
    /**
     * True if this activity is a copy. Either the user manually copied it or postprocessing created a copy because of a conflicting change. Can only be set when the copy is created, cannot be changed later.
     * @type {boolean}
     * @memberof ActivityBase
     */
    'isCopy'?: boolean;
    /**
     * 
     * @type {ProcessingState}
     * @memberof ActivityBase
     */
    'processingState'?: ProcessingState | null;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityBase
     */
    'completed'?: boolean;
    /**
     * Products used in this activity. The products must either be global products of the same region as the company that the field/processorder belongs to or a product of that company. Additional region specific rules like matching SGD Version might apply.
     * @type {Array<ActivityProduct>}
     * @memberof ActivityBase
     */
    'products'?: Array<ActivityProduct>;
    /**
     * Equipment used in this activity. The equipment must belong to the same company as the field/processorder.
     * @type {Array<ActivityEquipment>}
     * @memberof ActivityBase
     */
    'equipment'?: Array<ActivityEquipment>;
}
/**
 * 
 * @export
 * @interface ActivityBaseAllOf
 */
export interface ActivityBaseAllOf {
    /**
     * Unique ID of the track for this activity.
     * @type {string}
     * @memberof ActivityBaseAllOf
     */
    'trackId'?: string | null;
    /**
     * Unique ID of the activity-type. Must belong to the same company as the process order `processOrderId` and field `fieldId`. The category of the rough activity-type must be `user-allowed`.
     * @type {string}
     * @memberof ActivityBaseAllOf
     */
    'activityTypeId'?: string;
    /**
     * Processed area in ha.
     * @type {number}
     * @memberof ActivityBaseAllOf
     */
    'processedArea'?: number | null;
    /**
     * Working width in meters.
     * @type {number}
     * @memberof ActivityBaseAllOf
     */
    'workingWidth'?: number | null;
    /**
     * Working-time in seconds, fractional seconds allowed.
     * @type {number}
     * @memberof ActivityBaseAllOf
     */
    'workingTime'?: number | null;
    /**
     * Driving-time in seconds, fractional seconds allowed.
     * @type {number}
     * @memberof ActivityBaseAllOf
     */
    'drivingTime'?: number | null;
    /**
     * Setup-time in seconds, fractional seconds allowed.
     * @type {number}
     * @memberof ActivityBaseAllOf
     */
    'setupTime'?: number | null;
    /**
     * Pause-time in seconds, fractional seconds allowed.
     * @type {number}
     * @memberof ActivityBaseAllOf
     */
    'pauseTime'?: number | null;
    /**
     * True if this activity is a copy. Either the user manually copied it or postprocessing created a copy because of a conflicting change. Can only be set when the copy is created, cannot be changed later.
     * @type {boolean}
     * @memberof ActivityBaseAllOf
     */
    'isCopy'?: boolean;
    /**
     * 
     * @type {ProcessingState}
     * @memberof ActivityBaseAllOf
     */
    'processingState'?: ProcessingState | null;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityBaseAllOf
     */
    'completed'?: boolean;
    /**
     * Products used in this activity. The products must either be global products of the same region as the company that the field/processorder belongs to or a product of that company. Additional region specific rules like matching SGD Version might apply.
     * @type {Array<ActivityProduct>}
     * @memberof ActivityBaseAllOf
     */
    'products'?: Array<ActivityProduct>;
    /**
     * Equipment used in this activity. The equipment must belong to the same company as the field/processorder.
     * @type {Array<ActivityEquipment>}
     * @memberof ActivityBaseAllOf
     */
    'equipment'?: Array<ActivityEquipment>;
}
/**
 * 
 * @export
 * @interface ActivityDelete200Response
 */
export interface ActivityDelete200Response {
    /**
     * 
     * @type {string}
     * @memberof ActivityDelete200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityDelete200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof ActivityDelete200Response
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {Array<Activity>}
     * @memberof ActivityDelete200Response
     */
    'data'?: Array<Activity>;
}
/**
 * 
 * @export
 * @interface ActivityDelete200ResponseAllOf
 */
export interface ActivityDelete200ResponseAllOf {
    /**
     * 
     * @type {Array<Activity>}
     * @memberof ActivityDelete200ResponseAllOf
     */
    'data'?: Array<Activity>;
}
/**
 * 
 * @export
 * @interface ActivityEquipment
 */
export interface ActivityEquipment {
    /**
     * Unique id of this object.
     * @type {string}
     * @memberof ActivityEquipment
     */
    'id': string;
    /**
     * Timestamp when this object was deleted, null if object is not deleted.
     * @type {number}
     * @memberof ActivityEquipment
     */
    'deleted'?: number | null;
    /**
     * Last change timestamp, updated automatically by the backend when object is changed.
     * @type {number}
     * @memberof ActivityEquipment
     */
    'tstamp': number;
    /**
     * 
     * @type {string}
     * @memberof ActivityEquipment
     */
    'equipmentId': string;
}
/**
 * 
 * @export
 * @interface ActivityEquipmentBase
 */
export interface ActivityEquipmentBase {
    /**
     * Unique id of this object.
     * @type {string}
     * @memberof ActivityEquipmentBase
     */
    'id': string;
    /**
     * Timestamp when this object was deleted, null if object is not deleted.
     * @type {number}
     * @memberof ActivityEquipmentBase
     */
    'deleted'?: number | null;
    /**
     * Last change timestamp, updated automatically by the backend when object is changed.
     * @type {number}
     * @memberof ActivityEquipmentBase
     */
    'tstamp'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActivityEquipmentBase
     */
    'equipmentId'?: string;
}
/**
 * 
 * @export
 * @interface ActivityFavouriteProducts200Response
 */
export interface ActivityFavouriteProducts200Response {
    /**
     * 
     * @type {string}
     * @memberof ActivityFavouriteProducts200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityFavouriteProducts200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof ActivityFavouriteProducts200Response
     */
    'developerPayload'?: string;
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof ActivityFavouriteProducts200Response
     */
    'pageKey'?: string;
    /**
     * 
     * @type {Array<FavouriteProducts>}
     * @memberof ActivityFavouriteProducts200Response
     */
    'data'?: Array<FavouriteProducts>;
}
/**
 * 
 * @export
 * @interface ActivityFavouriteProducts200ResponseAllOf
 */
export interface ActivityFavouriteProducts200ResponseAllOf {
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof ActivityFavouriteProducts200ResponseAllOf
     */
    'pageKey'?: string;
    /**
     * 
     * @type {Array<FavouriteProducts>}
     * @memberof ActivityFavouriteProducts200ResponseAllOf
     */
    'data'?: Array<FavouriteProducts>;
}
/**
 * 
 * @export
 * @interface ActivityFile
 */
export interface ActivityFile {
    /**
     * Unique id of this object.
     * @type {string}
     * @memberof ActivityFile
     */
    'id': string;
    /**
     * Timestamp when this object was deleted, null if object is not deleted.
     * @type {number}
     * @memberof ActivityFile
     */
    'deleted'?: number | null;
    /**
     * Last change timestamp, updated automatically by the backend when object is changed.
     * @type {number}
     * @memberof ActivityFile
     */
    'tstamp': number;
    /**
     * 
     * @type {string}
     * @memberof ActivityFile
     */
    'filename': string;
    /**
     * 
     * @type {string}
     * @memberof ActivityFile
     */
    'fileType': string;
    /**
     * 
     * @type {string}
     * @memberof ActivityFile
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface ActivityFileBase
 */
export interface ActivityFileBase {
    /**
     * Unique id of this object.
     * @type {string}
     * @memberof ActivityFileBase
     */
    'id': string;
    /**
     * Timestamp when this object was deleted, null if object is not deleted.
     * @type {number}
     * @memberof ActivityFileBase
     */
    'deleted'?: number | null;
    /**
     * Last change timestamp, updated automatically by the backend when object is changed.
     * @type {number}
     * @memberof ActivityFileBase
     */
    'tstamp'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActivityFileBase
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityFileBase
     */
    'fileType'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityFileBase
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface ActivityImportDeleteObjects200Response
 */
export interface ActivityImportDeleteObjects200Response {
    /**
     * 
     * @type {string}
     * @memberof ActivityImportDeleteObjects200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityImportDeleteObjects200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof ActivityImportDeleteObjects200Response
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {ActivityImportDeleteObjects200ResponseAllOfData}
     * @memberof ActivityImportDeleteObjects200Response
     */
    'data'?: ActivityImportDeleteObjects200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface ActivityImportDeleteObjects200ResponseAllOf
 */
export interface ActivityImportDeleteObjects200ResponseAllOf {
    /**
     * 
     * @type {ActivityImportDeleteObjects200ResponseAllOfData}
     * @memberof ActivityImportDeleteObjects200ResponseAllOf
     */
    'data'?: ActivityImportDeleteObjects200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface ActivityImportDeleteObjects200ResponseAllOfData
 */
export interface ActivityImportDeleteObjects200ResponseAllOfData {
    /**
     * 
     * @type {ActivityImportStatusCode}
     * @memberof ActivityImportDeleteObjects200ResponseAllOfData
     */
    'status': ActivityImportStatusCode;
    /**
     * Information about individual objects in this import session. Can contain errors / warnings for individual import objects.
     * @type {Array<CnhActivityImportObjectInfo>}
     * @memberof ActivityImportDeleteObjects200ResponseAllOfData
     */
    'objects'?: Array<CnhActivityImportObjectInfo> | null;
    /**
     * Progress information if available. Can be displayed to the user.
     * @type {string}
     * @memberof ActivityImportDeleteObjects200ResponseAllOfData
     */
    'progressText'?: string | null;
    /**
     * Progress percentage if available.
     * @type {number}
     * @memberof ActivityImportDeleteObjects200ResponseAllOfData
     */
    'progressPercent'?: number | null;
    /**
     * Generall error message not pertaining to an individual import object.
     * @type {string}
     * @memberof ActivityImportDeleteObjects200ResponseAllOfData
     */
    'errorUserMessage'?: string | null;
    /**
     * CNH Company id.
     * @type {string}
     * @memberof ActivityImportDeleteObjects200ResponseAllOfData
     */
    'cnhCompanyId'?: string;
    /**
     * Task id\'s requested by the user.
     * @type {Array<string>}
     * @memberof ActivityImportDeleteObjects200ResponseAllOfData
     */
    'taskIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ActivityImportDeleteObjectsRequest
 */
export interface ActivityImportDeleteObjectsRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof ActivityImportDeleteObjectsRequest
     */
    'version'?: string;
    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof ActivityImportDeleteObjectsRequest
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {ActivityImportSource}
     * @memberof ActivityImportDeleteObjectsRequest
     */
    'source': ActivityImportSource;
    /**
     * List of objectIds to remove.
     * @type {Array<string>}
     * @memberof ActivityImportDeleteObjectsRequest
     */
    'objectIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ActivityImportDeleteObjectsRequestAllOf
 */
export interface ActivityImportDeleteObjectsRequestAllOf {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof ActivityImportDeleteObjectsRequestAllOf
     */
    'version'?: string;
    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof ActivityImportDeleteObjectsRequestAllOf
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {ActivityImportSource}
     * @memberof ActivityImportDeleteObjectsRequestAllOf
     */
    'source': ActivityImportSource;
    /**
     * List of objectIds to remove.
     * @type {Array<string>}
     * @memberof ActivityImportDeleteObjectsRequestAllOf
     */
    'objectIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ActivityImportMapData200Response
 */
export interface ActivityImportMapData200Response {
    /**
     * 
     * @type {string}
     * @memberof ActivityImportMapData200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityImportMapData200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof ActivityImportMapData200Response
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {Array<ImportActivity>}
     * @memberof ActivityImportMapData200Response
     */
    'data'?: Array<ImportActivity>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ActivityImportMapData200Response
     */
    'errorUserMessages'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ActivityImportMapData200ResponseAllOf
 */
export interface ActivityImportMapData200ResponseAllOf {
    /**
     * 
     * @type {Array<ImportActivity>}
     * @memberof ActivityImportMapData200ResponseAllOf
     */
    'data'?: Array<ImportActivity>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ActivityImportMapData200ResponseAllOf
     */
    'errorUserMessages'?: Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ActivityImportMappedProperty = {
    FieldId: 'fieldId',
    ProductId: 'productId',
    UnitId: 'unitId',
    UserId: 'userId',
    EquipmentId: 'equipmentId',
    ActivityTypeId: 'activityTypeId'
} as const;

export type ActivityImportMappedProperty = typeof ActivityImportMappedProperty[keyof typeof ActivityImportMappedProperty];


/**
 * 
 * @export
 * @interface ActivityImportResetMappingsRequest
 */
export interface ActivityImportResetMappingsRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof ActivityImportResetMappingsRequest
     */
    'version'?: string;
    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof ActivityImportResetMappingsRequest
     */
    'developerPayload'?: string;
    /**
     * The id of the company for which to clear mappings.
     * @type {string}
     * @memberof ActivityImportResetMappingsRequest
     */
    'companyId': string;
}
/**
 * 
 * @export
 * @interface ActivityImportResetMappingsRequestAllOf
 */
export interface ActivityImportResetMappingsRequestAllOf {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof ActivityImportResetMappingsRequestAllOf
     */
    'version'?: string;
    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof ActivityImportResetMappingsRequestAllOf
     */
    'developerPayload'?: string;
    /**
     * The id of the company for which to clear mappings.
     * @type {string}
     * @memberof ActivityImportResetMappingsRequestAllOf
     */
    'companyId': string;
}
/**
 * 
 * @export
 * @interface ActivityImportResetRequest
 */
export interface ActivityImportResetRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof ActivityImportResetRequest
     */
    'version'?: string;
    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof ActivityImportResetRequest
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {ActivityImportSource}
     * @memberof ActivityImportResetRequest
     */
    'source': ActivityImportSource;
}
/**
 * 
 * @export
 * @interface ActivityImportResetRequestAllOf
 */
export interface ActivityImportResetRequestAllOf {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof ActivityImportResetRequestAllOf
     */
    'version'?: string;
    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof ActivityImportResetRequestAllOf
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {ActivityImportSource}
     * @memberof ActivityImportResetRequestAllOf
     */
    'source': ActivityImportSource;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ActivityImportSource = {
    UserUpload: 'UserUpload',
    CnhDownload: 'CnhDownload'
} as const;

export type ActivityImportSource = typeof ActivityImportSource[keyof typeof ActivityImportSource];


/**
 * 
 * @export
 * @interface ActivityImportStatus
 */
export interface ActivityImportStatus {
    /**
     * 
     * @type {ActivityImportStatusCode}
     * @memberof ActivityImportStatus
     */
    'status': ActivityImportStatusCode;
    /**
     * Information about individual objects in this import session, type depends on import source. Can contain errors / warnings for individual import objects.
     * @type {Array<ImportObjectInfo>}
     * @memberof ActivityImportStatus
     */
    'objects'?: Array<ImportObjectInfo> | null;
    /**
     * Progress information if available. Can be displayed to the user.
     * @type {string}
     * @memberof ActivityImportStatus
     */
    'progressText'?: string | null;
    /**
     * Progress percentage if available.
     * @type {number}
     * @memberof ActivityImportStatus
     */
    'progressPercent'?: number | null;
    /**
     * Generall error message not pertaining to an individual import object.
     * @type {string}
     * @memberof ActivityImportStatus
     */
    'errorUserMessage'?: string | null;
}
/**
 * 
 * @export
 * @interface ActivityImportStatus200Response
 */
export interface ActivityImportStatus200Response {
    /**
     * 
     * @type {string}
     * @memberof ActivityImportStatus200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityImportStatus200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof ActivityImportStatus200Response
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {ActivityImportStatus}
     * @memberof ActivityImportStatus200Response
     */
    'data'?: ActivityImportStatus;
}
/**
 * 
 * @export
 * @interface ActivityImportStatus200ResponseAllOf
 */
export interface ActivityImportStatus200ResponseAllOf {
    /**
     * 
     * @type {ActivityImportStatus}
     * @memberof ActivityImportStatus200ResponseAllOf
     */
    'data'?: ActivityImportStatus;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ActivityImportStatusCode = {
    Empty: 'Empty',
    Ready: 'Ready',
    PartialError: 'PartialError',
    Error: 'Error',
    Pending: 'Pending',
    Canceling: 'Canceling'
} as const;

export type ActivityImportStatusCode = typeof ActivityImportStatusCode[keyof typeof ActivityImportStatusCode];


/**
 * 
 * @export
 * @interface ActivityImportStoreMappings200Response
 */
export interface ActivityImportStoreMappings200Response {
    /**
     * 
     * @type {string}
     * @memberof ActivityImportStoreMappings200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityImportStoreMappings200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof ActivityImportStoreMappings200Response
     */
    'developerPayload'?: string;
}
/**
 * 
 * @export
 * @interface ActivityImportStoreMappingsRequest
 */
export interface ActivityImportStoreMappingsRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof ActivityImportStoreMappingsRequest
     */
    'version'?: string;
    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof ActivityImportStoreMappingsRequest
     */
    'developerPayload'?: string;
    /**
     * The id of the company for which to store a mapping.
     * @type {string}
     * @memberof ActivityImportStoreMappingsRequest
     */
    'companyId': string;
    /**
     * 
     * @type {Array<ActivityImportStoreMappingsRequestAllOfMappingsInner>}
     * @memberof ActivityImportStoreMappingsRequest
     */
    'mappings': Array<ActivityImportStoreMappingsRequestAllOfMappingsInner>;
}
/**
 * 
 * @export
 * @interface ActivityImportStoreMappingsRequestAllOf
 */
export interface ActivityImportStoreMappingsRequestAllOf {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof ActivityImportStoreMappingsRequestAllOf
     */
    'version'?: string;
    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof ActivityImportStoreMappingsRequestAllOf
     */
    'developerPayload'?: string;
    /**
     * The id of the company for which to store a mapping.
     * @type {string}
     * @memberof ActivityImportStoreMappingsRequestAllOf
     */
    'companyId': string;
    /**
     * 
     * @type {Array<ActivityImportStoreMappingsRequestAllOfMappingsInner>}
     * @memberof ActivityImportStoreMappingsRequestAllOf
     */
    'mappings': Array<ActivityImportStoreMappingsRequestAllOfMappingsInner>;
}
/**
 * 
 * @export
 * @interface ActivityImportStoreMappingsRequestAllOfMappingsInner
 */
export interface ActivityImportStoreMappingsRequestAllOfMappingsInner {
    /**
     * External key for which to map a farmdok object.
     * @type {string}
     * @memberof ActivityImportStoreMappingsRequestAllOfMappingsInner
     */
    'externalKey': string;
    /**
     * 
     * @type {ActivityImportMappedProperty}
     * @memberof ActivityImportStoreMappingsRequestAllOfMappingsInner
     */
    'mappedProperty': ActivityImportMappedProperty;
    /**
     * Id of the farmdok object to map to the external key.
     * @type {string}
     * @memberof ActivityImportStoreMappingsRequestAllOfMappingsInner
     */
    'farmdokId': string;
}
/**
 * 
 * @export
 * @interface ActivityImportUpload200Response
 */
export interface ActivityImportUpload200Response {
    /**
     * 
     * @type {string}
     * @memberof ActivityImportUpload200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityImportUpload200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof ActivityImportUpload200Response
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {ActivityImportUpload200ResponseAllOfData}
     * @memberof ActivityImportUpload200Response
     */
    'data'?: ActivityImportUpload200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface ActivityImportUpload200ResponseAllOf
 */
export interface ActivityImportUpload200ResponseAllOf {
    /**
     * 
     * @type {ActivityImportUpload200ResponseAllOfData}
     * @memberof ActivityImportUpload200ResponseAllOf
     */
    'data'?: ActivityImportUpload200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface ActivityImportUpload200ResponseAllOfData
 */
export interface ActivityImportUpload200ResponseAllOfData {
    /**
     * 
     * @type {ActivityImportStatusCode}
     * @memberof ActivityImportUpload200ResponseAllOfData
     */
    'status': ActivityImportStatusCode;
    /**
     * Information about individual objects in this import session, type depends on import source. Can contain errors / warnings for individual import objects.
     * @type {Array<ImportObjectInfo>}
     * @memberof ActivityImportUpload200ResponseAllOfData
     */
    'objects'?: Array<ImportObjectInfo> | null;
    /**
     * Progress information if available. Can be displayed to the user.
     * @type {string}
     * @memberof ActivityImportUpload200ResponseAllOfData
     */
    'progressText'?: string | null;
    /**
     * Progress percentage if available.
     * @type {number}
     * @memberof ActivityImportUpload200ResponseAllOfData
     */
    'progressPercent'?: number | null;
    /**
     * Generall error message not pertaining to an individual import object.
     * @type {string}
     * @memberof ActivityImportUpload200ResponseAllOfData
     */
    'errorUserMessage'?: string | null;
}
/**
 * 
 * @export
 * @interface ActivityLastUsedProductAmount200Response
 */
export interface ActivityLastUsedProductAmount200Response {
    /**
     * 
     * @type {string}
     * @memberof ActivityLastUsedProductAmount200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLastUsedProductAmount200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof ActivityLastUsedProductAmount200Response
     */
    'developerPayload'?: string;
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof ActivityLastUsedProductAmount200Response
     */
    'pageKey'?: string;
    /**
     * 
     * @type {Array<LastUsedProductAmount>}
     * @memberof ActivityLastUsedProductAmount200Response
     */
    'data'?: Array<LastUsedProductAmount>;
}
/**
 * 
 * @export
 * @interface ActivityLastUsedProductAmount200ResponseAllOf
 */
export interface ActivityLastUsedProductAmount200ResponseAllOf {
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof ActivityLastUsedProductAmount200ResponseAllOf
     */
    'pageKey'?: string;
    /**
     * 
     * @type {Array<LastUsedProductAmount>}
     * @memberof ActivityLastUsedProductAmount200ResponseAllOf
     */
    'data'?: Array<LastUsedProductAmount>;
}
/**
 * 
 * @export
 * @interface ActivityMoveToProcessOrder200Response
 */
export interface ActivityMoveToProcessOrder200Response {
    /**
     * 
     * @type {string}
     * @memberof ActivityMoveToProcessOrder200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityMoveToProcessOrder200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof ActivityMoveToProcessOrder200Response
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {Array<MoveActivityResponse>}
     * @memberof ActivityMoveToProcessOrder200Response
     */
    'data'?: Array<MoveActivityResponse>;
}
/**
 * 
 * @export
 * @interface ActivityMoveToProcessOrder200ResponseAllOf
 */
export interface ActivityMoveToProcessOrder200ResponseAllOf {
    /**
     * 
     * @type {Array<MoveActivityResponse>}
     * @memberof ActivityMoveToProcessOrder200ResponseAllOf
     */
    'data'?: Array<MoveActivityResponse>;
}
/**
 * 
 * @export
 * @interface ActivityMoveToProcessOrderRequest
 */
export interface ActivityMoveToProcessOrderRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof ActivityMoveToProcessOrderRequest
     */
    'version'?: string;
    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof ActivityMoveToProcessOrderRequest
     */
    'developerPayload'?: string;
    /**
     * The id of the process order into which the activities should be moved.
     * @type {string}
     * @memberof ActivityMoveToProcessOrderRequest
     */
    'destinationProcessOrderId': string;
    /**
     * Move parameters for each activity to be moved.
     * @type {Array<MoveActivityParameters>}
     * @memberof ActivityMoveToProcessOrderRequest
     */
    'activities': Array<MoveActivityParameters>;
}
/**
 * 
 * @export
 * @interface ActivityMoveToProcessOrderRequestAllOf
 */
export interface ActivityMoveToProcessOrderRequestAllOf {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof ActivityMoveToProcessOrderRequestAllOf
     */
    'version'?: string;
    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof ActivityMoveToProcessOrderRequestAllOf
     */
    'developerPayload'?: string;
    /**
     * The id of the process order into which the activities should be moved.
     * @type {string}
     * @memberof ActivityMoveToProcessOrderRequestAllOf
     */
    'destinationProcessOrderId': string;
    /**
     * Move parameters for each activity to be moved.
     * @type {Array<MoveActivityParameters>}
     * @memberof ActivityMoveToProcessOrderRequestAllOf
     */
    'activities': Array<MoveActivityParameters>;
}
/**
 * 
 * @export
 * @interface ActivityObjects200Response
 */
export interface ActivityObjects200Response {
    /**
     * 
     * @type {string}
     * @memberof ActivityObjects200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityObjects200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof ActivityObjects200Response
     */
    'developerPayload'?: string;
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof ActivityObjects200Response
     */
    'pageKey'?: string;
    /**
     * 
     * @type {Array<ActivityBase>}
     * @memberof ActivityObjects200Response
     */
    'data'?: Array<ActivityBase>;
}
/**
 * 
 * @export
 * @interface ActivityObjects200ResponseAllOf
 */
export interface ActivityObjects200ResponseAllOf {
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof ActivityObjects200ResponseAllOf
     */
    'pageKey'?: string;
    /**
     * 
     * @type {Array<ActivityBase>}
     * @memberof ActivityObjects200ResponseAllOf
     */
    'data'?: Array<ActivityBase>;
}
/**
 * 
 * @export
 * @interface ActivityPatchRequest
 */
export interface ActivityPatchRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof ActivityPatchRequest
     */
    'version'?: string;
    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof ActivityPatchRequest
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {Array<ActivityBase>}
     * @memberof ActivityPatchRequest
     */
    'data': Array<ActivityBase>;
}
/**
 * 
 * @export
 * @interface ActivityProduct
 */
export interface ActivityProduct {
    /**
     * Unique id of this object.
     * @type {string}
     * @memberof ActivityProduct
     */
    'id': string;
    /**
     * Timestamp when this object was deleted, null if object is not deleted.
     * @type {number}
     * @memberof ActivityProduct
     */
    'deleted'?: number | null;
    /**
     * Last change timestamp, updated automatically by the backend when object is changed.
     * @type {number}
     * @memberof ActivityProduct
     */
    'tstamp': number;
    /**
     * Unique ID of the referenced product. Must be either a global product or belong to the same company as the field of the activity.
     * @type {string}
     * @memberof ActivityProduct
     */
    'productId': string;
    /**
     * Unique ID of the storage-place from which the product is taken. Null if the product wasn\'t take from a specific storage place. The product must actually be stored in the specified storage place, meaning that only combinations of $productId and $storagePlaceId that have a corresponding ProductStoragePlace entry are valid.
     * @type {string}
     * @memberof ActivityProduct
     */
    'storagePlaceId'?: string | null;
    /**
     * The amount in `unitId` units. If `unitId` references a \'per-area\' unit then the total amount is calculated as amount times the processedArea of the activity. \'per-load\' units are not allowed for activity products.
     * @type {number}
     * @memberof ActivityProduct
     */
    'amount'?: number | null;
    /**
     * Unique ID of the unit for `amount`. Which units are allowable depends on the product selected and the company-region.
     * @type {string}
     * @memberof ActivityProduct
     */
    'unitId'?: string | null;
    /**
     * Unique ID of the pesticide-indication for this product, must be set together with `pestId`. The pesticide-indication must be for the same pesticide referenced by `productId`.
     * @type {string}
     * @memberof ActivityProduct
     */
    'pesticideIndicationId'?: string | null;
    /**
     * Unique ID of the pest, must be one of the pests listed in the pesticide-indication referenced by `pesticideIndicationId`.
     * @type {string}
     * @memberof ActivityProduct
     */
    'pestId'?: string | null;
}
/**
 * 
 * @export
 * @interface ActivityProductBase
 */
export interface ActivityProductBase {
    /**
     * Unique id of this object.
     * @type {string}
     * @memberof ActivityProductBase
     */
    'id': string;
    /**
     * Timestamp when this object was deleted, null if object is not deleted.
     * @type {number}
     * @memberof ActivityProductBase
     */
    'deleted'?: number | null;
    /**
     * Last change timestamp, updated automatically by the backend when object is changed.
     * @type {number}
     * @memberof ActivityProductBase
     */
    'tstamp'?: number;
    /**
     * Unique ID of the referenced product. Must be either a global product or belong to the same company as the field of the activity.
     * @type {string}
     * @memberof ActivityProductBase
     */
    'productId'?: string;
    /**
     * Unique ID of the storage-place from which the product is taken. Null if the product wasn\'t take from a specific storage place. The product must actually be stored in the specified storage place, meaning that only combinations of $productId and $storagePlaceId that have a corresponding ProductStoragePlace entry are valid.
     * @type {string}
     * @memberof ActivityProductBase
     */
    'storagePlaceId'?: string | null;
    /**
     * The amount in `unitId` units. If `unitId` references a \'per-area\' unit then the total amount is calculated as amount times the processedArea of the activity. \'per-load\' units are not allowed for activity products.
     * @type {number}
     * @memberof ActivityProductBase
     */
    'amount'?: number | null;
    /**
     * Unique ID of the unit for `amount`. Which units are allowable depends on the product selected and the company-region.
     * @type {string}
     * @memberof ActivityProductBase
     */
    'unitId'?: string | null;
    /**
     * Unique ID of the pesticide-indication for this product, must be set together with `pestId`. The pesticide-indication must be for the same pesticide referenced by `productId`.
     * @type {string}
     * @memberof ActivityProductBase
     */
    'pesticideIndicationId'?: string | null;
    /**
     * Unique ID of the pest, must be one of the pests listed in the pesticide-indication referenced by `pesticideIndicationId`.
     * @type {string}
     * @memberof ActivityProductBase
     */
    'pestId'?: string | null;
}
/**
 * 
 * @export
 * @interface ActivityPut200Response
 */
export interface ActivityPut200Response {
    /**
     * 
     * @type {string}
     * @memberof ActivityPut200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityPut200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof ActivityPut200Response
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {Array<Activity>}
     * @memberof ActivityPut200Response
     */
    'data': Array<Activity>;
    /**
     * 
     * @type {{ [key: string]: ActivityPut200ResponseAllOfErrorsValue; }}
     * @memberof ActivityPut200Response
     */
    'errors'?: { [key: string]: ActivityPut200ResponseAllOfErrorsValue; };
}
/**
 * 
 * @export
 * @interface ActivityPut200ResponseAllOf
 */
export interface ActivityPut200ResponseAllOf {
    /**
     * 
     * @type {Array<Activity>}
     * @memberof ActivityPut200ResponseAllOf
     */
    'data': Array<Activity>;
    /**
     * 
     * @type {{ [key: string]: ActivityPut200ResponseAllOfErrorsValue; }}
     * @memberof ActivityPut200ResponseAllOf
     */
    'errors'?: { [key: string]: ActivityPut200ResponseAllOfErrorsValue; };
}
/**
 * 
 * @export
 * @interface ActivityPut200ResponseAllOfErrorsValue
 */
export interface ActivityPut200ResponseAllOfErrorsValue {
    /**
     * 
     * @type {string}
     * @memberof ActivityPut200ResponseAllOfErrorsValue
     */
    'errorCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityPut200ResponseAllOfErrorsValue
     */
    'errorUserMessage'?: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ActivityPut200ResponseAllOfErrorsValue
     */
    'errorAttributeUserMessages'?: { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @interface ActivityPut200ResponseAllOfErrorsValueAnyOf
 */
export interface ActivityPut200ResponseAllOfErrorsValueAnyOf {
    /**
     * 
     * @type {string}
     * @memberof ActivityPut200ResponseAllOfErrorsValueAnyOf
     */
    'errorCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityPut200ResponseAllOfErrorsValueAnyOf
     */
    'errorUserMessage'?: string;
}
/**
 * 
 * @export
 * @interface ActivityPut200ResponseAllOfErrorsValueAnyOf1
 */
export interface ActivityPut200ResponseAllOfErrorsValueAnyOf1 {
    /**
     * 
     * @type {string}
     * @memberof ActivityPut200ResponseAllOfErrorsValueAnyOf1
     */
    'errorCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityPut200ResponseAllOfErrorsValueAnyOf1
     */
    'errorUserMessage'?: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ActivityPut200ResponseAllOfErrorsValueAnyOf1
     */
    'errorAttributeUserMessages'?: { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @interface ActivityPutRequest
 */
export interface ActivityPutRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof ActivityPutRequest
     */
    'version'?: string;
    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof ActivityPutRequest
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {Array<Activity>}
     * @memberof ActivityPutRequest
     */
    'data': Array<Activity>;
}
/**
 * 
 * @export
 * @interface ActivityRuleViolation
 */
export interface ActivityRuleViolation {
    /**
     * Unique id of this object.
     * @type {string}
     * @memberof ActivityRuleViolation
     */
    'id': string;
    /**
     * Timestamp when this object was deleted, null if object is not deleted.
     * @type {number}
     * @memberof ActivityRuleViolation
     */
    'deleted'?: number | null;
    /**
     * Last change timestamp, updated automatically by the backend when object is changed.
     * @type {number}
     * @memberof ActivityRuleViolation
     */
    'tstamp': number;
    /**
     * 
     * @type {string}
     * @memberof ActivityRuleViolation
     */
    'activityId': string;
    /**
     * 
     * @type {string}
     * @memberof ActivityRuleViolation
     */
    'activityProductId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActivityRuleViolation
     */
    'ruleId': string;
    /**
     * 
     * @type {RuleViolationType}
     * @memberof ActivityRuleViolation
     */
    'type': RuleViolationType;
    /**
     * A Key/Value list of properties used to format the rule violation text. Used with `Rule.longMessage` and `Rule.parameterNames`.
     * @type {{ [key: string]: any; }}
     * @memberof ActivityRuleViolation
     */
    'violationData': { [key: string]: any; };
}
/**
 * 
 * @export
 * @interface ActivityRuleViolationBase
 */
export interface ActivityRuleViolationBase {
    /**
     * Unique id of this object.
     * @type {string}
     * @memberof ActivityRuleViolationBase
     */
    'id': string;
    /**
     * Timestamp when this object was deleted, null if object is not deleted.
     * @type {number}
     * @memberof ActivityRuleViolationBase
     */
    'deleted'?: number | null;
    /**
     * Last change timestamp, updated automatically by the backend when object is changed.
     * @type {number}
     * @memberof ActivityRuleViolationBase
     */
    'tstamp'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActivityRuleViolationBase
     */
    'activityId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityRuleViolationBase
     */
    'activityProductId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActivityRuleViolationBase
     */
    'ruleId'?: string;
    /**
     * 
     * @type {RuleViolationType}
     * @memberof ActivityRuleViolationBase
     */
    'type'?: RuleViolationType;
    /**
     * A Key/Value list of properties used to format the rule violation text. Used with `Rule.longMessage` and `Rule.parameterNames`.
     * @type {{ [key: string]: any; }}
     * @memberof ActivityRuleViolationBase
     */
    'violationData'?: { [key: string]: any; };
}
/**
 * 
 * @export
 * @interface ActivityUndelete200Response
 */
export interface ActivityUndelete200Response {
    /**
     * 
     * @type {string}
     * @memberof ActivityUndelete200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityUndelete200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof ActivityUndelete200Response
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {Array<Activity>}
     * @memberof ActivityUndelete200Response
     */
    'data'?: Array<Activity>;
    /**
     * 
     * @type {{ [key: string]: ActivityPut200ResponseAllOfErrorsValue; }}
     * @memberof ActivityUndelete200Response
     */
    'errors'?: { [key: string]: ActivityPut200ResponseAllOfErrorsValue; };
}
/**
 * 
 * @export
 * @interface ActivityUndelete200ResponseAllOf
 */
export interface ActivityUndelete200ResponseAllOf {
    /**
     * 
     * @type {Array<Activity>}
     * @memberof ActivityUndelete200ResponseAllOf
     */
    'data'?: Array<Activity>;
    /**
     * 
     * @type {{ [key: string]: ActivityPut200ResponseAllOfErrorsValue; }}
     * @memberof ActivityUndelete200ResponseAllOf
     */
    'errors'?: { [key: string]: ActivityPut200ResponseAllOfErrorsValue; };
}
/**
 * 
 * @export
 * @interface ApplicationMapsTerrazoFieldTimestamp200Response
 */
export interface ApplicationMapsTerrazoFieldTimestamp200Response {
    /**
     * 
     * @type {string}
     * @memberof ApplicationMapsTerrazoFieldTimestamp200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationMapsTerrazoFieldTimestamp200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof ApplicationMapsTerrazoFieldTimestamp200Response
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {Array<TerrazoFieldTimestampEntry>}
     * @memberof ApplicationMapsTerrazoFieldTimestamp200Response
     */
    'data'?: Array<TerrazoFieldTimestampEntry>;
}
/**
 * 
 * @export
 * @interface ApplicationMapsTerrazoFieldTimestamp200ResponseAllOf
 */
export interface ApplicationMapsTerrazoFieldTimestamp200ResponseAllOf {
    /**
     * 
     * @type {Array<TerrazoFieldTimestampEntry>}
     * @memberof ApplicationMapsTerrazoFieldTimestamp200ResponseAllOf
     */
    'data'?: Array<TerrazoFieldTimestampEntry>;
}
/**
 * 
 * @export
 * @interface ApplicationMapsTerrazoMap200Response
 */
export interface ApplicationMapsTerrazoMap200Response {
    /**
     * 
     * @type {string}
     * @memberof ApplicationMapsTerrazoMap200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationMapsTerrazoMap200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof ApplicationMapsTerrazoMap200Response
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {ApplicationMapsTerrazoMap200ResponseAllOfData}
     * @memberof ApplicationMapsTerrazoMap200Response
     */
    'data'?: ApplicationMapsTerrazoMap200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface ApplicationMapsTerrazoMap200ResponseAllOf
 */
export interface ApplicationMapsTerrazoMap200ResponseAllOf {
    /**
     * 
     * @type {ApplicationMapsTerrazoMap200ResponseAllOfData}
     * @memberof ApplicationMapsTerrazoMap200ResponseAllOf
     */
    'data'?: ApplicationMapsTerrazoMap200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface ApplicationMapsTerrazoMap200ResponseAllOfData
 */
export interface ApplicationMapsTerrazoMap200ResponseAllOfData {
    /**
     * 
     * @type {string}
     * @memberof ApplicationMapsTerrazoMap200ResponseAllOfData
     */
    'type': string;
    /**
     * 
     * @type {ApplicationMapsTerrazoMap200ResponseAllOfDataMeta}
     * @memberof ApplicationMapsTerrazoMap200ResponseAllOfData
     */
    'meta'?: ApplicationMapsTerrazoMap200ResponseAllOfDataMeta;
    /**
     * 
     * @type {Array<ApplicationMapsTerrazoMap200ResponseAllOfDataFeaturesInner>}
     * @memberof ApplicationMapsTerrazoMap200ResponseAllOfData
     */
    'features': Array<ApplicationMapsTerrazoMap200ResponseAllOfDataFeaturesInner>;
}
/**
 * 
 * @export
 * @interface ApplicationMapsTerrazoMap200ResponseAllOfDataFeaturesInner
 */
export interface ApplicationMapsTerrazoMap200ResponseAllOfDataFeaturesInner {
    /**
     * 
     * @type {string}
     * @memberof ApplicationMapsTerrazoMap200ResponseAllOfDataFeaturesInner
     */
    'type': string;
    /**
     * 
     * @type {ApplicationMapsTerrazoMap200ResponseAllOfDataFeaturesInnerProperties}
     * @memberof ApplicationMapsTerrazoMap200ResponseAllOfDataFeaturesInner
     */
    'properties': ApplicationMapsTerrazoMap200ResponseAllOfDataFeaturesInnerProperties;
    /**
     * 
     * @type {ApplicationMapsTerrazoMap200ResponseAllOfDataFeaturesInnerGeometry}
     * @memberof ApplicationMapsTerrazoMap200ResponseAllOfDataFeaturesInner
     */
    'geometry': ApplicationMapsTerrazoMap200ResponseAllOfDataFeaturesInnerGeometry;
}
/**
 * 
 * @export
 * @interface ApplicationMapsTerrazoMap200ResponseAllOfDataFeaturesInnerGeometry
 */
export interface ApplicationMapsTerrazoMap200ResponseAllOfDataFeaturesInnerGeometry {
    /**
     * 
     * @type {string}
     * @memberof ApplicationMapsTerrazoMap200ResponseAllOfDataFeaturesInnerGeometry
     */
    'type': string;
    /**
     * 
     * @type {Array<Array<Array<number>>>}
     * @memberof ApplicationMapsTerrazoMap200ResponseAllOfDataFeaturesInnerGeometry
     */
    'coordinates': Array<Array<Array<number>>>;
}
/**
 * 
 * @export
 * @interface ApplicationMapsTerrazoMap200ResponseAllOfDataFeaturesInnerProperties
 */
export interface ApplicationMapsTerrazoMap200ResponseAllOfDataFeaturesInnerProperties {
    /**
     * 
     * @type {string}
     * @memberof ApplicationMapsTerrazoMap200ResponseAllOfDataFeaturesInnerProperties
     */
    'NDVI'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationMapsTerrazoMap200ResponseAllOfDataFeaturesInnerProperties
     */
    'stroke-opacity'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationMapsTerrazoMap200ResponseAllOfDataFeaturesInnerProperties
     */
    'fill'?: string;
    /**
     * Only in `mapType` == `ZONE_MAP`
     * @type {string}
     * @memberof ApplicationMapsTerrazoMap200ResponseAllOfDataFeaturesInnerProperties
     */
    'ZONE'?: string;
    /**
     * Only in `mapType` == `ZONE_MAP`
     * @type {string}
     * @memberof ApplicationMapsTerrazoMap200ResponseAllOfDataFeaturesInnerProperties
     */
    'ZONE_MAX_NDVI'?: string;
    /**
     * Only in `mapType` == `ZONE_MAP`
     * @type {string}
     * @memberof ApplicationMapsTerrazoMap200ResponseAllOfDataFeaturesInnerProperties
     */
    'ZONE_MIN_NDVI'?: string;
}
/**
 * 
 * @export
 * @interface ApplicationMapsTerrazoMap200ResponseAllOfDataMeta
 */
export interface ApplicationMapsTerrazoMap200ResponseAllOfDataMeta {
    /**
     * 
     * @type {Array<ApplicationMapsTerrazoMap200ResponseAllOfDataMetaZonesInner>}
     * @memberof ApplicationMapsTerrazoMap200ResponseAllOfDataMeta
     */
    'zones'?: Array<ApplicationMapsTerrazoMap200ResponseAllOfDataMetaZonesInner>;
}
/**
 * 
 * @export
 * @interface ApplicationMapsTerrazoMap200ResponseAllOfDataMetaZonesInner
 */
export interface ApplicationMapsTerrazoMap200ResponseAllOfDataMetaZonesInner {
    /**
     * 
     * @type {number}
     * @memberof ApplicationMapsTerrazoMap200ResponseAllOfDataMetaZonesInner
     */
    'index'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationMapsTerrazoMap200ResponseAllOfDataMetaZonesInner
     */
    'min'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationMapsTerrazoMap200ResponseAllOfDataMetaZonesInner
     */
    'avg'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationMapsTerrazoMap200ResponseAllOfDataMetaZonesInner
     */
    'max'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationMapsTerrazoMap200ResponseAllOfDataMetaZonesInner
     */
    'nRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationMapsTerrazoMap200ResponseAllOfDataMetaZonesInner
     */
    'area'?: number;
}
/**
 * 
 * @export
 * @interface BaseActivity
 */
export interface BaseActivity {
    /**
     * Unique id of this object.
     * @type {string}
     * @memberof BaseActivity
     */
    'id': string;
    /**
     * Timestamp when this object was deleted, null if object is not deleted.
     * @type {number}
     * @memberof BaseActivity
     */
    'deleted'?: number | null;
    /**
     * Last change timestamp, updated automatically by the backend when object is changed.
     * @type {number}
     * @memberof BaseActivity
     */
    'tstamp': number;
    /**
     * Unique ID of the user who processed this activity.
     * @type {string}
     * @memberof BaseActivity
     */
    'userId'?: string | null;
    /**
     * Unique ID of the processorder this activity belongs to.
     * @type {string}
     * @memberof BaseActivity
     */
    'processOrderId': string;
    /**
     * Unique ID of the field this activity was performed on. Must be in the process order referenced by `processOrderId`.
     * @type {string}
     * @memberof BaseActivity
     */
    'fieldId'?: string | null;
    /**
     * Optional user supplied comment. In certain cases system generated comments are appended at the end of this, delimitated by a `|`.
     * @type {string}
     * @memberof BaseActivity
     */
    'userComment'?: string | null;
    /**
     * Date/time when the activity started, unix timestamp.
     * @type {number}
     * @memberof BaseActivity
     */
    'timeStart'?: number | null;
    /**
     * Date/time when the activity ended, unix timestamp.
     * @type {number}
     * @memberof BaseActivity
     */
    'timeFinish'?: number | null;
    /**
     * Date/time when the activity was originally created, unix timestamp.
     * @type {number}
     * @memberof BaseActivity
     */
    'creationDate'?: number | null;
    /**
     * Files referenced by this activity.
     * @type {Array<ActivityFile>}
     * @memberof BaseActivity
     */
    'files'?: Array<ActivityFile> | null;
}
/**
 * 
 * @export
 * @interface BaseActivityBase
 */
export interface BaseActivityBase {
    /**
     * Unique id of this object.
     * @type {string}
     * @memberof BaseActivityBase
     */
    'id': string;
    /**
     * Timestamp when this object was deleted, null if object is not deleted.
     * @type {number}
     * @memberof BaseActivityBase
     */
    'deleted'?: number | null;
    /**
     * Last change timestamp, updated automatically by the backend when object is changed.
     * @type {number}
     * @memberof BaseActivityBase
     */
    'tstamp'?: number;
    /**
     * Unique ID of the user who processed this activity.
     * @type {string}
     * @memberof BaseActivityBase
     */
    'userId'?: string | null;
    /**
     * Unique ID of the processorder this activity belongs to.
     * @type {string}
     * @memberof BaseActivityBase
     */
    'processOrderId': string;
    /**
     * Unique ID of the field this activity was performed on. Must be in the process order referenced by `processOrderId`.
     * @type {string}
     * @memberof BaseActivityBase
     */
    'fieldId'?: string | null;
    /**
     * Optional user supplied comment. In certain cases system generated comments are appended at the end of this, delimitated by a `|`.
     * @type {string}
     * @memberof BaseActivityBase
     */
    'userComment'?: string | null;
    /**
     * Date/time when the activity started, unix timestamp.
     * @type {number}
     * @memberof BaseActivityBase
     */
    'timeStart'?: number | null;
    /**
     * Date/time when the activity ended, unix timestamp.
     * @type {number}
     * @memberof BaseActivityBase
     */
    'timeFinish'?: number | null;
    /**
     * Date/time when the activity was originally created, unix timestamp.
     * @type {number}
     * @memberof BaseActivityBase
     */
    'creationDate'?: number | null;
    /**
     * Files referenced by this activity.
     * @type {Array<ActivityFile>}
     * @memberof BaseActivityBase
     */
    'files'?: Array<ActivityFile> | null;
}
/**
 * 
 * @export
 * @interface CnhActivityImportObjectInfo
 */
export interface CnhActivityImportObjectInfo {
    /**
     * 
     * @type {string}
     * @memberof CnhActivityImportObjectInfo
     */
    'id': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CnhActivityImportObjectInfo
     */
    'errorUserMessages'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CnhActivityImportObjectInfo
     */
    'warningUserMessages'?: Array<string>;
    /**
     * The name for an import object, for example the original filename.
     * @type {string}
     * @memberof CnhActivityImportObjectInfo
     */
    'name'?: string | null;
    /**
     * Currently unused, will hold information for individual parts of multipart objects like shape files.
     * @type {Array<any>}
     * @memberof CnhActivityImportObjectInfo
     */
    'parts'?: Array<any>;
    /**
     * CNH file id.
     * @type {string}
     * @memberof CnhActivityImportObjectInfo
     */
    'fileId'?: string | null;
    /**
     * CNH task id.
     * @type {string}
     * @memberof CnhActivityImportObjectInfo
     */
    'taskId'?: string | null;
}
/**
 * 
 * @export
 * @interface CnhActivityImportObjectInfoAllOf
 */
export interface CnhActivityImportObjectInfoAllOf {
    /**
     * CNH file id.
     * @type {string}
     * @memberof CnhActivityImportObjectInfoAllOf
     */
    'fileId'?: string | null;
    /**
     * CNH task id.
     * @type {string}
     * @memberof CnhActivityImportObjectInfoAllOf
     */
    'taskId'?: string | null;
}
/**
 * 
 * @export
 * @interface CnhActivityImportStatus
 */
export interface CnhActivityImportStatus {
    /**
     * 
     * @type {ActivityImportStatusCode}
     * @memberof CnhActivityImportStatus
     */
    'status': ActivityImportStatusCode;
    /**
     * Information about individual objects in this import session. Can contain errors / warnings for individual import objects.
     * @type {Array<CnhActivityImportObjectInfo>}
     * @memberof CnhActivityImportStatus
     */
    'objects'?: Array<CnhActivityImportObjectInfo> | null;
    /**
     * Progress information if available. Can be displayed to the user.
     * @type {string}
     * @memberof CnhActivityImportStatus
     */
    'progressText'?: string | null;
    /**
     * Progress percentage if available.
     * @type {number}
     * @memberof CnhActivityImportStatus
     */
    'progressPercent'?: number | null;
    /**
     * Generall error message not pertaining to an individual import object.
     * @type {string}
     * @memberof CnhActivityImportStatus
     */
    'errorUserMessage'?: string | null;
    /**
     * CNH Company id.
     * @type {string}
     * @memberof CnhActivityImportStatus
     */
    'cnhCompanyId'?: string;
    /**
     * Task id\'s requested by the user.
     * @type {Array<string>}
     * @memberof CnhActivityImportStatus
     */
    'taskIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface CnhActivityImportStatusAllOf
 */
export interface CnhActivityImportStatusAllOf {
    /**
     * Information about individual objects in this import session. Can contain errors / warnings for individual import objects.
     * @type {Array<CnhActivityImportObjectInfo>}
     * @memberof CnhActivityImportStatusAllOf
     */
    'objects'?: Array<CnhActivityImportObjectInfo> | null;
    /**
     * CNH Company id.
     * @type {string}
     * @memberof CnhActivityImportStatusAllOf
     */
    'cnhCompanyId'?: string;
    /**
     * Task id\'s requested by the user.
     * @type {Array<string>}
     * @memberof CnhActivityImportStatusAllOf
     */
    'taskIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface CnhCompanyOverview
 */
export interface CnhCompanyOverview {
    /**
     * Company id
     * @type {string}
     * @memberof CnhCompanyOverview
     */
    'id': string;
    /**
     * Company Name
     * @type {string}
     * @memberof CnhCompanyOverview
     */
    'name': string;
    /**
     * Growers for this company
     * @type {Array<CnhGrowerOverview>}
     * @memberof CnhCompanyOverview
     */
    'growers': Array<CnhGrowerOverview>;
}
/**
 * 
 * @export
 * @interface CnhConnectionStatus
 */
export interface CnhConnectionStatus {
    /**
     * True if a valid login to CNH exists for the current user
     * @type {boolean}
     * @memberof CnhConnectionStatus
     */
    'loggedIn': boolean;
    /**
     * 
     * @type {number}
     * @memberof CnhConnectionStatus
     */
    'loginTimestamp'?: number;
    /**
     * Returns CNH username if a user is logged in
     * @type {string}
     * @memberof CnhConnectionStatus
     */
    'userName'?: string;
    /**
     * Returns CNH user email if a user is logged in
     * @type {string}
     * @memberof CnhConnectionStatus
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface CnhFarmOverview
 */
export interface CnhFarmOverview {
    /**
     * Farm id
     * @type {string}
     * @memberof CnhFarmOverview
     */
    'id': string;
    /**
     * Farm name
     * @type {string}
     * @memberof CnhFarmOverview
     */
    'name': string;
    /**
     * Fields for this farm
     * @type {Array<CnhFieldOverview>}
     * @memberof CnhFarmOverview
     */
    'fields': Array<CnhFieldOverview>;
}
/**
 * 
 * @export
 * @interface CnhFieldOverview
 */
export interface CnhFieldOverview {
    /**
     * Field id
     * @type {string}
     * @memberof CnhFieldOverview
     */
    'id': string;
    /**
     * Field name
     * @type {string}
     * @memberof CnhFieldOverview
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CnhGrowerOverview
 */
export interface CnhGrowerOverview {
    /**
     * Grower id
     * @type {string}
     * @memberof CnhGrowerOverview
     */
    'id': string;
    /**
     * Grower name
     * @type {string}
     * @memberof CnhGrowerOverview
     */
    'name': string;
    /**
     * Forms for this grower
     * @type {Array<CnhFarmOverview>}
     * @memberof CnhGrowerOverview
     */
    'farms': Array<CnhFarmOverview>;
}
/**
 * 
 * @export
 * @interface CnhOverview
 */
export interface CnhOverview {
    /**
     * 
     * @type {CnhCompanyOverview}
     * @memberof CnhOverview
     */
    'company'?: CnhCompanyOverview;
}
/**
 * 
 * @export
 * @interface CnhTask
 */
export interface CnhTask {
    /**
     * 
     * @type {string}
     * @memberof CnhTask
     */
    'taskId': string;
    /**
     * 
     * @type {string}
     * @memberof CnhTask
     */
    'fieldId': string;
    /**
     * 
     * @type {string}
     * @memberof CnhTask
     */
    'vehicleId': string;
    /**
     * 
     * @type {string}
     * @memberof CnhTask
     */
    'operationType': string;
    /**
     * 
     * @type {string}
     * @memberof CnhTask
     */
    'crop': string;
    /**
     * 
     * @type {number}
     * @memberof CnhTask
     */
    'year': number;
    /**
     * 
     * @type {string}
     * @memberof CnhTask
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof CnhTask
     */
    'startTime': number;
    /**
     * 
     * @type {number}
     * @memberof CnhTask
     */
    'endTime': number;
    /**
     * 
     * @type {number}
     * @memberof CnhTask
     */
    'created': number;
    /**
     * 
     * @type {number}
     * @memberof CnhTask
     */
    'modified': number;
}
/**
 * 
 * @export
 * @interface CultivationDataAt
 */
export interface CultivationDataAt {
    /**
     * ID of the field to which this cultivation data belongs.
     * @type {string}
     * @memberof CultivationDataAt
     */
    'fieldId'?: string;
    /**
     * Expected yield in t/ha.
     * @type {number}
     * @memberof CultivationDataAt
     */
    'expectedYield'?: number | null;
}
/**
 * 
 * @export
 * @interface CultivationPlanAtList200Response
 */
export interface CultivationPlanAtList200Response {
    /**
     * 
     * @type {string}
     * @memberof CultivationPlanAtList200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof CultivationPlanAtList200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof CultivationPlanAtList200Response
     */
    'developerPayload'?: string;
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof CultivationPlanAtList200Response
     */
    'pageKey'?: string;
    /**
     * 
     * @type {Array<CultivationDataAt>}
     * @memberof CultivationPlanAtList200Response
     */
    'data'?: Array<CultivationDataAt>;
}
/**
 * 
 * @export
 * @interface CultivationPlanAtList200ResponseAllOf
 */
export interface CultivationPlanAtList200ResponseAllOf {
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof CultivationPlanAtList200ResponseAllOf
     */
    'pageKey'?: string;
    /**
     * 
     * @type {Array<CultivationDataAt>}
     * @memberof CultivationPlanAtList200ResponseAllOf
     */
    'data'?: Array<CultivationDataAt>;
}
/**
 * 
 * @export
 * @interface DaturaDroneAssessment
 */
export interface DaturaDroneAssessment {
    /**
     * Unique id of this object.
     * @type {string}
     * @memberof DaturaDroneAssessment
     */
    'id': string;
    /**
     * Timestamp when this object was deleted, null if object is not deleted.
     * @type {number}
     * @memberof DaturaDroneAssessment
     */
    'deleted'?: number | null;
    /**
     * Last change timestamp, updated automatically by the backend when object is changed.
     * @type {number}
     * @memberof DaturaDroneAssessment
     */
    'tstamp'?: number;
    /**
     * Unique ID of the user who processed this activity.
     * @type {string}
     * @memberof DaturaDroneAssessment
     */
    'userId'?: string | null;
    /**
     * Unique ID of the processorder this activity belongs to.
     * @type {string}
     * @memberof DaturaDroneAssessment
     */
    'processOrderId': string;
    /**
     * Unique ID of the field this activity was performed on. Must be in the process order referenced by `processOrderId`.
     * @type {string}
     * @memberof DaturaDroneAssessment
     */
    'fieldId'?: string | null;
    /**
     * Optional user supplied comment. In certain cases system generated comments are appended at the end of this, delimitated by a `|`.
     * @type {string}
     * @memberof DaturaDroneAssessment
     */
    'userComment'?: string | null;
    /**
     * Date/time when the activity started, unix timestamp.
     * @type {number}
     * @memberof DaturaDroneAssessment
     */
    'timeStart'?: number | null;
    /**
     * Date/time when the activity ended, unix timestamp.
     * @type {number}
     * @memberof DaturaDroneAssessment
     */
    'timeFinish'?: number | null;
    /**
     * Date/time when the activity was originally created, unix timestamp.
     * @type {number}
     * @memberof DaturaDroneAssessment
     */
    'creationDate'?: number | null;
    /**
     * Files referenced by this activity.
     * @type {Array<ActivityFile>}
     * @memberof DaturaDroneAssessment
     */
    'files'?: Array<ActivityFile> | null;
    /**
     * 
     * @type {DaturaDroneAssessmentData}
     * @memberof DaturaDroneAssessment
     */
    'additionalData': DaturaDroneAssessmentData;
}
/**
 * 
 * @export
 * @interface DaturaDroneAssessmentBase
 */
export interface DaturaDroneAssessmentBase {
    /**
     * Unique id of this object.
     * @type {string}
     * @memberof DaturaDroneAssessmentBase
     */
    'id': string;
    /**
     * Timestamp when this object was deleted, null if object is not deleted.
     * @type {number}
     * @memberof DaturaDroneAssessmentBase
     */
    'deleted'?: number | null;
    /**
     * Last change timestamp, updated automatically by the backend when object is changed.
     * @type {number}
     * @memberof DaturaDroneAssessmentBase
     */
    'tstamp'?: number;
    /**
     * Unique ID of the user who processed this activity.
     * @type {string}
     * @memberof DaturaDroneAssessmentBase
     */
    'userId'?: string | null;
    /**
     * Unique ID of the processorder this activity belongs to.
     * @type {string}
     * @memberof DaturaDroneAssessmentBase
     */
    'processOrderId': string;
    /**
     * Unique ID of the field this activity was performed on. Must be in the process order referenced by `processOrderId`.
     * @type {string}
     * @memberof DaturaDroneAssessmentBase
     */
    'fieldId'?: string | null;
    /**
     * Optional user supplied comment. In certain cases system generated comments are appended at the end of this, delimitated by a `|`.
     * @type {string}
     * @memberof DaturaDroneAssessmentBase
     */
    'userComment'?: string | null;
    /**
     * Date/time when the activity started, unix timestamp.
     * @type {number}
     * @memberof DaturaDroneAssessmentBase
     */
    'timeStart'?: number | null;
    /**
     * Date/time when the activity ended, unix timestamp.
     * @type {number}
     * @memberof DaturaDroneAssessmentBase
     */
    'timeFinish'?: number | null;
    /**
     * Date/time when the activity was originally created, unix timestamp.
     * @type {number}
     * @memberof DaturaDroneAssessmentBase
     */
    'creationDate'?: number | null;
    /**
     * Files referenced by this activity.
     * @type {Array<ActivityFile>}
     * @memberof DaturaDroneAssessmentBase
     */
    'files'?: Array<ActivityFile> | null;
    /**
     * 
     * @type {DaturaDroneAssessmentData}
     * @memberof DaturaDroneAssessmentBase
     */
    'additionalData'?: DaturaDroneAssessmentData;
}
/**
 * 
 * @export
 * @interface DaturaDroneAssessmentBaseAllOf
 */
export interface DaturaDroneAssessmentBaseAllOf {
    /**
     * 
     * @type {DaturaDroneAssessmentData}
     * @memberof DaturaDroneAssessmentBaseAllOf
     */
    'additionalData'?: DaturaDroneAssessmentData;
    /**
     * Unique ID of the processorder this activity belongs to.
     * @type {string}
     * @memberof DaturaDroneAssessmentBaseAllOf
     */
    'processOrderId'?: string;
    /**
     * Optional user supplied comment. In certain cases system generated comments are appended at the end of this, delimitated by a `|`.
     * @type {string}
     * @memberof DaturaDroneAssessmentBaseAllOf
     */
    'userComment'?: string | null;
    /**
     * Date/time when the activity started, unix timestamp.
     * @type {number}
     * @memberof DaturaDroneAssessmentBaseAllOf
     */
    'timeStart'?: number | null;
    /**
     * Date/time when the activity ended, unix timestamp.
     * @type {number}
     * @memberof DaturaDroneAssessmentBaseAllOf
     */
    'timeFinish'?: number | null;
}
/**
 * 
 * @export
 * @interface DaturaDroneAssessmentData
 */
export interface DaturaDroneAssessmentData {
    /**
     * Name of the import job
     * @type {string}
     * @memberof DaturaDroneAssessmentData
     */
    'assessmentName': string;
    /**
     * 
     * @type {number}
     * @memberof DaturaDroneAssessmentData
     */
    'occurrencesPerHa': number;
    /**
     * 
     * @type {number}
     * @memberof DaturaDroneAssessmentData
     */
    'occurrencesTotal': number;
    /**
     * 
     * @type {DaturaDroneAssessmentRiskCategory}
     * @memberof DaturaDroneAssessmentData
     */
    'riskCategory': DaturaDroneAssessmentRiskCategory;
}
/**
 * 
 * @export
 * @interface DaturaDroneAssessmentDelete200Response
 */
export interface DaturaDroneAssessmentDelete200Response {
    /**
     * 
     * @type {string}
     * @memberof DaturaDroneAssessmentDelete200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof DaturaDroneAssessmentDelete200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof DaturaDroneAssessmentDelete200Response
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {Array<DaturaDroneAssessment>}
     * @memberof DaturaDroneAssessmentDelete200Response
     */
    'data'?: Array<DaturaDroneAssessment>;
}
/**
 * 
 * @export
 * @interface DaturaDroneAssessmentDelete200ResponseAllOf
 */
export interface DaturaDroneAssessmentDelete200ResponseAllOf {
    /**
     * 
     * @type {Array<DaturaDroneAssessment>}
     * @memberof DaturaDroneAssessmentDelete200ResponseAllOf
     */
    'data'?: Array<DaturaDroneAssessment>;
}
/**
 * 
 * @export
 * @interface DaturaDroneAssessmentObjects200Response
 */
export interface DaturaDroneAssessmentObjects200Response {
    /**
     * 
     * @type {string}
     * @memberof DaturaDroneAssessmentObjects200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof DaturaDroneAssessmentObjects200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof DaturaDroneAssessmentObjects200Response
     */
    'developerPayload'?: string;
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof DaturaDroneAssessmentObjects200Response
     */
    'pageKey'?: string;
    /**
     * 
     * @type {Array<DaturaDroneAssessmentBase>}
     * @memberof DaturaDroneAssessmentObjects200Response
     */
    'data'?: Array<DaturaDroneAssessmentBase>;
}
/**
 * 
 * @export
 * @interface DaturaDroneAssessmentObjects200ResponseAllOf
 */
export interface DaturaDroneAssessmentObjects200ResponseAllOf {
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof DaturaDroneAssessmentObjects200ResponseAllOf
     */
    'pageKey'?: string;
    /**
     * 
     * @type {Array<DaturaDroneAssessmentBase>}
     * @memberof DaturaDroneAssessmentObjects200ResponseAllOf
     */
    'data'?: Array<DaturaDroneAssessmentBase>;
}
/**
 * 
 * @export
 * @interface DaturaDroneAssessmentPatch200Response
 */
export interface DaturaDroneAssessmentPatch200Response {
    /**
     * 
     * @type {string}
     * @memberof DaturaDroneAssessmentPatch200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof DaturaDroneAssessmentPatch200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof DaturaDroneAssessmentPatch200Response
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {Array<DaturaDroneAssessment>}
     * @memberof DaturaDroneAssessmentPatch200Response
     */
    'data': Array<DaturaDroneAssessment>;
    /**
     * 
     * @type {{ [key: string]: ActivityPut200ResponseAllOfErrorsValue; }}
     * @memberof DaturaDroneAssessmentPatch200Response
     */
    'errors'?: { [key: string]: ActivityPut200ResponseAllOfErrorsValue; };
}
/**
 * 
 * @export
 * @interface DaturaDroneAssessmentPatch200ResponseAllOf
 */
export interface DaturaDroneAssessmentPatch200ResponseAllOf {
    /**
     * 
     * @type {Array<DaturaDroneAssessment>}
     * @memberof DaturaDroneAssessmentPatch200ResponseAllOf
     */
    'data': Array<DaturaDroneAssessment>;
    /**
     * 
     * @type {{ [key: string]: ActivityPut200ResponseAllOfErrorsValue; }}
     * @memberof DaturaDroneAssessmentPatch200ResponseAllOf
     */
    'errors'?: { [key: string]: ActivityPut200ResponseAllOfErrorsValue; };
}
/**
 * 
 * @export
 * @interface DaturaDroneAssessmentPatchRequest
 */
export interface DaturaDroneAssessmentPatchRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof DaturaDroneAssessmentPatchRequest
     */
    'version'?: string;
    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof DaturaDroneAssessmentPatchRequest
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {Array<DaturaDroneAssessmentBase>}
     * @memberof DaturaDroneAssessmentPatchRequest
     */
    'data': Array<DaturaDroneAssessmentBase>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DaturaDroneAssessmentRiskCategory = {
    Low: 'Low',
    Medium: 'Medium',
    High: 'High'
} as const;

export type DaturaDroneAssessmentRiskCategory = typeof DaturaDroneAssessmentRiskCategory[keyof typeof DaturaDroneAssessmentRiskCategory];


/**
 * 
 * @export
 * @interface DaturaImportJob
 */
export interface DaturaImportJob {
    /**
     * Unique id of this object.
     * @type {string}
     * @memberof DaturaImportJob
     */
    'id': string;
    /**
     * Timestamp when this object was deleted, null if object is not deleted.
     * @type {number}
     * @memberof DaturaImportJob
     */
    'deleted'?: number | null;
    /**
     * Last change timestamp, updated automatically by the backend when object is changed.
     * @type {number}
     * @memberof DaturaImportJob
     */
    'tstamp': number;
    /**
     * Timestamp when this object was created.
     * @type {number}
     * @memberof DaturaImportJob
     */
    'created': number;
    /**
     * Processorder this import job belongs to.
     * @type {string}
     * @memberof DaturaImportJob
     */
    'processOrderId': string;
    /**
     * User that created the import job, only this user can upload the files.
     * @type {string}
     * @memberof DaturaImportJob
     */
    'userId': string;
    /**
     * User assigned import job name.
     * @type {string}
     * @memberof DaturaImportJob
     */
    'name': string;
    /**
     * 
     * @type {DaturaImportJobState}
     * @memberof DaturaImportJob
     */
    'state': DaturaImportJobState;
    /**
     * Files belonging to this import job.
     * @type {Array<DaturaImportJobFile>}
     * @memberof DaturaImportJob
     */
    'files': Array<DaturaImportJobFile>;
    /**
     * Progress in percent.
     * @type {number}
     * @memberof DaturaImportJob
     */
    'progress': number;
    /**
     * Number of image files in this import job.
     * @type {number}
     * @memberof DaturaImportJob
     */
    'imageCount'?: number | null;
    /**
     * Timestamp when this import job was completed.
     * @type {number}
     * @memberof DaturaImportJob
     */
    'completed'?: number | null;
    /**
     * 
     * @type {DaturaImportJobBaseAdditionalData}
     * @memberof DaturaImportJob
     */
    'additionalData'?: DaturaImportJobBaseAdditionalData | null;
}
/**
 * 
 * @export
 * @interface DaturaImportJobBase
 */
export interface DaturaImportJobBase {
    /**
     * Unique id of this object.
     * @type {string}
     * @memberof DaturaImportJobBase
     */
    'id': string;
    /**
     * Timestamp when this object was deleted, null if object is not deleted.
     * @type {number}
     * @memberof DaturaImportJobBase
     */
    'deleted'?: number | null;
    /**
     * Last change timestamp, updated automatically by the backend when object is changed.
     * @type {number}
     * @memberof DaturaImportJobBase
     */
    'tstamp'?: number;
    /**
     * Timestamp when this object was created.
     * @type {number}
     * @memberof DaturaImportJobBase
     */
    'created'?: number;
    /**
     * Processorder this import job belongs to.
     * @type {string}
     * @memberof DaturaImportJobBase
     */
    'processOrderId'?: string;
    /**
     * User that created the import job, only this user can upload the files.
     * @type {string}
     * @memberof DaturaImportJobBase
     */
    'userId'?: string;
    /**
     * User assigned import job name.
     * @type {string}
     * @memberof DaturaImportJobBase
     */
    'name'?: string;
    /**
     * 
     * @type {DaturaImportJobState}
     * @memberof DaturaImportJobBase
     */
    'state'?: DaturaImportJobState;
    /**
     * Files belonging to this import job.
     * @type {Array<DaturaImportJobFile>}
     * @memberof DaturaImportJobBase
     */
    'files'?: Array<DaturaImportJobFile>;
    /**
     * Progress in percent.
     * @type {number}
     * @memberof DaturaImportJobBase
     */
    'progress'?: number;
    /**
     * Number of image files in this import job.
     * @type {number}
     * @memberof DaturaImportJobBase
     */
    'imageCount'?: number | null;
    /**
     * Timestamp when this import job was completed.
     * @type {number}
     * @memberof DaturaImportJobBase
     */
    'completed'?: number | null;
    /**
     * 
     * @type {DaturaImportJobBaseAdditionalData}
     * @memberof DaturaImportJobBase
     */
    'additionalData'?: DaturaImportJobBaseAdditionalData | null;
}
/**
 * @type DaturaImportJobBaseAdditionalData
 * @export
 */
export type DaturaImportJobBaseAdditionalData = DaturaImportJobData;

/**
 * 
 * @export
 * @interface DaturaImportJobData
 */
export interface DaturaImportJobData {
    /**
     * 
     * @type {Array<string>}
     * @memberof DaturaImportJobData
     */
    'assessmentIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface DaturaImportJobFile
 */
export interface DaturaImportJobFile {
    /**
     * Unique id of this file.
     * @type {string}
     * @memberof DaturaImportJobFile
     */
    'id': string;
    /**
     * Size of the file. The file uploaded must match this size.
     * @type {number}
     * @memberof DaturaImportJobFile
     */
    'filesize': number;
    /**
     * Filename of the file including extension. Only jpg, jpeg and zip extensions are supported.
     * @type {string}
     * @memberof DaturaImportJobFile
     */
    'filename': string;
    /**
     * Destination url for file upload.
     * @type {string}
     * @memberof DaturaImportJobFile
     */
    'url'?: string | null;
}
/**
 * 
 * @export
 * @interface DaturaImportJobObjects200Response
 */
export interface DaturaImportJobObjects200Response {
    /**
     * 
     * @type {string}
     * @memberof DaturaImportJobObjects200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof DaturaImportJobObjects200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof DaturaImportJobObjects200Response
     */
    'developerPayload'?: string;
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof DaturaImportJobObjects200Response
     */
    'pageKey'?: string;
    /**
     * 
     * @type {Array<DaturaImportJobBase>}
     * @memberof DaturaImportJobObjects200Response
     */
    'data'?: Array<DaturaImportJobBase>;
}
/**
 * 
 * @export
 * @interface DaturaImportJobObjects200ResponseAllOf
 */
export interface DaturaImportJobObjects200ResponseAllOf {
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof DaturaImportJobObjects200ResponseAllOf
     */
    'pageKey'?: string;
    /**
     * 
     * @type {Array<DaturaImportJobBase>}
     * @memberof DaturaImportJobObjects200ResponseAllOf
     */
    'data'?: Array<DaturaImportJobBase>;
}
/**
 * 
 * @export
 * @interface DaturaImportJobPatch200Response
 */
export interface DaturaImportJobPatch200Response {
    /**
     * 
     * @type {string}
     * @memberof DaturaImportJobPatch200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof DaturaImportJobPatch200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof DaturaImportJobPatch200Response
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {Array<DaturaImportJob>}
     * @memberof DaturaImportJobPatch200Response
     */
    'data': Array<DaturaImportJob>;
    /**
     * 
     * @type {{ [key: string]: ActivityPut200ResponseAllOfErrorsValue; }}
     * @memberof DaturaImportJobPatch200Response
     */
    'errors'?: { [key: string]: ActivityPut200ResponseAllOfErrorsValue; };
}
/**
 * 
 * @export
 * @interface DaturaImportJobPatch200ResponseAllOf
 */
export interface DaturaImportJobPatch200ResponseAllOf {
    /**
     * 
     * @type {Array<DaturaImportJob>}
     * @memberof DaturaImportJobPatch200ResponseAllOf
     */
    'data': Array<DaturaImportJob>;
    /**
     * 
     * @type {{ [key: string]: ActivityPut200ResponseAllOfErrorsValue; }}
     * @memberof DaturaImportJobPatch200ResponseAllOf
     */
    'errors'?: { [key: string]: ActivityPut200ResponseAllOfErrorsValue; };
}
/**
 * 
 * @export
 * @interface DaturaImportJobPatchRequest
 */
export interface DaturaImportJobPatchRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof DaturaImportJobPatchRequest
     */
    'version'?: string;
    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof DaturaImportJobPatchRequest
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {Array<DaturaImportJobBase>}
     * @memberof DaturaImportJobPatchRequest
     */
    'data': Array<DaturaImportJobBase>;
}
/**
 * 
 * @export
 * @interface DaturaImportJobRequest
 */
export interface DaturaImportJobRequest {
    /**
     * Unique id for import job. Must not yet exist.
     * @type {string}
     * @memberof DaturaImportJobRequest
     */
    'id': string;
    /**
     * Process order this import job belongs to.
     * @type {string}
     * @memberof DaturaImportJobRequest
     */
    'processOrderId': string;
    /**
     * User assigned import job name.
     * @type {string}
     * @memberof DaturaImportJobRequest
     */
    'name': string;
    /**
     * Files belonging to this import job.
     * @type {Array<DaturaImportJobFile>}
     * @memberof DaturaImportJobRequest
     */
    'files': Array<DaturaImportJobFile>;
}
/**
 * Result from Datura import job, submitted by DIS
 * @export
 * @interface DaturaImportJobResult
 */
export interface DaturaImportJobResult {
    /**
     * 
     * @type {string}
     * @memberof DaturaImportJobResult
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DaturaImportJobResult
     */
    'jobId'?: string;
    /**
     * 
     * @type {number}
     * @memberof DaturaImportJobResult
     */
    'created'?: number;
    /**
     * 
     * @type {string}
     * @memberof DaturaImportJobResult
     */
    'fieldId'?: string;
    /**
     * 
     * @type {number}
     * @memberof DaturaImportJobResult
     */
    'occurrencesPerHa'?: number;
    /**
     * 
     * @type {number}
     * @memberof DaturaImportJobResult
     */
    'occurrencesTotal'?: number;
    /**
     * 
     * @type {string}
     * @memberof DaturaImportJobResult
     */
    'resultsFile'?: string;
}
/**
 * 
 * @export
 * @interface DaturaImportJobResultsPutRequest
 */
export interface DaturaImportJobResultsPutRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof DaturaImportJobResultsPutRequest
     */
    'version'?: string;
    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof DaturaImportJobResultsPutRequest
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {Array<DaturaImportJobResult>}
     * @memberof DaturaImportJobResultsPutRequest
     */
    'data': Array<DaturaImportJobResult>;
}
/**
 * 
 * @export
 * @interface DaturaImportJobStart200Response
 */
export interface DaturaImportJobStart200Response {
    /**
     * 
     * @type {string}
     * @memberof DaturaImportJobStart200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof DaturaImportJobStart200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof DaturaImportJobStart200Response
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {DaturaImportJob}
     * @memberof DaturaImportJobStart200Response
     */
    'data'?: DaturaImportJob;
}
/**
 * 
 * @export
 * @interface DaturaImportJobStart200ResponseAllOf
 */
export interface DaturaImportJobStart200ResponseAllOf {
    /**
     * 
     * @type {DaturaImportJob}
     * @memberof DaturaImportJobStart200ResponseAllOf
     */
    'data'?: DaturaImportJob;
}
/**
 * 
 * @export
 * @interface DaturaImportJobStartRequest
 */
export interface DaturaImportJobStartRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof DaturaImportJobStartRequest
     */
    'version'?: string;
    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof DaturaImportJobStartRequest
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {DaturaImportJobRequest}
     * @memberof DaturaImportJobStartRequest
     */
    'data': DaturaImportJobRequest;
}
/**
 * 
 * @export
 * @interface DaturaImportJobStartRequestAllOf
 */
export interface DaturaImportJobStartRequestAllOf {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof DaturaImportJobStartRequestAllOf
     */
    'version'?: string;
    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof DaturaImportJobStartRequestAllOf
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {DaturaImportJobRequest}
     * @memberof DaturaImportJobStartRequestAllOf
     */
    'data': DaturaImportJobRequest;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DaturaImportJobState = {
    UploadPending: 'UploadPending',
    UploadExpired: 'UploadExpired',
    Calculating: 'Calculating',
    Finished: 'Finished',
    Failed: 'Failed'
} as const;

export type DaturaImportJobState = typeof DaturaImportJobState[keyof typeof DaturaImportJobState];


/**
 * 
 * @export
 * @interface Equipment
 */
export interface Equipment {
    /**
     * Unique id of this object.
     * @type {string}
     * @memberof Equipment
     */
    'id': string;
    /**
     * Timestamp when this object was deleted, null if object is not deleted.
     * @type {number}
     * @memberof Equipment
     */
    'deleted'?: number | null;
    /**
     * Last change timestamp, updated automatically by the backend when object is changed.
     * @type {number}
     * @memberof Equipment
     */
    'tstamp': number;
    /**
     * 
     * @type {string}
     * @memberof Equipment
     */
    'name': string;
    /**
     * Free text description.
     * @type {string}
     * @memberof Equipment
     */
    'description': string;
    /**
     * Company owning the equipment. If null the piece of equipment is a global template. Pieces of equipment with a null `companyId` cannot be used directly.
     * @type {string}
     * @memberof Equipment
     */
    'companyId'?: string | null;
    /**
     * True if the pieces of equipment is synced between all companies in a multi-company cluster.
     * @type {boolean}
     * @memberof Equipment
     */
    'companyClusterSync': boolean;
    /**
     * Must be set if `companyClusterSync` is true, refers to the original piece of equipment from which this piece of equipment was synced. Will refer to itself if this is the original pieces of equipment that was synced to the other companies in the cluster.
     * @type {string}
     * @memberof Equipment
     */
    'relatedEquipmentId'?: string | null;
    /**
     * The original (global/template) pieces of equipment that this pieces of equipment was copied from.
     * @type {string}
     * @memberof Equipment
     */
    'originEquipmentId'?: string | null;
    /**
     * The equipment-list this piece of equipment belongs to. For global/template equipments this places the pieces of equipment into the list so that pieces of equipment from the list can be imported into a company. For company-owned pieces of equipment this is the equipment-list that the piece of equipment was imported from. Not set for pieces of equipment that were created by the user.
     * @type {string}
     * @memberof Equipment
     */
    'equipmentListId'?: string | null;
    /**
     * The category this equipment belongs to.
     * @type {string}
     * @memberof Equipment
     */
    'equipmentCategoryId': string;
    /**
     * Engine power in kilo Watt.
     * @type {number}
     * @memberof Equipment
     */
    'enginePower'?: number | null;
    /**
     * Working width in meters.
     * @type {number}
     * @memberof Equipment
     */
    'workingWidth'?: number | null;
    /**
     * Date on which the piece of equipment was acquired.
     * @type {number}
     * @memberof Equipment
     */
    'acquisitionDate'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Equipment
     */
    'acquisitionPrice'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Equipment
     */
    'constructionYear'?: string | null;
    /**
     * Annual utilization in hours.
     * @type {number}
     * @memberof Equipment
     */
    'utilizationAnnual'?: number | null;
    /**
     * 
     * @type {EquipmentBaseTenureStatus}
     * @memberof Equipment
     */
    'tenureStatus'?: EquipmentBaseTenureStatus | null;
    /**
     * Ownership percentage.
     * @type {number}
     * @memberof Equipment
     */
    'share'?: number | null;
    /**
     * Capacity in liters.
     * @type {number}
     * @memberof Equipment
     */
    'capacity'?: number | null;
}
/**
 * 
 * @export
 * @interface EquipmentBase
 */
export interface EquipmentBase {
    /**
     * Unique id of this object.
     * @type {string}
     * @memberof EquipmentBase
     */
    'id': string;
    /**
     * Timestamp when this object was deleted, null if object is not deleted.
     * @type {number}
     * @memberof EquipmentBase
     */
    'deleted'?: number | null;
    /**
     * Last change timestamp, updated automatically by the backend when object is changed.
     * @type {number}
     * @memberof EquipmentBase
     */
    'tstamp'?: number;
    /**
     * 
     * @type {string}
     * @memberof EquipmentBase
     */
    'name'?: string;
    /**
     * Free text description.
     * @type {string}
     * @memberof EquipmentBase
     */
    'description'?: string;
    /**
     * Company owning the equipment. If null the piece of equipment is a global template. Pieces of equipment with a null `companyId` cannot be used directly.
     * @type {string}
     * @memberof EquipmentBase
     */
    'companyId'?: string | null;
    /**
     * True if the pieces of equipment is synced between all companies in a multi-company cluster.
     * @type {boolean}
     * @memberof EquipmentBase
     */
    'companyClusterSync'?: boolean;
    /**
     * Must be set if `companyClusterSync` is true, refers to the original piece of equipment from which this piece of equipment was synced. Will refer to itself if this is the original pieces of equipment that was synced to the other companies in the cluster.
     * @type {string}
     * @memberof EquipmentBase
     */
    'relatedEquipmentId'?: string | null;
    /**
     * The original (global/template) pieces of equipment that this pieces of equipment was copied from.
     * @type {string}
     * @memberof EquipmentBase
     */
    'originEquipmentId'?: string | null;
    /**
     * The equipment-list this piece of equipment belongs to. For global/template equipments this places the pieces of equipment into the list so that pieces of equipment from the list can be imported into a company. For company-owned pieces of equipment this is the equipment-list that the piece of equipment was imported from. Not set for pieces of equipment that were created by the user.
     * @type {string}
     * @memberof EquipmentBase
     */
    'equipmentListId'?: string | null;
    /**
     * The category this equipment belongs to.
     * @type {string}
     * @memberof EquipmentBase
     */
    'equipmentCategoryId'?: string;
    /**
     * Engine power in kilo Watt.
     * @type {number}
     * @memberof EquipmentBase
     */
    'enginePower'?: number | null;
    /**
     * Working width in meters.
     * @type {number}
     * @memberof EquipmentBase
     */
    'workingWidth'?: number | null;
    /**
     * Date on which the piece of equipment was acquired.
     * @type {number}
     * @memberof EquipmentBase
     */
    'acquisitionDate'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EquipmentBase
     */
    'acquisitionPrice'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EquipmentBase
     */
    'constructionYear'?: string | null;
    /**
     * Annual utilization in hours.
     * @type {number}
     * @memberof EquipmentBase
     */
    'utilizationAnnual'?: number | null;
    /**
     * 
     * @type {EquipmentBaseTenureStatus}
     * @memberof EquipmentBase
     */
    'tenureStatus'?: EquipmentBaseTenureStatus | null;
    /**
     * Ownership percentage.
     * @type {number}
     * @memberof EquipmentBase
     */
    'share'?: number | null;
    /**
     * Capacity in liters.
     * @type {number}
     * @memberof EquipmentBase
     */
    'capacity'?: number | null;
}
/**
 * @type EquipmentBaseTenureStatus
 * @export
 */
export type EquipmentBaseTenureStatus = TenureStatus;

/**
 * 
 * @export
 * @interface EquipmentDelete200Response
 */
export interface EquipmentDelete200Response {
    /**
     * 
     * @type {string}
     * @memberof EquipmentDelete200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentDelete200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof EquipmentDelete200Response
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {Array<Equipment>}
     * @memberof EquipmentDelete200Response
     */
    'data'?: Array<Equipment>;
}
/**
 * 
 * @export
 * @interface EquipmentDelete200ResponseAllOf
 */
export interface EquipmentDelete200ResponseAllOf {
    /**
     * 
     * @type {Array<Equipment>}
     * @memberof EquipmentDelete200ResponseAllOf
     */
    'data'?: Array<Equipment>;
}
/**
 * 
 * @export
 * @interface EquipmentObjects200Response
 */
export interface EquipmentObjects200Response {
    /**
     * 
     * @type {string}
     * @memberof EquipmentObjects200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentObjects200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof EquipmentObjects200Response
     */
    'developerPayload'?: string;
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof EquipmentObjects200Response
     */
    'pageKey'?: string;
    /**
     * 
     * @type {Array<EquipmentBase>}
     * @memberof EquipmentObjects200Response
     */
    'data'?: Array<EquipmentBase>;
}
/**
 * 
 * @export
 * @interface EquipmentObjects200ResponseAllOf
 */
export interface EquipmentObjects200ResponseAllOf {
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof EquipmentObjects200ResponseAllOf
     */
    'pageKey'?: string;
    /**
     * 
     * @type {Array<EquipmentBase>}
     * @memberof EquipmentObjects200ResponseAllOf
     */
    'data'?: Array<EquipmentBase>;
}
/**
 * 
 * @export
 * @interface EquipmentPatchRequest
 */
export interface EquipmentPatchRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof EquipmentPatchRequest
     */
    'version'?: string;
    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof EquipmentPatchRequest
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {Array<EquipmentBase>}
     * @memberof EquipmentPatchRequest
     */
    'data': Array<EquipmentBase>;
}
/**
 * 
 * @export
 * @interface EquipmentPut200Response
 */
export interface EquipmentPut200Response {
    /**
     * 
     * @type {string}
     * @memberof EquipmentPut200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentPut200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof EquipmentPut200Response
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {Array<Equipment>}
     * @memberof EquipmentPut200Response
     */
    'data': Array<Equipment>;
    /**
     * 
     * @type {{ [key: string]: ActivityPut200ResponseAllOfErrorsValue; }}
     * @memberof EquipmentPut200Response
     */
    'errors'?: { [key: string]: ActivityPut200ResponseAllOfErrorsValue; };
}
/**
 * 
 * @export
 * @interface EquipmentPut200ResponseAllOf
 */
export interface EquipmentPut200ResponseAllOf {
    /**
     * 
     * @type {Array<Equipment>}
     * @memberof EquipmentPut200ResponseAllOf
     */
    'data': Array<Equipment>;
    /**
     * 
     * @type {{ [key: string]: ActivityPut200ResponseAllOfErrorsValue; }}
     * @memberof EquipmentPut200ResponseAllOf
     */
    'errors'?: { [key: string]: ActivityPut200ResponseAllOfErrorsValue; };
}
/**
 * 
 * @export
 * @interface EquipmentPutRequest
 */
export interface EquipmentPutRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof EquipmentPutRequest
     */
    'version'?: string;
    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof EquipmentPutRequest
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {Array<Equipment>}
     * @memberof EquipmentPutRequest
     */
    'data': Array<Equipment>;
}
/**
 * 
 * @export
 * @interface EquipmentUndelete200Response
 */
export interface EquipmentUndelete200Response {
    /**
     * 
     * @type {string}
     * @memberof EquipmentUndelete200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentUndelete200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof EquipmentUndelete200Response
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {Array<Equipment>}
     * @memberof EquipmentUndelete200Response
     */
    'data'?: Array<Equipment>;
    /**
     * 
     * @type {{ [key: string]: ActivityPut200ResponseAllOfErrorsValue; }}
     * @memberof EquipmentUndelete200Response
     */
    'errors'?: { [key: string]: ActivityPut200ResponseAllOfErrorsValue; };
}
/**
 * 
 * @export
 * @interface EquipmentUndelete200ResponseAllOf
 */
export interface EquipmentUndelete200ResponseAllOf {
    /**
     * 
     * @type {Array<Equipment>}
     * @memberof EquipmentUndelete200ResponseAllOf
     */
    'data'?: Array<Equipment>;
    /**
     * 
     * @type {{ [key: string]: ActivityPut200ResponseAllOfErrorsValue; }}
     * @memberof EquipmentUndelete200ResponseAllOf
     */
    'errors'?: { [key: string]: ActivityPut200ResponseAllOfErrorsValue; };
}
/**
 * 
 * @export
 * @interface FavouriteProduct
 */
export interface FavouriteProduct {
    /**
     * Unique ID of the product.
     * @type {string}
     * @memberof FavouriteProduct
     */
    'productId': string;
    /**
     * Unique ID of the storage-place from which the product is taken. Null if the product wasn\'t take from a specific storage place.
     * @type {string}
     * @memberof FavouriteProduct
     */
    'storagePlaceId'?: string | null;
    /**
     * The amount of the last usage in `unitId` units.
     * @type {number}
     * @memberof FavouriteProduct
     */
    'amount'?: number | null;
    /**
     * The unit of the last usage for `amount`.
     * @type {string}
     * @memberof FavouriteProduct
     */
    'unitId'?: string | null;
    /**
     * Unique ID of the pesticide-indication for this product.
     * @type {string}
     * @memberof FavouriteProduct
     */
    'pesticideIndicationId'?: string | null;
    /**
     * Unique ID of the pest, one of the pests listed in the pesticide-indication referenced by `pesticideIndicationId`.
     * @type {string}
     * @memberof FavouriteProduct
     */
    'pestId'?: string | null;
}
/**
 * Combination of products used frequently.
 * @export
 * @interface FavouriteProducts
 */
export interface FavouriteProducts {
    /**
     * Products used together in activity.
     * @type {Array<FavouriteProduct>}
     * @memberof FavouriteProducts
     */
    'products': Array<FavouriteProduct>;
}
/**
 * 
 * @export
 * @interface Feature
 */
export interface Feature {
    /**
     * 
     * @type {GeometryType}
     * @memberof Feature
     */
    'type': GeometryType;
    /**
     * 
     * @type {any}
     * @memberof Feature
     */
    'properties': any | null;
    /**
     * Geometry for this feature.
     * @type {any}
     * @memberof Feature
     */
    'geometry': any;
}
/**
 * 
 * @export
 * @interface FeatureAllOf
 */
export interface FeatureAllOf {
    /**
     * 
     * @type {any}
     * @memberof FeatureAllOf
     */
    'properties'?: any | null;
    /**
     * Geometry for this feature.
     * @type {any}
     * @memberof FeatureAllOf
     */
    'geometry'?: any;
}
/**
 * 
 * @export
 * @interface FeatureCollection
 */
export interface FeatureCollection {
    /**
     * 
     * @type {GeometryType}
     * @memberof FeatureCollection
     */
    'type': GeometryType;
    /**
     * 
     * @type {Array<Feature>}
     * @memberof FeatureCollection
     */
    'features': Array<Feature>;
}
/**
 * 
 * @export
 * @interface FeatureCollectionAllOf
 */
export interface FeatureCollectionAllOf {
    /**
     * 
     * @type {Array<Feature>}
     * @memberof FeatureCollectionAllOf
     */
    'features'?: Array<Feature>;
}
/**
 * 
 * @export
 * @interface FertilizationPlanFieldBasedList200Response
 */
export interface FertilizationPlanFieldBasedList200Response {
    /**
     * 
     * @type {string}
     * @memberof FertilizationPlanFieldBasedList200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof FertilizationPlanFieldBasedList200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof FertilizationPlanFieldBasedList200Response
     */
    'developerPayload'?: string;
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof FertilizationPlanFieldBasedList200Response
     */
    'pageKey'?: string;
    /**
     * 
     * @type {Array<FieldFertilizationData>}
     * @memberof FertilizationPlanFieldBasedList200Response
     */
    'data'?: Array<FieldFertilizationData>;
}
/**
 * 
 * @export
 * @interface FertilizationPlanFieldBasedList200ResponseAllOf
 */
export interface FertilizationPlanFieldBasedList200ResponseAllOf {
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof FertilizationPlanFieldBasedList200ResponseAllOf
     */
    'pageKey'?: string;
    /**
     * 
     * @type {Array<FieldFertilizationData>}
     * @memberof FertilizationPlanFieldBasedList200ResponseAllOf
     */
    'data'?: Array<FieldFertilizationData>;
}
/**
 * 
 * @export
 * @interface FieldContour
 */
export interface FieldContour {
    /**
     * Unique id of this object.
     * @type {string}
     * @memberof FieldContour
     */
    'id': string;
    /**
     * Timestamp when this object was deleted, null if object is not deleted.
     * @type {number}
     * @memberof FieldContour
     */
    'deleted'?: number | null;
    /**
     * Last change timestamp, updated automatically by the backend when object is changed.
     * @type {number}
     * @memberof FieldContour
     */
    'tstamp': number;
    /**
     * 
     * @type {Polygon}
     * @memberof FieldContour
     */
    'geometry': Polygon;
    /**
     * Area of the contour in ha. Calculated automatically when the contour is saved.
     * @type {number}
     * @memberof FieldContour
     */
    'area': number;
    /**
     * Unique ID of the activity this contour belongs to.
     * @type {string}
     * @memberof FieldContour
     */
    'fieldId': string;
}
/**
 * 
 * @export
 * @interface FieldContourBase
 */
export interface FieldContourBase {
    /**
     * Unique id of this object.
     * @type {string}
     * @memberof FieldContourBase
     */
    'id': string;
    /**
     * Timestamp when this object was deleted, null if object is not deleted.
     * @type {number}
     * @memberof FieldContourBase
     */
    'deleted'?: number | null;
    /**
     * Last change timestamp, updated automatically by the backend when object is changed.
     * @type {number}
     * @memberof FieldContourBase
     */
    'tstamp'?: number;
    /**
     * 
     * @type {Polygon}
     * @memberof FieldContourBase
     */
    'geometry'?: Polygon;
    /**
     * Area of the contour in ha. Calculated automatically when the contour is saved.
     * @type {number}
     * @memberof FieldContourBase
     */
    'area'?: number;
    /**
     * Unique ID of the activity this contour belongs to.
     * @type {string}
     * @memberof FieldContourBase
     */
    'fieldId'?: string;
}
/**
 * 
 * @export
 * @interface FieldContourDelete200Response
 */
export interface FieldContourDelete200Response {
    /**
     * 
     * @type {string}
     * @memberof FieldContourDelete200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof FieldContourDelete200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof FieldContourDelete200Response
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {Array<FieldContour>}
     * @memberof FieldContourDelete200Response
     */
    'data'?: Array<FieldContour>;
}
/**
 * 
 * @export
 * @interface FieldContourDelete200ResponseAllOf
 */
export interface FieldContourDelete200ResponseAllOf {
    /**
     * 
     * @type {Array<FieldContour>}
     * @memberof FieldContourDelete200ResponseAllOf
     */
    'data'?: Array<FieldContour>;
}
/**
 * 
 * @export
 * @interface FieldContourObjects200Response
 */
export interface FieldContourObjects200Response {
    /**
     * 
     * @type {string}
     * @memberof FieldContourObjects200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof FieldContourObjects200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof FieldContourObjects200Response
     */
    'developerPayload'?: string;
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof FieldContourObjects200Response
     */
    'pageKey'?: string;
    /**
     * 
     * @type {Array<FieldContourBase>}
     * @memberof FieldContourObjects200Response
     */
    'data'?: Array<FieldContourBase>;
}
/**
 * 
 * @export
 * @interface FieldContourObjects200ResponseAllOf
 */
export interface FieldContourObjects200ResponseAllOf {
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof FieldContourObjects200ResponseAllOf
     */
    'pageKey'?: string;
    /**
     * 
     * @type {Array<FieldContourBase>}
     * @memberof FieldContourObjects200ResponseAllOf
     */
    'data'?: Array<FieldContourBase>;
}
/**
 * 
 * @export
 * @interface FieldContourPatchRequest
 */
export interface FieldContourPatchRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof FieldContourPatchRequest
     */
    'version'?: string;
    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof FieldContourPatchRequest
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {Array<FieldContourBase>}
     * @memberof FieldContourPatchRequest
     */
    'data': Array<FieldContourBase>;
}
/**
 * 
 * @export
 * @interface FieldContourPut200Response
 */
export interface FieldContourPut200Response {
    /**
     * 
     * @type {string}
     * @memberof FieldContourPut200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof FieldContourPut200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof FieldContourPut200Response
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {Array<FieldContour>}
     * @memberof FieldContourPut200Response
     */
    'data': Array<FieldContour>;
    /**
     * 
     * @type {{ [key: string]: ActivityPut200ResponseAllOfErrorsValue; }}
     * @memberof FieldContourPut200Response
     */
    'errors'?: { [key: string]: ActivityPut200ResponseAllOfErrorsValue; };
}
/**
 * 
 * @export
 * @interface FieldContourPut200ResponseAllOf
 */
export interface FieldContourPut200ResponseAllOf {
    /**
     * 
     * @type {Array<FieldContour>}
     * @memberof FieldContourPut200ResponseAllOf
     */
    'data': Array<FieldContour>;
    /**
     * 
     * @type {{ [key: string]: ActivityPut200ResponseAllOfErrorsValue; }}
     * @memberof FieldContourPut200ResponseAllOf
     */
    'errors'?: { [key: string]: ActivityPut200ResponseAllOfErrorsValue; };
}
/**
 * 
 * @export
 * @interface FieldContourPutRequest
 */
export interface FieldContourPutRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof FieldContourPutRequest
     */
    'version'?: string;
    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof FieldContourPutRequest
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {Array<FieldContour>}
     * @memberof FieldContourPutRequest
     */
    'data': Array<FieldContour>;
}
/**
 * 
 * @export
 * @interface FieldFertilizationData
 */
export interface FieldFertilizationData {
    /**
     * ID of the field to which this fertilization data belongs.
     * @type {string}
     * @memberof FieldFertilizationData
     */
    'fieldId': string;
    /**
     * Nitrogen fertilized in kg/ha.
     * @type {number}
     * @memberof FieldFertilizationData
     */
    'nFertilized': number;
    /**
     * Nitrogen fertilized as part of the first application.
     * @type {number}
     * @memberof FieldFertilizationData
     */
    'nFertilizedFirstApplication': number;
    /**
     * Nitrogen fertilized as part of the remaining applications.
     * @type {number}
     * @memberof FieldFertilizationData
     */
    'nFertilizedOtherApplications': number;
}
/**
 * 
 * @export
 * @interface FieldUtilization
 */
export interface FieldUtilization {
    /**
     * 
     * @type {string}
     * @memberof FieldUtilization
     */
    'id': string;
    /**
     * 
     * @type {Polygon}
     * @memberof FieldUtilization
     */
    'geometry': Polygon;
    /**
     * Crop id for field. Note that this is an external id that does not map to farmdok crop/product ids.
     * @type {string}
     * @memberof FieldUtilization
     */
    'cropId'?: string | null;
    /**
     * Crop name for field.
     * @type {string}
     * @memberof FieldUtilization
     */
    'cropName'?: string | null;
    /**
     * Area in ha.
     * @type {number}
     * @memberof FieldUtilization
     */
    'area'?: number;
}
/**
 * 
 * @export
 * @interface GeoObject
 */
export interface GeoObject {
    /**
     * Unique id of this object.
     * @type {string}
     * @memberof GeoObject
     */
    'id': string;
    /**
     * Timestamp when this object was deleted, null if object is not deleted.
     * @type {number}
     * @memberof GeoObject
     */
    'deleted'?: number | null;
    /**
     * Last change timestamp, updated automatically by the backend when object is changed.
     * @type {number}
     * @memberof GeoObject
     */
    'tstamp': number;
    /**
     * Unique ID of the processorder this geo-object belongs to.
     * @type {string}
     * @memberof GeoObject
     */
    'processOrderId': string;
    /**
     * 
     * @type {GeoObjectBaseGeometry}
     * @memberof GeoObject
     */
    'geometry': GeoObjectBaseGeometry;
    /**
     * 
     * @type {GeoObjectType}
     * @memberof GeoObject
     */
    'objectType': GeoObjectType;
    /**
     * 
     * @type {GeoObjectBaseProperties}
     * @memberof GeoObject
     */
    'properties': GeoObjectBaseProperties;
}
/**
 * 
 * @export
 * @interface GeoObject200Response
 */
export interface GeoObject200Response {
    /**
     * 
     * @type {string}
     * @memberof GeoObject200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeoObject200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof GeoObject200Response
     */
    'developerPayload'?: string;
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof GeoObject200Response
     */
    'pageKey'?: string;
    /**
     * 
     * @type {Array<GeoObjectBase>}
     * @memberof GeoObject200Response
     */
    'data'?: Array<GeoObjectBase>;
}
/**
 * 
 * @export
 * @interface GeoObject200ResponseAllOf
 */
export interface GeoObject200ResponseAllOf {
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof GeoObject200ResponseAllOf
     */
    'pageKey'?: string;
    /**
     * 
     * @type {Array<GeoObjectBase>}
     * @memberof GeoObject200ResponseAllOf
     */
    'data'?: Array<GeoObjectBase>;
}
/**
 * 
 * @export
 * @interface GeoObjectBase
 */
export interface GeoObjectBase {
    /**
     * Unique id of this object.
     * @type {string}
     * @memberof GeoObjectBase
     */
    'id': string;
    /**
     * Timestamp when this object was deleted, null if object is not deleted.
     * @type {number}
     * @memberof GeoObjectBase
     */
    'deleted'?: number | null;
    /**
     * Last change timestamp, updated automatically by the backend when object is changed.
     * @type {number}
     * @memberof GeoObjectBase
     */
    'tstamp'?: number;
    /**
     * Unique ID of the processorder this geo-object belongs to.
     * @type {string}
     * @memberof GeoObjectBase
     */
    'processOrderId'?: string;
    /**
     * 
     * @type {GeoObjectBaseGeometry}
     * @memberof GeoObjectBase
     */
    'geometry'?: GeoObjectBaseGeometry;
    /**
     * 
     * @type {GeoObjectType}
     * @memberof GeoObjectBase
     */
    'objectType'?: GeoObjectType;
    /**
     * 
     * @type {GeoObjectBaseProperties}
     * @memberof GeoObjectBase
     */
    'properties'?: GeoObjectBaseProperties;
}
/**
 * @type GeoObjectBaseGeometry
 * Geometry for this geo object.
 * @export
 */
export type GeoObjectBaseGeometry = LineString | Polygon;

/**
 * @type GeoObjectBaseProperties
 * Properties, IsolationZoneProperties if `objectType` is `IsolationZone`, `MultiplierGroupProperties` if `objectType` is `MultiplierGroup`
 * @export
 */
export type GeoObjectBaseProperties = IsolationZoneProperties | MultiplierGroupProperties;

/**
 * 
 * @export
 * @interface GeoObjectDelete200Response
 */
export interface GeoObjectDelete200Response {
    /**
     * 
     * @type {string}
     * @memberof GeoObjectDelete200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeoObjectDelete200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof GeoObjectDelete200Response
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {Array<GeoObject>}
     * @memberof GeoObjectDelete200Response
     */
    'data'?: Array<GeoObject>;
}
/**
 * 
 * @export
 * @interface GeoObjectDelete200ResponseAllOf
 */
export interface GeoObjectDelete200ResponseAllOf {
    /**
     * 
     * @type {Array<GeoObject>}
     * @memberof GeoObjectDelete200ResponseAllOf
     */
    'data'?: Array<GeoObject>;
}
/**
 * 
 * @export
 * @interface GeoObjectPatchRequest
 */
export interface GeoObjectPatchRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof GeoObjectPatchRequest
     */
    'version'?: string;
    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof GeoObjectPatchRequest
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {Array<GeoObjectBase>}
     * @memberof GeoObjectPatchRequest
     */
    'data': Array<GeoObjectBase>;
}
/**
 * 
 * @export
 * @interface GeoObjectPut200Response
 */
export interface GeoObjectPut200Response {
    /**
     * 
     * @type {string}
     * @memberof GeoObjectPut200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeoObjectPut200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof GeoObjectPut200Response
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {Array<GeoObject>}
     * @memberof GeoObjectPut200Response
     */
    'data': Array<GeoObject>;
    /**
     * 
     * @type {{ [key: string]: ActivityPut200ResponseAllOfErrorsValue; }}
     * @memberof GeoObjectPut200Response
     */
    'errors'?: { [key: string]: ActivityPut200ResponseAllOfErrorsValue; };
}
/**
 * 
 * @export
 * @interface GeoObjectPut200ResponseAllOf
 */
export interface GeoObjectPut200ResponseAllOf {
    /**
     * 
     * @type {Array<GeoObject>}
     * @memberof GeoObjectPut200ResponseAllOf
     */
    'data': Array<GeoObject>;
    /**
     * 
     * @type {{ [key: string]: ActivityPut200ResponseAllOfErrorsValue; }}
     * @memberof GeoObjectPut200ResponseAllOf
     */
    'errors'?: { [key: string]: ActivityPut200ResponseAllOfErrorsValue; };
}
/**
 * 
 * @export
 * @interface GeoObjectPutRequest
 */
export interface GeoObjectPutRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof GeoObjectPutRequest
     */
    'version'?: string;
    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof GeoObjectPutRequest
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {Array<GeoObject>}
     * @memberof GeoObjectPutRequest
     */
    'data': Array<GeoObject>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const GeoObjectType = {
    IsolationZone: 'IsolationZone',
    MultiplierGroup: 'MultiplierGroup'
} as const;

export type GeoObjectType = typeof GeoObjectType[keyof typeof GeoObjectType];


/**
 * 
 * @export
 * @interface GeoObjectUndelete200Response
 */
export interface GeoObjectUndelete200Response {
    /**
     * 
     * @type {string}
     * @memberof GeoObjectUndelete200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeoObjectUndelete200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof GeoObjectUndelete200Response
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {Array<GeoObject>}
     * @memberof GeoObjectUndelete200Response
     */
    'data'?: Array<GeoObject>;
    /**
     * 
     * @type {{ [key: string]: ActivityPut200ResponseAllOfErrorsValue; }}
     * @memberof GeoObjectUndelete200Response
     */
    'errors'?: { [key: string]: ActivityPut200ResponseAllOfErrorsValue; };
}
/**
 * 
 * @export
 * @interface GeoObjectUndelete200ResponseAllOf
 */
export interface GeoObjectUndelete200ResponseAllOf {
    /**
     * 
     * @type {Array<GeoObject>}
     * @memberof GeoObjectUndelete200ResponseAllOf
     */
    'data'?: Array<GeoObject>;
    /**
     * 
     * @type {{ [key: string]: ActivityPut200ResponseAllOfErrorsValue; }}
     * @memberof GeoObjectUndelete200ResponseAllOf
     */
    'errors'?: { [key: string]: ActivityPut200ResponseAllOfErrorsValue; };
}
/**
 * 
 * @export
 * @interface Geometry
 */
export interface Geometry {
    /**
     * 
     * @type {GeometryType}
     * @memberof Geometry
     */
    'type': GeometryType;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const GeometryType = {
    Point: 'Point',
    MultiPoint: 'MultiPoint',
    LineString: 'LineString',
    MultiLineString: 'MultiLineString',
    PolyGon: 'PolyGon',
    MultiPolygon: 'MultiPolygon',
    GeometryCollection: 'GeometryCollection'
} as const;

export type GeometryType = typeof GeometryType[keyof typeof GeometryType];


/**
 * 
 * @export
 * @enum {string}
 */

export const HatchStyle = {
    Solid: 'solid',
    ForwardDiagonal: 'forwardDiagonal',
    BackwardDiagonal: 'backwardDiagonal',
    Horizontal: 'horizontal',
    Vertical: 'vertical',
    Grid: 'grid',
    CheckerBoard: 'checkerBoard'
} as const;

export type HatchStyle = typeof HatchStyle[keyof typeof HatchStyle];


/**
 * 
 * @export
 * @interface ImportActivity
 */
export interface ImportActivity {
    /**
     * Unique ID of the user who processed this activity.
     * @type {string}
     * @memberof ImportActivity
     */
    'userId': string | null;
    /**
     * 
     * @type {ImportMatch}
     * @memberof ImportActivity
     */
    'userIdMatch': ImportMatch;
    /**
     * Unique ID of the processorder this activity belongs to.
     * @type {string}
     * @memberof ImportActivity
     */
    'processOrderId': string | null;
    /**
     * Unique ID of the activity-type. Must belong to the same company as the process order `processOrderId` and field `fieldId`. The category of the rough activity-type must be `user-allowed`.
     * @type {string}
     * @memberof ImportActivity
     */
    'activityTypeId': string | null;
    /**
     * 
     * @type {ImportMatch}
     * @memberof ImportActivity
     */
    'activityTypeIdMatch': ImportMatch;
    /**
     * Unique ID of the field this activity was performed on. Must be in the process order referenced by `processOrderId`.
     * @type {string}
     * @memberof ImportActivity
     */
    'fieldId': string | null;
    /**
     * 
     * @type {ImportMatch}
     * @memberof ImportActivity
     */
    'fieldIdMatch': ImportMatch;
    /**
     * Optional user supplied comment. In certain cases system generated comments are appended at the end of this, delimitated by a `|`.
     * @type {string}
     * @memberof ImportActivity
     */
    'userComment': string | null;
    /**
     * Date/time when the activity started, unix timestamp.
     * @type {number}
     * @memberof ImportActivity
     */
    'timeStart': number | null;
    /**
     * Date/time when the activity ended, unix timestamp.
     * @type {number}
     * @memberof ImportActivity
     */
    'timeFinish': number | null;
    /**
     * Processed area in ha.
     * @type {number}
     * @memberof ImportActivity
     */
    'processedArea': number | null;
    /**
     * Working width in meters.
     * @type {number}
     * @memberof ImportActivity
     */
    'workingWidth': number | null;
    /**
     * Working-time in seconds, fractional seconds allowed.
     * @type {number}
     * @memberof ImportActivity
     */
    'workingTime': number | null;
    /**
     * Driving-time in seconds, fractional seconds allowed.
     * @type {number}
     * @memberof ImportActivity
     */
    'drivingTime': number | null;
    /**
     * Setup-time in seconds, fractional seconds allowed.
     * @type {number}
     * @memberof ImportActivity
     */
    'setupTime': number | null;
    /**
     * Pause-time in seconds, fractional seconds allowed.
     * @type {number}
     * @memberof ImportActivity
     */
    'pauseTime': number | null;
    /**
     * Products used in this activity. The products must either be global products of the same region as the company that the field/processorder belongs to or a product of that company. Additional region specific rules like matching SGD Version might apply.
     * @type {Array<ImportActivityProduct>}
     * @memberof ImportActivity
     */
    'products': Array<ImportActivityProduct>;
    /**
     * Equipment used in this activity. The equipment must belong to the same company as the field/processorder.
     * @type {Array<ImportActivityEquipment>}
     * @memberof ImportActivity
     */
    'equipment': Array<ImportActivityEquipment>;
}
/**
 * 
 * @export
 * @interface ImportActivityEquipment
 */
export interface ImportActivityEquipment {
    /**
     * Unique ID of the equipment.
     * @type {string}
     * @memberof ImportActivityEquipment
     */
    'equipmentId': string | null;
    /**
     * 
     * @type {ImportMatch}
     * @memberof ImportActivityEquipment
     */
    'equipmentIdMatch': ImportMatch;
}
/**
 * 
 * @export
 * @interface ImportActivityProduct
 */
export interface ImportActivityProduct {
    /**
     * Unique ID of the referenced product. Must be either a global product or belong to the same company as the field of the activity.
     * @type {string}
     * @memberof ImportActivityProduct
     */
    'productId'?: string | null;
    /**
     * 
     * @type {ImportMatch}
     * @memberof ImportActivityProduct
     */
    'productIdMatch'?: ImportMatch;
    /**
     * The amount in `unitId` units. If `unitId` references a \'per-area\' unit then the total amount is calculated as amount times the processedArea of the activity. \'per-load\' units are not allowed for activity products.
     * @type {number}
     * @memberof ImportActivityProduct
     */
    'amount'?: number | null;
    /**
     * Unique ID of the unit for `amount`. Which units are allowable depends on the product selected and the company-region.
     * @type {string}
     * @memberof ImportActivityProduct
     */
    'unitId'?: string | null;
    /**
     * 
     * @type {ImportMatch}
     * @memberof ImportActivityProduct
     */
    'unitIdMatch'?: ImportMatch;
}
/**
 * 
 * @export
 * @interface ImportMatch
 */
export interface ImportMatch {
    /**
     * Original value from the import data source.
     * @type {string}
     * @memberof ImportMatch
     */
    'originalValue': string | null;
    /**
     * Unique ids of additional potential matches.
     * @type {Array<string>}
     * @memberof ImportMatch
     */
    'additionalMatches': Array<string>;
    /**
     * 
     * @type {ImportMatchType}
     * @memberof ImportMatch
     */
    'matchType': ImportMatchType;
    /**
     * Key that identifies the value in the import data source.
     * @type {string}
     * @memberof ImportMatch
     */
    'originalKey': string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ImportMatchType = {
    NoMatch: 'NoMatch',
    ExactMatch: 'ExactMatch',
    LastMatch: 'LastMatch',
    BestMatch: 'BestMatch'
} as const;

export type ImportMatchType = typeof ImportMatchType[keyof typeof ImportMatchType];


/**
 * 
 * @export
 * @interface ImportObjectInfo
 */
export interface ImportObjectInfo {
    /**
     * 
     * @type {string}
     * @memberof ImportObjectInfo
     */
    'id': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ImportObjectInfo
     */
    'errorUserMessages'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ImportObjectInfo
     */
    'warningUserMessages'?: Array<string>;
    /**
     * The name for an import object, for example the original filename.
     * @type {string}
     * @memberof ImportObjectInfo
     */
    'name'?: string | null;
    /**
     * Currently unused, will hold information for individual parts of multipart objects like shape files.
     * @type {Array<any>}
     * @memberof ImportObjectInfo
     */
    'parts'?: Array<any>;
}
/**
 * 
 * @export
 * @interface InterfacesCnhConnectionStatus200Response
 */
export interface InterfacesCnhConnectionStatus200Response {
    /**
     * 
     * @type {string}
     * @memberof InterfacesCnhConnectionStatus200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof InterfacesCnhConnectionStatus200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof InterfacesCnhConnectionStatus200Response
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {CnhConnectionStatus}
     * @memberof InterfacesCnhConnectionStatus200Response
     */
    'data'?: CnhConnectionStatus;
}
/**
 * 
 * @export
 * @interface InterfacesCnhConnectionStatus200ResponseAllOf
 */
export interface InterfacesCnhConnectionStatus200ResponseAllOf {
    /**
     * 
     * @type {CnhConnectionStatus}
     * @memberof InterfacesCnhConnectionStatus200ResponseAllOf
     */
    'data'?: CnhConnectionStatus;
}
/**
 * 
 * @export
 * @interface InterfacesCnhDownloadTasks200Response
 */
export interface InterfacesCnhDownloadTasks200Response {
    /**
     * 
     * @type {string}
     * @memberof InterfacesCnhDownloadTasks200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof InterfacesCnhDownloadTasks200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof InterfacesCnhDownloadTasks200Response
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {CnhActivityImportStatus}
     * @memberof InterfacesCnhDownloadTasks200Response
     */
    'data'?: CnhActivityImportStatus;
}
/**
 * 
 * @export
 * @interface InterfacesCnhDownloadTasks200ResponseAllOf
 */
export interface InterfacesCnhDownloadTasks200ResponseAllOf {
    /**
     * 
     * @type {CnhActivityImportStatus}
     * @memberof InterfacesCnhDownloadTasks200ResponseAllOf
     */
    'data'?: CnhActivityImportStatus;
}
/**
 * 
 * @export
 * @interface InterfacesCnhDownloadTasksRequest
 */
export interface InterfacesCnhDownloadTasksRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof InterfacesCnhDownloadTasksRequest
     */
    'version'?: string;
    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof InterfacesCnhDownloadTasksRequest
     */
    'developerPayload'?: string;
    /**
     * The id of the CNH company for which to download task files.
     * @type {string}
     * @memberof InterfacesCnhDownloadTasksRequest
     */
    'companyId': string;
    /**
     * A list of CNH task id\'s to download.
     * @type {Array<string>}
     * @memberof InterfacesCnhDownloadTasksRequest
     */
    'taskIds': Array<string>;
}
/**
 * 
 * @export
 * @interface InterfacesCnhDownloadTasksRequestAllOf
 */
export interface InterfacesCnhDownloadTasksRequestAllOf {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof InterfacesCnhDownloadTasksRequestAllOf
     */
    'version'?: string;
    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof InterfacesCnhDownloadTasksRequestAllOf
     */
    'developerPayload'?: string;
    /**
     * The id of the CNH company for which to download task files.
     * @type {string}
     * @memberof InterfacesCnhDownloadTasksRequestAllOf
     */
    'companyId': string;
    /**
     * A list of CNH task id\'s to download.
     * @type {Array<string>}
     * @memberof InterfacesCnhDownloadTasksRequestAllOf
     */
    'taskIds': Array<string>;
}
/**
 * 
 * @export
 * @interface InterfacesCnhLoginRequest
 */
export interface InterfacesCnhLoginRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof InterfacesCnhLoginRequest
     */
    'version'?: string;
    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof InterfacesCnhLoginRequest
     */
    'developerPayload'?: string;
    /**
     * The code supplied to the OAuth callback by CNH.
     * @type {string}
     * @memberof InterfacesCnhLoginRequest
     */
    'authorizationCode': string;
    /**
     * The the redirect url used to get the authorization from CNH.
     * @type {string}
     * @memberof InterfacesCnhLoginRequest
     */
    'redirectUrl': string;
}
/**
 * 
 * @export
 * @interface InterfacesCnhLoginRequestAllOf
 */
export interface InterfacesCnhLoginRequestAllOf {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof InterfacesCnhLoginRequestAllOf
     */
    'version'?: string;
    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof InterfacesCnhLoginRequestAllOf
     */
    'developerPayload'?: string;
    /**
     * The code supplied to the OAuth callback by CNH.
     * @type {string}
     * @memberof InterfacesCnhLoginRequestAllOf
     */
    'authorizationCode': string;
    /**
     * The the redirect url used to get the authorization from CNH.
     * @type {string}
     * @memberof InterfacesCnhLoginRequestAllOf
     */
    'redirectUrl': string;
}
/**
 * 
 * @export
 * @interface InterfacesCnhOverview200Response
 */
export interface InterfacesCnhOverview200Response {
    /**
     * 
     * @type {string}
     * @memberof InterfacesCnhOverview200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof InterfacesCnhOverview200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof InterfacesCnhOverview200Response
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {CnhOverview}
     * @memberof InterfacesCnhOverview200Response
     */
    'data'?: CnhOverview;
}
/**
 * 
 * @export
 * @interface InterfacesCnhOverview200ResponseAllOf
 */
export interface InterfacesCnhOverview200ResponseAllOf {
    /**
     * 
     * @type {CnhOverview}
     * @memberof InterfacesCnhOverview200ResponseAllOf
     */
    'data'?: CnhOverview;
}
/**
 * 
 * @export
 * @interface InterfacesCnhTasks200Response
 */
export interface InterfacesCnhTasks200Response {
    /**
     * 
     * @type {string}
     * @memberof InterfacesCnhTasks200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof InterfacesCnhTasks200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof InterfacesCnhTasks200Response
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {Array<CnhTask>}
     * @memberof InterfacesCnhTasks200Response
     */
    'data'?: Array<CnhTask>;
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof InterfacesCnhTasks200Response
     */
    'pageKey'?: string;
}
/**
 * 
 * @export
 * @interface InterfacesCnhTasks200ResponseAllOf
 */
export interface InterfacesCnhTasks200ResponseAllOf {
    /**
     * 
     * @type {Array<CnhTask>}
     * @memberof InterfacesCnhTasks200ResponseAllOf
     */
    'data'?: Array<CnhTask>;
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof InterfacesCnhTasks200ResponseAllOf
     */
    'pageKey'?: string;
}
/**
 * 
 * @export
 * @interface IsolationZoneProperties
 */
export interface IsolationZoneProperties {
    /**
     * 
     * @type {string}
     * @memberof IsolationZoneProperties
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof IsolationZoneProperties
     */
    'color'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IsolationZoneProperties
     */
    'note'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IsolationZoneProperties
     */
    'width'?: number | null;
}
/**
 * 
 * @export
 * @interface LandCadastre
 */
export interface LandCadastre {
    /**
     * 
     * @type {string}
     * @memberof LandCadastre
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LandCadastre
     */
    'cadastralMunicipality': string;
    /**
     * 
     * @type {string}
     * @memberof LandCadastre
     */
    'parcelNumber': string;
    /**
     * 
     * @type {Polygon}
     * @memberof LandCadastre
     */
    'geometry': Polygon;
}
/**
 * 
 * @export
 * @interface LandCadastreSearchResult
 */
export interface LandCadastreSearchResult {
    /**
     * 
     * @type {string}
     * @memberof LandCadastreSearchResult
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof LandCadastreSearchResult
     */
    'lat': number;
    /**
     * 
     * @type {number}
     * @memberof LandCadastreSearchResult
     */
    'lon': number;
}
/**
 * 
 * @export
 * @interface LastUsedProductAmount
 */
export interface LastUsedProductAmount {
    /**
     * The amount of the last usage in `unitId` units.
     * @type {number}
     * @memberof LastUsedProductAmount
     */
    'amount'?: number | null;
    /**
     * The unit of the last usage for `amount`.
     * @type {string}
     * @memberof LastUsedProductAmount
     */
    'unitId'?: string | null;
}
/**
 * 
 * @export
 * @interface LineString
 */
export interface LineString {
    /**
     * 
     * @type {GeometryType}
     * @memberof LineString
     */
    'type': GeometryType;
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof LineString
     */
    'coordinates': Array<Array<number>>;
}
/**
 * 
 * @export
 * @interface LineStringAllOf
 */
export interface LineStringAllOf {
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof LineStringAllOf
     */
    'coordinates'?: Array<Array<number>>;
}
/**
 * 
 * @export
 * @interface MapCopyright
 */
export interface MapCopyright {
    /**
     * Copyright text to be shown.
     * @type {string}
     * @memberof MapCopyright
     */
    'text'?: string;
    /**
     * Link to copyright holder / data source.
     * @type {string}
     * @memberof MapCopyright
     */
    'link'?: string | null;
    /**
     * License identifier.
     * @type {string}
     * @memberof MapCopyright
     */
    'licenseId'?: string | null;
    /**
     * License name.
     * @type {string}
     * @memberof MapCopyright
     */
    'licenseName'?: string | null;
    /**
     * Link to license.
     * @type {string}
     * @memberof MapCopyright
     */
    'licenseUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface MapLayerFieldUtilization200Response
 */
export interface MapLayerFieldUtilization200Response {
    /**
     * 
     * @type {string}
     * @memberof MapLayerFieldUtilization200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof MapLayerFieldUtilization200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof MapLayerFieldUtilization200Response
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {Array<MapCopyright>}
     * @memberof MapLayerFieldUtilization200Response
     */
    'copyrights'?: Array<MapCopyright>;
    /**
     * 
     * @type {Array<FieldUtilization>}
     * @memberof MapLayerFieldUtilization200Response
     */
    'data'?: Array<FieldUtilization>;
}
/**
 * 
 * @export
 * @interface MapLayerFieldUtilization200ResponseAllOf
 */
export interface MapLayerFieldUtilization200ResponseAllOf {
    /**
     * 
     * @type {Array<MapCopyright>}
     * @memberof MapLayerFieldUtilization200ResponseAllOf
     */
    'copyrights'?: Array<MapCopyright>;
    /**
     * 
     * @type {Array<FieldUtilization>}
     * @memberof MapLayerFieldUtilization200ResponseAllOf
     */
    'data'?: Array<FieldUtilization>;
}
/**
 * 
 * @export
 * @interface MapLayerLandCadastre200Response
 */
export interface MapLayerLandCadastre200Response {
    /**
     * 
     * @type {string}
     * @memberof MapLayerLandCadastre200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof MapLayerLandCadastre200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof MapLayerLandCadastre200Response
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {Array<MapCopyright>}
     * @memberof MapLayerLandCadastre200Response
     */
    'copyrights'?: Array<MapCopyright>;
    /**
     * 
     * @type {Array<LandCadastre>}
     * @memberof MapLayerLandCadastre200Response
     */
    'data'?: Array<LandCadastre>;
}
/**
 * 
 * @export
 * @interface MapLayerLandCadastre200ResponseAllOf
 */
export interface MapLayerLandCadastre200ResponseAllOf {
    /**
     * 
     * @type {Array<MapCopyright>}
     * @memberof MapLayerLandCadastre200ResponseAllOf
     */
    'copyrights'?: Array<MapCopyright>;
    /**
     * 
     * @type {Array<LandCadastre>}
     * @memberof MapLayerLandCadastre200ResponseAllOf
     */
    'data'?: Array<LandCadastre>;
}
/**
 * 
 * @export
 * @interface MapLayerLandCadastreSearch200Response
 */
export interface MapLayerLandCadastreSearch200Response {
    /**
     * 
     * @type {string}
     * @memberof MapLayerLandCadastreSearch200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof MapLayerLandCadastreSearch200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof MapLayerLandCadastreSearch200Response
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {Array<LandCadastreSearchResult>}
     * @memberof MapLayerLandCadastreSearch200Response
     */
    'data'?: Array<LandCadastreSearchResult>;
}
/**
 * 
 * @export
 * @interface MapLayerLandCadastreSearch200ResponseAllOf
 */
export interface MapLayerLandCadastreSearch200ResponseAllOf {
    /**
     * 
     * @type {Array<LandCadastreSearchResult>}
     * @memberof MapLayerLandCadastreSearch200ResponseAllOf
     */
    'data'?: Array<LandCadastreSearchResult>;
}
/**
 * 
 * @export
 * @interface MapLayerWaterProtectionZone200Response
 */
export interface MapLayerWaterProtectionZone200Response {
    /**
     * 
     * @type {string}
     * @memberof MapLayerWaterProtectionZone200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof MapLayerWaterProtectionZone200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof MapLayerWaterProtectionZone200Response
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {Array<MapCopyright>}
     * @memberof MapLayerWaterProtectionZone200Response
     */
    'copyrights'?: Array<MapCopyright>;
    /**
     * 
     * @type {Array<WaterProtectionZone>}
     * @memberof MapLayerWaterProtectionZone200Response
     */
    'data'?: Array<WaterProtectionZone>;
}
/**
 * 
 * @export
 * @interface MapLayerWaterProtectionZone200ResponseAllOf
 */
export interface MapLayerWaterProtectionZone200ResponseAllOf {
    /**
     * 
     * @type {Array<MapCopyright>}
     * @memberof MapLayerWaterProtectionZone200ResponseAllOf
     */
    'copyrights'?: Array<MapCopyright>;
    /**
     * 
     * @type {Array<WaterProtectionZone>}
     * @memberof MapLayerWaterProtectionZone200ResponseAllOf
     */
    'data'?: Array<WaterProtectionZone>;
}
/**
 * 
 * @export
 * @interface MapLayerWaterProtectionZoneIntersects200Response
 */
export interface MapLayerWaterProtectionZoneIntersects200Response {
    /**
     * 
     * @type {string}
     * @memberof MapLayerWaterProtectionZoneIntersects200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof MapLayerWaterProtectionZoneIntersects200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof MapLayerWaterProtectionZoneIntersects200Response
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {Array<WaterProtectionZoneIntersection>}
     * @memberof MapLayerWaterProtectionZoneIntersects200Response
     */
    'data'?: Array<WaterProtectionZoneIntersection>;
}
/**
 * 
 * @export
 * @interface MapLayerWaterProtectionZoneIntersects200ResponseAllOf
 */
export interface MapLayerWaterProtectionZoneIntersects200ResponseAllOf {
    /**
     * 
     * @type {Array<WaterProtectionZoneIntersection>}
     * @memberof MapLayerWaterProtectionZoneIntersects200ResponseAllOf
     */
    'data'?: Array<WaterProtectionZoneIntersection>;
}
/**
 * 
 * @export
 * @interface MapLayerWaterProtectionZoneIntersectsRequest
 */
export interface MapLayerWaterProtectionZoneIntersectsRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof MapLayerWaterProtectionZoneIntersectsRequest
     */
    'version'?: string;
    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof MapLayerWaterProtectionZoneIntersectsRequest
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {Array<MapLayerWaterProtectionZoneIntersectsRequestAllOfGeometriesInner>}
     * @memberof MapLayerWaterProtectionZoneIntersectsRequest
     */
    'geometries': Array<MapLayerWaterProtectionZoneIntersectsRequestAllOfGeometriesInner>;
}
/**
 * 
 * @export
 * @interface MapLayerWaterProtectionZoneIntersectsRequestAllOf
 */
export interface MapLayerWaterProtectionZoneIntersectsRequestAllOf {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof MapLayerWaterProtectionZoneIntersectsRequestAllOf
     */
    'version'?: string;
    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof MapLayerWaterProtectionZoneIntersectsRequestAllOf
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {Array<MapLayerWaterProtectionZoneIntersectsRequestAllOfGeometriesInner>}
     * @memberof MapLayerWaterProtectionZoneIntersectsRequestAllOf
     */
    'geometries': Array<MapLayerWaterProtectionZoneIntersectsRequestAllOfGeometriesInner>;
}
/**
 * 
 * @export
 * @interface MapLayerWaterProtectionZoneIntersectsRequestAllOfGeometriesInner
 */
export interface MapLayerWaterProtectionZoneIntersectsRequestAllOfGeometriesInner {
    /**
     * Id for this geometry, will be included in the response to map respone object to request objects.
     * @type {string}
     * @memberof MapLayerWaterProtectionZoneIntersectsRequestAllOfGeometriesInner
     */
    'id': string;
    /**
     * 
     * @type {MapLayerWaterProtectionZoneIntersectsRequestAllOfGeometriesInnerGeometry}
     * @memberof MapLayerWaterProtectionZoneIntersectsRequestAllOfGeometriesInner
     */
    'geometry': MapLayerWaterProtectionZoneIntersectsRequestAllOfGeometriesInnerGeometry;
}
/**
 * @type MapLayerWaterProtectionZoneIntersectsRequestAllOfGeometriesInnerGeometry
 * The geometry to intersect with the water protection zone layer.
 * @export
 */
export type MapLayerWaterProtectionZoneIntersectsRequestAllOfGeometriesInnerGeometry = MultiPolygon | Point | Polygon;

/**
 * 
 * @export
 * @enum {string}
 */

export const MoveActivityMode = {
    Autodetect: 'autodetect',
    CopyField: 'copyField',
    ReplaceField: 'replaceField',
    NoField: 'noField'
} as const;

export type MoveActivityMode = typeof MoveActivityMode[keyof typeof MoveActivityMode];


/**
 * 
 * @export
 * @interface MoveActivityParameters
 */
export interface MoveActivityParameters {
    /**
     * The id of the source activity.
     * @type {string}
     * @memberof MoveActivityParameters
     */
    'sourceActivityId': string;
    /**
     * The id of the field in the destination process-order that the activity should be moved to. Only relevant if `mode` is `replaceField`.
     * @type {string}
     * @memberof MoveActivityParameters
     */
    'destinationFieldId'?: string | null;
    /**
     * 
     * @type {MoveActivityMode}
     * @memberof MoveActivityParameters
     */
    'mode': MoveActivityMode;
}
/**
 * 
 * @export
 * @interface MoveActivityResponse
 */
export interface MoveActivityResponse {
    /**
     * The id of the source activity, before being moved.
     * @type {string}
     * @memberof MoveActivityResponse
     */
    'sourceActivityId': string;
    /**
     * The id of the activity in the destination process-order, only set if the activity was moved.
     * @type {string}
     * @memberof MoveActivityResponse
     */
    'destinationActivityId'?: string | null;
    /**
     * The id of the field in the destination process-order that this activity was matched to.
     * @type {string}
     * @memberof MoveActivityResponse
     */
    'destinationFieldId'?: string | null;
    /**
     * 
     * @type {MoveActivityState}
     * @memberof MoveActivityResponse
     */
    'result': MoveActivityState;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MoveActivityState = {
    CopyField: 'copyField',
    ReplaceField: 'replaceField',
    NoField: 'noField',
    SameProcessOrder: 'sameProcessOrder',
    WrongProcessOrder: 'wrongProcessOrder',
    AccessDenied: 'accessDenied',
    NoMatchFound: 'noMatchFound'
} as const;

export type MoveActivityState = typeof MoveActivityState[keyof typeof MoveActivityState];


/**
 * 
 * @export
 * @interface MultiLineString
 */
export interface MultiLineString {
    /**
     * 
     * @type {GeometryType}
     * @memberof MultiLineString
     */
    'type': GeometryType;
    /**
     * 
     * @type {Array<Array<Array<number>>>}
     * @memberof MultiLineString
     */
    'coordinates': Array<Array<Array<number>>>;
}
/**
 * 
 * @export
 * @interface MultiLineStringAllOf
 */
export interface MultiLineStringAllOf {
    /**
     * 
     * @type {Array<Array<Array<number>>>}
     * @memberof MultiLineStringAllOf
     */
    'coordinates'?: Array<Array<Array<number>>>;
}
/**
 * 
 * @export
 * @interface MultiPolygon
 */
export interface MultiPolygon {
    /**
     * 
     * @type {GeometryType}
     * @memberof MultiPolygon
     */
    'type': GeometryType;
    /**
     * 
     * @type {Array<Array<Array<Array<number>>>>}
     * @memberof MultiPolygon
     */
    'coordinates': Array<Array<Array<Array<number>>>>;
}
/**
 * 
 * @export
 * @interface MultiPolygonAllOf
 */
export interface MultiPolygonAllOf {
    /**
     * 
     * @type {Array<Array<Array<Array<number>>>>}
     * @memberof MultiPolygonAllOf
     */
    'coordinates'?: Array<Array<Array<Array<number>>>>;
}
/**
 * 
 * @export
 * @interface MultiplierGroupProperties
 */
export interface MultiplierGroupProperties {
    /**
     * 
     * @type {string}
     * @memberof MultiplierGroupProperties
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof MultiplierGroupProperties
     */
    'color'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MultiplierGroupProperties
     */
    'note'?: string | null;
}
/**
 * 
 * @export
 * @interface Pest
 */
export interface Pest {
    /**
     * Unique id of this object.
     * @type {string}
     * @memberof Pest
     */
    'id': string;
    /**
     * Timestamp when this object was deleted, null if object is not deleted.
     * @type {number}
     * @memberof Pest
     */
    'deleted'?: number | null;
    /**
     * Last change timestamp, updated automatically by the backend when object is changed.
     * @type {number}
     * @memberof Pest
     */
    'tstamp': number;
    /**
     * Name of the pest.
     * @type {string}
     * @memberof Pest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface PestBase
 */
export interface PestBase {
    /**
     * Unique id of this object.
     * @type {string}
     * @memberof PestBase
     */
    'id': string;
    /**
     * Timestamp when this object was deleted, null if object is not deleted.
     * @type {number}
     * @memberof PestBase
     */
    'deleted'?: number | null;
    /**
     * Last change timestamp, updated automatically by the backend when object is changed.
     * @type {number}
     * @memberof PestBase
     */
    'tstamp'?: number;
    /**
     * Name of the pest.
     * @type {string}
     * @memberof PestBase
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface PestObjects200Response
 */
export interface PestObjects200Response {
    /**
     * 
     * @type {string}
     * @memberof PestObjects200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof PestObjects200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof PestObjects200Response
     */
    'developerPayload'?: string;
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof PestObjects200Response
     */
    'pageKey'?: string;
    /**
     * 
     * @type {Array<PestBase>}
     * @memberof PestObjects200Response
     */
    'data'?: Array<PestBase>;
}
/**
 * 
 * @export
 * @interface PestObjects200ResponseAllOf
 */
export interface PestObjects200ResponseAllOf {
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof PestObjects200ResponseAllOf
     */
    'pageKey'?: string;
    /**
     * 
     * @type {Array<PestBase>}
     * @memberof PestObjects200ResponseAllOf
     */
    'data'?: Array<PestBase>;
}
/**
 * 
 * @export
 * @interface PesticideIndication
 */
export interface PesticideIndication {
    /**
     * Unique id of this object.
     * @type {string}
     * @memberof PesticideIndication
     */
    'id': string;
    /**
     * Timestamp when this object was deleted, null if object is not deleted.
     * @type {number}
     * @memberof PesticideIndication
     */
    'deleted'?: number | null;
    /**
     * Last change timestamp, updated automatically by the backend when object is changed.
     * @type {number}
     * @memberof PesticideIndication
     */
    'tstamp': number;
    /**
     * ID of the pesticide product for which this indication is valid.
     * @type {string}
     * @memberof PesticideIndication
     */
    'pesticideId': string;
    /**
     * ID for this indication in the source database. (Ages, ...)
     * @type {string}
     * @memberof PesticideIndication
     */
    'externalId': string;
    /**
     * Date from which this indication is valid.
     * @type {number}
     * @memberof PesticideIndication
     */
    'approvalStart': number;
    /**
     * Date until this indication is valid. If null then the end date is not yet known.
     * @type {number}
     * @memberof PesticideIndication
     */
    'approvalEnd'?: number | null;
    /**
     * List of `Crop` IDs for which this indication is valid.
     * @type {Array<string>}
     * @memberof PesticideIndication
     */
    'cropIds': Array<string>;
    /**
     * List of `Pest` IDs for which this indication is valid.
     * @type {Array<string>}
     * @memberof PesticideIndication
     */
    'pestIds': Array<string>;
    /**
     * Maximum number of times this indication can be used per vegetation (=crop year)
     * @type {number}
     * @memberof PesticideIndication
     */
    'maxApplicationsPerVegetation'?: number | null;
    /**
     * Maximum number of times this indication can be used per unique infestation.
     * @type {number}
     * @memberof PesticideIndication
     */
    'maxApplicationsPerInfestation'?: number | null;
    /**
     * Minimum wait time between applications in hours.
     * @type {number}
     * @memberof PesticideIndication
     */
    'minTimeBetweenApplications'?: number | null;
    /**
     * Maximum wait time between applications in hours.
     * @type {number}
     * @memberof PesticideIndication
     */
    'maxTimeBetweenApplications'?: number | null;
    /**
     * Minimum wait time between applications in `timeBetweenApplicationsUnit`.
     * @type {number}
     * @memberof PesticideIndication
     */
    'minTimeBetweenApplicationsOriginal'?: number | null;
    /**
     * Maximum wait time between applicatiosn in `timeBetweenApplicationsUnit`.
     * @type {number}
     * @memberof PesticideIndication
     */
    'maxTimeBetweenApplicationsOriginal'?: number | null;
    /**
     * Unit (free text) for `minTimeBetweenApplicationsOriginal` and `maxTimeBetweenApplicationsOriginal`.
     * @type {string}
     * @memberof PesticideIndication
     */
    'timeBetweenApplicationsUnit'?: string | null;
    /**
     * If true time between indications in the original datasource did contain a value but the value could not be interpreted.
     * @type {boolean}
     * @memberof PesticideIndication
     */
    'timeBetweenApplicationsInvalid': boolean;
    /**
     * Maximum amount allowed per application in `maxAmountUnitId`.
     * @type {number}
     * @memberof PesticideIndication
     */
    'maxAmount'?: number | null;
    /**
     * Unit for `maxAmount`.
     * @type {string}
     * @memberof PesticideIndication
     */
    'maxAmountUnitId'?: string | null;
    /**
     * If false it means the data source contained multiple amounts. The highest amount that could be converted into known units was used.
     * @type {boolean}
     * @memberof PesticideIndication
     */
    'amountUnique': boolean;
    /**
     * Waiting period until harvest in days.
     * @type {number}
     * @memberof PesticideIndication
     */
    'waitPeriod'?: number | null;
    /**
     * If false it means the data source contained multiple waiting periods. The highest was used.
     * @type {boolean}
     * @memberof PesticideIndication
     */
    'waitPeriodUnique': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PesticideIndication
     */
    'isSplitting': boolean;
    /**
     * Additional limitations by crop id. Freetext.
     * @type {{ [key: string]: Array<string>; }}
     * @memberof PesticideIndication
     */
    'cropLimitations': { [key: string]: Array<string>; };
    /**
     * Additional limitations by pest id. Freetext.
     * @type {{ [key: string]: Array<string>; }}
     * @memberof PesticideIndication
     */
    'pestLimitations': { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @interface PesticideIndicationBase
 */
export interface PesticideIndicationBase {
    /**
     * Unique id of this object.
     * @type {string}
     * @memberof PesticideIndicationBase
     */
    'id': string;
    /**
     * Timestamp when this object was deleted, null if object is not deleted.
     * @type {number}
     * @memberof PesticideIndicationBase
     */
    'deleted'?: number | null;
    /**
     * Last change timestamp, updated automatically by the backend when object is changed.
     * @type {number}
     * @memberof PesticideIndicationBase
     */
    'tstamp'?: number;
    /**
     * ID of the pesticide product for which this indication is valid.
     * @type {string}
     * @memberof PesticideIndicationBase
     */
    'pesticideId'?: string;
    /**
     * ID for this indication in the source database. (Ages, ...)
     * @type {string}
     * @memberof PesticideIndicationBase
     */
    'externalId'?: string;
    /**
     * Date from which this indication is valid.
     * @type {number}
     * @memberof PesticideIndicationBase
     */
    'approvalStart'?: number;
    /**
     * Date until this indication is valid. If null then the end date is not yet known.
     * @type {number}
     * @memberof PesticideIndicationBase
     */
    'approvalEnd'?: number | null;
    /**
     * List of `Crop` IDs for which this indication is valid.
     * @type {Array<string>}
     * @memberof PesticideIndicationBase
     */
    'cropIds'?: Array<string>;
    /**
     * List of `Pest` IDs for which this indication is valid.
     * @type {Array<string>}
     * @memberof PesticideIndicationBase
     */
    'pestIds'?: Array<string>;
    /**
     * Maximum number of times this indication can be used per vegetation (=crop year)
     * @type {number}
     * @memberof PesticideIndicationBase
     */
    'maxApplicationsPerVegetation'?: number | null;
    /**
     * Maximum number of times this indication can be used per unique infestation.
     * @type {number}
     * @memberof PesticideIndicationBase
     */
    'maxApplicationsPerInfestation'?: number | null;
    /**
     * Minimum wait time between applications in hours.
     * @type {number}
     * @memberof PesticideIndicationBase
     */
    'minTimeBetweenApplications'?: number | null;
    /**
     * Maximum wait time between applications in hours.
     * @type {number}
     * @memberof PesticideIndicationBase
     */
    'maxTimeBetweenApplications'?: number | null;
    /**
     * Minimum wait time between applications in `timeBetweenApplicationsUnit`.
     * @type {number}
     * @memberof PesticideIndicationBase
     */
    'minTimeBetweenApplicationsOriginal'?: number | null;
    /**
     * Maximum wait time between applicatiosn in `timeBetweenApplicationsUnit`.
     * @type {number}
     * @memberof PesticideIndicationBase
     */
    'maxTimeBetweenApplicationsOriginal'?: number | null;
    /**
     * Unit (free text) for `minTimeBetweenApplicationsOriginal` and `maxTimeBetweenApplicationsOriginal`.
     * @type {string}
     * @memberof PesticideIndicationBase
     */
    'timeBetweenApplicationsUnit'?: string | null;
    /**
     * If true time between indications in the original datasource did contain a value but the value could not be interpreted.
     * @type {boolean}
     * @memberof PesticideIndicationBase
     */
    'timeBetweenApplicationsInvalid'?: boolean;
    /**
     * Maximum amount allowed per application in `maxAmountUnitId`.
     * @type {number}
     * @memberof PesticideIndicationBase
     */
    'maxAmount'?: number | null;
    /**
     * Unit for `maxAmount`.
     * @type {string}
     * @memberof PesticideIndicationBase
     */
    'maxAmountUnitId'?: string | null;
    /**
     * If false it means the data source contained multiple amounts. The highest amount that could be converted into known units was used.
     * @type {boolean}
     * @memberof PesticideIndicationBase
     */
    'amountUnique'?: boolean;
    /**
     * Waiting period until harvest in days.
     * @type {number}
     * @memberof PesticideIndicationBase
     */
    'waitPeriod'?: number | null;
    /**
     * If false it means the data source contained multiple waiting periods. The highest was used.
     * @type {boolean}
     * @memberof PesticideIndicationBase
     */
    'waitPeriodUnique'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PesticideIndicationBase
     */
    'isSplitting'?: boolean;
    /**
     * Additional limitations by crop id. Freetext.
     * @type {{ [key: string]: Array<string>; }}
     * @memberof PesticideIndicationBase
     */
    'cropLimitations'?: { [key: string]: Array<string>; };
    /**
     * Additional limitations by pest id. Freetext.
     * @type {{ [key: string]: Array<string>; }}
     * @memberof PesticideIndicationBase
     */
    'pestLimitations'?: { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @interface PlantVariety
 */
export interface PlantVariety {
    /**
     * Unique id of this object.
     * @type {string}
     * @memberof PlantVariety
     */
    'id': string;
    /**
     * Timestamp when this object was deleted, null if object is not deleted.
     * @type {number}
     * @memberof PlantVariety
     */
    'deleted'?: number | null;
    /**
     * Last change timestamp, updated automatically by the backend when object is changed.
     * @type {number}
     * @memberof PlantVariety
     */
    'tstamp': number;
    /**
     * 
     * @type {string}
     * @memberof PlantVariety
     */
    'name': string;
    /**
     * ID in the source database.
     * @type {string}
     * @memberof PlantVariety
     */
    'externalId': string;
    /**
     * Owner company, if null the variety is a global variety.
     * @type {string}
     * @memberof PlantVariety
     */
    'companyId'?: string | null;
    /**
     * Plant species.
     * @type {string}
     * @memberof PlantVariety
     */
    'speciesId': string;
    /**
     * Primary color for `hatchStyle`.
     * @type {string}
     * @memberof PlantVariety
     */
    'mapPrimaryColor'?: string | null;
    /**
     * Secondary color for `hatchStyle`.
     * @type {string}
     * @memberof PlantVariety
     */
    'mapSecondaryColor'?: string | null;
    /**
     * 
     * @type {PlantVarietyBaseMapHatchStyle}
     * @memberof PlantVariety
     */
    'mapHatchStyle'?: PlantVarietyBaseMapHatchStyle | null;
}
/**
 * 
 * @export
 * @interface PlantVarietyBase
 */
export interface PlantVarietyBase {
    /**
     * Unique id of this object.
     * @type {string}
     * @memberof PlantVarietyBase
     */
    'id': string;
    /**
     * Timestamp when this object was deleted, null if object is not deleted.
     * @type {number}
     * @memberof PlantVarietyBase
     */
    'deleted'?: number | null;
    /**
     * Last change timestamp, updated automatically by the backend when object is changed.
     * @type {number}
     * @memberof PlantVarietyBase
     */
    'tstamp'?: number;
    /**
     * 
     * @type {string}
     * @memberof PlantVarietyBase
     */
    'name'?: string;
    /**
     * ID in the source database.
     * @type {string}
     * @memberof PlantVarietyBase
     */
    'externalId'?: string;
    /**
     * Owner company, if null the variety is a global variety.
     * @type {string}
     * @memberof PlantVarietyBase
     */
    'companyId'?: string | null;
    /**
     * Plant species.
     * @type {string}
     * @memberof PlantVarietyBase
     */
    'speciesId'?: string;
    /**
     * Primary color for `hatchStyle`.
     * @type {string}
     * @memberof PlantVarietyBase
     */
    'mapPrimaryColor'?: string | null;
    /**
     * Secondary color for `hatchStyle`.
     * @type {string}
     * @memberof PlantVarietyBase
     */
    'mapSecondaryColor'?: string | null;
    /**
     * 
     * @type {PlantVarietyBaseMapHatchStyle}
     * @memberof PlantVarietyBase
     */
    'mapHatchStyle'?: PlantVarietyBaseMapHatchStyle | null;
}
/**
 * @type PlantVarietyBaseMapHatchStyle
 * Hatch style used to color fields in map, used with `$mapPrimaryColor` and `$mapSecondaryColor`.
 * @export
 */
export type PlantVarietyBaseMapHatchStyle = HatchStyle;

/**
 * 
 * @export
 * @interface PlantVarietyObjects200Response
 */
export interface PlantVarietyObjects200Response {
    /**
     * 
     * @type {string}
     * @memberof PlantVarietyObjects200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof PlantVarietyObjects200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof PlantVarietyObjects200Response
     */
    'developerPayload'?: string;
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof PlantVarietyObjects200Response
     */
    'pageKey'?: string;
    /**
     * 
     * @type {Array<PlantVarietyBase>}
     * @memberof PlantVarietyObjects200Response
     */
    'data'?: Array<PlantVarietyBase>;
}
/**
 * 
 * @export
 * @interface PlantVarietyObjects200ResponseAllOf
 */
export interface PlantVarietyObjects200ResponseAllOf {
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof PlantVarietyObjects200ResponseAllOf
     */
    'pageKey'?: string;
    /**
     * 
     * @type {Array<PlantVarietyBase>}
     * @memberof PlantVarietyObjects200ResponseAllOf
     */
    'data'?: Array<PlantVarietyBase>;
}
/**
 * 
 * @export
 * @interface Point
 */
export interface Point {
    /**
     * 
     * @type {GeometryType}
     * @memberof Point
     */
    'type': GeometryType;
    /**
     * 
     * @type {Array<number>}
     * @memberof Point
     */
    'coordinates': Array<number>;
}
/**
 * 
 * @export
 * @interface PointAllOf
 */
export interface PointAllOf {
    /**
     * 
     * @type {Array<number>}
     * @memberof PointAllOf
     */
    'coordinates'?: Array<number>;
}
/**
 * 
 * @export
 * @interface Polygon
 */
export interface Polygon {
    /**
     * 
     * @type {GeometryType}
     * @memberof Polygon
     */
    'type': GeometryType;
    /**
     * 
     * @type {Array<Array<Array<number>>>}
     * @memberof Polygon
     */
    'coordinates': Array<Array<Array<number>>>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ProcessingState = {
    Locked: 'locked',
    Modified: 'modified'
} as const;

export type ProcessingState = typeof ProcessingState[keyof typeof ProcessingState];


/**
 * 
 * @export
 * @interface ProductCategory
 */
export interface ProductCategory {
    /**
     * Unique id of this object.
     * @type {string}
     * @memberof ProductCategory
     */
    'id': string;
    /**
     * Timestamp when this object was deleted, null if object is not deleted.
     * @type {number}
     * @memberof ProductCategory
     */
    'deleted'?: number | null;
    /**
     * Last change timestamp, updated automatically by the backend when object is changed.
     * @type {number}
     * @memberof ProductCategory
     */
    'tstamp': number;
    /**
     * 
     * @type {string}
     * @memberof ProductCategory
     */
    'name': string;
    /**
     * 
     * @type {ProductCategoryType}
     * @memberof ProductCategory
     */
    'type': ProductCategoryType;
    /**
     * 
     * @type {string}
     * @memberof ProductCategory
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductCategoryBase
 */
export interface ProductCategoryBase {
    /**
     * Unique id of this object.
     * @type {string}
     * @memberof ProductCategoryBase
     */
    'id': string;
    /**
     * Timestamp when this object was deleted, null if object is not deleted.
     * @type {number}
     * @memberof ProductCategoryBase
     */
    'deleted'?: number | null;
    /**
     * Last change timestamp, updated automatically by the backend when object is changed.
     * @type {number}
     * @memberof ProductCategoryBase
     */
    'tstamp'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryBase
     */
    'name'?: string;
    /**
     * 
     * @type {ProductCategoryType}
     * @memberof ProductCategoryBase
     */
    'type'?: ProductCategoryType;
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryBase
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductCategoryObjects200Response
 */
export interface ProductCategoryObjects200Response {
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryObjects200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryObjects200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof ProductCategoryObjects200Response
     */
    'developerPayload'?: string;
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof ProductCategoryObjects200Response
     */
    'pageKey'?: string;
    /**
     * 
     * @type {Array<ProductCategoryBase>}
     * @memberof ProductCategoryObjects200Response
     */
    'data'?: Array<ProductCategoryBase>;
}
/**
 * 
 * @export
 * @interface ProductCategoryObjects200ResponseAllOf
 */
export interface ProductCategoryObjects200ResponseAllOf {
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof ProductCategoryObjects200ResponseAllOf
     */
    'pageKey'?: string;
    /**
     * 
     * @type {Array<ProductCategoryBase>}
     * @memberof ProductCategoryObjects200ResponseAllOf
     */
    'data'?: Array<ProductCategoryBase>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ProductCategoryType = {
    Crop: 'crop',
    Feco: 'feco',
    Femi: 'femi',
    Feoa: 'feoa',
    Herb: 'herb',
    Harvest: 'harvest',
    Seed: 'seed',
    Fesec: 'fesec',
    Misc: 'misc'
} as const;

export type ProductCategoryType = typeof ProductCategoryType[keyof typeof ProductCategoryType];


/**
 * 
 * @export
 * @interface ProductPesticideIndicationList200Response
 */
export interface ProductPesticideIndicationList200Response {
    /**
     * 
     * @type {string}
     * @memberof ProductPesticideIndicationList200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductPesticideIndicationList200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof ProductPesticideIndicationList200Response
     */
    'developerPayload'?: string;
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof ProductPesticideIndicationList200Response
     */
    'pageKey'?: string;
    /**
     * 
     * @type {Array<PesticideIndicationBase>}
     * @memberof ProductPesticideIndicationList200Response
     */
    'data'?: Array<PesticideIndicationBase>;
}
/**
 * 
 * @export
 * @interface ProductPesticideIndicationList200ResponseAllOf
 */
export interface ProductPesticideIndicationList200ResponseAllOf {
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof ProductPesticideIndicationList200ResponseAllOf
     */
    'pageKey'?: string;
    /**
     * 
     * @type {Array<PesticideIndicationBase>}
     * @memberof ProductPesticideIndicationList200ResponseAllOf
     */
    'data'?: Array<PesticideIndicationBase>;
}
/**
 * 
 * @export
 * @interface RequestTerrazoFieldTimestamp
 */
export interface RequestTerrazoFieldTimestamp {
    /**
     * 
     * @type {string}
     * @memberof RequestTerrazoFieldTimestamp
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestTerrazoFieldTimestamp
     */
    'developerPayload'?: string | null;
    /**
     * The id of the field for which to return a list of timestamps.
     * @type {string}
     * @memberof RequestTerrazoFieldTimestamp
     */
    'fieldId': string;
    /**
     * Beginning of time range for which to return results.
     * @type {number}
     * @memberof RequestTerrazoFieldTimestamp
     */
    'timeStart': number;
    /**
     * End of time range for which to return results.
     * @type {number}
     * @memberof RequestTerrazoFieldTimestamp
     */
    'timeEnd': number;
}
/**
 * 
 * @export
 * @interface RequestTerrazoFieldTimestampAllOf
 */
export interface RequestTerrazoFieldTimestampAllOf {
    /**
     * The id of the field for which to return a list of timestamps.
     * @type {string}
     * @memberof RequestTerrazoFieldTimestampAllOf
     */
    'fieldId'?: string;
    /**
     * Beginning of time range for which to return results.
     * @type {number}
     * @memberof RequestTerrazoFieldTimestampAllOf
     */
    'timeStart'?: number;
    /**
     * End of time range for which to return results.
     * @type {number}
     * @memberof RequestTerrazoFieldTimestampAllOf
     */
    'timeEnd'?: number;
}
/**
 * 
 * @export
 * @interface RequestTerrazoMap
 */
export interface RequestTerrazoMap {
    /**
     * 
     * @type {string}
     * @memberof RequestTerrazoMap
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestTerrazoMap
     */
    'developerPayload'?: string | null;
    /**
     * Id of a Farmdok field. The field must have a valid contour.
     * @type {string}
     * @memberof RequestTerrazoMap
     */
    'fieldId': string;
    /**
     * A dbId returned by fieldTimestamp.
     * @type {string}
     * @memberof RequestTerrazoMap
     */
    'dbId': string;
    /**
     * 
     * @type {TerrazoMapType}
     * @memberof RequestTerrazoMap
     */
    'mapType': TerrazoMapType;
    /**
     * The number of zones in the map. Only if `mapType` is `ZONE_MAP`, ignored otherwise.
     * @type {number}
     * @memberof RequestTerrazoMap
     */
    'numberOfZones'?: number;
    /**
     * Specifies whether the image should be corrected in the border area to remove influence caused by adjacent fields or roads. Only if `mapType` is `ZONE_MAP`, ignored otherwise.
     * @type {boolean}
     * @memberof RequestTerrazoMap
     */
    'corrected'?: boolean;
    /**
     * 
     * @type {TerrazoZoningMode}
     * @memberof RequestTerrazoMap
     */
    'zoningMode'?: TerrazoZoningMode;
    /**
     * Required if `mapType` is `ZONE_MAP`, ignored otherwise.
     * @type {TerrazoFertilizerAdviceType}
     * @memberof RequestTerrazoMap
     */
    'fertilizerAdviceType'?: TerrazoFertilizerAdviceType | null;
    /**
     * Required if `fertilizerAdviceType` is `WinterGrains1N`
     * @type {TerrazoFertilizerAdviceParamsWinterGrains1N}
     * @memberof RequestTerrazoMap
     */
    'fertilizerAdviceParamsWinterGrains1N'?: TerrazoFertilizerAdviceParamsWinterGrains1N | null;
    /**
     * Required if `fertilizerAdviceType is `WinterGrains3N`
     * @type {TerrazoFertilizerAdviceParamsWinterGrains3N}
     * @memberof RequestTerrazoMap
     */
    'fertilizerAdviceParamsWinterGrains3N'?: TerrazoFertilizerAdviceParamsWinterGrains3N | null;
}
/**
 * 
 * @export
 * @interface RequestTerrazoMapAllOf
 */
export interface RequestTerrazoMapAllOf {
    /**
     * Id of a Farmdok field. The field must have a valid contour.
     * @type {string}
     * @memberof RequestTerrazoMapAllOf
     */
    'fieldId'?: string;
    /**
     * A dbId returned by fieldTimestamp.
     * @type {string}
     * @memberof RequestTerrazoMapAllOf
     */
    'dbId'?: string;
    /**
     * 
     * @type {TerrazoMapType}
     * @memberof RequestTerrazoMapAllOf
     */
    'mapType'?: TerrazoMapType;
    /**
     * The number of zones in the map. Only if `mapType` is `ZONE_MAP`, ignored otherwise.
     * @type {number}
     * @memberof RequestTerrazoMapAllOf
     */
    'numberOfZones'?: number;
    /**
     * Specifies whether the image should be corrected in the border area to remove influence caused by adjacent fields or roads. Only if `mapType` is `ZONE_MAP`, ignored otherwise.
     * @type {boolean}
     * @memberof RequestTerrazoMapAllOf
     */
    'corrected'?: boolean;
    /**
     * 
     * @type {TerrazoZoningMode}
     * @memberof RequestTerrazoMapAllOf
     */
    'zoningMode'?: TerrazoZoningMode;
    /**
     * Required if `mapType` is `ZONE_MAP`, ignored otherwise.
     * @type {TerrazoFertilizerAdviceType}
     * @memberof RequestTerrazoMapAllOf
     */
    'fertilizerAdviceType'?: TerrazoFertilizerAdviceType | null;
    /**
     * Required if `fertilizerAdviceType` is `WinterGrains1N`
     * @type {TerrazoFertilizerAdviceParamsWinterGrains1N}
     * @memberof RequestTerrazoMapAllOf
     */
    'fertilizerAdviceParamsWinterGrains1N'?: TerrazoFertilizerAdviceParamsWinterGrains1N | null;
    /**
     * Required if `fertilizerAdviceType is `WinterGrains3N`
     * @type {TerrazoFertilizerAdviceParamsWinterGrains3N}
     * @memberof RequestTerrazoMapAllOf
     */
    'fertilizerAdviceParamsWinterGrains3N'?: TerrazoFertilizerAdviceParamsWinterGrains3N | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RestErrorCode = {
    GeneralError: 'GeneralError',
    AttributeValidationError: 'AttributeValidationError',
    PermissionError: 'PermissionError',
    DatabaseError: 'DatabaseError',
    NotFound: 'NotFound'
} as const;

export type RestErrorCode = typeof RestErrorCode[keyof typeof RestErrorCode];


/**
 * 
 * @export
 * @interface RestErrorResponse
 */
export interface RestErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof RestErrorResponse
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestErrorResponse
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof RestErrorResponse
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {RestErrorCode}
     * @memberof RestErrorResponse
     */
    'errorCode'?: RestErrorCode;
    /**
     * 
     * @type {Array<string>}
     * @memberof RestErrorResponse
     */
    'errorUserMessage'?: Array<string>;
}
/**
 * 
 * @export
 * @interface RestErrorResponseAllOf
 */
export interface RestErrorResponseAllOf {
    /**
     * 
     * @type {RestErrorCode}
     * @memberof RestErrorResponseAllOf
     */
    'errorCode'?: RestErrorCode;
    /**
     * 
     * @type {Array<string>}
     * @memberof RestErrorResponseAllOf
     */
    'errorUserMessage'?: Array<string>;
}
/**
 * 
 * @export
 * @interface RestRequest
 */
export interface RestRequest {
    /**
     * 
     * @type {string}
     * @memberof RestRequest
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestRequest
     */
    'developerPayload'?: string | null;
}
/**
 * 
 * @export
 * @interface RestResponse
 */
export interface RestResponse {
    /**
     * 
     * @type {string}
     * @memberof RestResponse
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponse
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof RestResponse
     */
    'developerPayload'?: string;
}
/**
 * 
 * @export
 * @interface Rule
 */
export interface Rule {
    /**
     * Unique id of this object.
     * @type {string}
     * @memberof Rule
     */
    'id': string;
    /**
     * Timestamp when this object was deleted, null if object is not deleted.
     * @type {number}
     * @memberof Rule
     */
    'deleted'?: number | null;
    /**
     * Last change timestamp, updated automatically by the backend when object is changed.
     * @type {number}
     * @memberof Rule
     */
    'tstamp': number;
    /**
     * 
     * @type {string}
     * @memberof Rule
     */
    'name': string;
    /**
     * Short violation message, does not contain format strings.
     * @type {string}
     * @memberof Rule
     */
    'shortMessage': string;
    /**
     * Long violation message, contains format strings that need to be replaced using the parameters in `parameterNames`.
     * @type {string}
     * @memberof Rule
     */
    'longMessage': string;
    /**
     * Parameter names, used in combination with indication data to replace format strings in `longMessage`.
     * @type {Array<string>}
     * @memberof Rule
     */
    'parameterNames': Array<string>;
}
/**
 * 
 * @export
 * @interface RuleBase
 */
export interface RuleBase {
    /**
     * Unique id of this object.
     * @type {string}
     * @memberof RuleBase
     */
    'id': string;
    /**
     * Timestamp when this object was deleted, null if object is not deleted.
     * @type {number}
     * @memberof RuleBase
     */
    'deleted'?: number | null;
    /**
     * Last change timestamp, updated automatically by the backend when object is changed.
     * @type {number}
     * @memberof RuleBase
     */
    'tstamp'?: number;
    /**
     * 
     * @type {string}
     * @memberof RuleBase
     */
    'name'?: string;
    /**
     * Short violation message, does not contain format strings.
     * @type {string}
     * @memberof RuleBase
     */
    'shortMessage'?: string;
    /**
     * Long violation message, contains format strings that need to be replaced using the parameters in `parameterNames`.
     * @type {string}
     * @memberof RuleBase
     */
    'longMessage'?: string;
    /**
     * Parameter names, used in combination with indication data to replace format strings in `longMessage`.
     * @type {Array<string>}
     * @memberof RuleBase
     */
    'parameterNames'?: Array<string>;
}
/**
 * 
 * @export
 * @interface RuleObjects200Response
 */
export interface RuleObjects200Response {
    /**
     * 
     * @type {string}
     * @memberof RuleObjects200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof RuleObjects200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof RuleObjects200Response
     */
    'developerPayload'?: string;
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof RuleObjects200Response
     */
    'pageKey'?: string;
    /**
     * 
     * @type {Array<RuleBase>}
     * @memberof RuleObjects200Response
     */
    'data'?: Array<RuleBase>;
}
/**
 * 
 * @export
 * @interface RuleObjects200ResponseAllOf
 */
export interface RuleObjects200ResponseAllOf {
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof RuleObjects200ResponseAllOf
     */
    'pageKey'?: string;
    /**
     * 
     * @type {Array<RuleBase>}
     * @memberof RuleObjects200ResponseAllOf
     */
    'data'?: Array<RuleBase>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RuleViolationType = {
    Violation: 'violation',
    NotChecked: 'not_checked'
} as const;

export type RuleViolationType = typeof RuleViolationType[keyof typeof RuleViolationType];


/**
 * 
 * @export
 * @interface RulecheckActivityRuleViolations200Response
 */
export interface RulecheckActivityRuleViolations200Response {
    /**
     * 
     * @type {string}
     * @memberof RulecheckActivityRuleViolations200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof RulecheckActivityRuleViolations200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof RulecheckActivityRuleViolations200Response
     */
    'developerPayload'?: string;
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof RulecheckActivityRuleViolations200Response
     */
    'pageKey'?: string;
    /**
     * 
     * @type {Array<ActivityRuleViolationBase>}
     * @memberof RulecheckActivityRuleViolations200Response
     */
    'data'?: Array<ActivityRuleViolationBase>;
}
/**
 * 
 * @export
 * @interface RulecheckActivityRuleViolations200ResponseAllOf
 */
export interface RulecheckActivityRuleViolations200ResponseAllOf {
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof RulecheckActivityRuleViolations200ResponseAllOf
     */
    'pageKey'?: string;
    /**
     * 
     * @type {Array<ActivityRuleViolationBase>}
     * @memberof RulecheckActivityRuleViolations200ResponseAllOf
     */
    'data'?: Array<ActivityRuleViolationBase>;
}
/**
 * 
 * @export
 * @interface RulecheckRecheck200Response
 */
export interface RulecheckRecheck200Response {
    /**
     * 
     * @type {string}
     * @memberof RulecheckRecheck200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof RulecheckRecheck200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof RulecheckRecheck200Response
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {Array<ActivityRuleViolation>}
     * @memberof RulecheckRecheck200Response
     */
    'data'?: Array<ActivityRuleViolation>;
}
/**
 * 
 * @export
 * @interface RulecheckRecheck200ResponseAllOf
 */
export interface RulecheckRecheck200ResponseAllOf {
    /**
     * 
     * @type {Array<ActivityRuleViolation>}
     * @memberof RulecheckRecheck200ResponseAllOf
     */
    'data'?: Array<ActivityRuleViolation>;
}
/**
 * 
 * @export
 * @interface RulecheckRecheckRequest
 */
export interface RulecheckRecheckRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof RulecheckRecheckRequest
     */
    'version'?: string;
    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof RulecheckRecheckRequest
     */
    'developerPayload'?: string;
    /**
     * The id of the process order for which the rules should be rechecked.
     * @type {string}
     * @memberof RulecheckRecheckRequest
     */
    'processOrderId': string;
}
/**
 * 
 * @export
 * @interface RulecheckRecheckRequestAllOf
 */
export interface RulecheckRecheckRequestAllOf {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof RulecheckRecheckRequestAllOf
     */
    'version'?: string;
    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof RulecheckRecheckRequestAllOf
     */
    'developerPayload'?: string;
    /**
     * The id of the process order for which the rules should be rechecked.
     * @type {string}
     * @memberof RulecheckRecheckRequestAllOf
     */
    'processOrderId': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Sen4IndexType = {
    Ndvi: 'Ndvi',
    Lai: 'Lai',
    Ndwi: 'Ndwi',
    Cire: 'Cire',
    DnnNdvi: 'DnnNdvi',
    DnnLai: 'DnnLai',
    DnnNdwi: 'DnnNdwi',
    DnnCire: 'DnnCire',
    Mbi: 'Mbi'
} as const;

export type Sen4IndexType = typeof Sen4IndexType[keyof typeof Sen4IndexType];


/**
 * 
 * @export
 * @interface Sen4VegetationIndexData
 */
export interface Sen4VegetationIndexData {
    /**
     * 
     * @type {Sen4VegetationIndexUnits}
     * @memberof Sen4VegetationIndexData
     */
    'units': Sen4VegetationIndexUnits;
    /**
     * 
     * @type {Sen4VegetationIndexDataRows}
     * @memberof Sen4VegetationIndexData
     */
    'data': Sen4VegetationIndexDataRows;
    /**
     * 
     * @type {Sen4IndexType}
     * @memberof Sen4VegetationIndexData
     */
    'indexType'?: Sen4IndexType;
    /**
     * 
     * @type {Sen4VegetationIndexStatistics}
     * @memberof Sen4VegetationIndexData
     */
    'statistics'?: Sen4VegetationIndexStatistics;
}
/**
 * 
 * @export
 * @interface Sen4VegetationIndexDataRows
 */
export interface Sen4VegetationIndexDataRows {
    /**
     * Timestamps
     * @type {Array<string>}
     * @memberof Sen4VegetationIndexDataRows
     */
    'time': Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Sen4VegetationIndexDataRows
     */
    'value': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Sen4VegetationIndexDataRows
     */
    'min'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Sen4VegetationIndexDataRows
     */
    'max'?: Array<number>;
}
/**
 * 
 * @export
 * @interface Sen4VegetationIndexGraph200Response
 */
export interface Sen4VegetationIndexGraph200Response {
    /**
     * 
     * @type {string}
     * @memberof Sen4VegetationIndexGraph200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof Sen4VegetationIndexGraph200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof Sen4VegetationIndexGraph200Response
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {Sen4VegetationIndexUnits}
     * @memberof Sen4VegetationIndexGraph200Response
     */
    'units': Sen4VegetationIndexUnits;
    /**
     * 
     * @type {Sen4VegetationIndexDataRows}
     * @memberof Sen4VegetationIndexGraph200Response
     */
    'data': Sen4VegetationIndexDataRows;
    /**
     * 
     * @type {Sen4IndexType}
     * @memberof Sen4VegetationIndexGraph200Response
     */
    'indexType'?: Sen4IndexType;
    /**
     * 
     * @type {Sen4VegetationIndexStatistics}
     * @memberof Sen4VegetationIndexGraph200Response
     */
    'statistics'?: Sen4VegetationIndexStatistics;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Sen4VegetationIndexStatistics = {
    No: 'No',
    MeanY5: 'MeanY5'
} as const;

export type Sen4VegetationIndexStatistics = typeof Sen4VegetationIndexStatistics[keyof typeof Sen4VegetationIndexStatistics];


/**
 * 
 * @export
 * @interface Sen4VegetationIndexUnits
 */
export interface Sen4VegetationIndexUnits {
    /**
     * 
     * @type {string}
     * @memberof Sen4VegetationIndexUnits
     */
    'time': string;
    /**
     * 
     * @type {string}
     * @memberof Sen4VegetationIndexUnits
     */
    'value': string | null;
    /**
     * 
     * @type {string}
     * @memberof Sen4VegetationIndexUnits
     */
    'min'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Sen4VegetationIndexUnits
     */
    'max'?: string | null;
}
/**
 * 
 * @export
 * @interface StockLevel
 */
export interface StockLevel {
    /**
     * Unique ID of the product.
     * @type {string}
     * @memberof StockLevel
     */
    'productId': string;
    /**
     * Unique ID of the storage-place in which the product is stored. NULL if the stock level is not specific to a sotrage-place.
     * @type {string}
     * @memberof StockLevel
     */
    'storagePlaceId'?: string | null;
    /**
     * Unique ID of the unit for `amount`.
     * @type {string}
     * @memberof StockLevel
     */
    'unitId': string;
    /**
     * Stock-level amount in `unitId` units.
     * @type {number}
     * @memberof StockLevel
     */
    'amount': number;
}
/**
 * 
 * @export
 * @interface StockMovementsStockLevels200Response
 */
export interface StockMovementsStockLevels200Response {
    /**
     * 
     * @type {string}
     * @memberof StockMovementsStockLevels200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof StockMovementsStockLevels200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof StockMovementsStockLevels200Response
     */
    'developerPayload'?: string;
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof StockMovementsStockLevels200Response
     */
    'pageKey'?: string;
    /**
     * 
     * @type {Array<StockLevel>}
     * @memberof StockMovementsStockLevels200Response
     */
    'data'?: Array<StockLevel>;
}
/**
 * 
 * @export
 * @interface StockMovementsStockLevels200ResponseAllOf
 */
export interface StockMovementsStockLevels200ResponseAllOf {
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof StockMovementsStockLevels200ResponseAllOf
     */
    'pageKey'?: string;
    /**
     * 
     * @type {Array<StockLevel>}
     * @memberof StockMovementsStockLevels200ResponseAllOf
     */
    'data'?: Array<StockLevel>;
}
/**
 * 
 * @export
 * @interface StoragePlace
 */
export interface StoragePlace {
    /**
     * Unique id of this object.
     * @type {string}
     * @memberof StoragePlace
     */
    'id': string;
    /**
     * Timestamp when this object was deleted, null if object is not deleted.
     * @type {number}
     * @memberof StoragePlace
     */
    'deleted'?: number | null;
    /**
     * Last change timestamp, updated automatically by the backend when object is changed.
     * @type {number}
     * @memberof StoragePlace
     */
    'tstamp': number;
    /**
     * Unique ID of the company this storage place belongs to.
     * @type {string}
     * @memberof StoragePlace
     */
    'companyId': string;
    /**
     * Name of the storage place.
     * @type {string}
     * @memberof StoragePlace
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof StoragePlace
     */
    'description'?: string | null;
    /**
     * 
     * @type {StoragePlaceKind}
     * @memberof StoragePlace
     */
    'kind': StoragePlaceKind;
    /**
     * 
     * @type {number}
     * @memberof StoragePlace
     */
    'capacity': number;
    /**
     * The unit for the `capacity` of the storage place. Which units are allowable depends on the `kind` of the storage place.
     * @type {string}
     * @memberof StoragePlace
     */
    'unitId': string;
    /**
     * 
     * @type {Array<StoragePlaceProduct>}
     * @memberof StoragePlace
     */
    'products': Array<StoragePlaceProduct>;
}
/**
 * 
 * @export
 * @interface StoragePlaceBase
 */
export interface StoragePlaceBase {
    /**
     * Unique id of this object.
     * @type {string}
     * @memberof StoragePlaceBase
     */
    'id': string;
    /**
     * Timestamp when this object was deleted, null if object is not deleted.
     * @type {number}
     * @memberof StoragePlaceBase
     */
    'deleted'?: number | null;
    /**
     * Last change timestamp, updated automatically by the backend when object is changed.
     * @type {number}
     * @memberof StoragePlaceBase
     */
    'tstamp'?: number;
    /**
     * Unique ID of the company this storage place belongs to.
     * @type {string}
     * @memberof StoragePlaceBase
     */
    'companyId'?: string;
    /**
     * Name of the storage place.
     * @type {string}
     * @memberof StoragePlaceBase
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof StoragePlaceBase
     */
    'description'?: string | null;
    /**
     * 
     * @type {StoragePlaceKind}
     * @memberof StoragePlaceBase
     */
    'kind'?: StoragePlaceKind;
    /**
     * 
     * @type {number}
     * @memberof StoragePlaceBase
     */
    'capacity'?: number;
    /**
     * The unit for the `capacity` of the storage place. Which units are allowable depends on the `kind` of the storage place.
     * @type {string}
     * @memberof StoragePlaceBase
     */
    'unitId'?: string;
    /**
     * 
     * @type {Array<StoragePlaceProduct>}
     * @memberof StoragePlaceBase
     */
    'products'?: Array<StoragePlaceProduct>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const StoragePlaceKind = {
    Undef: 'undef',
    Liquid: 'liquid',
    Solid: 'solid',
    Generic: 'generic'
} as const;

export type StoragePlaceKind = typeof StoragePlaceKind[keyof typeof StoragePlaceKind];


/**
 * 
 * @export
 * @interface StoragePlaceObjects200Response
 */
export interface StoragePlaceObjects200Response {
    /**
     * 
     * @type {string}
     * @memberof StoragePlaceObjects200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof StoragePlaceObjects200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof StoragePlaceObjects200Response
     */
    'developerPayload'?: string;
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof StoragePlaceObjects200Response
     */
    'pageKey'?: string;
    /**
     * 
     * @type {Array<StoragePlaceBase>}
     * @memberof StoragePlaceObjects200Response
     */
    'data'?: Array<StoragePlaceBase>;
}
/**
 * 
 * @export
 * @interface StoragePlaceObjects200ResponseAllOf
 */
export interface StoragePlaceObjects200ResponseAllOf {
    /**
     * If set more data is available. To get additional results run the same request                              again with the `pageKey` parameter set to this value. Leave all other parameters unchanged.
     * @type {string}
     * @memberof StoragePlaceObjects200ResponseAllOf
     */
    'pageKey'?: string;
    /**
     * 
     * @type {Array<StoragePlaceBase>}
     * @memberof StoragePlaceObjects200ResponseAllOf
     */
    'data'?: Array<StoragePlaceBase>;
}
/**
 * 
 * @export
 * @interface StoragePlaceProduct
 */
export interface StoragePlaceProduct {
    /**
     * Unique id of this object.
     * @type {string}
     * @memberof StoragePlaceProduct
     */
    'id': string;
    /**
     * Timestamp when this object was deleted, null if object is not deleted.
     * @type {number}
     * @memberof StoragePlaceProduct
     */
    'deleted'?: number | null;
    /**
     * Last change timestamp, updated automatically by the backend when object is changed.
     * @type {number}
     * @memberof StoragePlaceProduct
     */
    'tstamp': number;
    /**
     * 
     * @type {string}
     * @memberof StoragePlaceProduct
     */
    'productId': string;
    /**
     * 
     * @type {StoragePlaceProductType}
     * @memberof StoragePlaceProduct
     */
    'type': StoragePlaceProductType;
}
/**
 * 
 * @export
 * @interface StoragePlaceProductBase
 */
export interface StoragePlaceProductBase {
    /**
     * Unique id of this object.
     * @type {string}
     * @memberof StoragePlaceProductBase
     */
    'id': string;
    /**
     * Timestamp when this object was deleted, null if object is not deleted.
     * @type {number}
     * @memberof StoragePlaceProductBase
     */
    'deleted'?: number | null;
    /**
     * Last change timestamp, updated automatically by the backend when object is changed.
     * @type {number}
     * @memberof StoragePlaceProductBase
     */
    'tstamp'?: number;
    /**
     * 
     * @type {string}
     * @memberof StoragePlaceProductBase
     */
    'productId'?: string;
    /**
     * 
     * @type {StoragePlaceProductType}
     * @memberof StoragePlaceProductBase
     */
    'type'?: StoragePlaceProductType;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const StoragePlaceProductType = {
    Empty: '',
    MutuallyDependent: 'mutually_dependent'
} as const;

export type StoragePlaceProductType = typeof StoragePlaceProductType[keyof typeof StoragePlaceProductType];


/**
 * 
 * @export
 * @enum {string}
 */

export const TenureStatus = {
    Property: 'property',
    Community: 'community',
    Hired: 'hired'
} as const;

export type TenureStatus = typeof TenureStatus[keyof typeof TenureStatus];


/**
 * 
 * @export
 * @interface TerrazoCoverageInfo
 */
export interface TerrazoCoverageInfo {
    /**
     * Cloud coverage ratio.
     * @type {number}
     * @memberof TerrazoCoverageInfo
     */
    'cloud'?: number;
}
/**
 * 
 * @export
 * @interface TerrazoFertilizerAdviceParamsWinterGrains1N
 */
export interface TerrazoFertilizerAdviceParamsWinterGrains1N {
    /**
     * 
     * @type {number}
     * @memberof TerrazoFertilizerAdviceParamsWinterGrains1N
     */
    'averageFertilizerRate': number;
}
/**
 * 
 * @export
 * @interface TerrazoFertilizerAdviceParamsWinterGrains3N
 */
export interface TerrazoFertilizerAdviceParamsWinterGrains3N {
    /**
     * 
     * @type {number}
     * @memberof TerrazoFertilizerAdviceParamsWinterGrains3N
     */
    'averageFertilizerRate1N': number;
    /**
     * 
     * @type {number}
     * @memberof TerrazoFertilizerAdviceParamsWinterGrains3N
     */
    'averageFertilizerRate2N': number;
    /**
     * 
     * @type {number}
     * @memberof TerrazoFertilizerAdviceParamsWinterGrains3N
     */
    'expectedYield': number;
    /**
     * 
     * @type {TerrazoHumidityAreaType}
     * @memberof TerrazoFertilizerAdviceParamsWinterGrains3N
     */
    'humidityAreaType': TerrazoHumidityAreaType;
    /**
     * 
     * @type {TerrazoGrainQuality}
     * @memberof TerrazoFertilizerAdviceParamsWinterGrains3N
     */
    'targetGrainQuality': TerrazoGrainQuality;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TerrazoFertilizerAdviceType = {
    None: 'None',
    WinterGrains1N: 'WinterGrains1N',
    WinterGrains3N: 'WinterGrains3N'
} as const;

export type TerrazoFertilizerAdviceType = typeof TerrazoFertilizerAdviceType[keyof typeof TerrazoFertilizerAdviceType];


/**
 * 
 * @export
 * @interface TerrazoFieldTimestampEntry
 */
export interface TerrazoFieldTimestampEntry {
    /**
     * Database ID of this entry, for use with multiPoly.
     * @type {string}
     * @memberof TerrazoFieldTimestampEntry
     */
    'dbId'?: string;
    /**
     * The timestamp for this entry.
     * @type {number}
     * @memberof TerrazoFieldTimestampEntry
     */
    'timestamp'?: number;
    /**
     * 
     * @type {TerrazoCoverageInfo}
     * @memberof TerrazoFieldTimestampEntry
     */
    'coverageRatio'?: TerrazoCoverageInfo;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TerrazoGrainQuality = {
    LiveStock: 'LIVE_STOCK',
    Milling: 'MILLING',
    Quality: 'QUALITY',
    Premium: 'PREMIUM'
} as const;

export type TerrazoGrainQuality = typeof TerrazoGrainQuality[keyof typeof TerrazoGrainQuality];


/**
 * 
 * @export
 * @enum {string}
 */

export const TerrazoHumidityAreaType = {
    HumidArea: 'HUMID_AREA',
    DryArea: 'DRY_AREA'
} as const;

export type TerrazoHumidityAreaType = typeof TerrazoHumidityAreaType[keyof typeof TerrazoHumidityAreaType];


/**
 * 
 * @export
 * @enum {string}
 */

export const TerrazoMapType = {
    Tci: 'TCI',
    Ndvi: 'NDVI',
    ZoneMap: 'ZONE_MAP'
} as const;

export type TerrazoMapType = typeof TerrazoMapType[keyof typeof TerrazoMapType];


/**
 * 
 * @export
 * @enum {string}
 */

export const TerrazoZoningMode = {
    EqualZoneSize: 'EQUAL_ZONE_SIZE',
    EqualIndexDistance: 'EQUAL_INDEX_DISTANCE',
    JenksNaturalBreaks: 'JENKS_NATURAL_BREAKS'
} as const;

export type TerrazoZoningMode = typeof TerrazoZoningMode[keyof typeof TerrazoZoningMode];


/**
 * 
 * @export
 * @interface WaterProtectionZone
 */
export interface WaterProtectionZone {
    /**
     * 
     * @type {string}
     * @memberof WaterProtectionZone
     */
    'id': string;
    /**
     * 
     * @type {Polygon}
     * @memberof WaterProtectionZone
     */
    'geometry': Polygon;
}
/**
 * 
 * @export
 * @interface WaterProtectionZoneIntersection
 */
export interface WaterProtectionZoneIntersection {
    /**
     * 
     * @type {string}
     * @memberof WaterProtectionZoneIntersection
     */
    'id': string;
    /**
     * True if the geometry with $id intersects with a water protection zone.
     * @type {boolean}
     * @memberof WaterProtectionZoneIntersection
     */
    'intersects': boolean;
}
/**
 * 
 * @export
 * @interface WeatherAggregate200Response
 */
export interface WeatherAggregate200Response {
    /**
     * 
     * @type {string}
     * @memberof WeatherAggregate200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof WeatherAggregate200Response
     */
    'version'?: string;
    /**
     * If a `developerPayload` was set in the request it will be returned in the response, without change.
     * @type {string}
     * @memberof WeatherAggregate200Response
     */
    'developerPayload'?: string;
    /**
     * 
     * @type {number}
     * @memberof WeatherAggregate200Response
     */
    'latitude': number;
    /**
     * 
     * @type {number}
     * @memberof WeatherAggregate200Response
     */
    'longitude': number;
    /**
     * Time it took to generate the request in the weather backend, for debugging use.
     * @type {number}
     * @memberof WeatherAggregate200Response
     */
    'generationTimeMs': number;
    /**
     * Timezone offset relative to UTC in seconds.
     * @type {number}
     * @memberof WeatherAggregate200Response
     */
    'utcOffsetSeconds': number;
    /**
     * Long timezone name
     * @type {string}
     * @memberof WeatherAggregate200Response
     */
    'timezone': string;
    /**
     * Short timezone name
     * @type {string}
     * @memberof WeatherAggregate200Response
     */
    'timezoneAbbreviation': string;
    /**
     * The elevation from a 90 meter digital elevation model.
     * @type {number}
     * @memberof WeatherAggregate200Response
     */
    'elevation': number;
    /**
     * 
     * @type {WeatherDataUnits}
     * @memberof WeatherAggregate200Response
     */
    'units': WeatherDataUnits;
    /**
     * 
     * @type {WeatherDataRows}
     * @memberof WeatherAggregate200Response
     */
    'data': WeatherDataRows;
}
/**
 * 
 * @export
 * @interface WeatherData
 */
export interface WeatherData {
    /**
     * 
     * @type {number}
     * @memberof WeatherData
     */
    'latitude': number;
    /**
     * 
     * @type {number}
     * @memberof WeatherData
     */
    'longitude': number;
    /**
     * Time it took to generate the request in the weather backend, for debugging use.
     * @type {number}
     * @memberof WeatherData
     */
    'generationTimeMs': number;
    /**
     * Timezone offset relative to UTC in seconds.
     * @type {number}
     * @memberof WeatherData
     */
    'utcOffsetSeconds': number;
    /**
     * Long timezone name
     * @type {string}
     * @memberof WeatherData
     */
    'timezone': string;
    /**
     * Short timezone name
     * @type {string}
     * @memberof WeatherData
     */
    'timezoneAbbreviation': string;
    /**
     * The elevation from a 90 meter digital elevation model.
     * @type {number}
     * @memberof WeatherData
     */
    'elevation': number;
    /**
     * 
     * @type {WeatherDataUnits}
     * @memberof WeatherData
     */
    'units': WeatherDataUnits;
    /**
     * 
     * @type {WeatherDataRows}
     * @memberof WeatherData
     */
    'data': WeatherDataRows;
}
/**
 * 
 * @export
 * @interface WeatherDataRows
 */
export interface WeatherDataRows {
    /**
     * Timestamps
     * @type {Array<string>}
     * @memberof WeatherDataRows
     */
    'time': Array<string>;
    /**
     * Air temperatures at 2 meters above ground
     * @type {Array<number>}
     * @memberof WeatherDataRows
     */
    'temperature2m'?: Array<number>;
    /**
     * Minimum air temperatures at 2 meters above ground
     * @type {Array<number>}
     * @memberof WeatherDataRows
     */
    'temperature2mMin'?: Array<number>;
    /**
     * Maximum air temperatures at 2 meters above ground
     * @type {Array<number>}
     * @memberof WeatherDataRows
     */
    'temperature2mMax'?: Array<number>;
    /**
     * Sum of precipitation (including rain, showers and snowfall)
     * @type {Array<number>}
     * @memberof WeatherDataRows
     */
    'precipitation'?: Array<number>;
    /**
     * Wind speeds at 10 meters above ground
     * @type {Array<number>}
     * @memberof WeatherDataRows
     */
    'windSpeed10m'?: Array<number>;
    /**
     * Average soil temperature at ground level
     * @type {Array<number>}
     * @memberof WeatherDataRows
     */
    'soilTemperatureSurface'?: Array<number>;
    /**
     * Average soil water content as volumetric mixing ratio
     * @type {Array<number>}
     * @memberof WeatherDataRows
     */
    'soilMoistureSurface'?: Array<number>;
    /**
     * Shortwave solar radiation
     * @type {Array<number>}
     * @memberof WeatherDataRows
     */
    'shortwaveRadiation'?: Array<number>;
    /**
     * ET₀ Reference Evapotranspiration of a well watered grass field.
     * @type {Array<number>}
     * @memberof WeatherDataRows
     */
    'et0FaoEvapotranspiration'?: Array<number>;
    /**
     * Accumulated temperature sum since `dateAccumulate`.
     * @type {Array<number>}
     * @memberof WeatherDataRows
     */
    'heatUnit'?: Array<number>;
    /**
     * Accumulated precipitation since `dateAccumulate`.
     * @type {Array<number>}
     * @memberof WeatherDataRows
     */
    'precipitationAccumulated'?: Array<number>;
    /**
     * Probability of precipitation with more than 0.1 mm
     * @type {Array<number>}
     * @memberof WeatherDataRows
     */
    'precipitationProbability'?: Array<number>;
    /**
     * Weather condition as a numeric code. Follows WMO weather interpretation codes.
     * @type {Array<number>}
     * @memberof WeatherDataRows
     */
    'weatherCode'?: Array<number>;
    /**
     * Relative humidity at 2 meters above ground
     * @type {Array<number>}
     * @memberof WeatherDataRows
     */
    'relativeHumidity2m'?: Array<number>;
    /**
     * Atmospheric air pressure at surface.
     * @type {Array<number>}
     * @memberof WeatherDataRows
     */
    'surfacePressure'?: Array<number>;
    /**
     * Total cloud cover as an area fraction
     * @type {Array<number>}
     * @memberof WeatherDataRows
     */
    'cloudCover'?: Array<number>;
    /**
     * Direct solar radiation as average of the preceding hour on the horizontal plane
     * @type {Array<number>}
     * @memberof WeatherDataRows
     */
    'directRadiation'?: Array<number>;
}
/**
 * 
 * @export
 * @interface WeatherDataUnits
 */
export interface WeatherDataUnits {
    /**
     * 
     * @type {string}
     * @memberof WeatherDataUnits
     */
    'time': string;
    /**
     * 
     * @type {string}
     * @memberof WeatherDataUnits
     */
    'temperature2m'?: string;
    /**
     * 
     * @type {string}
     * @memberof WeatherDataUnits
     */
    'temperature2mMin'?: string;
    /**
     * 
     * @type {string}
     * @memberof WeatherDataUnits
     */
    'temperature2mMax'?: string;
    /**
     * 
     * @type {string}
     * @memberof WeatherDataUnits
     */
    'precipitation'?: string;
    /**
     * 
     * @type {string}
     * @memberof WeatherDataUnits
     */
    'windSpeed10m'?: string;
    /**
     * 
     * @type {string}
     * @memberof WeatherDataUnits
     */
    'soilTemperatureSurface'?: string;
    /**
     * 
     * @type {string}
     * @memberof WeatherDataUnits
     */
    'soilMoistureSurface'?: string;
    /**
     * 
     * @type {string}
     * @memberof WeatherDataUnits
     */
    'shortwaveRadiation'?: string;
    /**
     * 
     * @type {string}
     * @memberof WeatherDataUnits
     */
    'et0FaoEvapotranspiration'?: string;
    /**
     * 
     * @type {string}
     * @memberof WeatherDataUnits
     */
    'heatUnit'?: string;
    /**
     * 
     * @type {string}
     * @memberof WeatherDataUnits
     */
    'precipitationAccumulated'?: string;
    /**
     * 
     * @type {string}
     * @memberof WeatherDataUnits
     */
    'precipitationProbability'?: string;
    /**
     * 
     * @type {string}
     * @memberof WeatherDataUnits
     */
    'weatherCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof WeatherDataUnits
     */
    'relativeHumidity2m'?: string;
    /**
     * 
     * @type {string}
     * @memberof WeatherDataUnits
     */
    'surfacePressure'?: string;
    /**
     * 
     * @type {string}
     * @memberof WeatherDataUnits
     */
    'cloudCover'?: string;
    /**
     * 
     * @type {string}
     * @memberof WeatherDataUnits
     */
    'directRadiation'?: string;
}

/**
 * ActivitiesApi - axios parameter creator
 * @export
 */
export const ActivitiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the gps log for an activity in geoJson format for the selected `datatypes`.         Only points with at least one defined value for the selected `datatypes` are returned.         Currently `time` is the only supported datatype supported, in the future the activities endpoint will provide a         list of available datatypes for each activity.         Currently only every forth point is returned.
         * @param {string} activityId Id of the activity for which to return the gps track.
         * @param {string} dataTypes Comma seperated list of datatypes.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activitiesGpsLog: async (activityId: string, dataTypes: string, pageKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('activitiesGpsLog', 'activityId', activityId)
            // verify required parameter 'dataTypes' is not null or undefined
            assertParamExists('activitiesGpsLog', 'dataTypes', dataTypes)
            const localVarPath = `/activities/{activityId}/gpsLog`
                .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dataTypes !== undefined) {
                localVarQueryParameter['dataTypes'] = dataTypes;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the gps track for an activity in geoJson format.
         * @param {string} activityId Id of the activity for which to return the gps track.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activitiesGpsTrack: async (activityId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('activitiesGpsTrack', 'activityId', activityId)
            const localVarPath = `/activities/{activityId}/gpsTrack`
                .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete one or more activities.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityDelete: async (objectIds: string, version?: string, developerPayload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectIds' is not null or undefined
            assertParamExists('activityDelete', 'objectIds', objectIds)
            const localVarPath = `/activities/{objectIds}`
                .replace(`{${"objectIds"}}`, encodeURIComponent(String(objectIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the favourite (most commonly used) product combinations for the given activity type and crop.
         * @param {string} companyIds A comma seperated list of company ids for which to return favourite products. If more than one company is specified they must belong to the same copmany cluster and the user must be authorized on all of them.
         * @param {string} processOrderId Id of the currently selected process order, for multicompany the current process order of any of the companies listed in &#x60;companyIds&#x60; can be used.
         * @param {string} activityTypeId The id of the selected activity type.
         * @param {string} cropIds A comma seperated list of crop ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityFavouriteProducts: async (companyIds: string, processOrderId: string, activityTypeId: string, cropIds: string, version?: string, developerPayload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyIds' is not null or undefined
            assertParamExists('activityFavouriteProducts', 'companyIds', companyIds)
            // verify required parameter 'processOrderId' is not null or undefined
            assertParamExists('activityFavouriteProducts', 'processOrderId', processOrderId)
            // verify required parameter 'activityTypeId' is not null or undefined
            assertParamExists('activityFavouriteProducts', 'activityTypeId', activityTypeId)
            // verify required parameter 'cropIds' is not null or undefined
            assertParamExists('activityFavouriteProducts', 'cropIds', cropIds)
            const localVarPath = `/activities/favouriteProducts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (companyIds !== undefined) {
                localVarQueryParameter['companyIds'] = companyIds;
            }

            if (processOrderId !== undefined) {
                localVarQueryParameter['processOrderId'] = processOrderId;
            }

            if (activityTypeId !== undefined) {
                localVarQueryParameter['activityTypeId'] = activityTypeId;
            }

            if (cropIds !== undefined) {
                localVarQueryParameter['cropIds'] = cropIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the amount and unitId for the last time the product `productId` was used in companies `companyIds`
         * @param {string} companyIds A comma seperated list of company ids for which to return the last used amount.              If more than one company is specified they must belong to the same company cluster and              the user must be authorized on all of them.
         * @param {string} productId The id of the product for which to find the last used amount.
         * @param {string} processOrderId The id of the current process order, this and the previous process order are searched.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityLastUsedProductAmount: async (companyIds: string, productId: string, processOrderId: string, version?: string, developerPayload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyIds' is not null or undefined
            assertParamExists('activityLastUsedProductAmount', 'companyIds', companyIds)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('activityLastUsedProductAmount', 'productId', productId)
            // verify required parameter 'processOrderId' is not null or undefined
            assertParamExists('activityLastUsedProductAmount', 'processOrderId', processOrderId)
            const localVarPath = `/activities/lastUsedProductAmount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (companyIds !== undefined) {
                localVarQueryParameter['companyIds'] = companyIds;
            }

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }

            if (processOrderId !== undefined) {
                localVarQueryParameter['processOrderId'] = processOrderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load one or more activities.             If the user does not have `GLOBAL_ALL_COMPANY_MEASURES_ACCESSIBLE` permissions only their own activities are returned.             If the user does not have `GLOBAL_ALL_FIELDS` permissions only activities on active fields or activities without fields are returned.
         * @param {string} processOrderId The id of the process order for which all activities should be returned
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [permissionMode] Enable special permission modes
         * @param {string} [fieldSharingCompanyId] For &#x60;permissionMode&#x3D;fieldSharing&#x60;: The Id of the company for which to load data related to shared fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityList: async (processOrderId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, permissionMode?: string, fieldSharingCompanyId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processOrderId' is not null or undefined
            assertParamExists('activityList', 'processOrderId', processOrderId)
            const localVarPath = `/activities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }

            if (processOrderId !== undefined) {
                localVarQueryParameter['processOrderId'] = processOrderId;
            }

            if (permissionMode !== undefined) {
                localVarQueryParameter['permissionMode'] = permissionMode;
            }

            if (fieldSharingCompanyId !== undefined) {
                localVarQueryParameter['fieldSharingCompanyId'] = fieldSharingCompanyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Moves one or more activities to a different process order.              The activities are copied to the destination process order and are deleted in the source process order.             If one or more of the activities cannot be copied because of a conflict (no matching field found, permission error, ...)              no activities will be moved and a 409 response with status `conflict` will be returned.
         * @param {ActivityMoveToProcessOrderRequest} [activityMoveToProcessOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityMoveToProcessOrder: async (activityMoveToProcessOrderRequest?: ActivityMoveToProcessOrderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/activities/moveToProcessOrder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityMoveToProcessOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load one or more activities by activity id.              If the user does not have `GLOBAL_ALL_COMPANY_MEASURES_ACCESSIBLE` permissions only their own activities are returned.             If the user does not have `GLOBAL_ALL_FIELDS` permissions only activities on active fields or activities without fields are returned.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [permissionMode] Enable special permission modes
         * @param {string} [fieldSharingCompanyId] For &#x60;permissionMode&#x3D;fieldSharing&#x60;: The Id of the company for which to load data related to shared fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityObjects: async (objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, permissionMode?: string, fieldSharingCompanyId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectIds' is not null or undefined
            assertParamExists('activityObjects', 'objectIds', objectIds)
            const localVarPath = `/activities/{objectIds}`
                .replace(`{${"objectIds"}}`, encodeURIComponent(String(objectIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }

            if (permissionMode !== undefined) {
                localVarQueryParameter['permissionMode'] = permissionMode;
            }

            if (fieldSharingCompanyId !== undefined) {
                localVarQueryParameter['fieldSharingCompanyId'] = fieldSharingCompanyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update one or more activities.
         * @param {ActivityPatchRequest} activityPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityPatch: async (activityPatchRequest: ActivityPatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityPatchRequest' is not null or undefined
            assertParamExists('activityPatch', 'activityPatchRequest', activityPatchRequest)
            const localVarPath = `/activities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityPatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create or replace one or more activities.
         * @param {ActivityPutRequest} activityPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityPut: async (activityPutRequest: ActivityPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityPutRequest' is not null or undefined
            assertParamExists('activityPut', 'activityPutRequest', activityPutRequest)
            const localVarPath = `/activities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Restore one or more activities.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityUndelete: async (objectIds: string, version?: string, developerPayload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectIds' is not null or undefined
            assertParamExists('activityUndelete', 'objectIds', objectIds)
            const localVarPath = `/activities/undelete/{objectIds}`
                .replace(`{${"objectIds"}}`, encodeURIComponent(String(objectIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivitiesApi - functional programming interface
 * @export
 */
export const ActivitiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActivitiesApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the gps log for an activity in geoJson format for the selected `datatypes`.         Only points with at least one defined value for the selected `datatypes` are returned.         Currently `time` is the only supported datatype supported, in the future the activities endpoint will provide a         list of available datatypes for each activity.         Currently only every forth point is returned.
         * @param {string} activityId Id of the activity for which to return the gps track.
         * @param {string} dataTypes Comma seperated list of datatypes.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activitiesGpsLog(activityId: string, dataTypes: string, pageKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivitiesGpsLog200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activitiesGpsLog(activityId, dataTypes, pageKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the gps track for an activity in geoJson format.
         * @param {string} activityId Id of the activity for which to return the gps track.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activitiesGpsTrack(activityId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivitiesGpsTrack200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activitiesGpsTrack(activityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete one or more activities.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityDelete(objectIds: string, version?: string, developerPayload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityDelete(objectIds, version, developerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the favourite (most commonly used) product combinations for the given activity type and crop.
         * @param {string} companyIds A comma seperated list of company ids for which to return favourite products. If more than one company is specified they must belong to the same copmany cluster and the user must be authorized on all of them.
         * @param {string} processOrderId Id of the currently selected process order, for multicompany the current process order of any of the companies listed in &#x60;companyIds&#x60; can be used.
         * @param {string} activityTypeId The id of the selected activity type.
         * @param {string} cropIds A comma seperated list of crop ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityFavouriteProducts(companyIds: string, processOrderId: string, activityTypeId: string, cropIds: string, version?: string, developerPayload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityFavouriteProducts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityFavouriteProducts(companyIds, processOrderId, activityTypeId, cropIds, version, developerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the amount and unitId for the last time the product `productId` was used in companies `companyIds`
         * @param {string} companyIds A comma seperated list of company ids for which to return the last used amount.              If more than one company is specified they must belong to the same company cluster and              the user must be authorized on all of them.
         * @param {string} productId The id of the product for which to find the last used amount.
         * @param {string} processOrderId The id of the current process order, this and the previous process order are searched.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityLastUsedProductAmount(companyIds: string, productId: string, processOrderId: string, version?: string, developerPayload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLastUsedProductAmount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityLastUsedProductAmount(companyIds, productId, processOrderId, version, developerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load one or more activities.             If the user does not have `GLOBAL_ALL_COMPANY_MEASURES_ACCESSIBLE` permissions only their own activities are returned.             If the user does not have `GLOBAL_ALL_FIELDS` permissions only activities on active fields or activities without fields are returned.
         * @param {string} processOrderId The id of the process order for which all activities should be returned
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [permissionMode] Enable special permission modes
         * @param {string} [fieldSharingCompanyId] For &#x60;permissionMode&#x3D;fieldSharing&#x60;: The Id of the company for which to load data related to shared fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityList(processOrderId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, permissionMode?: string, fieldSharingCompanyId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityList(processOrderId, version, deleted, pageKey, developerPayload, properties, permissionMode, fieldSharingCompanyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Moves one or more activities to a different process order.              The activities are copied to the destination process order and are deleted in the source process order.             If one or more of the activities cannot be copied because of a conflict (no matching field found, permission error, ...)              no activities will be moved and a 409 response with status `conflict` will be returned.
         * @param {ActivityMoveToProcessOrderRequest} [activityMoveToProcessOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityMoveToProcessOrder(activityMoveToProcessOrderRequest?: ActivityMoveToProcessOrderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityMoveToProcessOrder200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityMoveToProcessOrder(activityMoveToProcessOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load one or more activities by activity id.              If the user does not have `GLOBAL_ALL_COMPANY_MEASURES_ACCESSIBLE` permissions only their own activities are returned.             If the user does not have `GLOBAL_ALL_FIELDS` permissions only activities on active fields or activities without fields are returned.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [permissionMode] Enable special permission modes
         * @param {string} [fieldSharingCompanyId] For &#x60;permissionMode&#x3D;fieldSharing&#x60;: The Id of the company for which to load data related to shared fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, permissionMode?: string, fieldSharingCompanyId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityObjects(objectIds, version, deleted, pageKey, developerPayload, properties, permissionMode, fieldSharingCompanyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update one or more activities.
         * @param {ActivityPatchRequest} activityPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityPatch(activityPatchRequest: ActivityPatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityPut200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityPatch(activityPatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create or replace one or more activities.
         * @param {ActivityPutRequest} activityPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityPut(activityPutRequest: ActivityPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityPut200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityPut(activityPutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Restore one or more activities.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityUndelete(objectIds: string, version?: string, developerPayload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityUndelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityUndelete(objectIds, version, developerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActivitiesApi - factory interface
 * @export
 */
export const ActivitiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActivitiesApiFp(configuration)
    return {
        /**
         * Returns the gps log for an activity in geoJson format for the selected `datatypes`.         Only points with at least one defined value for the selected `datatypes` are returned.         Currently `time` is the only supported datatype supported, in the future the activities endpoint will provide a         list of available datatypes for each activity.         Currently only every forth point is returned.
         * @param {string} activityId Id of the activity for which to return the gps track.
         * @param {string} dataTypes Comma seperated list of datatypes.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activitiesGpsLog(activityId: string, dataTypes: string, pageKey?: string, options?: any): AxiosPromise<ActivitiesGpsLog200Response> {
            return localVarFp.activitiesGpsLog(activityId, dataTypes, pageKey, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the gps track for an activity in geoJson format.
         * @param {string} activityId Id of the activity for which to return the gps track.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activitiesGpsTrack(activityId: string, options?: any): AxiosPromise<ActivitiesGpsTrack200Response> {
            return localVarFp.activitiesGpsTrack(activityId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete one or more activities.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityDelete(objectIds: string, version?: string, developerPayload?: string, options?: any): AxiosPromise<ActivityDelete200Response> {
            return localVarFp.activityDelete(objectIds, version, developerPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the favourite (most commonly used) product combinations for the given activity type and crop.
         * @param {string} companyIds A comma seperated list of company ids for which to return favourite products. If more than one company is specified they must belong to the same copmany cluster and the user must be authorized on all of them.
         * @param {string} processOrderId Id of the currently selected process order, for multicompany the current process order of any of the companies listed in &#x60;companyIds&#x60; can be used.
         * @param {string} activityTypeId The id of the selected activity type.
         * @param {string} cropIds A comma seperated list of crop ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityFavouriteProducts(companyIds: string, processOrderId: string, activityTypeId: string, cropIds: string, version?: string, developerPayload?: string, options?: any): AxiosPromise<ActivityFavouriteProducts200Response> {
            return localVarFp.activityFavouriteProducts(companyIds, processOrderId, activityTypeId, cropIds, version, developerPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the amount and unitId for the last time the product `productId` was used in companies `companyIds`
         * @param {string} companyIds A comma seperated list of company ids for which to return the last used amount.              If more than one company is specified they must belong to the same company cluster and              the user must be authorized on all of them.
         * @param {string} productId The id of the product for which to find the last used amount.
         * @param {string} processOrderId The id of the current process order, this and the previous process order are searched.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityLastUsedProductAmount(companyIds: string, productId: string, processOrderId: string, version?: string, developerPayload?: string, options?: any): AxiosPromise<ActivityLastUsedProductAmount200Response> {
            return localVarFp.activityLastUsedProductAmount(companyIds, productId, processOrderId, version, developerPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Load one or more activities.             If the user does not have `GLOBAL_ALL_COMPANY_MEASURES_ACCESSIBLE` permissions only their own activities are returned.             If the user does not have `GLOBAL_ALL_FIELDS` permissions only activities on active fields or activities without fields are returned.
         * @param {string} processOrderId The id of the process order for which all activities should be returned
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [permissionMode] Enable special permission modes
         * @param {string} [fieldSharingCompanyId] For &#x60;permissionMode&#x3D;fieldSharing&#x60;: The Id of the company for which to load data related to shared fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityList(processOrderId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, permissionMode?: string, fieldSharingCompanyId?: string, options?: any): AxiosPromise<ActivityObjects200Response> {
            return localVarFp.activityList(processOrderId, version, deleted, pageKey, developerPayload, properties, permissionMode, fieldSharingCompanyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Moves one or more activities to a different process order.              The activities are copied to the destination process order and are deleted in the source process order.             If one or more of the activities cannot be copied because of a conflict (no matching field found, permission error, ...)              no activities will be moved and a 409 response with status `conflict` will be returned.
         * @param {ActivityMoveToProcessOrderRequest} [activityMoveToProcessOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityMoveToProcessOrder(activityMoveToProcessOrderRequest?: ActivityMoveToProcessOrderRequest, options?: any): AxiosPromise<ActivityMoveToProcessOrder200Response> {
            return localVarFp.activityMoveToProcessOrder(activityMoveToProcessOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Load one or more activities by activity id.              If the user does not have `GLOBAL_ALL_COMPANY_MEASURES_ACCESSIBLE` permissions only their own activities are returned.             If the user does not have `GLOBAL_ALL_FIELDS` permissions only activities on active fields or activities without fields are returned.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [permissionMode] Enable special permission modes
         * @param {string} [fieldSharingCompanyId] For &#x60;permissionMode&#x3D;fieldSharing&#x60;: The Id of the company for which to load data related to shared fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, permissionMode?: string, fieldSharingCompanyId?: string, options?: any): AxiosPromise<ActivityObjects200Response> {
            return localVarFp.activityObjects(objectIds, version, deleted, pageKey, developerPayload, properties, permissionMode, fieldSharingCompanyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update one or more activities.
         * @param {ActivityPatchRequest} activityPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityPatch(activityPatchRequest: ActivityPatchRequest, options?: any): AxiosPromise<ActivityPut200Response> {
            return localVarFp.activityPatch(activityPatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create or replace one or more activities.
         * @param {ActivityPutRequest} activityPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityPut(activityPutRequest: ActivityPutRequest, options?: any): AxiosPromise<ActivityPut200Response> {
            return localVarFp.activityPut(activityPutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Restore one or more activities.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityUndelete(objectIds: string, version?: string, developerPayload?: string, options?: any): AxiosPromise<ActivityUndelete200Response> {
            return localVarFp.activityUndelete(objectIds, version, developerPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for activitiesGpsLog operation in ActivitiesApi.
 * @export
 * @interface ActivitiesApiActivitiesGpsLogRequest
 */
export interface ActivitiesApiActivitiesGpsLogRequest {
    /**
     * Id of the activity for which to return the gps track.
     * @type {string}
     * @memberof ActivitiesApiActivitiesGpsLog
     */
    readonly activityId: string

    /**
     * Comma seperated list of datatypes.
     * @type {string}
     * @memberof ActivitiesApiActivitiesGpsLog
     */
    readonly dataTypes: string

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof ActivitiesApiActivitiesGpsLog
     */
    readonly pageKey?: string
}

/**
 * Request parameters for activitiesGpsTrack operation in ActivitiesApi.
 * @export
 * @interface ActivitiesApiActivitiesGpsTrackRequest
 */
export interface ActivitiesApiActivitiesGpsTrackRequest {
    /**
     * Id of the activity for which to return the gps track.
     * @type {string}
     * @memberof ActivitiesApiActivitiesGpsTrack
     */
    readonly activityId: string
}

/**
 * Request parameters for activityDelete operation in ActivitiesApi.
 * @export
 * @interface ActivitiesApiActivityDeleteRequest
 */
export interface ActivitiesApiActivityDeleteRequest {
    /**
     * A comma sperated list of object ids.
     * @type {string}
     * @memberof ActivitiesApiActivityDelete
     */
    readonly objectIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof ActivitiesApiActivityDelete
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof ActivitiesApiActivityDelete
     */
    readonly developerPayload?: string
}

/**
 * Request parameters for activityFavouriteProducts operation in ActivitiesApi.
 * @export
 * @interface ActivitiesApiActivityFavouriteProductsRequest
 */
export interface ActivitiesApiActivityFavouriteProductsRequest {
    /**
     * A comma seperated list of company ids for which to return favourite products. If more than one company is specified they must belong to the same copmany cluster and the user must be authorized on all of them.
     * @type {string}
     * @memberof ActivitiesApiActivityFavouriteProducts
     */
    readonly companyIds: string

    /**
     * Id of the currently selected process order, for multicompany the current process order of any of the companies listed in &#x60;companyIds&#x60; can be used.
     * @type {string}
     * @memberof ActivitiesApiActivityFavouriteProducts
     */
    readonly processOrderId: string

    /**
     * The id of the selected activity type.
     * @type {string}
     * @memberof ActivitiesApiActivityFavouriteProducts
     */
    readonly activityTypeId: string

    /**
     * A comma seperated list of crop ids.
     * @type {string}
     * @memberof ActivitiesApiActivityFavouriteProducts
     */
    readonly cropIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof ActivitiesApiActivityFavouriteProducts
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof ActivitiesApiActivityFavouriteProducts
     */
    readonly developerPayload?: string
}

/**
 * Request parameters for activityLastUsedProductAmount operation in ActivitiesApi.
 * @export
 * @interface ActivitiesApiActivityLastUsedProductAmountRequest
 */
export interface ActivitiesApiActivityLastUsedProductAmountRequest {
    /**
     * A comma seperated list of company ids for which to return the last used amount.              If more than one company is specified they must belong to the same company cluster and              the user must be authorized on all of them.
     * @type {string}
     * @memberof ActivitiesApiActivityLastUsedProductAmount
     */
    readonly companyIds: string

    /**
     * The id of the product for which to find the last used amount.
     * @type {string}
     * @memberof ActivitiesApiActivityLastUsedProductAmount
     */
    readonly productId: string

    /**
     * The id of the current process order, this and the previous process order are searched.
     * @type {string}
     * @memberof ActivitiesApiActivityLastUsedProductAmount
     */
    readonly processOrderId: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof ActivitiesApiActivityLastUsedProductAmount
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof ActivitiesApiActivityLastUsedProductAmount
     */
    readonly developerPayload?: string
}

/**
 * Request parameters for activityList operation in ActivitiesApi.
 * @export
 * @interface ActivitiesApiActivityListRequest
 */
export interface ActivitiesApiActivityListRequest {
    /**
     * The id of the process order for which all activities should be returned
     * @type {string}
     * @memberof ActivitiesApiActivityList
     */
    readonly processOrderId: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof ActivitiesApiActivityList
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof ActivitiesApiActivityList
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof ActivitiesApiActivityList
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof ActivitiesApiActivityList
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof ActivitiesApiActivityList
     */
    readonly properties?: string

    /**
     * Enable special permission modes
     * @type {string}
     * @memberof ActivitiesApiActivityList
     */
    readonly permissionMode?: string

    /**
     * For &#x60;permissionMode&#x3D;fieldSharing&#x60;: The Id of the company for which to load data related to shared fields.
     * @type {string}
     * @memberof ActivitiesApiActivityList
     */
    readonly fieldSharingCompanyId?: string
}

/**
 * Request parameters for activityMoveToProcessOrder operation in ActivitiesApi.
 * @export
 * @interface ActivitiesApiActivityMoveToProcessOrderRequest
 */
export interface ActivitiesApiActivityMoveToProcessOrderRequest {
    /**
     * 
     * @type {ActivityMoveToProcessOrderRequest}
     * @memberof ActivitiesApiActivityMoveToProcessOrder
     */
    readonly activityMoveToProcessOrderRequest?: ActivityMoveToProcessOrderRequest
}

/**
 * Request parameters for activityObjects operation in ActivitiesApi.
 * @export
 * @interface ActivitiesApiActivityObjectsRequest
 */
export interface ActivitiesApiActivityObjectsRequest {
    /**
     * Comma seperated list of object ids, use this to request specific objects.
     * @type {string}
     * @memberof ActivitiesApiActivityObjects
     */
    readonly objectIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof ActivitiesApiActivityObjects
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof ActivitiesApiActivityObjects
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof ActivitiesApiActivityObjects
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof ActivitiesApiActivityObjects
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof ActivitiesApiActivityObjects
     */
    readonly properties?: string

    /**
     * Enable special permission modes
     * @type {string}
     * @memberof ActivitiesApiActivityObjects
     */
    readonly permissionMode?: string

    /**
     * For &#x60;permissionMode&#x3D;fieldSharing&#x60;: The Id of the company for which to load data related to shared fields.
     * @type {string}
     * @memberof ActivitiesApiActivityObjects
     */
    readonly fieldSharingCompanyId?: string
}

/**
 * Request parameters for activityPatch operation in ActivitiesApi.
 * @export
 * @interface ActivitiesApiActivityPatchRequest
 */
export interface ActivitiesApiActivityPatchRequest {
    /**
     * 
     * @type {ActivityPatchRequest}
     * @memberof ActivitiesApiActivityPatch
     */
    readonly activityPatchRequest: ActivityPatchRequest
}

/**
 * Request parameters for activityPut operation in ActivitiesApi.
 * @export
 * @interface ActivitiesApiActivityPutRequest
 */
export interface ActivitiesApiActivityPutRequest {
    /**
     * 
     * @type {ActivityPutRequest}
     * @memberof ActivitiesApiActivityPut
     */
    readonly activityPutRequest: ActivityPutRequest
}

/**
 * Request parameters for activityUndelete operation in ActivitiesApi.
 * @export
 * @interface ActivitiesApiActivityUndeleteRequest
 */
export interface ActivitiesApiActivityUndeleteRequest {
    /**
     * A comma sperated list of object ids.
     * @type {string}
     * @memberof ActivitiesApiActivityUndelete
     */
    readonly objectIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof ActivitiesApiActivityUndelete
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof ActivitiesApiActivityUndelete
     */
    readonly developerPayload?: string
}

/**
 * ActivitiesApi - object-oriented interface
 * @export
 * @class ActivitiesApi
 * @extends {BaseAPI}
 */
export class ActivitiesApi extends BaseAPI {
    /**
     * Returns the gps log for an activity in geoJson format for the selected `datatypes`.         Only points with at least one defined value for the selected `datatypes` are returned.         Currently `time` is the only supported datatype supported, in the future the activities endpoint will provide a         list of available datatypes for each activity.         Currently only every forth point is returned.
     * @param {ActivitiesApiActivitiesGpsLogRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    public activitiesGpsLog(requestParameters: ActivitiesApiActivitiesGpsLogRequest, options?: AxiosRequestConfig) {
        return ActivitiesApiFp(this.configuration).activitiesGpsLog(requestParameters.activityId, requestParameters.dataTypes, requestParameters.pageKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the gps track for an activity in geoJson format.
     * @param {ActivitiesApiActivitiesGpsTrackRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    public activitiesGpsTrack(requestParameters: ActivitiesApiActivitiesGpsTrackRequest, options?: AxiosRequestConfig) {
        return ActivitiesApiFp(this.configuration).activitiesGpsTrack(requestParameters.activityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete one or more activities.
     * @param {ActivitiesApiActivityDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    public activityDelete(requestParameters: ActivitiesApiActivityDeleteRequest, options?: AxiosRequestConfig) {
        return ActivitiesApiFp(this.configuration).activityDelete(requestParameters.objectIds, requestParameters.version, requestParameters.developerPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the favourite (most commonly used) product combinations for the given activity type and crop.
     * @param {ActivitiesApiActivityFavouriteProductsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    public activityFavouriteProducts(requestParameters: ActivitiesApiActivityFavouriteProductsRequest, options?: AxiosRequestConfig) {
        return ActivitiesApiFp(this.configuration).activityFavouriteProducts(requestParameters.companyIds, requestParameters.processOrderId, requestParameters.activityTypeId, requestParameters.cropIds, requestParameters.version, requestParameters.developerPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the amount and unitId for the last time the product `productId` was used in companies `companyIds`
     * @param {ActivitiesApiActivityLastUsedProductAmountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    public activityLastUsedProductAmount(requestParameters: ActivitiesApiActivityLastUsedProductAmountRequest, options?: AxiosRequestConfig) {
        return ActivitiesApiFp(this.configuration).activityLastUsedProductAmount(requestParameters.companyIds, requestParameters.productId, requestParameters.processOrderId, requestParameters.version, requestParameters.developerPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load one or more activities.             If the user does not have `GLOBAL_ALL_COMPANY_MEASURES_ACCESSIBLE` permissions only their own activities are returned.             If the user does not have `GLOBAL_ALL_FIELDS` permissions only activities on active fields or activities without fields are returned.
     * @param {ActivitiesApiActivityListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    public activityList(requestParameters: ActivitiesApiActivityListRequest, options?: AxiosRequestConfig) {
        return ActivitiesApiFp(this.configuration).activityList(requestParameters.processOrderId, requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, requestParameters.permissionMode, requestParameters.fieldSharingCompanyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Moves one or more activities to a different process order.              The activities are copied to the destination process order and are deleted in the source process order.             If one or more of the activities cannot be copied because of a conflict (no matching field found, permission error, ...)              no activities will be moved and a 409 response with status `conflict` will be returned.
     * @param {ActivitiesApiActivityMoveToProcessOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    public activityMoveToProcessOrder(requestParameters: ActivitiesApiActivityMoveToProcessOrderRequest = {}, options?: AxiosRequestConfig) {
        return ActivitiesApiFp(this.configuration).activityMoveToProcessOrder(requestParameters.activityMoveToProcessOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load one or more activities by activity id.              If the user does not have `GLOBAL_ALL_COMPANY_MEASURES_ACCESSIBLE` permissions only their own activities are returned.             If the user does not have `GLOBAL_ALL_FIELDS` permissions only activities on active fields or activities without fields are returned.
     * @param {ActivitiesApiActivityObjectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    public activityObjects(requestParameters: ActivitiesApiActivityObjectsRequest, options?: AxiosRequestConfig) {
        return ActivitiesApiFp(this.configuration).activityObjects(requestParameters.objectIds, requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, requestParameters.permissionMode, requestParameters.fieldSharingCompanyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update one or more activities.
     * @param {ActivitiesApiActivityPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    public activityPatch(requestParameters: ActivitiesApiActivityPatchRequest, options?: AxiosRequestConfig) {
        return ActivitiesApiFp(this.configuration).activityPatch(requestParameters.activityPatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create or replace one or more activities.
     * @param {ActivitiesApiActivityPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    public activityPut(requestParameters: ActivitiesApiActivityPutRequest, options?: AxiosRequestConfig) {
        return ActivitiesApiFp(this.configuration).activityPut(requestParameters.activityPutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Restore one or more activities.
     * @param {ActivitiesApiActivityUndeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    public activityUndelete(requestParameters: ActivitiesApiActivityUndeleteRequest, options?: AxiosRequestConfig) {
        return ActivitiesApiFp(this.configuration).activityUndelete(requestParameters.objectIds, requestParameters.version, requestParameters.developerPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ActivityContoursApi - axios parameter creator
 * @export
 */
export const ActivityContoursApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Load one or more field contours by field contour id.              If the user does not have `GLOBAL_ALL_FIELDS` permissions only contours for active fields are returned.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [permissionMode] Enable special permission modes
         * @param {string} [fieldSharingCompanyId] For &#x60;permissionMode&#x3D;fieldSharing&#x60;: The Id of the company for which to load data related to shared fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldContourObjects: async (objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, permissionMode?: string, fieldSharingCompanyId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectIds' is not null or undefined
            assertParamExists('fieldContourObjects', 'objectIds', objectIds)
            const localVarPath = `/fieldContours/{objectIds}`
                .replace(`{${"objectIds"}}`, encodeURIComponent(String(objectIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }

            if (permissionMode !== undefined) {
                localVarQueryParameter['permissionMode'] = permissionMode;
            }

            if (fieldSharingCompanyId !== undefined) {
                localVarQueryParameter['fieldSharingCompanyId'] = fieldSharingCompanyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivityContoursApi - functional programming interface
 * @export
 */
export const ActivityContoursApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActivityContoursApiAxiosParamCreator(configuration)
    return {
        /**
         * Load one or more field contours by field contour id.              If the user does not have `GLOBAL_ALL_FIELDS` permissions only contours for active fields are returned.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [permissionMode] Enable special permission modes
         * @param {string} [fieldSharingCompanyId] For &#x60;permissionMode&#x3D;fieldSharing&#x60;: The Id of the company for which to load data related to shared fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldContourObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, permissionMode?: string, fieldSharingCompanyId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldContourObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldContourObjects(objectIds, version, deleted, pageKey, developerPayload, properties, permissionMode, fieldSharingCompanyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActivityContoursApi - factory interface
 * @export
 */
export const ActivityContoursApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActivityContoursApiFp(configuration)
    return {
        /**
         * Load one or more field contours by field contour id.              If the user does not have `GLOBAL_ALL_FIELDS` permissions only contours for active fields are returned.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [permissionMode] Enable special permission modes
         * @param {string} [fieldSharingCompanyId] For &#x60;permissionMode&#x3D;fieldSharing&#x60;: The Id of the company for which to load data related to shared fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldContourObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, permissionMode?: string, fieldSharingCompanyId?: string, options?: any): AxiosPromise<FieldContourObjects200Response> {
            return localVarFp.fieldContourObjects(objectIds, version, deleted, pageKey, developerPayload, properties, permissionMode, fieldSharingCompanyId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for fieldContourObjects operation in ActivityContoursApi.
 * @export
 * @interface ActivityContoursApiFieldContourObjectsRequest
 */
export interface ActivityContoursApiFieldContourObjectsRequest {
    /**
     * Comma seperated list of object ids, use this to request specific objects.
     * @type {string}
     * @memberof ActivityContoursApiFieldContourObjects
     */
    readonly objectIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof ActivityContoursApiFieldContourObjects
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof ActivityContoursApiFieldContourObjects
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof ActivityContoursApiFieldContourObjects
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof ActivityContoursApiFieldContourObjects
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof ActivityContoursApiFieldContourObjects
     */
    readonly properties?: string

    /**
     * Enable special permission modes
     * @type {string}
     * @memberof ActivityContoursApiFieldContourObjects
     */
    readonly permissionMode?: string

    /**
     * For &#x60;permissionMode&#x3D;fieldSharing&#x60;: The Id of the company for which to load data related to shared fields.
     * @type {string}
     * @memberof ActivityContoursApiFieldContourObjects
     */
    readonly fieldSharingCompanyId?: string
}

/**
 * ActivityContoursApi - object-oriented interface
 * @export
 * @class ActivityContoursApi
 * @extends {BaseAPI}
 */
export class ActivityContoursApi extends BaseAPI {
    /**
     * Load one or more field contours by field contour id.              If the user does not have `GLOBAL_ALL_FIELDS` permissions only contours for active fields are returned.
     * @param {ActivityContoursApiFieldContourObjectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityContoursApi
     */
    public fieldContourObjects(requestParameters: ActivityContoursApiFieldContourObjectsRequest, options?: AxiosRequestConfig) {
        return ActivityContoursApiFp(this.configuration).fieldContourObjects(requestParameters.objectIds, requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, requestParameters.permissionMode, requestParameters.fieldSharingCompanyId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ActivityImportApi - axios parameter creator
 * @export
 */
export const ActivityImportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete objects from an import source.          Response is the status after the objects were deleted  (see `/activityImport/status`).
         * @param {ActivityImportDeleteObjectsRequest} [activityImportDeleteObjectsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityImportDeleteObjects: async (activityImportDeleteObjectsRequest?: ActivityImportDeleteObjectsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/activityImport/deleteObjects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityImportDeleteObjectsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Processes and maps the available data for the specified import source and returns activities that can be imported.
         * @param {ActivityImportSource} source The import source to get the current status for.
         * @param {string} processOrderId The process order for which to map the import data.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [objectIds] Comma separated list of object ids to import, if not supplied all objects will be imported
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityImportMapData: async (source: ActivityImportSource, processOrderId: string, version?: string, developerPayload?: string, objectIds?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'source' is not null or undefined
            assertParamExists('activityImportMapData', 'source', source)
            // verify required parameter 'processOrderId' is not null or undefined
            assertParamExists('activityImportMapData', 'processOrderId', processOrderId)
            const localVarPath = `/activityImport/mapData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }

            if (processOrderId !== undefined) {
                localVarQueryParameter['processOrderId'] = processOrderId;
            }

            if (objectIds !== undefined) {
                localVarQueryParameter['objectIds'] = objectIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Resets the import and deletes all uploaded/downloaded files for the logged in user. Note that it might not be immediately possible to cancel a running import, poll `/activityImport/status` if needed.
         * @param {ActivityImportResetRequest} [activityImportResetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityImportReset: async (activityImportResetRequest?: ActivityImportResetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/activityImport/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityImportResetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Clears all mappings for a company.
         * @param {ActivityImportResetMappingsRequest} [activityImportResetMappingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityImportResetMappings: async (activityImportResetMappingsRequest?: ActivityImportResetMappingsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/activityImport/resetMappings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityImportResetMappingsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the current status for the specified import source.
         * @param {ActivityImportSource} source The import source to get the current status for.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityImportStatus: async (source: ActivityImportSource, version?: string, developerPayload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'source' is not null or undefined
            assertParamExists('activityImportStatus', 'source', source)
            const localVarPath = `/activityImport/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Store or update a mapping for the activity import.
         * @param {ActivityImportStoreMappingsRequest} [activityImportStoreMappingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityImportStoreMappings: async (activityImportStoreMappingsRequest?: ActivityImportStoreMappingsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/activityImport/storeMappings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityImportStoreMappingsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Uploads files for the activities import. Uploaded files will be automatically deleted after a period of time.         Response on success is the status after uploading the files (see `/activityImport/status`)
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {Array<File>} [files] Files to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityImportUpload: async (version?: string, developerPayload?: string, files?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/activityImport/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (version !== undefined) { 
                localVarFormParams.append('version', version as any);
            }
    
            if (developerPayload !== undefined) { 
                localVarFormParams.append('developerPayload', developerPayload as any);
            }
                if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files[]', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivityImportApi - functional programming interface
 * @export
 */
export const ActivityImportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActivityImportApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete objects from an import source.          Response is the status after the objects were deleted  (see `/activityImport/status`).
         * @param {ActivityImportDeleteObjectsRequest} [activityImportDeleteObjectsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityImportDeleteObjects(activityImportDeleteObjectsRequest?: ActivityImportDeleteObjectsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityImportDeleteObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityImportDeleteObjects(activityImportDeleteObjectsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Processes and maps the available data for the specified import source and returns activities that can be imported.
         * @param {ActivityImportSource} source The import source to get the current status for.
         * @param {string} processOrderId The process order for which to map the import data.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [objectIds] Comma separated list of object ids to import, if not supplied all objects will be imported
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityImportMapData(source: ActivityImportSource, processOrderId: string, version?: string, developerPayload?: string, objectIds?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityImportMapData200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityImportMapData(source, processOrderId, version, developerPayload, objectIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Resets the import and deletes all uploaded/downloaded files for the logged in user. Note that it might not be immediately possible to cancel a running import, poll `/activityImport/status` if needed.
         * @param {ActivityImportResetRequest} [activityImportResetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityImportReset(activityImportResetRequest?: ActivityImportResetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityImportDeleteObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityImportReset(activityImportResetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Clears all mappings for a company.
         * @param {ActivityImportResetMappingsRequest} [activityImportResetMappingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityImportResetMappings(activityImportResetMappingsRequest?: ActivityImportResetMappingsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityImportStoreMappings200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityImportResetMappings(activityImportResetMappingsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the current status for the specified import source.
         * @param {ActivityImportSource} source The import source to get the current status for.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityImportStatus(source: ActivityImportSource, version?: string, developerPayload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityImportStatus200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityImportStatus(source, version, developerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Store or update a mapping for the activity import.
         * @param {ActivityImportStoreMappingsRequest} [activityImportStoreMappingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityImportStoreMappings(activityImportStoreMappingsRequest?: ActivityImportStoreMappingsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityImportStoreMappings200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityImportStoreMappings(activityImportStoreMappingsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Uploads files for the activities import. Uploaded files will be automatically deleted after a period of time.         Response on success is the status after uploading the files (see `/activityImport/status`)
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {Array<File>} [files] Files to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityImportUpload(version?: string, developerPayload?: string, files?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityImportUpload200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityImportUpload(version, developerPayload, files, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActivityImportApi - factory interface
 * @export
 */
export const ActivityImportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActivityImportApiFp(configuration)
    return {
        /**
         * Delete objects from an import source.          Response is the status after the objects were deleted  (see `/activityImport/status`).
         * @param {ActivityImportDeleteObjectsRequest} [activityImportDeleteObjectsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityImportDeleteObjects(activityImportDeleteObjectsRequest?: ActivityImportDeleteObjectsRequest, options?: any): AxiosPromise<ActivityImportDeleteObjects200Response> {
            return localVarFp.activityImportDeleteObjects(activityImportDeleteObjectsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Processes and maps the available data for the specified import source and returns activities that can be imported.
         * @param {ActivityImportSource} source The import source to get the current status for.
         * @param {string} processOrderId The process order for which to map the import data.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [objectIds] Comma separated list of object ids to import, if not supplied all objects will be imported
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityImportMapData(source: ActivityImportSource, processOrderId: string, version?: string, developerPayload?: string, objectIds?: string, options?: any): AxiosPromise<ActivityImportMapData200Response> {
            return localVarFp.activityImportMapData(source, processOrderId, version, developerPayload, objectIds, options).then((request) => request(axios, basePath));
        },
        /**
         * Resets the import and deletes all uploaded/downloaded files for the logged in user. Note that it might not be immediately possible to cancel a running import, poll `/activityImport/status` if needed.
         * @param {ActivityImportResetRequest} [activityImportResetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityImportReset(activityImportResetRequest?: ActivityImportResetRequest, options?: any): AxiosPromise<ActivityImportDeleteObjects200Response> {
            return localVarFp.activityImportReset(activityImportResetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Clears all mappings for a company.
         * @param {ActivityImportResetMappingsRequest} [activityImportResetMappingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityImportResetMappings(activityImportResetMappingsRequest?: ActivityImportResetMappingsRequest, options?: any): AxiosPromise<ActivityImportStoreMappings200Response> {
            return localVarFp.activityImportResetMappings(activityImportResetMappingsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the current status for the specified import source.
         * @param {ActivityImportSource} source The import source to get the current status for.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityImportStatus(source: ActivityImportSource, version?: string, developerPayload?: string, options?: any): AxiosPromise<ActivityImportStatus200Response> {
            return localVarFp.activityImportStatus(source, version, developerPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Store or update a mapping for the activity import.
         * @param {ActivityImportStoreMappingsRequest} [activityImportStoreMappingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityImportStoreMappings(activityImportStoreMappingsRequest?: ActivityImportStoreMappingsRequest, options?: any): AxiosPromise<ActivityImportStoreMappings200Response> {
            return localVarFp.activityImportStoreMappings(activityImportStoreMappingsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Uploads files for the activities import. Uploaded files will be automatically deleted after a period of time.         Response on success is the status after uploading the files (see `/activityImport/status`)
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {Array<File>} [files] Files to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityImportUpload(version?: string, developerPayload?: string, files?: Array<File>, options?: any): AxiosPromise<ActivityImportUpload200Response> {
            return localVarFp.activityImportUpload(version, developerPayload, files, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for activityImportDeleteObjects operation in ActivityImportApi.
 * @export
 * @interface ActivityImportApiActivityImportDeleteObjectsRequest
 */
export interface ActivityImportApiActivityImportDeleteObjectsRequest {
    /**
     * 
     * @type {ActivityImportDeleteObjectsRequest}
     * @memberof ActivityImportApiActivityImportDeleteObjects
     */
    readonly activityImportDeleteObjectsRequest?: ActivityImportDeleteObjectsRequest
}

/**
 * Request parameters for activityImportMapData operation in ActivityImportApi.
 * @export
 * @interface ActivityImportApiActivityImportMapDataRequest
 */
export interface ActivityImportApiActivityImportMapDataRequest {
    /**
     * The import source to get the current status for.
     * @type {ActivityImportSource}
     * @memberof ActivityImportApiActivityImportMapData
     */
    readonly source: ActivityImportSource

    /**
     * The process order for which to map the import data.
     * @type {string}
     * @memberof ActivityImportApiActivityImportMapData
     */
    readonly processOrderId: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof ActivityImportApiActivityImportMapData
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof ActivityImportApiActivityImportMapData
     */
    readonly developerPayload?: string

    /**
     * Comma separated list of object ids to import, if not supplied all objects will be imported
     * @type {string}
     * @memberof ActivityImportApiActivityImportMapData
     */
    readonly objectIds?: string
}

/**
 * Request parameters for activityImportReset operation in ActivityImportApi.
 * @export
 * @interface ActivityImportApiActivityImportResetRequest
 */
export interface ActivityImportApiActivityImportResetRequest {
    /**
     * 
     * @type {ActivityImportResetRequest}
     * @memberof ActivityImportApiActivityImportReset
     */
    readonly activityImportResetRequest?: ActivityImportResetRequest
}

/**
 * Request parameters for activityImportResetMappings operation in ActivityImportApi.
 * @export
 * @interface ActivityImportApiActivityImportResetMappingsRequest
 */
export interface ActivityImportApiActivityImportResetMappingsRequest {
    /**
     * 
     * @type {ActivityImportResetMappingsRequest}
     * @memberof ActivityImportApiActivityImportResetMappings
     */
    readonly activityImportResetMappingsRequest?: ActivityImportResetMappingsRequest
}

/**
 * Request parameters for activityImportStatus operation in ActivityImportApi.
 * @export
 * @interface ActivityImportApiActivityImportStatusRequest
 */
export interface ActivityImportApiActivityImportStatusRequest {
    /**
     * The import source to get the current status for.
     * @type {ActivityImportSource}
     * @memberof ActivityImportApiActivityImportStatus
     */
    readonly source: ActivityImportSource

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof ActivityImportApiActivityImportStatus
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof ActivityImportApiActivityImportStatus
     */
    readonly developerPayload?: string
}

/**
 * Request parameters for activityImportStoreMappings operation in ActivityImportApi.
 * @export
 * @interface ActivityImportApiActivityImportStoreMappingsRequest
 */
export interface ActivityImportApiActivityImportStoreMappingsRequest {
    /**
     * 
     * @type {ActivityImportStoreMappingsRequest}
     * @memberof ActivityImportApiActivityImportStoreMappings
     */
    readonly activityImportStoreMappingsRequest?: ActivityImportStoreMappingsRequest
}

/**
 * Request parameters for activityImportUpload operation in ActivityImportApi.
 * @export
 * @interface ActivityImportApiActivityImportUploadRequest
 */
export interface ActivityImportApiActivityImportUploadRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof ActivityImportApiActivityImportUpload
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof ActivityImportApiActivityImportUpload
     */
    readonly developerPayload?: string

    /**
     * Files to upload.
     * @type {Array<File>}
     * @memberof ActivityImportApiActivityImportUpload
     */
    readonly files?: Array<File>
}

/**
 * ActivityImportApi - object-oriented interface
 * @export
 * @class ActivityImportApi
 * @extends {BaseAPI}
 */
export class ActivityImportApi extends BaseAPI {
    /**
     * Delete objects from an import source.          Response is the status after the objects were deleted  (see `/activityImport/status`).
     * @param {ActivityImportApiActivityImportDeleteObjectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityImportApi
     */
    public activityImportDeleteObjects(requestParameters: ActivityImportApiActivityImportDeleteObjectsRequest = {}, options?: AxiosRequestConfig) {
        return ActivityImportApiFp(this.configuration).activityImportDeleteObjects(requestParameters.activityImportDeleteObjectsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Processes and maps the available data for the specified import source and returns activities that can be imported.
     * @param {ActivityImportApiActivityImportMapDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityImportApi
     */
    public activityImportMapData(requestParameters: ActivityImportApiActivityImportMapDataRequest, options?: AxiosRequestConfig) {
        return ActivityImportApiFp(this.configuration).activityImportMapData(requestParameters.source, requestParameters.processOrderId, requestParameters.version, requestParameters.developerPayload, requestParameters.objectIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Resets the import and deletes all uploaded/downloaded files for the logged in user. Note that it might not be immediately possible to cancel a running import, poll `/activityImport/status` if needed.
     * @param {ActivityImportApiActivityImportResetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityImportApi
     */
    public activityImportReset(requestParameters: ActivityImportApiActivityImportResetRequest = {}, options?: AxiosRequestConfig) {
        return ActivityImportApiFp(this.configuration).activityImportReset(requestParameters.activityImportResetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Clears all mappings for a company.
     * @param {ActivityImportApiActivityImportResetMappingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityImportApi
     */
    public activityImportResetMappings(requestParameters: ActivityImportApiActivityImportResetMappingsRequest = {}, options?: AxiosRequestConfig) {
        return ActivityImportApiFp(this.configuration).activityImportResetMappings(requestParameters.activityImportResetMappingsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the current status for the specified import source.
     * @param {ActivityImportApiActivityImportStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityImportApi
     */
    public activityImportStatus(requestParameters: ActivityImportApiActivityImportStatusRequest, options?: AxiosRequestConfig) {
        return ActivityImportApiFp(this.configuration).activityImportStatus(requestParameters.source, requestParameters.version, requestParameters.developerPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Store or update a mapping for the activity import.
     * @param {ActivityImportApiActivityImportStoreMappingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityImportApi
     */
    public activityImportStoreMappings(requestParameters: ActivityImportApiActivityImportStoreMappingsRequest = {}, options?: AxiosRequestConfig) {
        return ActivityImportApiFp(this.configuration).activityImportStoreMappings(requestParameters.activityImportStoreMappingsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Uploads files for the activities import. Uploaded files will be automatically deleted after a period of time.         Response on success is the status after uploading the files (see `/activityImport/status`)
     * @param {ActivityImportApiActivityImportUploadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityImportApi
     */
    public activityImportUpload(requestParameters: ActivityImportApiActivityImportUploadRequest = {}, options?: AxiosRequestConfig) {
        return ActivityImportApiFp(this.configuration).activityImportUpload(requestParameters.version, requestParameters.developerPayload, requestParameters.files, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApplicationMapsApi - axios parameter creator
 * @export
 */
export const ApplicationMapsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns a list of timestamps with satelite images for the given field.
         * @param {RequestTerrazoFieldTimestamp} [requestTerrazoFieldTimestamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationMapsTerrazoFieldTimestamp: async (requestTerrazoFieldTimestamp?: RequestTerrazoFieldTimestamp, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/applicationMaps/terrazo/fieldTimestamp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestTerrazoFieldTimestamp, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a geoJson map for the given field at the selected timestamp (`dbId`).
         * @param {RequestTerrazoMap} [requestTerrazoMap] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationMapsTerrazoMap: async (requestTerrazoMap?: RequestTerrazoMap, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/applicationMaps/terrazo/map`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestTerrazoMap, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationMapsApi - functional programming interface
 * @export
 */
export const ApplicationMapsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationMapsApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns a list of timestamps with satelite images for the given field.
         * @param {RequestTerrazoFieldTimestamp} [requestTerrazoFieldTimestamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicationMapsTerrazoFieldTimestamp(requestTerrazoFieldTimestamp?: RequestTerrazoFieldTimestamp, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationMapsTerrazoFieldTimestamp200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applicationMapsTerrazoFieldTimestamp(requestTerrazoFieldTimestamp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a geoJson map for the given field at the selected timestamp (`dbId`).
         * @param {RequestTerrazoMap} [requestTerrazoMap] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicationMapsTerrazoMap(requestTerrazoMap?: RequestTerrazoMap, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationMapsTerrazoMap200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applicationMapsTerrazoMap(requestTerrazoMap, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicationMapsApi - factory interface
 * @export
 */
export const ApplicationMapsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationMapsApiFp(configuration)
    return {
        /**
         * Returns a list of timestamps with satelite images for the given field.
         * @param {RequestTerrazoFieldTimestamp} [requestTerrazoFieldTimestamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationMapsTerrazoFieldTimestamp(requestTerrazoFieldTimestamp?: RequestTerrazoFieldTimestamp, options?: any): AxiosPromise<ApplicationMapsTerrazoFieldTimestamp200Response> {
            return localVarFp.applicationMapsTerrazoFieldTimestamp(requestTerrazoFieldTimestamp, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a geoJson map for the given field at the selected timestamp (`dbId`).
         * @param {RequestTerrazoMap} [requestTerrazoMap] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationMapsTerrazoMap(requestTerrazoMap?: RequestTerrazoMap, options?: any): AxiosPromise<ApplicationMapsTerrazoMap200Response> {
            return localVarFp.applicationMapsTerrazoMap(requestTerrazoMap, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for applicationMapsTerrazoFieldTimestamp operation in ApplicationMapsApi.
 * @export
 * @interface ApplicationMapsApiApplicationMapsTerrazoFieldTimestampRequest
 */
export interface ApplicationMapsApiApplicationMapsTerrazoFieldTimestampRequest {
    /**
     * 
     * @type {RequestTerrazoFieldTimestamp}
     * @memberof ApplicationMapsApiApplicationMapsTerrazoFieldTimestamp
     */
    readonly requestTerrazoFieldTimestamp?: RequestTerrazoFieldTimestamp
}

/**
 * Request parameters for applicationMapsTerrazoMap operation in ApplicationMapsApi.
 * @export
 * @interface ApplicationMapsApiApplicationMapsTerrazoMapRequest
 */
export interface ApplicationMapsApiApplicationMapsTerrazoMapRequest {
    /**
     * 
     * @type {RequestTerrazoMap}
     * @memberof ApplicationMapsApiApplicationMapsTerrazoMap
     */
    readonly requestTerrazoMap?: RequestTerrazoMap
}

/**
 * ApplicationMapsApi - object-oriented interface
 * @export
 * @class ApplicationMapsApi
 * @extends {BaseAPI}
 */
export class ApplicationMapsApi extends BaseAPI {
    /**
     * Returns a list of timestamps with satelite images for the given field.
     * @param {ApplicationMapsApiApplicationMapsTerrazoFieldTimestampRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationMapsApi
     */
    public applicationMapsTerrazoFieldTimestamp(requestParameters: ApplicationMapsApiApplicationMapsTerrazoFieldTimestampRequest = {}, options?: AxiosRequestConfig) {
        return ApplicationMapsApiFp(this.configuration).applicationMapsTerrazoFieldTimestamp(requestParameters.requestTerrazoFieldTimestamp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a geoJson map for the given field at the selected timestamp (`dbId`).
     * @param {ApplicationMapsApiApplicationMapsTerrazoMapRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationMapsApi
     */
    public applicationMapsTerrazoMap(requestParameters: ApplicationMapsApiApplicationMapsTerrazoMapRequest = {}, options?: AxiosRequestConfig) {
        return ApplicationMapsApiFp(this.configuration).applicationMapsTerrazoMap(requestParameters.requestTerrazoMap, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * Class340Api - axios parameter creator
 * @export
 */
export const Class340ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete one or more activities.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityDelete: async (objectIds: string, version?: string, developerPayload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectIds' is not null or undefined
            assertParamExists('activityDelete', 'objectIds', objectIds)
            const localVarPath = `/activities/{objectIds}`
                .replace(`{${"objectIds"}}`, encodeURIComponent(String(objectIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the favourite (most commonly used) product combinations for the given activity type and crop.
         * @param {string} companyIds A comma seperated list of company ids for which to return favourite products. If more than one company is specified they must belong to the same copmany cluster and the user must be authorized on all of them.
         * @param {string} processOrderId Id of the currently selected process order, for multicompany the current process order of any of the companies listed in &#x60;companyIds&#x60; can be used.
         * @param {string} activityTypeId The id of the selected activity type.
         * @param {string} cropIds A comma seperated list of crop ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityFavouriteProducts: async (companyIds: string, processOrderId: string, activityTypeId: string, cropIds: string, version?: string, developerPayload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyIds' is not null or undefined
            assertParamExists('activityFavouriteProducts', 'companyIds', companyIds)
            // verify required parameter 'processOrderId' is not null or undefined
            assertParamExists('activityFavouriteProducts', 'processOrderId', processOrderId)
            // verify required parameter 'activityTypeId' is not null or undefined
            assertParamExists('activityFavouriteProducts', 'activityTypeId', activityTypeId)
            // verify required parameter 'cropIds' is not null or undefined
            assertParamExists('activityFavouriteProducts', 'cropIds', cropIds)
            const localVarPath = `/activities/favouriteProducts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (companyIds !== undefined) {
                localVarQueryParameter['companyIds'] = companyIds;
            }

            if (processOrderId !== undefined) {
                localVarQueryParameter['processOrderId'] = processOrderId;
            }

            if (activityTypeId !== undefined) {
                localVarQueryParameter['activityTypeId'] = activityTypeId;
            }

            if (cropIds !== undefined) {
                localVarQueryParameter['cropIds'] = cropIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the amount and unitId for the last time the product `productId` was used in companies `companyIds`
         * @param {string} companyIds A comma seperated list of company ids for which to return the last used amount.              If more than one company is specified they must belong to the same company cluster and              the user must be authorized on all of them.
         * @param {string} productId The id of the product for which to find the last used amount.
         * @param {string} processOrderId The id of the current process order, this and the previous process order are searched.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityLastUsedProductAmount: async (companyIds: string, productId: string, processOrderId: string, version?: string, developerPayload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyIds' is not null or undefined
            assertParamExists('activityLastUsedProductAmount', 'companyIds', companyIds)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('activityLastUsedProductAmount', 'productId', productId)
            // verify required parameter 'processOrderId' is not null or undefined
            assertParamExists('activityLastUsedProductAmount', 'processOrderId', processOrderId)
            const localVarPath = `/activities/lastUsedProductAmount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (companyIds !== undefined) {
                localVarQueryParameter['companyIds'] = companyIds;
            }

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }

            if (processOrderId !== undefined) {
                localVarQueryParameter['processOrderId'] = processOrderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load one or more activities.             If the user does not have `GLOBAL_ALL_COMPANY_MEASURES_ACCESSIBLE` permissions only their own activities are returned.             If the user does not have `GLOBAL_ALL_FIELDS` permissions only activities on active fields or activities without fields are returned.
         * @param {string} processOrderId The id of the process order for which all activities should be returned
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [permissionMode] Enable special permission modes
         * @param {string} [fieldSharingCompanyId] For &#x60;permissionMode&#x3D;fieldSharing&#x60;: The Id of the company for which to load data related to shared fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityList: async (processOrderId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, permissionMode?: string, fieldSharingCompanyId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processOrderId' is not null or undefined
            assertParamExists('activityList', 'processOrderId', processOrderId)
            const localVarPath = `/activities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }

            if (processOrderId !== undefined) {
                localVarQueryParameter['processOrderId'] = processOrderId;
            }

            if (permissionMode !== undefined) {
                localVarQueryParameter['permissionMode'] = permissionMode;
            }

            if (fieldSharingCompanyId !== undefined) {
                localVarQueryParameter['fieldSharingCompanyId'] = fieldSharingCompanyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Moves one or more activities to a different process order.              The activities are copied to the destination process order and are deleted in the source process order.             If one or more of the activities cannot be copied because of a conflict (no matching field found, permission error, ...)              no activities will be moved and a 409 response with status `conflict` will be returned.
         * @param {ActivityMoveToProcessOrderRequest} [activityMoveToProcessOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityMoveToProcessOrder: async (activityMoveToProcessOrderRequest?: ActivityMoveToProcessOrderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/activities/moveToProcessOrder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityMoveToProcessOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load one or more activities by activity id.              If the user does not have `GLOBAL_ALL_COMPANY_MEASURES_ACCESSIBLE` permissions only their own activities are returned.             If the user does not have `GLOBAL_ALL_FIELDS` permissions only activities on active fields or activities without fields are returned.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [permissionMode] Enable special permission modes
         * @param {string} [fieldSharingCompanyId] For &#x60;permissionMode&#x3D;fieldSharing&#x60;: The Id of the company for which to load data related to shared fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityObjects: async (objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, permissionMode?: string, fieldSharingCompanyId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectIds' is not null or undefined
            assertParamExists('activityObjects', 'objectIds', objectIds)
            const localVarPath = `/activities/{objectIds}`
                .replace(`{${"objectIds"}}`, encodeURIComponent(String(objectIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }

            if (permissionMode !== undefined) {
                localVarQueryParameter['permissionMode'] = permissionMode;
            }

            if (fieldSharingCompanyId !== undefined) {
                localVarQueryParameter['fieldSharingCompanyId'] = fieldSharingCompanyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update one or more activities.
         * @param {ActivityPatchRequest} activityPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityPatch: async (activityPatchRequest: ActivityPatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityPatchRequest' is not null or undefined
            assertParamExists('activityPatch', 'activityPatchRequest', activityPatchRequest)
            const localVarPath = `/activities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityPatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create or replace one or more activities.
         * @param {ActivityPutRequest} activityPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityPut: async (activityPutRequest: ActivityPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityPutRequest' is not null or undefined
            assertParamExists('activityPut', 'activityPutRequest', activityPutRequest)
            const localVarPath = `/activities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Restore one or more activities.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityUndelete: async (objectIds: string, version?: string, developerPayload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectIds' is not null or undefined
            assertParamExists('activityUndelete', 'objectIds', objectIds)
            const localVarPath = `/activities/undelete/{objectIds}`
                .replace(`{${"objectIds"}}`, encodeURIComponent(String(objectIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of timestamps with satelite images for the given field.
         * @param {RequestTerrazoFieldTimestamp} [requestTerrazoFieldTimestamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationMapsTerrazoFieldTimestamp: async (requestTerrazoFieldTimestamp?: RequestTerrazoFieldTimestamp, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/applicationMaps/terrazo/fieldTimestamp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestTerrazoFieldTimestamp, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a geoJson map for the given field at the selected timestamp (`dbId`).
         * @param {RequestTerrazoMap} [requestTerrazoMap] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationMapsTerrazoMap: async (requestTerrazoMap?: RequestTerrazoMap, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/applicationMaps/terrazo/map`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestTerrazoMap, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load austrian cultivation plan data by field id. Note: currently only returns expected yield,            for use with the terrazo API.            Will eventually replace the legacy cultivation plan API and return all cultivation plan data.
         * @param {string} fieldIds A comma seperated list of field ids for which to return cultivation plan data.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cultivationPlanAtList: async (fieldIds: string, version?: string, developerPayload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fieldIds' is not null or undefined
            assertParamExists('cultivationPlanAtList', 'fieldIds', fieldIds)
            const localVarPath = `/cultivationPlan/At/{fieldIds}`
                .replace(`{${"fieldIds"}}`, encodeURIComponent(String(fieldIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load a list of objects
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [companyId] The id of the company for which all equipment should be returned,              if not set or null global equipment are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentList: async (version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, companyId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/equipment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load one ore more equipment by equipment id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentObjects: async (objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectIds' is not null or undefined
            assertParamExists('equipmentObjects', 'objectIds', objectIds)
            const localVarPath = `/equipment/{objectIds}`
                .replace(`{${"objectIds"}}`, encodeURIComponent(String(objectIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load fertilization plan data by field id. Note: currently only returns limited data,            for use with the terrazo API.            Will eventually replace the legacy fertilization plan API and return all fertilization plan data.
         * @param {string} fieldIds A comma seperated list of field ids for which to return fertilization plan data.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fertilizationPlanFieldBasedList: async (fieldIds: string, version?: string, developerPayload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fieldIds' is not null or undefined
            assertParamExists('fertilizationPlanFieldBasedList', 'fieldIds', fieldIds)
            const localVarPath = `/fertilizationPlan/fieldBased/{fieldIds}`
                .replace(`{${"fieldIds"}}`, encodeURIComponent(String(fieldIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load a list of objects
         * @param {string} regionId The id of the region for which all pests should be returned
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pestList: async (regionId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('pestList', 'regionId', regionId)
            const localVarPath = `/pests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }

            if (regionId !== undefined) {
                localVarQueryParameter['regionId'] = regionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load one ore more pests by pest id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pestObjects: async (objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectIds' is not null or undefined
            assertParamExists('pestObjects', 'objectIds', objectIds)
            const localVarPath = `/pests/{objectIds}`
                .replace(`{${"objectIds"}}`, encodeURIComponent(String(objectIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load a list of objects
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productCategoryList: async (version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/productCategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load one ore more product categories by id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productCategoryObjects: async (objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectIds' is not null or undefined
            assertParamExists('productCategoryObjects', 'objectIds', objectIds)
            const localVarPath = `/productCategories/{objectIds}`
                .replace(`{${"objectIds"}}`, encodeURIComponent(String(objectIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of pesticide indications. Either `regionId` or `pesticideId` is required.               Performance note: There is a large and growing number of indications per region, therefore loading all              indications is discouraged.             When ever possible only load the required indications for the pesticide currently in use using the              `pesticideId` parameter.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [regionId] The ID of the region for which pesticide indicaitons should be returned. See note in operation description.
         * @param {string} [pesticideId] A pesticide ID, if set only indications for this pesticide are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productPesticideIndicationList: async (version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, regionId?: string, pesticideId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/products/pesticideIndications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }

            if (regionId !== undefined) {
                localVarQueryParameter['regionId'] = regionId;
            }

            if (pesticideId !== undefined) {
                localVarQueryParameter['pesticideId'] = pesticideId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load a list of objects
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ruleList: async (version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rulecheck/rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load a list of objects by id
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ruleObjects: async (objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectIds' is not null or undefined
            assertParamExists('ruleObjects', 'objectIds', objectIds)
            const localVarPath = `/rulecheck/rules/{objectIds}`
                .replace(`{${"objectIds"}}`, encodeURIComponent(String(objectIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load activity rule violations.
         * @param {string} processOrderId The id of the process order for which to get all activity rule violation
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rulecheckActivityRuleViolations: async (processOrderId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processOrderId' is not null or undefined
            assertParamExists('rulecheckActivityRuleViolations', 'processOrderId', processOrderId)
            const localVarPath = `/rulecheck/activityRuleViolations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }

            if (processOrderId !== undefined) {
                localVarQueryParameter['processOrderId'] = processOrderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Dissmisses the activity-rule violation with id `activityRuleViolationId`.              The violation will not be shown again unless the underlying data changes.
         * @param {string} activityRuleViolationId The ID of the activityRuleViolation to dismiss.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rulecheckDismiss: async (activityRuleViolationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityRuleViolationId' is not null or undefined
            assertParamExists('rulecheckDismiss', 'activityRuleViolationId', activityRuleViolationId)
            const localVarPath = `/rulecheck/activityRuleViolations/dismiss/{activityRuleViolationId}`
                .replace(`{${"activityRuleViolationId"}}`, encodeURIComponent(String(activityRuleViolationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Manually trigger a recheck of the rules for a process order and return the results.               Note that rules are automatically rechecked in the background after some time whenever activities are changed,             manually calling recheck is only neccessary if rulecheck results must be shown the user immediately.               Because rulecheck runs are resource intensive, recheck requests should be debounced so that for a              process order only one request is sent at a time. Requests should not be sent while a user is still activly              changing data.               Specifically clients *must* wait at-least five seconds after the last activity change was sent to the server and              at least ten seconds after a previous recheck request before triggering a new recheck.
         * @param {RulecheckRecheckRequest} [rulecheckRecheckRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rulecheckRecheck: async (rulecheckRecheckRequest?: RulecheckRecheckRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rulecheck/recheck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rulecheckRecheckRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the stock levels for a company at the supplied `timestamp`.
         * @param {string} companyId ID of the company for which to return stock-levels.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {number} [timestamp] Timestamp at which to get stock-levels. If not set, the current stock-levels are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockMovementsStockLevels: async (companyId: string, version?: string, developerPayload?: string, timestamp?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('stockMovementsStockLevels', 'companyId', companyId)
            const localVarPath = `/stockMovements/stockLevels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (timestamp !== undefined) {
                localVarQueryParameter['timestamp'] = timestamp;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load a list of objects
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [companyId] The id of the company for which all storage places should be returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storagePlaceList: async (version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, companyId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storagePlaces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load a list of objects by id
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storagePlaceObjects: async (objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectIds' is not null or undefined
            assertParamExists('storagePlaceObjects', 'objectIds', objectIds)
            const localVarPath = `/storagePlaces/{objectIds}`
                .replace(`{${"objectIds"}}`, encodeURIComponent(String(objectIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Class340Api - functional programming interface
 * @export
 */
export const Class340ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Class340ApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete one or more activities.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityDelete(objectIds: string, version?: string, developerPayload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityDelete(objectIds, version, developerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the favourite (most commonly used) product combinations for the given activity type and crop.
         * @param {string} companyIds A comma seperated list of company ids for which to return favourite products. If more than one company is specified they must belong to the same copmany cluster and the user must be authorized on all of them.
         * @param {string} processOrderId Id of the currently selected process order, for multicompany the current process order of any of the companies listed in &#x60;companyIds&#x60; can be used.
         * @param {string} activityTypeId The id of the selected activity type.
         * @param {string} cropIds A comma seperated list of crop ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityFavouriteProducts(companyIds: string, processOrderId: string, activityTypeId: string, cropIds: string, version?: string, developerPayload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityFavouriteProducts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityFavouriteProducts(companyIds, processOrderId, activityTypeId, cropIds, version, developerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the amount and unitId for the last time the product `productId` was used in companies `companyIds`
         * @param {string} companyIds A comma seperated list of company ids for which to return the last used amount.              If more than one company is specified they must belong to the same company cluster and              the user must be authorized on all of them.
         * @param {string} productId The id of the product for which to find the last used amount.
         * @param {string} processOrderId The id of the current process order, this and the previous process order are searched.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityLastUsedProductAmount(companyIds: string, productId: string, processOrderId: string, version?: string, developerPayload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLastUsedProductAmount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityLastUsedProductAmount(companyIds, productId, processOrderId, version, developerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load one or more activities.             If the user does not have `GLOBAL_ALL_COMPANY_MEASURES_ACCESSIBLE` permissions only their own activities are returned.             If the user does not have `GLOBAL_ALL_FIELDS` permissions only activities on active fields or activities without fields are returned.
         * @param {string} processOrderId The id of the process order for which all activities should be returned
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [permissionMode] Enable special permission modes
         * @param {string} [fieldSharingCompanyId] For &#x60;permissionMode&#x3D;fieldSharing&#x60;: The Id of the company for which to load data related to shared fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityList(processOrderId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, permissionMode?: string, fieldSharingCompanyId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityList(processOrderId, version, deleted, pageKey, developerPayload, properties, permissionMode, fieldSharingCompanyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Moves one or more activities to a different process order.              The activities are copied to the destination process order and are deleted in the source process order.             If one or more of the activities cannot be copied because of a conflict (no matching field found, permission error, ...)              no activities will be moved and a 409 response with status `conflict` will be returned.
         * @param {ActivityMoveToProcessOrderRequest} [activityMoveToProcessOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityMoveToProcessOrder(activityMoveToProcessOrderRequest?: ActivityMoveToProcessOrderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityMoveToProcessOrder200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityMoveToProcessOrder(activityMoveToProcessOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load one or more activities by activity id.              If the user does not have `GLOBAL_ALL_COMPANY_MEASURES_ACCESSIBLE` permissions only their own activities are returned.             If the user does not have `GLOBAL_ALL_FIELDS` permissions only activities on active fields or activities without fields are returned.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [permissionMode] Enable special permission modes
         * @param {string} [fieldSharingCompanyId] For &#x60;permissionMode&#x3D;fieldSharing&#x60;: The Id of the company for which to load data related to shared fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, permissionMode?: string, fieldSharingCompanyId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityObjects(objectIds, version, deleted, pageKey, developerPayload, properties, permissionMode, fieldSharingCompanyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update one or more activities.
         * @param {ActivityPatchRequest} activityPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityPatch(activityPatchRequest: ActivityPatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityPut200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityPatch(activityPatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create or replace one or more activities.
         * @param {ActivityPutRequest} activityPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityPut(activityPutRequest: ActivityPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityPut200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityPut(activityPutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Restore one or more activities.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityUndelete(objectIds: string, version?: string, developerPayload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityUndelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityUndelete(objectIds, version, developerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of timestamps with satelite images for the given field.
         * @param {RequestTerrazoFieldTimestamp} [requestTerrazoFieldTimestamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicationMapsTerrazoFieldTimestamp(requestTerrazoFieldTimestamp?: RequestTerrazoFieldTimestamp, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationMapsTerrazoFieldTimestamp200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applicationMapsTerrazoFieldTimestamp(requestTerrazoFieldTimestamp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a geoJson map for the given field at the selected timestamp (`dbId`).
         * @param {RequestTerrazoMap} [requestTerrazoMap] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicationMapsTerrazoMap(requestTerrazoMap?: RequestTerrazoMap, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationMapsTerrazoMap200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applicationMapsTerrazoMap(requestTerrazoMap, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load austrian cultivation plan data by field id. Note: currently only returns expected yield,            for use with the terrazo API.            Will eventually replace the legacy cultivation plan API and return all cultivation plan data.
         * @param {string} fieldIds A comma seperated list of field ids for which to return cultivation plan data.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cultivationPlanAtList(fieldIds: string, version?: string, developerPayload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CultivationPlanAtList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cultivationPlanAtList(fieldIds, version, developerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load a list of objects
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [companyId] The id of the company for which all equipment should be returned,              if not set or null global equipment are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equipmentList(version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, companyId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equipmentList(version, deleted, pageKey, developerPayload, properties, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load one ore more equipment by equipment id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equipmentObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equipmentObjects(objectIds, version, deleted, pageKey, developerPayload, properties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load fertilization plan data by field id. Note: currently only returns limited data,            for use with the terrazo API.            Will eventually replace the legacy fertilization plan API and return all fertilization plan data.
         * @param {string} fieldIds A comma seperated list of field ids for which to return fertilization plan data.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fertilizationPlanFieldBasedList(fieldIds: string, version?: string, developerPayload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FertilizationPlanFieldBasedList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fertilizationPlanFieldBasedList(fieldIds, version, developerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load a list of objects
         * @param {string} regionId The id of the region for which all pests should be returned
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pestList(regionId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PestObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pestList(regionId, version, deleted, pageKey, developerPayload, properties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load one ore more pests by pest id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pestObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PestObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pestObjects(objectIds, version, deleted, pageKey, developerPayload, properties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load a list of objects
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productCategoryList(version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductCategoryObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productCategoryList(version, deleted, pageKey, developerPayload, properties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load one ore more product categories by id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productCategoryObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductCategoryObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productCategoryObjects(objectIds, version, deleted, pageKey, developerPayload, properties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of pesticide indications. Either `regionId` or `pesticideId` is required.               Performance note: There is a large and growing number of indications per region, therefore loading all              indications is discouraged.             When ever possible only load the required indications for the pesticide currently in use using the              `pesticideId` parameter.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [regionId] The ID of the region for which pesticide indicaitons should be returned. See note in operation description.
         * @param {string} [pesticideId] A pesticide ID, if set only indications for this pesticide are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productPesticideIndicationList(version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, regionId?: string, pesticideId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductPesticideIndicationList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productPesticideIndicationList(version, deleted, pageKey, developerPayload, properties, regionId, pesticideId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load a list of objects
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ruleList(version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RuleObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ruleList(version, deleted, pageKey, developerPayload, properties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load a list of objects by id
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ruleObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RuleObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ruleObjects(objectIds, version, deleted, pageKey, developerPayload, properties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load activity rule violations.
         * @param {string} processOrderId The id of the process order for which to get all activity rule violation
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rulecheckActivityRuleViolations(processOrderId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RulecheckActivityRuleViolations200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rulecheckActivityRuleViolations(processOrderId, version, deleted, pageKey, developerPayload, properties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Dissmisses the activity-rule violation with id `activityRuleViolationId`.              The violation will not be shown again unless the underlying data changes.
         * @param {string} activityRuleViolationId The ID of the activityRuleViolation to dismiss.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rulecheckDismiss(activityRuleViolationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rulecheckDismiss(activityRuleViolationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Manually trigger a recheck of the rules for a process order and return the results.               Note that rules are automatically rechecked in the background after some time whenever activities are changed,             manually calling recheck is only neccessary if rulecheck results must be shown the user immediately.               Because rulecheck runs are resource intensive, recheck requests should be debounced so that for a              process order only one request is sent at a time. Requests should not be sent while a user is still activly              changing data.               Specifically clients *must* wait at-least five seconds after the last activity change was sent to the server and              at least ten seconds after a previous recheck request before triggering a new recheck.
         * @param {RulecheckRecheckRequest} [rulecheckRecheckRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rulecheckRecheck(rulecheckRecheckRequest?: RulecheckRecheckRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RulecheckRecheck200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rulecheckRecheck(rulecheckRecheckRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the stock levels for a company at the supplied `timestamp`.
         * @param {string} companyId ID of the company for which to return stock-levels.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {number} [timestamp] Timestamp at which to get stock-levels. If not set, the current stock-levels are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockMovementsStockLevels(companyId: string, version?: string, developerPayload?: string, timestamp?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockMovementsStockLevels200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockMovementsStockLevels(companyId, version, developerPayload, timestamp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load a list of objects
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [companyId] The id of the company for which all storage places should be returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storagePlaceList(version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, companyId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoragePlaceObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storagePlaceList(version, deleted, pageKey, developerPayload, properties, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load a list of objects by id
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storagePlaceObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoragePlaceObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storagePlaceObjects(objectIds, version, deleted, pageKey, developerPayload, properties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * Class340Api - factory interface
 * @export
 */
export const Class340ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Class340ApiFp(configuration)
    return {
        /**
         * Delete one or more activities.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityDelete(objectIds: string, version?: string, developerPayload?: string, options?: any): AxiosPromise<ActivityDelete200Response> {
            return localVarFp.activityDelete(objectIds, version, developerPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the favourite (most commonly used) product combinations for the given activity type and crop.
         * @param {string} companyIds A comma seperated list of company ids for which to return favourite products. If more than one company is specified they must belong to the same copmany cluster and the user must be authorized on all of them.
         * @param {string} processOrderId Id of the currently selected process order, for multicompany the current process order of any of the companies listed in &#x60;companyIds&#x60; can be used.
         * @param {string} activityTypeId The id of the selected activity type.
         * @param {string} cropIds A comma seperated list of crop ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityFavouriteProducts(companyIds: string, processOrderId: string, activityTypeId: string, cropIds: string, version?: string, developerPayload?: string, options?: any): AxiosPromise<ActivityFavouriteProducts200Response> {
            return localVarFp.activityFavouriteProducts(companyIds, processOrderId, activityTypeId, cropIds, version, developerPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the amount and unitId for the last time the product `productId` was used in companies `companyIds`
         * @param {string} companyIds A comma seperated list of company ids for which to return the last used amount.              If more than one company is specified they must belong to the same company cluster and              the user must be authorized on all of them.
         * @param {string} productId The id of the product for which to find the last used amount.
         * @param {string} processOrderId The id of the current process order, this and the previous process order are searched.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityLastUsedProductAmount(companyIds: string, productId: string, processOrderId: string, version?: string, developerPayload?: string, options?: any): AxiosPromise<ActivityLastUsedProductAmount200Response> {
            return localVarFp.activityLastUsedProductAmount(companyIds, productId, processOrderId, version, developerPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Load one or more activities.             If the user does not have `GLOBAL_ALL_COMPANY_MEASURES_ACCESSIBLE` permissions only their own activities are returned.             If the user does not have `GLOBAL_ALL_FIELDS` permissions only activities on active fields or activities without fields are returned.
         * @param {string} processOrderId The id of the process order for which all activities should be returned
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [permissionMode] Enable special permission modes
         * @param {string} [fieldSharingCompanyId] For &#x60;permissionMode&#x3D;fieldSharing&#x60;: The Id of the company for which to load data related to shared fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityList(processOrderId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, permissionMode?: string, fieldSharingCompanyId?: string, options?: any): AxiosPromise<ActivityObjects200Response> {
            return localVarFp.activityList(processOrderId, version, deleted, pageKey, developerPayload, properties, permissionMode, fieldSharingCompanyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Moves one or more activities to a different process order.              The activities are copied to the destination process order and are deleted in the source process order.             If one or more of the activities cannot be copied because of a conflict (no matching field found, permission error, ...)              no activities will be moved and a 409 response with status `conflict` will be returned.
         * @param {ActivityMoveToProcessOrderRequest} [activityMoveToProcessOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityMoveToProcessOrder(activityMoveToProcessOrderRequest?: ActivityMoveToProcessOrderRequest, options?: any): AxiosPromise<ActivityMoveToProcessOrder200Response> {
            return localVarFp.activityMoveToProcessOrder(activityMoveToProcessOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Load one or more activities by activity id.              If the user does not have `GLOBAL_ALL_COMPANY_MEASURES_ACCESSIBLE` permissions only their own activities are returned.             If the user does not have `GLOBAL_ALL_FIELDS` permissions only activities on active fields or activities without fields are returned.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [permissionMode] Enable special permission modes
         * @param {string} [fieldSharingCompanyId] For &#x60;permissionMode&#x3D;fieldSharing&#x60;: The Id of the company for which to load data related to shared fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, permissionMode?: string, fieldSharingCompanyId?: string, options?: any): AxiosPromise<ActivityObjects200Response> {
            return localVarFp.activityObjects(objectIds, version, deleted, pageKey, developerPayload, properties, permissionMode, fieldSharingCompanyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update one or more activities.
         * @param {ActivityPatchRequest} activityPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityPatch(activityPatchRequest: ActivityPatchRequest, options?: any): AxiosPromise<ActivityPut200Response> {
            return localVarFp.activityPatch(activityPatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create or replace one or more activities.
         * @param {ActivityPutRequest} activityPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityPut(activityPutRequest: ActivityPutRequest, options?: any): AxiosPromise<ActivityPut200Response> {
            return localVarFp.activityPut(activityPutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Restore one or more activities.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityUndelete(objectIds: string, version?: string, developerPayload?: string, options?: any): AxiosPromise<ActivityUndelete200Response> {
            return localVarFp.activityUndelete(objectIds, version, developerPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of timestamps with satelite images for the given field.
         * @param {RequestTerrazoFieldTimestamp} [requestTerrazoFieldTimestamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationMapsTerrazoFieldTimestamp(requestTerrazoFieldTimestamp?: RequestTerrazoFieldTimestamp, options?: any): AxiosPromise<ApplicationMapsTerrazoFieldTimestamp200Response> {
            return localVarFp.applicationMapsTerrazoFieldTimestamp(requestTerrazoFieldTimestamp, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a geoJson map for the given field at the selected timestamp (`dbId`).
         * @param {RequestTerrazoMap} [requestTerrazoMap] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationMapsTerrazoMap(requestTerrazoMap?: RequestTerrazoMap, options?: any): AxiosPromise<ApplicationMapsTerrazoMap200Response> {
            return localVarFp.applicationMapsTerrazoMap(requestTerrazoMap, options).then((request) => request(axios, basePath));
        },
        /**
         * Load austrian cultivation plan data by field id. Note: currently only returns expected yield,            for use with the terrazo API.            Will eventually replace the legacy cultivation plan API and return all cultivation plan data.
         * @param {string} fieldIds A comma seperated list of field ids for which to return cultivation plan data.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cultivationPlanAtList(fieldIds: string, version?: string, developerPayload?: string, options?: any): AxiosPromise<CultivationPlanAtList200Response> {
            return localVarFp.cultivationPlanAtList(fieldIds, version, developerPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Load a list of objects
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [companyId] The id of the company for which all equipment should be returned,              if not set or null global equipment are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentList(version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, companyId?: string, options?: any): AxiosPromise<EquipmentObjects200Response> {
            return localVarFp.equipmentList(version, deleted, pageKey, developerPayload, properties, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Load one ore more equipment by equipment id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: any): AxiosPromise<EquipmentObjects200Response> {
            return localVarFp.equipmentObjects(objectIds, version, deleted, pageKey, developerPayload, properties, options).then((request) => request(axios, basePath));
        },
        /**
         * Load fertilization plan data by field id. Note: currently only returns limited data,            for use with the terrazo API.            Will eventually replace the legacy fertilization plan API and return all fertilization plan data.
         * @param {string} fieldIds A comma seperated list of field ids for which to return fertilization plan data.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fertilizationPlanFieldBasedList(fieldIds: string, version?: string, developerPayload?: string, options?: any): AxiosPromise<FertilizationPlanFieldBasedList200Response> {
            return localVarFp.fertilizationPlanFieldBasedList(fieldIds, version, developerPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Load a list of objects
         * @param {string} regionId The id of the region for which all pests should be returned
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pestList(regionId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: any): AxiosPromise<PestObjects200Response> {
            return localVarFp.pestList(regionId, version, deleted, pageKey, developerPayload, properties, options).then((request) => request(axios, basePath));
        },
        /**
         * Load one ore more pests by pest id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pestObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: any): AxiosPromise<PestObjects200Response> {
            return localVarFp.pestObjects(objectIds, version, deleted, pageKey, developerPayload, properties, options).then((request) => request(axios, basePath));
        },
        /**
         * Load a list of objects
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productCategoryList(version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: any): AxiosPromise<ProductCategoryObjects200Response> {
            return localVarFp.productCategoryList(version, deleted, pageKey, developerPayload, properties, options).then((request) => request(axios, basePath));
        },
        /**
         * Load one ore more product categories by id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productCategoryObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: any): AxiosPromise<ProductCategoryObjects200Response> {
            return localVarFp.productCategoryObjects(objectIds, version, deleted, pageKey, developerPayload, properties, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of pesticide indications. Either `regionId` or `pesticideId` is required.               Performance note: There is a large and growing number of indications per region, therefore loading all              indications is discouraged.             When ever possible only load the required indications for the pesticide currently in use using the              `pesticideId` parameter.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [regionId] The ID of the region for which pesticide indicaitons should be returned. See note in operation description.
         * @param {string} [pesticideId] A pesticide ID, if set only indications for this pesticide are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productPesticideIndicationList(version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, regionId?: string, pesticideId?: string, options?: any): AxiosPromise<ProductPesticideIndicationList200Response> {
            return localVarFp.productPesticideIndicationList(version, deleted, pageKey, developerPayload, properties, regionId, pesticideId, options).then((request) => request(axios, basePath));
        },
        /**
         * Load a list of objects
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ruleList(version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: any): AxiosPromise<RuleObjects200Response> {
            return localVarFp.ruleList(version, deleted, pageKey, developerPayload, properties, options).then((request) => request(axios, basePath));
        },
        /**
         * Load a list of objects by id
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ruleObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: any): AxiosPromise<RuleObjects200Response> {
            return localVarFp.ruleObjects(objectIds, version, deleted, pageKey, developerPayload, properties, options).then((request) => request(axios, basePath));
        },
        /**
         * Load activity rule violations.
         * @param {string} processOrderId The id of the process order for which to get all activity rule violation
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rulecheckActivityRuleViolations(processOrderId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: any): AxiosPromise<RulecheckActivityRuleViolations200Response> {
            return localVarFp.rulecheckActivityRuleViolations(processOrderId, version, deleted, pageKey, developerPayload, properties, options).then((request) => request(axios, basePath));
        },
        /**
         * Dissmisses the activity-rule violation with id `activityRuleViolationId`.              The violation will not be shown again unless the underlying data changes.
         * @param {string} activityRuleViolationId The ID of the activityRuleViolation to dismiss.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rulecheckDismiss(activityRuleViolationId: string, options?: any): AxiosPromise<RestResponse> {
            return localVarFp.rulecheckDismiss(activityRuleViolationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Manually trigger a recheck of the rules for a process order and return the results.               Note that rules are automatically rechecked in the background after some time whenever activities are changed,             manually calling recheck is only neccessary if rulecheck results must be shown the user immediately.               Because rulecheck runs are resource intensive, recheck requests should be debounced so that for a              process order only one request is sent at a time. Requests should not be sent while a user is still activly              changing data.               Specifically clients *must* wait at-least five seconds after the last activity change was sent to the server and              at least ten seconds after a previous recheck request before triggering a new recheck.
         * @param {RulecheckRecheckRequest} [rulecheckRecheckRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rulecheckRecheck(rulecheckRecheckRequest?: RulecheckRecheckRequest, options?: any): AxiosPromise<RulecheckRecheck200Response> {
            return localVarFp.rulecheckRecheck(rulecheckRecheckRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the stock levels for a company at the supplied `timestamp`.
         * @param {string} companyId ID of the company for which to return stock-levels.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {number} [timestamp] Timestamp at which to get stock-levels. If not set, the current stock-levels are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockMovementsStockLevels(companyId: string, version?: string, developerPayload?: string, timestamp?: number, options?: any): AxiosPromise<StockMovementsStockLevels200Response> {
            return localVarFp.stockMovementsStockLevels(companyId, version, developerPayload, timestamp, options).then((request) => request(axios, basePath));
        },
        /**
         * Load a list of objects
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [companyId] The id of the company for which all storage places should be returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storagePlaceList(version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, companyId?: string, options?: any): AxiosPromise<StoragePlaceObjects200Response> {
            return localVarFp.storagePlaceList(version, deleted, pageKey, developerPayload, properties, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Load a list of objects by id
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storagePlaceObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: any): AxiosPromise<StoragePlaceObjects200Response> {
            return localVarFp.storagePlaceObjects(objectIds, version, deleted, pageKey, developerPayload, properties, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for activityDelete operation in Class340Api.
 * @export
 * @interface Class340ApiActivityDeleteRequest
 */
export interface Class340ApiActivityDeleteRequest {
    /**
     * A comma sperated list of object ids.
     * @type {string}
     * @memberof Class340ApiActivityDelete
     */
    readonly objectIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class340ApiActivityDelete
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class340ApiActivityDelete
     */
    readonly developerPayload?: string
}

/**
 * Request parameters for activityFavouriteProducts operation in Class340Api.
 * @export
 * @interface Class340ApiActivityFavouriteProductsRequest
 */
export interface Class340ApiActivityFavouriteProductsRequest {
    /**
     * A comma seperated list of company ids for which to return favourite products. If more than one company is specified they must belong to the same copmany cluster and the user must be authorized on all of them.
     * @type {string}
     * @memberof Class340ApiActivityFavouriteProducts
     */
    readonly companyIds: string

    /**
     * Id of the currently selected process order, for multicompany the current process order of any of the companies listed in &#x60;companyIds&#x60; can be used.
     * @type {string}
     * @memberof Class340ApiActivityFavouriteProducts
     */
    readonly processOrderId: string

    /**
     * The id of the selected activity type.
     * @type {string}
     * @memberof Class340ApiActivityFavouriteProducts
     */
    readonly activityTypeId: string

    /**
     * A comma seperated list of crop ids.
     * @type {string}
     * @memberof Class340ApiActivityFavouriteProducts
     */
    readonly cropIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class340ApiActivityFavouriteProducts
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class340ApiActivityFavouriteProducts
     */
    readonly developerPayload?: string
}

/**
 * Request parameters for activityLastUsedProductAmount operation in Class340Api.
 * @export
 * @interface Class340ApiActivityLastUsedProductAmountRequest
 */
export interface Class340ApiActivityLastUsedProductAmountRequest {
    /**
     * A comma seperated list of company ids for which to return the last used amount.              If more than one company is specified they must belong to the same company cluster and              the user must be authorized on all of them.
     * @type {string}
     * @memberof Class340ApiActivityLastUsedProductAmount
     */
    readonly companyIds: string

    /**
     * The id of the product for which to find the last used amount.
     * @type {string}
     * @memberof Class340ApiActivityLastUsedProductAmount
     */
    readonly productId: string

    /**
     * The id of the current process order, this and the previous process order are searched.
     * @type {string}
     * @memberof Class340ApiActivityLastUsedProductAmount
     */
    readonly processOrderId: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class340ApiActivityLastUsedProductAmount
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class340ApiActivityLastUsedProductAmount
     */
    readonly developerPayload?: string
}

/**
 * Request parameters for activityList operation in Class340Api.
 * @export
 * @interface Class340ApiActivityListRequest
 */
export interface Class340ApiActivityListRequest {
    /**
     * The id of the process order for which all activities should be returned
     * @type {string}
     * @memberof Class340ApiActivityList
     */
    readonly processOrderId: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class340ApiActivityList
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof Class340ApiActivityList
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof Class340ApiActivityList
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class340ApiActivityList
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof Class340ApiActivityList
     */
    readonly properties?: string

    /**
     * Enable special permission modes
     * @type {string}
     * @memberof Class340ApiActivityList
     */
    readonly permissionMode?: string

    /**
     * For &#x60;permissionMode&#x3D;fieldSharing&#x60;: The Id of the company for which to load data related to shared fields.
     * @type {string}
     * @memberof Class340ApiActivityList
     */
    readonly fieldSharingCompanyId?: string
}

/**
 * Request parameters for activityMoveToProcessOrder operation in Class340Api.
 * @export
 * @interface Class340ApiActivityMoveToProcessOrderRequest
 */
export interface Class340ApiActivityMoveToProcessOrderRequest {
    /**
     * 
     * @type {ActivityMoveToProcessOrderRequest}
     * @memberof Class340ApiActivityMoveToProcessOrder
     */
    readonly activityMoveToProcessOrderRequest?: ActivityMoveToProcessOrderRequest
}

/**
 * Request parameters for activityObjects operation in Class340Api.
 * @export
 * @interface Class340ApiActivityObjectsRequest
 */
export interface Class340ApiActivityObjectsRequest {
    /**
     * Comma seperated list of object ids, use this to request specific objects.
     * @type {string}
     * @memberof Class340ApiActivityObjects
     */
    readonly objectIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class340ApiActivityObjects
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof Class340ApiActivityObjects
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof Class340ApiActivityObjects
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class340ApiActivityObjects
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof Class340ApiActivityObjects
     */
    readonly properties?: string

    /**
     * Enable special permission modes
     * @type {string}
     * @memberof Class340ApiActivityObjects
     */
    readonly permissionMode?: string

    /**
     * For &#x60;permissionMode&#x3D;fieldSharing&#x60;: The Id of the company for which to load data related to shared fields.
     * @type {string}
     * @memberof Class340ApiActivityObjects
     */
    readonly fieldSharingCompanyId?: string
}

/**
 * Request parameters for activityPatch operation in Class340Api.
 * @export
 * @interface Class340ApiActivityPatchRequest
 */
export interface Class340ApiActivityPatchRequest {
    /**
     * 
     * @type {ActivityPatchRequest}
     * @memberof Class340ApiActivityPatch
     */
    readonly activityPatchRequest: ActivityPatchRequest
}

/**
 * Request parameters for activityPut operation in Class340Api.
 * @export
 * @interface Class340ApiActivityPutRequest
 */
export interface Class340ApiActivityPutRequest {
    /**
     * 
     * @type {ActivityPutRequest}
     * @memberof Class340ApiActivityPut
     */
    readonly activityPutRequest: ActivityPutRequest
}

/**
 * Request parameters for activityUndelete operation in Class340Api.
 * @export
 * @interface Class340ApiActivityUndeleteRequest
 */
export interface Class340ApiActivityUndeleteRequest {
    /**
     * A comma sperated list of object ids.
     * @type {string}
     * @memberof Class340ApiActivityUndelete
     */
    readonly objectIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class340ApiActivityUndelete
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class340ApiActivityUndelete
     */
    readonly developerPayload?: string
}

/**
 * Request parameters for applicationMapsTerrazoFieldTimestamp operation in Class340Api.
 * @export
 * @interface Class340ApiApplicationMapsTerrazoFieldTimestampRequest
 */
export interface Class340ApiApplicationMapsTerrazoFieldTimestampRequest {
    /**
     * 
     * @type {RequestTerrazoFieldTimestamp}
     * @memberof Class340ApiApplicationMapsTerrazoFieldTimestamp
     */
    readonly requestTerrazoFieldTimestamp?: RequestTerrazoFieldTimestamp
}

/**
 * Request parameters for applicationMapsTerrazoMap operation in Class340Api.
 * @export
 * @interface Class340ApiApplicationMapsTerrazoMapRequest
 */
export interface Class340ApiApplicationMapsTerrazoMapRequest {
    /**
     * 
     * @type {RequestTerrazoMap}
     * @memberof Class340ApiApplicationMapsTerrazoMap
     */
    readonly requestTerrazoMap?: RequestTerrazoMap
}

/**
 * Request parameters for cultivationPlanAtList operation in Class340Api.
 * @export
 * @interface Class340ApiCultivationPlanAtListRequest
 */
export interface Class340ApiCultivationPlanAtListRequest {
    /**
     * A comma seperated list of field ids for which to return cultivation plan data.
     * @type {string}
     * @memberof Class340ApiCultivationPlanAtList
     */
    readonly fieldIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class340ApiCultivationPlanAtList
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class340ApiCultivationPlanAtList
     */
    readonly developerPayload?: string
}

/**
 * Request parameters for equipmentList operation in Class340Api.
 * @export
 * @interface Class340ApiEquipmentListRequest
 */
export interface Class340ApiEquipmentListRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class340ApiEquipmentList
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof Class340ApiEquipmentList
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof Class340ApiEquipmentList
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class340ApiEquipmentList
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof Class340ApiEquipmentList
     */
    readonly properties?: string

    /**
     * The id of the company for which all equipment should be returned,              if not set or null global equipment are returned.
     * @type {string}
     * @memberof Class340ApiEquipmentList
     */
    readonly companyId?: string
}

/**
 * Request parameters for equipmentObjects operation in Class340Api.
 * @export
 * @interface Class340ApiEquipmentObjectsRequest
 */
export interface Class340ApiEquipmentObjectsRequest {
    /**
     * Comma seperated list of object ids, use this to request specific objects.
     * @type {string}
     * @memberof Class340ApiEquipmentObjects
     */
    readonly objectIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class340ApiEquipmentObjects
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof Class340ApiEquipmentObjects
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof Class340ApiEquipmentObjects
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class340ApiEquipmentObjects
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof Class340ApiEquipmentObjects
     */
    readonly properties?: string
}

/**
 * Request parameters for fertilizationPlanFieldBasedList operation in Class340Api.
 * @export
 * @interface Class340ApiFertilizationPlanFieldBasedListRequest
 */
export interface Class340ApiFertilizationPlanFieldBasedListRequest {
    /**
     * A comma seperated list of field ids for which to return fertilization plan data.
     * @type {string}
     * @memberof Class340ApiFertilizationPlanFieldBasedList
     */
    readonly fieldIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class340ApiFertilizationPlanFieldBasedList
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class340ApiFertilizationPlanFieldBasedList
     */
    readonly developerPayload?: string
}

/**
 * Request parameters for pestList operation in Class340Api.
 * @export
 * @interface Class340ApiPestListRequest
 */
export interface Class340ApiPestListRequest {
    /**
     * The id of the region for which all pests should be returned
     * @type {string}
     * @memberof Class340ApiPestList
     */
    readonly regionId: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class340ApiPestList
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof Class340ApiPestList
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof Class340ApiPestList
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class340ApiPestList
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof Class340ApiPestList
     */
    readonly properties?: string
}

/**
 * Request parameters for pestObjects operation in Class340Api.
 * @export
 * @interface Class340ApiPestObjectsRequest
 */
export interface Class340ApiPestObjectsRequest {
    /**
     * Comma seperated list of object ids, use this to request specific objects.
     * @type {string}
     * @memberof Class340ApiPestObjects
     */
    readonly objectIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class340ApiPestObjects
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof Class340ApiPestObjects
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof Class340ApiPestObjects
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class340ApiPestObjects
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof Class340ApiPestObjects
     */
    readonly properties?: string
}

/**
 * Request parameters for productCategoryList operation in Class340Api.
 * @export
 * @interface Class340ApiProductCategoryListRequest
 */
export interface Class340ApiProductCategoryListRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class340ApiProductCategoryList
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof Class340ApiProductCategoryList
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof Class340ApiProductCategoryList
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class340ApiProductCategoryList
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof Class340ApiProductCategoryList
     */
    readonly properties?: string
}

/**
 * Request parameters for productCategoryObjects operation in Class340Api.
 * @export
 * @interface Class340ApiProductCategoryObjectsRequest
 */
export interface Class340ApiProductCategoryObjectsRequest {
    /**
     * Comma seperated list of object ids, use this to request specific objects.
     * @type {string}
     * @memberof Class340ApiProductCategoryObjects
     */
    readonly objectIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class340ApiProductCategoryObjects
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof Class340ApiProductCategoryObjects
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof Class340ApiProductCategoryObjects
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class340ApiProductCategoryObjects
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof Class340ApiProductCategoryObjects
     */
    readonly properties?: string
}

/**
 * Request parameters for productPesticideIndicationList operation in Class340Api.
 * @export
 * @interface Class340ApiProductPesticideIndicationListRequest
 */
export interface Class340ApiProductPesticideIndicationListRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class340ApiProductPesticideIndicationList
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof Class340ApiProductPesticideIndicationList
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof Class340ApiProductPesticideIndicationList
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class340ApiProductPesticideIndicationList
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof Class340ApiProductPesticideIndicationList
     */
    readonly properties?: string

    /**
     * The ID of the region for which pesticide indicaitons should be returned. See note in operation description.
     * @type {string}
     * @memberof Class340ApiProductPesticideIndicationList
     */
    readonly regionId?: string

    /**
     * A pesticide ID, if set only indications for this pesticide are returned.
     * @type {string}
     * @memberof Class340ApiProductPesticideIndicationList
     */
    readonly pesticideId?: string
}

/**
 * Request parameters for ruleList operation in Class340Api.
 * @export
 * @interface Class340ApiRuleListRequest
 */
export interface Class340ApiRuleListRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class340ApiRuleList
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof Class340ApiRuleList
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof Class340ApiRuleList
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class340ApiRuleList
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof Class340ApiRuleList
     */
    readonly properties?: string
}

/**
 * Request parameters for ruleObjects operation in Class340Api.
 * @export
 * @interface Class340ApiRuleObjectsRequest
 */
export interface Class340ApiRuleObjectsRequest {
    /**
     * Comma seperated list of object ids, use this to request specific objects.
     * @type {string}
     * @memberof Class340ApiRuleObjects
     */
    readonly objectIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class340ApiRuleObjects
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof Class340ApiRuleObjects
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof Class340ApiRuleObjects
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class340ApiRuleObjects
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof Class340ApiRuleObjects
     */
    readonly properties?: string
}

/**
 * Request parameters for rulecheckActivityRuleViolations operation in Class340Api.
 * @export
 * @interface Class340ApiRulecheckActivityRuleViolationsRequest
 */
export interface Class340ApiRulecheckActivityRuleViolationsRequest {
    /**
     * The id of the process order for which to get all activity rule violation
     * @type {string}
     * @memberof Class340ApiRulecheckActivityRuleViolations
     */
    readonly processOrderId: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class340ApiRulecheckActivityRuleViolations
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof Class340ApiRulecheckActivityRuleViolations
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof Class340ApiRulecheckActivityRuleViolations
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class340ApiRulecheckActivityRuleViolations
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof Class340ApiRulecheckActivityRuleViolations
     */
    readonly properties?: string
}

/**
 * Request parameters for rulecheckDismiss operation in Class340Api.
 * @export
 * @interface Class340ApiRulecheckDismissRequest
 */
export interface Class340ApiRulecheckDismissRequest {
    /**
     * The ID of the activityRuleViolation to dismiss.
     * @type {string}
     * @memberof Class340ApiRulecheckDismiss
     */
    readonly activityRuleViolationId: string
}

/**
 * Request parameters for rulecheckRecheck operation in Class340Api.
 * @export
 * @interface Class340ApiRulecheckRecheckRequest
 */
export interface Class340ApiRulecheckRecheckRequest {
    /**
     * 
     * @type {RulecheckRecheckRequest}
     * @memberof Class340ApiRulecheckRecheck
     */
    readonly rulecheckRecheckRequest?: RulecheckRecheckRequest
}

/**
 * Request parameters for stockMovementsStockLevels operation in Class340Api.
 * @export
 * @interface Class340ApiStockMovementsStockLevelsRequest
 */
export interface Class340ApiStockMovementsStockLevelsRequest {
    /**
     * ID of the company for which to return stock-levels.
     * @type {string}
     * @memberof Class340ApiStockMovementsStockLevels
     */
    readonly companyId: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class340ApiStockMovementsStockLevels
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class340ApiStockMovementsStockLevels
     */
    readonly developerPayload?: string

    /**
     * Timestamp at which to get stock-levels. If not set, the current stock-levels are returned.
     * @type {number}
     * @memberof Class340ApiStockMovementsStockLevels
     */
    readonly timestamp?: number
}

/**
 * Request parameters for storagePlaceList operation in Class340Api.
 * @export
 * @interface Class340ApiStoragePlaceListRequest
 */
export interface Class340ApiStoragePlaceListRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class340ApiStoragePlaceList
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof Class340ApiStoragePlaceList
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof Class340ApiStoragePlaceList
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class340ApiStoragePlaceList
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof Class340ApiStoragePlaceList
     */
    readonly properties?: string

    /**
     * The id of the company for which all storage places should be returned
     * @type {string}
     * @memberof Class340ApiStoragePlaceList
     */
    readonly companyId?: string
}

/**
 * Request parameters for storagePlaceObjects operation in Class340Api.
 * @export
 * @interface Class340ApiStoragePlaceObjectsRequest
 */
export interface Class340ApiStoragePlaceObjectsRequest {
    /**
     * Comma seperated list of object ids, use this to request specific objects.
     * @type {string}
     * @memberof Class340ApiStoragePlaceObjects
     */
    readonly objectIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class340ApiStoragePlaceObjects
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof Class340ApiStoragePlaceObjects
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof Class340ApiStoragePlaceObjects
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class340ApiStoragePlaceObjects
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof Class340ApiStoragePlaceObjects
     */
    readonly properties?: string
}

/**
 * Class340Api - object-oriented interface
 * @export
 * @class Class340Api
 * @extends {BaseAPI}
 */
export class Class340Api extends BaseAPI {
    /**
     * Delete one or more activities.
     * @param {Class340ApiActivityDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class340Api
     */
    public activityDelete(requestParameters: Class340ApiActivityDeleteRequest, options?: AxiosRequestConfig) {
        return Class340ApiFp(this.configuration).activityDelete(requestParameters.objectIds, requestParameters.version, requestParameters.developerPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the favourite (most commonly used) product combinations for the given activity type and crop.
     * @param {Class340ApiActivityFavouriteProductsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class340Api
     */
    public activityFavouriteProducts(requestParameters: Class340ApiActivityFavouriteProductsRequest, options?: AxiosRequestConfig) {
        return Class340ApiFp(this.configuration).activityFavouriteProducts(requestParameters.companyIds, requestParameters.processOrderId, requestParameters.activityTypeId, requestParameters.cropIds, requestParameters.version, requestParameters.developerPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the amount and unitId for the last time the product `productId` was used in companies `companyIds`
     * @param {Class340ApiActivityLastUsedProductAmountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class340Api
     */
    public activityLastUsedProductAmount(requestParameters: Class340ApiActivityLastUsedProductAmountRequest, options?: AxiosRequestConfig) {
        return Class340ApiFp(this.configuration).activityLastUsedProductAmount(requestParameters.companyIds, requestParameters.productId, requestParameters.processOrderId, requestParameters.version, requestParameters.developerPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load one or more activities.             If the user does not have `GLOBAL_ALL_COMPANY_MEASURES_ACCESSIBLE` permissions only their own activities are returned.             If the user does not have `GLOBAL_ALL_FIELDS` permissions only activities on active fields or activities without fields are returned.
     * @param {Class340ApiActivityListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class340Api
     */
    public activityList(requestParameters: Class340ApiActivityListRequest, options?: AxiosRequestConfig) {
        return Class340ApiFp(this.configuration).activityList(requestParameters.processOrderId, requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, requestParameters.permissionMode, requestParameters.fieldSharingCompanyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Moves one or more activities to a different process order.              The activities are copied to the destination process order and are deleted in the source process order.             If one or more of the activities cannot be copied because of a conflict (no matching field found, permission error, ...)              no activities will be moved and a 409 response with status `conflict` will be returned.
     * @param {Class340ApiActivityMoveToProcessOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class340Api
     */
    public activityMoveToProcessOrder(requestParameters: Class340ApiActivityMoveToProcessOrderRequest = {}, options?: AxiosRequestConfig) {
        return Class340ApiFp(this.configuration).activityMoveToProcessOrder(requestParameters.activityMoveToProcessOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load one or more activities by activity id.              If the user does not have `GLOBAL_ALL_COMPANY_MEASURES_ACCESSIBLE` permissions only their own activities are returned.             If the user does not have `GLOBAL_ALL_FIELDS` permissions only activities on active fields or activities without fields are returned.
     * @param {Class340ApiActivityObjectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class340Api
     */
    public activityObjects(requestParameters: Class340ApiActivityObjectsRequest, options?: AxiosRequestConfig) {
        return Class340ApiFp(this.configuration).activityObjects(requestParameters.objectIds, requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, requestParameters.permissionMode, requestParameters.fieldSharingCompanyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update one or more activities.
     * @param {Class340ApiActivityPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class340Api
     */
    public activityPatch(requestParameters: Class340ApiActivityPatchRequest, options?: AxiosRequestConfig) {
        return Class340ApiFp(this.configuration).activityPatch(requestParameters.activityPatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create or replace one or more activities.
     * @param {Class340ApiActivityPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class340Api
     */
    public activityPut(requestParameters: Class340ApiActivityPutRequest, options?: AxiosRequestConfig) {
        return Class340ApiFp(this.configuration).activityPut(requestParameters.activityPutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Restore one or more activities.
     * @param {Class340ApiActivityUndeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class340Api
     */
    public activityUndelete(requestParameters: Class340ApiActivityUndeleteRequest, options?: AxiosRequestConfig) {
        return Class340ApiFp(this.configuration).activityUndelete(requestParameters.objectIds, requestParameters.version, requestParameters.developerPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of timestamps with satelite images for the given field.
     * @param {Class340ApiApplicationMapsTerrazoFieldTimestampRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class340Api
     */
    public applicationMapsTerrazoFieldTimestamp(requestParameters: Class340ApiApplicationMapsTerrazoFieldTimestampRequest = {}, options?: AxiosRequestConfig) {
        return Class340ApiFp(this.configuration).applicationMapsTerrazoFieldTimestamp(requestParameters.requestTerrazoFieldTimestamp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a geoJson map for the given field at the selected timestamp (`dbId`).
     * @param {Class340ApiApplicationMapsTerrazoMapRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class340Api
     */
    public applicationMapsTerrazoMap(requestParameters: Class340ApiApplicationMapsTerrazoMapRequest = {}, options?: AxiosRequestConfig) {
        return Class340ApiFp(this.configuration).applicationMapsTerrazoMap(requestParameters.requestTerrazoMap, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load austrian cultivation plan data by field id. Note: currently only returns expected yield,            for use with the terrazo API.            Will eventually replace the legacy cultivation plan API and return all cultivation plan data.
     * @param {Class340ApiCultivationPlanAtListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class340Api
     */
    public cultivationPlanAtList(requestParameters: Class340ApiCultivationPlanAtListRequest, options?: AxiosRequestConfig) {
        return Class340ApiFp(this.configuration).cultivationPlanAtList(requestParameters.fieldIds, requestParameters.version, requestParameters.developerPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load a list of objects
     * @param {Class340ApiEquipmentListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class340Api
     */
    public equipmentList(requestParameters: Class340ApiEquipmentListRequest = {}, options?: AxiosRequestConfig) {
        return Class340ApiFp(this.configuration).equipmentList(requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, requestParameters.companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load one ore more equipment by equipment id.
     * @param {Class340ApiEquipmentObjectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class340Api
     */
    public equipmentObjects(requestParameters: Class340ApiEquipmentObjectsRequest, options?: AxiosRequestConfig) {
        return Class340ApiFp(this.configuration).equipmentObjects(requestParameters.objectIds, requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load fertilization plan data by field id. Note: currently only returns limited data,            for use with the terrazo API.            Will eventually replace the legacy fertilization plan API and return all fertilization plan data.
     * @param {Class340ApiFertilizationPlanFieldBasedListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class340Api
     */
    public fertilizationPlanFieldBasedList(requestParameters: Class340ApiFertilizationPlanFieldBasedListRequest, options?: AxiosRequestConfig) {
        return Class340ApiFp(this.configuration).fertilizationPlanFieldBasedList(requestParameters.fieldIds, requestParameters.version, requestParameters.developerPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load a list of objects
     * @param {Class340ApiPestListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class340Api
     */
    public pestList(requestParameters: Class340ApiPestListRequest, options?: AxiosRequestConfig) {
        return Class340ApiFp(this.configuration).pestList(requestParameters.regionId, requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load one ore more pests by pest id.
     * @param {Class340ApiPestObjectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class340Api
     */
    public pestObjects(requestParameters: Class340ApiPestObjectsRequest, options?: AxiosRequestConfig) {
        return Class340ApiFp(this.configuration).pestObjects(requestParameters.objectIds, requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load a list of objects
     * @param {Class340ApiProductCategoryListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class340Api
     */
    public productCategoryList(requestParameters: Class340ApiProductCategoryListRequest = {}, options?: AxiosRequestConfig) {
        return Class340ApiFp(this.configuration).productCategoryList(requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load one ore more product categories by id.
     * @param {Class340ApiProductCategoryObjectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class340Api
     */
    public productCategoryObjects(requestParameters: Class340ApiProductCategoryObjectsRequest, options?: AxiosRequestConfig) {
        return Class340ApiFp(this.configuration).productCategoryObjects(requestParameters.objectIds, requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of pesticide indications. Either `regionId` or `pesticideId` is required.               Performance note: There is a large and growing number of indications per region, therefore loading all              indications is discouraged.             When ever possible only load the required indications for the pesticide currently in use using the              `pesticideId` parameter.
     * @param {Class340ApiProductPesticideIndicationListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class340Api
     */
    public productPesticideIndicationList(requestParameters: Class340ApiProductPesticideIndicationListRequest = {}, options?: AxiosRequestConfig) {
        return Class340ApiFp(this.configuration).productPesticideIndicationList(requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, requestParameters.regionId, requestParameters.pesticideId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load a list of objects
     * @param {Class340ApiRuleListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class340Api
     */
    public ruleList(requestParameters: Class340ApiRuleListRequest = {}, options?: AxiosRequestConfig) {
        return Class340ApiFp(this.configuration).ruleList(requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load a list of objects by id
     * @param {Class340ApiRuleObjectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class340Api
     */
    public ruleObjects(requestParameters: Class340ApiRuleObjectsRequest, options?: AxiosRequestConfig) {
        return Class340ApiFp(this.configuration).ruleObjects(requestParameters.objectIds, requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load activity rule violations.
     * @param {Class340ApiRulecheckActivityRuleViolationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class340Api
     */
    public rulecheckActivityRuleViolations(requestParameters: Class340ApiRulecheckActivityRuleViolationsRequest, options?: AxiosRequestConfig) {
        return Class340ApiFp(this.configuration).rulecheckActivityRuleViolations(requestParameters.processOrderId, requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Dissmisses the activity-rule violation with id `activityRuleViolationId`.              The violation will not be shown again unless the underlying data changes.
     * @param {Class340ApiRulecheckDismissRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class340Api
     */
    public rulecheckDismiss(requestParameters: Class340ApiRulecheckDismissRequest, options?: AxiosRequestConfig) {
        return Class340ApiFp(this.configuration).rulecheckDismiss(requestParameters.activityRuleViolationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Manually trigger a recheck of the rules for a process order and return the results.               Note that rules are automatically rechecked in the background after some time whenever activities are changed,             manually calling recheck is only neccessary if rulecheck results must be shown the user immediately.               Because rulecheck runs are resource intensive, recheck requests should be debounced so that for a              process order only one request is sent at a time. Requests should not be sent while a user is still activly              changing data.               Specifically clients *must* wait at-least five seconds after the last activity change was sent to the server and              at least ten seconds after a previous recheck request before triggering a new recheck.
     * @param {Class340ApiRulecheckRecheckRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class340Api
     */
    public rulecheckRecheck(requestParameters: Class340ApiRulecheckRecheckRequest = {}, options?: AxiosRequestConfig) {
        return Class340ApiFp(this.configuration).rulecheckRecheck(requestParameters.rulecheckRecheckRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the stock levels for a company at the supplied `timestamp`.
     * @param {Class340ApiStockMovementsStockLevelsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class340Api
     */
    public stockMovementsStockLevels(requestParameters: Class340ApiStockMovementsStockLevelsRequest, options?: AxiosRequestConfig) {
        return Class340ApiFp(this.configuration).stockMovementsStockLevels(requestParameters.companyId, requestParameters.version, requestParameters.developerPayload, requestParameters.timestamp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load a list of objects
     * @param {Class340ApiStoragePlaceListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class340Api
     */
    public storagePlaceList(requestParameters: Class340ApiStoragePlaceListRequest = {}, options?: AxiosRequestConfig) {
        return Class340ApiFp(this.configuration).storagePlaceList(requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, requestParameters.companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load a list of objects by id
     * @param {Class340ApiStoragePlaceObjectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class340Api
     */
    public storagePlaceObjects(requestParameters: Class340ApiStoragePlaceObjectsRequest, options?: AxiosRequestConfig) {
        return Class340ApiFp(this.configuration).storagePlaceObjects(requestParameters.objectIds, requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * Class350Api - axios parameter creator
 * @export
 */
export const Class350ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the field-utilization map layer entries for the specified area.            Notes:             - Data might require display of copyright notice, see `copyrights` property.            - If too many objects match the bounding box the `status` property will be `tooManyResults`.                The server might still return a partial result in this case. It is up to the client to decide whether to                use the partial result.             - The client should avoid duplicated / overlapping requests as much as possible.             - The client must not send multiple requests to this route at the same time, and should instead wait until                  the previous request has finished or cancel the request before sending a new one.
         * @param {string} bbox Boundingbox in geojson bbox format.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapLayerFieldUtilization: async (bbox: string, version?: string, developerPayload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bbox' is not null or undefined
            assertParamExists('mapLayerFieldUtilization', 'bbox', bbox)
            const localVarPath = `/map/layer/fieldUtilization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (bbox !== undefined) {
                localVarQueryParameter['bbox'] = bbox;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the land cadastre map layer entries for the specified area.            Notes:             - Currently only available for Austria.            - Data might require display of copyright notice, see `copyrights` property.            - If too many objects match the bounding box the `status` property will be `tooManyResults`.                The server might still return a partial result in this case. It is up to the client to decide whether to                use the partial result.             - The client should avoid duplicated / overlapping requests as much as possible.             - The client must not send multiple requests to this route at the same time, and should instead wait until                  the previous request has finished or cancel the request before sending a new one.
         * @param {string} bbox Boundingbox in geojson bbox format.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapLayerLandCadastre: async (bbox: string, version?: string, developerPayload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bbox' is not null or undefined
            assertParamExists('mapLayerLandCadastre', 'bbox', bbox)
            const localVarPath = `/map/layer/landCadastre`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (bbox !== undefined) {
                localVarQueryParameter['bbox'] = bbox;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load a list of objects
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [companyId] The id of the company for which all plant varieties should be returned,              if not set or null global plant varieties for &#x60;regionId&#x60; are returned.             Note: either &#x60;companyId&#x60; or &#x60;regionId&#x60; must be set.
         * @param {string} [regionId] The id of the region for which all global plant varieties should be returned,              if not set or null global plant varieties for &#x60;companyId&#x60; are returned.             Note: either &#x60;companyId&#x60; or &#x60;regionId&#x60; must be set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plantVarietyList: async (version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, companyId?: string, regionId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/plantVarieties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (regionId !== undefined) {
                localVarQueryParameter['regionId'] = regionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load one or more plant varieties by id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plantVarietyObjects: async (objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectIds' is not null or undefined
            assertParamExists('plantVarietyObjects', 'objectIds', objectIds)
            const localVarPath = `/plantVarieties/{objectIds}`
                .replace(`{${"objectIds"}}`, encodeURIComponent(String(objectIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Class350Api - functional programming interface
 * @export
 */
export const Class350ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Class350ApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the field-utilization map layer entries for the specified area.            Notes:             - Data might require display of copyright notice, see `copyrights` property.            - If too many objects match the bounding box the `status` property will be `tooManyResults`.                The server might still return a partial result in this case. It is up to the client to decide whether to                use the partial result.             - The client should avoid duplicated / overlapping requests as much as possible.             - The client must not send multiple requests to this route at the same time, and should instead wait until                  the previous request has finished or cancel the request before sending a new one.
         * @param {string} bbox Boundingbox in geojson bbox format.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mapLayerFieldUtilization(bbox: string, version?: string, developerPayload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MapLayerFieldUtilization200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mapLayerFieldUtilization(bbox, version, developerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the land cadastre map layer entries for the specified area.            Notes:             - Currently only available for Austria.            - Data might require display of copyright notice, see `copyrights` property.            - If too many objects match the bounding box the `status` property will be `tooManyResults`.                The server might still return a partial result in this case. It is up to the client to decide whether to                use the partial result.             - The client should avoid duplicated / overlapping requests as much as possible.             - The client must not send multiple requests to this route at the same time, and should instead wait until                  the previous request has finished or cancel the request before sending a new one.
         * @param {string} bbox Boundingbox in geojson bbox format.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mapLayerLandCadastre(bbox: string, version?: string, developerPayload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MapLayerLandCadastre200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mapLayerLandCadastre(bbox, version, developerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load a list of objects
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [companyId] The id of the company for which all plant varieties should be returned,              if not set or null global plant varieties for &#x60;regionId&#x60; are returned.             Note: either &#x60;companyId&#x60; or &#x60;regionId&#x60; must be set.
         * @param {string} [regionId] The id of the region for which all global plant varieties should be returned,              if not set or null global plant varieties for &#x60;companyId&#x60; are returned.             Note: either &#x60;companyId&#x60; or &#x60;regionId&#x60; must be set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plantVarietyList(version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, companyId?: string, regionId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlantVarietyObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plantVarietyList(version, deleted, pageKey, developerPayload, properties, companyId, regionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load one or more plant varieties by id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plantVarietyObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlantVarietyObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plantVarietyObjects(objectIds, version, deleted, pageKey, developerPayload, properties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * Class350Api - factory interface
 * @export
 */
export const Class350ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Class350ApiFp(configuration)
    return {
        /**
         * Returns the field-utilization map layer entries for the specified area.            Notes:             - Data might require display of copyright notice, see `copyrights` property.            - If too many objects match the bounding box the `status` property will be `tooManyResults`.                The server might still return a partial result in this case. It is up to the client to decide whether to                use the partial result.             - The client should avoid duplicated / overlapping requests as much as possible.             - The client must not send multiple requests to this route at the same time, and should instead wait until                  the previous request has finished or cancel the request before sending a new one.
         * @param {string} bbox Boundingbox in geojson bbox format.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapLayerFieldUtilization(bbox: string, version?: string, developerPayload?: string, options?: any): AxiosPromise<MapLayerFieldUtilization200Response> {
            return localVarFp.mapLayerFieldUtilization(bbox, version, developerPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the land cadastre map layer entries for the specified area.            Notes:             - Currently only available for Austria.            - Data might require display of copyright notice, see `copyrights` property.            - If too many objects match the bounding box the `status` property will be `tooManyResults`.                The server might still return a partial result in this case. It is up to the client to decide whether to                use the partial result.             - The client should avoid duplicated / overlapping requests as much as possible.             - The client must not send multiple requests to this route at the same time, and should instead wait until                  the previous request has finished or cancel the request before sending a new one.
         * @param {string} bbox Boundingbox in geojson bbox format.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapLayerLandCadastre(bbox: string, version?: string, developerPayload?: string, options?: any): AxiosPromise<MapLayerLandCadastre200Response> {
            return localVarFp.mapLayerLandCadastre(bbox, version, developerPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Load a list of objects
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [companyId] The id of the company for which all plant varieties should be returned,              if not set or null global plant varieties for &#x60;regionId&#x60; are returned.             Note: either &#x60;companyId&#x60; or &#x60;regionId&#x60; must be set.
         * @param {string} [regionId] The id of the region for which all global plant varieties should be returned,              if not set or null global plant varieties for &#x60;companyId&#x60; are returned.             Note: either &#x60;companyId&#x60; or &#x60;regionId&#x60; must be set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plantVarietyList(version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, companyId?: string, regionId?: string, options?: any): AxiosPromise<PlantVarietyObjects200Response> {
            return localVarFp.plantVarietyList(version, deleted, pageKey, developerPayload, properties, companyId, regionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Load one or more plant varieties by id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plantVarietyObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: any): AxiosPromise<PlantVarietyObjects200Response> {
            return localVarFp.plantVarietyObjects(objectIds, version, deleted, pageKey, developerPayload, properties, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for mapLayerFieldUtilization operation in Class350Api.
 * @export
 * @interface Class350ApiMapLayerFieldUtilizationRequest
 */
export interface Class350ApiMapLayerFieldUtilizationRequest {
    /**
     * Boundingbox in geojson bbox format.
     * @type {string}
     * @memberof Class350ApiMapLayerFieldUtilization
     */
    readonly bbox: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class350ApiMapLayerFieldUtilization
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class350ApiMapLayerFieldUtilization
     */
    readonly developerPayload?: string
}

/**
 * Request parameters for mapLayerLandCadastre operation in Class350Api.
 * @export
 * @interface Class350ApiMapLayerLandCadastreRequest
 */
export interface Class350ApiMapLayerLandCadastreRequest {
    /**
     * Boundingbox in geojson bbox format.
     * @type {string}
     * @memberof Class350ApiMapLayerLandCadastre
     */
    readonly bbox: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class350ApiMapLayerLandCadastre
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class350ApiMapLayerLandCadastre
     */
    readonly developerPayload?: string
}

/**
 * Request parameters for plantVarietyList operation in Class350Api.
 * @export
 * @interface Class350ApiPlantVarietyListRequest
 */
export interface Class350ApiPlantVarietyListRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class350ApiPlantVarietyList
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof Class350ApiPlantVarietyList
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof Class350ApiPlantVarietyList
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class350ApiPlantVarietyList
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof Class350ApiPlantVarietyList
     */
    readonly properties?: string

    /**
     * The id of the company for which all plant varieties should be returned,              if not set or null global plant varieties for &#x60;regionId&#x60; are returned.             Note: either &#x60;companyId&#x60; or &#x60;regionId&#x60; must be set.
     * @type {string}
     * @memberof Class350ApiPlantVarietyList
     */
    readonly companyId?: string

    /**
     * The id of the region for which all global plant varieties should be returned,              if not set or null global plant varieties for &#x60;companyId&#x60; are returned.             Note: either &#x60;companyId&#x60; or &#x60;regionId&#x60; must be set.
     * @type {string}
     * @memberof Class350ApiPlantVarietyList
     */
    readonly regionId?: string
}

/**
 * Request parameters for plantVarietyObjects operation in Class350Api.
 * @export
 * @interface Class350ApiPlantVarietyObjectsRequest
 */
export interface Class350ApiPlantVarietyObjectsRequest {
    /**
     * Comma seperated list of object ids, use this to request specific objects.
     * @type {string}
     * @memberof Class350ApiPlantVarietyObjects
     */
    readonly objectIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class350ApiPlantVarietyObjects
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof Class350ApiPlantVarietyObjects
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof Class350ApiPlantVarietyObjects
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class350ApiPlantVarietyObjects
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof Class350ApiPlantVarietyObjects
     */
    readonly properties?: string
}

/**
 * Class350Api - object-oriented interface
 * @export
 * @class Class350Api
 * @extends {BaseAPI}
 */
export class Class350Api extends BaseAPI {
    /**
     * Returns the field-utilization map layer entries for the specified area.            Notes:             - Data might require display of copyright notice, see `copyrights` property.            - If too many objects match the bounding box the `status` property will be `tooManyResults`.                The server might still return a partial result in this case. It is up to the client to decide whether to                use the partial result.             - The client should avoid duplicated / overlapping requests as much as possible.             - The client must not send multiple requests to this route at the same time, and should instead wait until                  the previous request has finished or cancel the request before sending a new one.
     * @param {Class350ApiMapLayerFieldUtilizationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class350Api
     */
    public mapLayerFieldUtilization(requestParameters: Class350ApiMapLayerFieldUtilizationRequest, options?: AxiosRequestConfig) {
        return Class350ApiFp(this.configuration).mapLayerFieldUtilization(requestParameters.bbox, requestParameters.version, requestParameters.developerPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the land cadastre map layer entries for the specified area.            Notes:             - Currently only available for Austria.            - Data might require display of copyright notice, see `copyrights` property.            - If too many objects match the bounding box the `status` property will be `tooManyResults`.                The server might still return a partial result in this case. It is up to the client to decide whether to                use the partial result.             - The client should avoid duplicated / overlapping requests as much as possible.             - The client must not send multiple requests to this route at the same time, and should instead wait until                  the previous request has finished or cancel the request before sending a new one.
     * @param {Class350ApiMapLayerLandCadastreRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class350Api
     */
    public mapLayerLandCadastre(requestParameters: Class350ApiMapLayerLandCadastreRequest, options?: AxiosRequestConfig) {
        return Class350ApiFp(this.configuration).mapLayerLandCadastre(requestParameters.bbox, requestParameters.version, requestParameters.developerPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load a list of objects
     * @param {Class350ApiPlantVarietyListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class350Api
     */
    public plantVarietyList(requestParameters: Class350ApiPlantVarietyListRequest = {}, options?: AxiosRequestConfig) {
        return Class350ApiFp(this.configuration).plantVarietyList(requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, requestParameters.companyId, requestParameters.regionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load one or more plant varieties by id.
     * @param {Class350ApiPlantVarietyObjectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class350Api
     */
    public plantVarietyObjects(requestParameters: Class350ApiPlantVarietyObjectsRequest, options?: AxiosRequestConfig) {
        return Class350ApiFp(this.configuration).plantVarietyObjects(requestParameters.objectIds, requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * Class360Api - axios parameter creator
 * @export
 */
export const Class360ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete objects from an import source.          Response is the status after the objects were deleted  (see `/activityImport/status`).
         * @param {ActivityImportDeleteObjectsRequest} [activityImportDeleteObjectsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityImportDeleteObjects: async (activityImportDeleteObjectsRequest?: ActivityImportDeleteObjectsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/activityImport/deleteObjects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityImportDeleteObjectsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Processes and maps the available data for the specified import source and returns activities that can be imported.
         * @param {ActivityImportSource} source The import source to get the current status for.
         * @param {string} processOrderId The process order for which to map the import data.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [objectIds] Comma separated list of object ids to import, if not supplied all objects will be imported
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityImportMapData: async (source: ActivityImportSource, processOrderId: string, version?: string, developerPayload?: string, objectIds?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'source' is not null or undefined
            assertParamExists('activityImportMapData', 'source', source)
            // verify required parameter 'processOrderId' is not null or undefined
            assertParamExists('activityImportMapData', 'processOrderId', processOrderId)
            const localVarPath = `/activityImport/mapData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }

            if (processOrderId !== undefined) {
                localVarQueryParameter['processOrderId'] = processOrderId;
            }

            if (objectIds !== undefined) {
                localVarQueryParameter['objectIds'] = objectIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Resets the import and deletes all uploaded/downloaded files for the logged in user. Note that it might not be immediately possible to cancel a running import, poll `/activityImport/status` if needed.
         * @param {ActivityImportResetRequest} [activityImportResetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityImportReset: async (activityImportResetRequest?: ActivityImportResetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/activityImport/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityImportResetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Clears all mappings for a company.
         * @param {ActivityImportResetMappingsRequest} [activityImportResetMappingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityImportResetMappings: async (activityImportResetMappingsRequest?: ActivityImportResetMappingsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/activityImport/resetMappings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityImportResetMappingsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the current status for the specified import source.
         * @param {ActivityImportSource} source The import source to get the current status for.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityImportStatus: async (source: ActivityImportSource, version?: string, developerPayload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'source' is not null or undefined
            assertParamExists('activityImportStatus', 'source', source)
            const localVarPath = `/activityImport/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Store or update a mapping for the activity import.
         * @param {ActivityImportStoreMappingsRequest} [activityImportStoreMappingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityImportStoreMappings: async (activityImportStoreMappingsRequest?: ActivityImportStoreMappingsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/activityImport/storeMappings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityImportStoreMappingsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Uploads files for the activities import. Uploaded files will be automatically deleted after a period of time.         Response on success is the status after uploading the files (see `/activityImport/status`)
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {Array<File>} [files] Files to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityImportUpload: async (version?: string, developerPayload?: string, files?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/activityImport/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (version !== undefined) { 
                localVarFormParams.append('version', version as any);
            }
    
            if (developerPayload !== undefined) { 
                localVarFormParams.append('developerPayload', developerPayload as any);
            }
                if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files[]', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the current Cnh connection status.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interfacesCnhConnectionStatus: async (version?: string, developerPayload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/external/cnh/connectionStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Starts CNH task download process. Tasks are downloaded as ISOXML files for later import.          Returns the same response as `/activitiesImport/status`.         To poll the current status of the download process use `/activitiesImport/status`.         Note that a user is not allowed to start a new download while a previous download initiated by this user is          still in progress. If a download is already in progress an error response with the errorCode `inProgress` is          returned.            To cancel a download that is already in progress use `/activitiesImport/reset`         
         * @param {InterfacesCnhDownloadTasksRequest} [interfacesCnhDownloadTasksRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interfacesCnhDownloadTasks: async (interfacesCnhDownloadTasksRequest?: InterfacesCnhDownloadTasksRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/external/cnh/downloadTasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(interfacesCnhDownloadTasksRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Complete CNH OAuth2 login using supplied code. If successfull the resulting refresh token is              stored for the current user allowing the user to perform CNH API requests.
         * @param {InterfacesCnhLoginRequest} [interfacesCnhLoginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interfacesCnhLogin: async (interfacesCnhLoginRequest?: InterfacesCnhLoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/external/cnh/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(interfacesCnhLoginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the CNH authentication information linked to the current users account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interfacesCnhLogout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/external/cnh/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all companies, growers, farmers and fields in an hirarchial list.                        In case the authorization fails (no linked account, token expired, ...)                        `errorCode` `externalApiAuthorization` is returned.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interfacesCnhOverview: async (version?: string, developerPayload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/external/cnh/overview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all tasks for a company. To get the companyId call `/external/cnh/overview`.                        Note that the same task id can be returned multiple times with different fieldIds.                        In case the authorization fails (no linked account, token expired, ...)                        `errorCode` `externalApiAuthorization` is returned.
         * @param {string} companyId The id of the CNH company for which all tasks should be returned.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interfacesCnhTasks: async (companyId: string, version?: string, developerPayload?: string, pageKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('interfacesCnhTasks', 'companyId', companyId)
            const localVarPath = `/external/cnh/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Class360Api - functional programming interface
 * @export
 */
export const Class360ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Class360ApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete objects from an import source.          Response is the status after the objects were deleted  (see `/activityImport/status`).
         * @param {ActivityImportDeleteObjectsRequest} [activityImportDeleteObjectsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityImportDeleteObjects(activityImportDeleteObjectsRequest?: ActivityImportDeleteObjectsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityImportDeleteObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityImportDeleteObjects(activityImportDeleteObjectsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Processes and maps the available data for the specified import source and returns activities that can be imported.
         * @param {ActivityImportSource} source The import source to get the current status for.
         * @param {string} processOrderId The process order for which to map the import data.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [objectIds] Comma separated list of object ids to import, if not supplied all objects will be imported
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityImportMapData(source: ActivityImportSource, processOrderId: string, version?: string, developerPayload?: string, objectIds?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityImportMapData200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityImportMapData(source, processOrderId, version, developerPayload, objectIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Resets the import and deletes all uploaded/downloaded files for the logged in user. Note that it might not be immediately possible to cancel a running import, poll `/activityImport/status` if needed.
         * @param {ActivityImportResetRequest} [activityImportResetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityImportReset(activityImportResetRequest?: ActivityImportResetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityImportDeleteObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityImportReset(activityImportResetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Clears all mappings for a company.
         * @param {ActivityImportResetMappingsRequest} [activityImportResetMappingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityImportResetMappings(activityImportResetMappingsRequest?: ActivityImportResetMappingsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityImportStoreMappings200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityImportResetMappings(activityImportResetMappingsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the current status for the specified import source.
         * @param {ActivityImportSource} source The import source to get the current status for.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityImportStatus(source: ActivityImportSource, version?: string, developerPayload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityImportStatus200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityImportStatus(source, version, developerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Store or update a mapping for the activity import.
         * @param {ActivityImportStoreMappingsRequest} [activityImportStoreMappingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityImportStoreMappings(activityImportStoreMappingsRequest?: ActivityImportStoreMappingsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityImportStoreMappings200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityImportStoreMappings(activityImportStoreMappingsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Uploads files for the activities import. Uploaded files will be automatically deleted after a period of time.         Response on success is the status after uploading the files (see `/activityImport/status`)
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {Array<File>} [files] Files to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityImportUpload(version?: string, developerPayload?: string, files?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityImportUpload200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityImportUpload(version, developerPayload, files, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the current Cnh connection status.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async interfacesCnhConnectionStatus(version?: string, developerPayload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InterfacesCnhConnectionStatus200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.interfacesCnhConnectionStatus(version, developerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Starts CNH task download process. Tasks are downloaded as ISOXML files for later import.          Returns the same response as `/activitiesImport/status`.         To poll the current status of the download process use `/activitiesImport/status`.         Note that a user is not allowed to start a new download while a previous download initiated by this user is          still in progress. If a download is already in progress an error response with the errorCode `inProgress` is          returned.            To cancel a download that is already in progress use `/activitiesImport/reset`         
         * @param {InterfacesCnhDownloadTasksRequest} [interfacesCnhDownloadTasksRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async interfacesCnhDownloadTasks(interfacesCnhDownloadTasksRequest?: InterfacesCnhDownloadTasksRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InterfacesCnhDownloadTasks200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.interfacesCnhDownloadTasks(interfacesCnhDownloadTasksRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Complete CNH OAuth2 login using supplied code. If successfull the resulting refresh token is              stored for the current user allowing the user to perform CNH API requests.
         * @param {InterfacesCnhLoginRequest} [interfacesCnhLoginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async interfacesCnhLogin(interfacesCnhLoginRequest?: InterfacesCnhLoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityImportStoreMappings200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.interfacesCnhLogin(interfacesCnhLoginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes the CNH authentication information linked to the current users account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async interfacesCnhLogout(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityImportStoreMappings200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.interfacesCnhLogout(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all companies, growers, farmers and fields in an hirarchial list.                        In case the authorization fails (no linked account, token expired, ...)                        `errorCode` `externalApiAuthorization` is returned.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async interfacesCnhOverview(version?: string, developerPayload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InterfacesCnhOverview200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.interfacesCnhOverview(version, developerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all tasks for a company. To get the companyId call `/external/cnh/overview`.                        Note that the same task id can be returned multiple times with different fieldIds.                        In case the authorization fails (no linked account, token expired, ...)                        `errorCode` `externalApiAuthorization` is returned.
         * @param {string} companyId The id of the CNH company for which all tasks should be returned.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async interfacesCnhTasks(companyId: string, version?: string, developerPayload?: string, pageKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InterfacesCnhTasks200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.interfacesCnhTasks(companyId, version, developerPayload, pageKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * Class360Api - factory interface
 * @export
 */
export const Class360ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Class360ApiFp(configuration)
    return {
        /**
         * Delete objects from an import source.          Response is the status after the objects were deleted  (see `/activityImport/status`).
         * @param {ActivityImportDeleteObjectsRequest} [activityImportDeleteObjectsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityImportDeleteObjects(activityImportDeleteObjectsRequest?: ActivityImportDeleteObjectsRequest, options?: any): AxiosPromise<ActivityImportDeleteObjects200Response> {
            return localVarFp.activityImportDeleteObjects(activityImportDeleteObjectsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Processes and maps the available data for the specified import source and returns activities that can be imported.
         * @param {ActivityImportSource} source The import source to get the current status for.
         * @param {string} processOrderId The process order for which to map the import data.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [objectIds] Comma separated list of object ids to import, if not supplied all objects will be imported
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityImportMapData(source: ActivityImportSource, processOrderId: string, version?: string, developerPayload?: string, objectIds?: string, options?: any): AxiosPromise<ActivityImportMapData200Response> {
            return localVarFp.activityImportMapData(source, processOrderId, version, developerPayload, objectIds, options).then((request) => request(axios, basePath));
        },
        /**
         * Resets the import and deletes all uploaded/downloaded files for the logged in user. Note that it might not be immediately possible to cancel a running import, poll `/activityImport/status` if needed.
         * @param {ActivityImportResetRequest} [activityImportResetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityImportReset(activityImportResetRequest?: ActivityImportResetRequest, options?: any): AxiosPromise<ActivityImportDeleteObjects200Response> {
            return localVarFp.activityImportReset(activityImportResetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Clears all mappings for a company.
         * @param {ActivityImportResetMappingsRequest} [activityImportResetMappingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityImportResetMappings(activityImportResetMappingsRequest?: ActivityImportResetMappingsRequest, options?: any): AxiosPromise<ActivityImportStoreMappings200Response> {
            return localVarFp.activityImportResetMappings(activityImportResetMappingsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the current status for the specified import source.
         * @param {ActivityImportSource} source The import source to get the current status for.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityImportStatus(source: ActivityImportSource, version?: string, developerPayload?: string, options?: any): AxiosPromise<ActivityImportStatus200Response> {
            return localVarFp.activityImportStatus(source, version, developerPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Store or update a mapping for the activity import.
         * @param {ActivityImportStoreMappingsRequest} [activityImportStoreMappingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityImportStoreMappings(activityImportStoreMappingsRequest?: ActivityImportStoreMappingsRequest, options?: any): AxiosPromise<ActivityImportStoreMappings200Response> {
            return localVarFp.activityImportStoreMappings(activityImportStoreMappingsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Uploads files for the activities import. Uploaded files will be automatically deleted after a period of time.         Response on success is the status after uploading the files (see `/activityImport/status`)
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {Array<File>} [files] Files to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityImportUpload(version?: string, developerPayload?: string, files?: Array<File>, options?: any): AxiosPromise<ActivityImportUpload200Response> {
            return localVarFp.activityImportUpload(version, developerPayload, files, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the current Cnh connection status.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interfacesCnhConnectionStatus(version?: string, developerPayload?: string, options?: any): AxiosPromise<InterfacesCnhConnectionStatus200Response> {
            return localVarFp.interfacesCnhConnectionStatus(version, developerPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Starts CNH task download process. Tasks are downloaded as ISOXML files for later import.          Returns the same response as `/activitiesImport/status`.         To poll the current status of the download process use `/activitiesImport/status`.         Note that a user is not allowed to start a new download while a previous download initiated by this user is          still in progress. If a download is already in progress an error response with the errorCode `inProgress` is          returned.            To cancel a download that is already in progress use `/activitiesImport/reset`         
         * @param {InterfacesCnhDownloadTasksRequest} [interfacesCnhDownloadTasksRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interfacesCnhDownloadTasks(interfacesCnhDownloadTasksRequest?: InterfacesCnhDownloadTasksRequest, options?: any): AxiosPromise<InterfacesCnhDownloadTasks200Response> {
            return localVarFp.interfacesCnhDownloadTasks(interfacesCnhDownloadTasksRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Complete CNH OAuth2 login using supplied code. If successfull the resulting refresh token is              stored for the current user allowing the user to perform CNH API requests.
         * @param {InterfacesCnhLoginRequest} [interfacesCnhLoginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interfacesCnhLogin(interfacesCnhLoginRequest?: InterfacesCnhLoginRequest, options?: any): AxiosPromise<ActivityImportStoreMappings200Response> {
            return localVarFp.interfacesCnhLogin(interfacesCnhLoginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the CNH authentication information linked to the current users account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interfacesCnhLogout(options?: any): AxiosPromise<ActivityImportStoreMappings200Response> {
            return localVarFp.interfacesCnhLogout(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all companies, growers, farmers and fields in an hirarchial list.                        In case the authorization fails (no linked account, token expired, ...)                        `errorCode` `externalApiAuthorization` is returned.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interfacesCnhOverview(version?: string, developerPayload?: string, options?: any): AxiosPromise<InterfacesCnhOverview200Response> {
            return localVarFp.interfacesCnhOverview(version, developerPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all tasks for a company. To get the companyId call `/external/cnh/overview`.                        Note that the same task id can be returned multiple times with different fieldIds.                        In case the authorization fails (no linked account, token expired, ...)                        `errorCode` `externalApiAuthorization` is returned.
         * @param {string} companyId The id of the CNH company for which all tasks should be returned.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interfacesCnhTasks(companyId: string, version?: string, developerPayload?: string, pageKey?: string, options?: any): AxiosPromise<InterfacesCnhTasks200Response> {
            return localVarFp.interfacesCnhTasks(companyId, version, developerPayload, pageKey, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for activityImportDeleteObjects operation in Class360Api.
 * @export
 * @interface Class360ApiActivityImportDeleteObjectsRequest
 */
export interface Class360ApiActivityImportDeleteObjectsRequest {
    /**
     * 
     * @type {ActivityImportDeleteObjectsRequest}
     * @memberof Class360ApiActivityImportDeleteObjects
     */
    readonly activityImportDeleteObjectsRequest?: ActivityImportDeleteObjectsRequest
}

/**
 * Request parameters for activityImportMapData operation in Class360Api.
 * @export
 * @interface Class360ApiActivityImportMapDataRequest
 */
export interface Class360ApiActivityImportMapDataRequest {
    /**
     * The import source to get the current status for.
     * @type {ActivityImportSource}
     * @memberof Class360ApiActivityImportMapData
     */
    readonly source: ActivityImportSource

    /**
     * The process order for which to map the import data.
     * @type {string}
     * @memberof Class360ApiActivityImportMapData
     */
    readonly processOrderId: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class360ApiActivityImportMapData
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class360ApiActivityImportMapData
     */
    readonly developerPayload?: string

    /**
     * Comma separated list of object ids to import, if not supplied all objects will be imported
     * @type {string}
     * @memberof Class360ApiActivityImportMapData
     */
    readonly objectIds?: string
}

/**
 * Request parameters for activityImportReset operation in Class360Api.
 * @export
 * @interface Class360ApiActivityImportResetRequest
 */
export interface Class360ApiActivityImportResetRequest {
    /**
     * 
     * @type {ActivityImportResetRequest}
     * @memberof Class360ApiActivityImportReset
     */
    readonly activityImportResetRequest?: ActivityImportResetRequest
}

/**
 * Request parameters for activityImportResetMappings operation in Class360Api.
 * @export
 * @interface Class360ApiActivityImportResetMappingsRequest
 */
export interface Class360ApiActivityImportResetMappingsRequest {
    /**
     * 
     * @type {ActivityImportResetMappingsRequest}
     * @memberof Class360ApiActivityImportResetMappings
     */
    readonly activityImportResetMappingsRequest?: ActivityImportResetMappingsRequest
}

/**
 * Request parameters for activityImportStatus operation in Class360Api.
 * @export
 * @interface Class360ApiActivityImportStatusRequest
 */
export interface Class360ApiActivityImportStatusRequest {
    /**
     * The import source to get the current status for.
     * @type {ActivityImportSource}
     * @memberof Class360ApiActivityImportStatus
     */
    readonly source: ActivityImportSource

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class360ApiActivityImportStatus
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class360ApiActivityImportStatus
     */
    readonly developerPayload?: string
}

/**
 * Request parameters for activityImportStoreMappings operation in Class360Api.
 * @export
 * @interface Class360ApiActivityImportStoreMappingsRequest
 */
export interface Class360ApiActivityImportStoreMappingsRequest {
    /**
     * 
     * @type {ActivityImportStoreMappingsRequest}
     * @memberof Class360ApiActivityImportStoreMappings
     */
    readonly activityImportStoreMappingsRequest?: ActivityImportStoreMappingsRequest
}

/**
 * Request parameters for activityImportUpload operation in Class360Api.
 * @export
 * @interface Class360ApiActivityImportUploadRequest
 */
export interface Class360ApiActivityImportUploadRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class360ApiActivityImportUpload
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class360ApiActivityImportUpload
     */
    readonly developerPayload?: string

    /**
     * Files to upload.
     * @type {Array<File>}
     * @memberof Class360ApiActivityImportUpload
     */
    readonly files?: Array<File>
}

/**
 * Request parameters for interfacesCnhConnectionStatus operation in Class360Api.
 * @export
 * @interface Class360ApiInterfacesCnhConnectionStatusRequest
 */
export interface Class360ApiInterfacesCnhConnectionStatusRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class360ApiInterfacesCnhConnectionStatus
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class360ApiInterfacesCnhConnectionStatus
     */
    readonly developerPayload?: string
}

/**
 * Request parameters for interfacesCnhDownloadTasks operation in Class360Api.
 * @export
 * @interface Class360ApiInterfacesCnhDownloadTasksRequest
 */
export interface Class360ApiInterfacesCnhDownloadTasksRequest {
    /**
     * 
     * @type {InterfacesCnhDownloadTasksRequest}
     * @memberof Class360ApiInterfacesCnhDownloadTasks
     */
    readonly interfacesCnhDownloadTasksRequest?: InterfacesCnhDownloadTasksRequest
}

/**
 * Request parameters for interfacesCnhLogin operation in Class360Api.
 * @export
 * @interface Class360ApiInterfacesCnhLoginRequest
 */
export interface Class360ApiInterfacesCnhLoginRequest {
    /**
     * 
     * @type {InterfacesCnhLoginRequest}
     * @memberof Class360ApiInterfacesCnhLogin
     */
    readonly interfacesCnhLoginRequest?: InterfacesCnhLoginRequest
}

/**
 * Request parameters for interfacesCnhOverview operation in Class360Api.
 * @export
 * @interface Class360ApiInterfacesCnhOverviewRequest
 */
export interface Class360ApiInterfacesCnhOverviewRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class360ApiInterfacesCnhOverview
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class360ApiInterfacesCnhOverview
     */
    readonly developerPayload?: string
}

/**
 * Request parameters for interfacesCnhTasks operation in Class360Api.
 * @export
 * @interface Class360ApiInterfacesCnhTasksRequest
 */
export interface Class360ApiInterfacesCnhTasksRequest {
    /**
     * The id of the CNH company for which all tasks should be returned.
     * @type {string}
     * @memberof Class360ApiInterfacesCnhTasks
     */
    readonly companyId: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class360ApiInterfacesCnhTasks
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class360ApiInterfacesCnhTasks
     */
    readonly developerPayload?: string

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof Class360ApiInterfacesCnhTasks
     */
    readonly pageKey?: string
}

/**
 * Class360Api - object-oriented interface
 * @export
 * @class Class360Api
 * @extends {BaseAPI}
 */
export class Class360Api extends BaseAPI {
    /**
     * Delete objects from an import source.          Response is the status after the objects were deleted  (see `/activityImport/status`).
     * @param {Class360ApiActivityImportDeleteObjectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class360Api
     */
    public activityImportDeleteObjects(requestParameters: Class360ApiActivityImportDeleteObjectsRequest = {}, options?: AxiosRequestConfig) {
        return Class360ApiFp(this.configuration).activityImportDeleteObjects(requestParameters.activityImportDeleteObjectsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Processes and maps the available data for the specified import source and returns activities that can be imported.
     * @param {Class360ApiActivityImportMapDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class360Api
     */
    public activityImportMapData(requestParameters: Class360ApiActivityImportMapDataRequest, options?: AxiosRequestConfig) {
        return Class360ApiFp(this.configuration).activityImportMapData(requestParameters.source, requestParameters.processOrderId, requestParameters.version, requestParameters.developerPayload, requestParameters.objectIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Resets the import and deletes all uploaded/downloaded files for the logged in user. Note that it might not be immediately possible to cancel a running import, poll `/activityImport/status` if needed.
     * @param {Class360ApiActivityImportResetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class360Api
     */
    public activityImportReset(requestParameters: Class360ApiActivityImportResetRequest = {}, options?: AxiosRequestConfig) {
        return Class360ApiFp(this.configuration).activityImportReset(requestParameters.activityImportResetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Clears all mappings for a company.
     * @param {Class360ApiActivityImportResetMappingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class360Api
     */
    public activityImportResetMappings(requestParameters: Class360ApiActivityImportResetMappingsRequest = {}, options?: AxiosRequestConfig) {
        return Class360ApiFp(this.configuration).activityImportResetMappings(requestParameters.activityImportResetMappingsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the current status for the specified import source.
     * @param {Class360ApiActivityImportStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class360Api
     */
    public activityImportStatus(requestParameters: Class360ApiActivityImportStatusRequest, options?: AxiosRequestConfig) {
        return Class360ApiFp(this.configuration).activityImportStatus(requestParameters.source, requestParameters.version, requestParameters.developerPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Store or update a mapping for the activity import.
     * @param {Class360ApiActivityImportStoreMappingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class360Api
     */
    public activityImportStoreMappings(requestParameters: Class360ApiActivityImportStoreMappingsRequest = {}, options?: AxiosRequestConfig) {
        return Class360ApiFp(this.configuration).activityImportStoreMappings(requestParameters.activityImportStoreMappingsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Uploads files for the activities import. Uploaded files will be automatically deleted after a period of time.         Response on success is the status after uploading the files (see `/activityImport/status`)
     * @param {Class360ApiActivityImportUploadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class360Api
     */
    public activityImportUpload(requestParameters: Class360ApiActivityImportUploadRequest = {}, options?: AxiosRequestConfig) {
        return Class360ApiFp(this.configuration).activityImportUpload(requestParameters.version, requestParameters.developerPayload, requestParameters.files, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the current Cnh connection status.
     * @param {Class360ApiInterfacesCnhConnectionStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class360Api
     */
    public interfacesCnhConnectionStatus(requestParameters: Class360ApiInterfacesCnhConnectionStatusRequest = {}, options?: AxiosRequestConfig) {
        return Class360ApiFp(this.configuration).interfacesCnhConnectionStatus(requestParameters.version, requestParameters.developerPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Starts CNH task download process. Tasks are downloaded as ISOXML files for later import.          Returns the same response as `/activitiesImport/status`.         To poll the current status of the download process use `/activitiesImport/status`.         Note that a user is not allowed to start a new download while a previous download initiated by this user is          still in progress. If a download is already in progress an error response with the errorCode `inProgress` is          returned.            To cancel a download that is already in progress use `/activitiesImport/reset`         
     * @param {Class360ApiInterfacesCnhDownloadTasksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class360Api
     */
    public interfacesCnhDownloadTasks(requestParameters: Class360ApiInterfacesCnhDownloadTasksRequest = {}, options?: AxiosRequestConfig) {
        return Class360ApiFp(this.configuration).interfacesCnhDownloadTasks(requestParameters.interfacesCnhDownloadTasksRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Complete CNH OAuth2 login using supplied code. If successfull the resulting refresh token is              stored for the current user allowing the user to perform CNH API requests.
     * @param {Class360ApiInterfacesCnhLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class360Api
     */
    public interfacesCnhLogin(requestParameters: Class360ApiInterfacesCnhLoginRequest = {}, options?: AxiosRequestConfig) {
        return Class360ApiFp(this.configuration).interfacesCnhLogin(requestParameters.interfacesCnhLoginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the CNH authentication information linked to the current users account.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class360Api
     */
    public interfacesCnhLogout(options?: AxiosRequestConfig) {
        return Class360ApiFp(this.configuration).interfacesCnhLogout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all companies, growers, farmers and fields in an hirarchial list.                        In case the authorization fails (no linked account, token expired, ...)                        `errorCode` `externalApiAuthorization` is returned.
     * @param {Class360ApiInterfacesCnhOverviewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class360Api
     */
    public interfacesCnhOverview(requestParameters: Class360ApiInterfacesCnhOverviewRequest = {}, options?: AxiosRequestConfig) {
        return Class360ApiFp(this.configuration).interfacesCnhOverview(requestParameters.version, requestParameters.developerPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all tasks for a company. To get the companyId call `/external/cnh/overview`.                        Note that the same task id can be returned multiple times with different fieldIds.                        In case the authorization fails (no linked account, token expired, ...)                        `errorCode` `externalApiAuthorization` is returned.
     * @param {Class360ApiInterfacesCnhTasksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class360Api
     */
    public interfacesCnhTasks(requestParameters: Class360ApiInterfacesCnhTasksRequest, options?: AxiosRequestConfig) {
        return Class360ApiFp(this.configuration).interfacesCnhTasks(requestParameters.companyId, requestParameters.version, requestParameters.developerPayload, requestParameters.pageKey, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * Class370Api - axios parameter creator
 * @export
 */
export const Class370ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the gps log for an activity in geoJson format for the selected `datatypes`.         Only points with at least one defined value for the selected `datatypes` are returned.         Currently `time` is the only supported datatype supported, in the future the activities endpoint will provide a         list of available datatypes for each activity.         Currently only every forth point is returned.
         * @param {string} activityId Id of the activity for which to return the gps track.
         * @param {string} dataTypes Comma seperated list of datatypes.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activitiesGpsLog: async (activityId: string, dataTypes: string, pageKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('activitiesGpsLog', 'activityId', activityId)
            // verify required parameter 'dataTypes' is not null or undefined
            assertParamExists('activitiesGpsLog', 'dataTypes', dataTypes)
            const localVarPath = `/activities/{activityId}/gpsLog`
                .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dataTypes !== undefined) {
                localVarQueryParameter['dataTypes'] = dataTypes;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the gps track for an activity in geoJson format.
         * @param {string} activityId Id of the activity for which to return the gps track.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activitiesGpsTrack: async (activityId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('activitiesGpsTrack', 'activityId', activityId)
            const localVarPath = `/activities/{activityId}/gpsTrack`
                .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete one or more field contours.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldContourDelete: async (objectIds: string, version?: string, developerPayload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectIds' is not null or undefined
            assertParamExists('fieldContourDelete', 'objectIds', objectIds)
            const localVarPath = `/fieldContours/{objectIds}`
                .replace(`{${"objectIds"}}`, encodeURIComponent(String(objectIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load one or more field contours.             If the user does not have `GLOBAL_ALL_FIELDS` permissions only contours for active fields are returned.
         * @param {string} processOrderId The id of the process order for which all activity contours should be returned
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [permissionMode] Enable special permission modes
         * @param {string} [fieldSharingCompanyId] For &#x60;permissionMode&#x3D;fieldSharing&#x60;: The Id of the company for which to load data related to shared fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldContourList: async (processOrderId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, permissionMode?: string, fieldSharingCompanyId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processOrderId' is not null or undefined
            assertParamExists('fieldContourList', 'processOrderId', processOrderId)
            const localVarPath = `/fieldContours`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }

            if (processOrderId !== undefined) {
                localVarQueryParameter['processOrderId'] = processOrderId;
            }

            if (permissionMode !== undefined) {
                localVarQueryParameter['permissionMode'] = permissionMode;
            }

            if (fieldSharingCompanyId !== undefined) {
                localVarQueryParameter['fieldSharingCompanyId'] = fieldSharingCompanyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load one or more field contours by field contour id.              If the user does not have `GLOBAL_ALL_FIELDS` permissions only contours for active fields are returned.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [permissionMode] Enable special permission modes
         * @param {string} [fieldSharingCompanyId] For &#x60;permissionMode&#x3D;fieldSharing&#x60;: The Id of the company for which to load data related to shared fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldContourObjects: async (objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, permissionMode?: string, fieldSharingCompanyId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectIds' is not null or undefined
            assertParamExists('fieldContourObjects', 'objectIds', objectIds)
            const localVarPath = `/fieldContours/{objectIds}`
                .replace(`{${"objectIds"}}`, encodeURIComponent(String(objectIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }

            if (permissionMode !== undefined) {
                localVarQueryParameter['permissionMode'] = permissionMode;
            }

            if (fieldSharingCompanyId !== undefined) {
                localVarQueryParameter['fieldSharingCompanyId'] = fieldSharingCompanyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update one or more field contours.
         * @param {FieldContourPatchRequest} fieldContourPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldContourPatch: async (fieldContourPatchRequest: FieldContourPatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fieldContourPatchRequest' is not null or undefined
            assertParamExists('fieldContourPatch', 'fieldContourPatchRequest', fieldContourPatchRequest)
            const localVarPath = `/fieldContours`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fieldContourPatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create or replace one or more field contours.
         * @param {FieldContourPutRequest} fieldContourPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldContourPut: async (fieldContourPutRequest: FieldContourPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fieldContourPutRequest' is not null or undefined
            assertParamExists('fieldContourPut', 'fieldContourPutRequest', fieldContourPutRequest)
            const localVarPath = `/fieldContours`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fieldContourPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load one ore more GeoObject by id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoObject: async (objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectIds' is not null or undefined
            assertParamExists('geoObject', 'objectIds', objectIds)
            const localVarPath = `/map/geo/objects/{objectIds}`
                .replace(`{${"objectIds"}}`, encodeURIComponent(String(objectIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete one or more geo object.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoObjectDelete: async (objectIds: string, version?: string, developerPayload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectIds' is not null or undefined
            assertParamExists('geoObjectDelete', 'objectIds', objectIds)
            const localVarPath = `/map/geo/objects/{objectIds}`
                .replace(`{${"objectIds"}}`, encodeURIComponent(String(objectIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load a list of objects
         * @param {GeoObjectType} objectType Type of object that should be returned
         * @param {string} processOrderId The id of the process order for which all isolation zones should be returned
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoObjectList: async (objectType: GeoObjectType, processOrderId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectType' is not null or undefined
            assertParamExists('geoObjectList', 'objectType', objectType)
            // verify required parameter 'processOrderId' is not null or undefined
            assertParamExists('geoObjectList', 'processOrderId', processOrderId)
            const localVarPath = `/map/geo/objects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }

            if (objectType !== undefined) {
                localVarQueryParameter['objectType'] = objectType;
            }

            if (processOrderId !== undefined) {
                localVarQueryParameter['processOrderId'] = processOrderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update one or more geo object.
         * @param {GeoObjectPatchRequest} geoObjectPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoObjectPatch: async (geoObjectPatchRequest: GeoObjectPatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'geoObjectPatchRequest' is not null or undefined
            assertParamExists('geoObjectPatch', 'geoObjectPatchRequest', geoObjectPatchRequest)
            const localVarPath = `/map/geo/objects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(geoObjectPatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create or replace one or more geo object.
         * @param {GeoObjectPutRequest} geoObjectPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoObjectPut: async (geoObjectPutRequest: GeoObjectPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'geoObjectPutRequest' is not null or undefined
            assertParamExists('geoObjectPut', 'geoObjectPutRequest', geoObjectPutRequest)
            const localVarPath = `/map/geo/objects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(geoObjectPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Restore one or more geo object.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoObjectUndelete: async (objectIds: string, version?: string, developerPayload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectIds' is not null or undefined
            assertParamExists('geoObjectUndelete', 'objectIds', objectIds)
            const localVarPath = `/map/geo/objects/undelete/{objectIds}`
                .replace(`{${"objectIds"}}`, encodeURIComponent(String(objectIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the id and point for land cadastre map layer entries matching the search query.
         * @param {string} query The search query.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapLayerLandCadastreSearch: async (query: string, version?: string, developerPayload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('mapLayerLandCadastreSearch', 'query', query)
            const localVarPath = `/map/layer/landCadastre/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the gps track for a track in geoJson format.
         * @param {string} trackId Id of the track for which to return the gps track.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tracksGpsTrack: async (trackId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trackId' is not null or undefined
            assertParamExists('tracksGpsTrack', 'trackId', trackId)
            const localVarPath = `/tracks/{trackId}/gpsTrack`
                .replace(`{${"trackId"}}`, encodeURIComponent(String(trackId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Class370Api - functional programming interface
 * @export
 */
export const Class370ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Class370ApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the gps log for an activity in geoJson format for the selected `datatypes`.         Only points with at least one defined value for the selected `datatypes` are returned.         Currently `time` is the only supported datatype supported, in the future the activities endpoint will provide a         list of available datatypes for each activity.         Currently only every forth point is returned.
         * @param {string} activityId Id of the activity for which to return the gps track.
         * @param {string} dataTypes Comma seperated list of datatypes.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activitiesGpsLog(activityId: string, dataTypes: string, pageKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivitiesGpsLog200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activitiesGpsLog(activityId, dataTypes, pageKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the gps track for an activity in geoJson format.
         * @param {string} activityId Id of the activity for which to return the gps track.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activitiesGpsTrack(activityId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivitiesGpsTrack200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activitiesGpsTrack(activityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete one or more field contours.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldContourDelete(objectIds: string, version?: string, developerPayload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldContourDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldContourDelete(objectIds, version, developerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load one or more field contours.             If the user does not have `GLOBAL_ALL_FIELDS` permissions only contours for active fields are returned.
         * @param {string} processOrderId The id of the process order for which all activity contours should be returned
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [permissionMode] Enable special permission modes
         * @param {string} [fieldSharingCompanyId] For &#x60;permissionMode&#x3D;fieldSharing&#x60;: The Id of the company for which to load data related to shared fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldContourList(processOrderId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, permissionMode?: string, fieldSharingCompanyId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldContourObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldContourList(processOrderId, version, deleted, pageKey, developerPayload, properties, permissionMode, fieldSharingCompanyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load one or more field contours by field contour id.              If the user does not have `GLOBAL_ALL_FIELDS` permissions only contours for active fields are returned.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [permissionMode] Enable special permission modes
         * @param {string} [fieldSharingCompanyId] For &#x60;permissionMode&#x3D;fieldSharing&#x60;: The Id of the company for which to load data related to shared fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldContourObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, permissionMode?: string, fieldSharingCompanyId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldContourObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldContourObjects(objectIds, version, deleted, pageKey, developerPayload, properties, permissionMode, fieldSharingCompanyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update one or more field contours.
         * @param {FieldContourPatchRequest} fieldContourPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldContourPatch(fieldContourPatchRequest: FieldContourPatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldContourPut200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldContourPatch(fieldContourPatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create or replace one or more field contours.
         * @param {FieldContourPutRequest} fieldContourPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldContourPut(fieldContourPutRequest: FieldContourPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldContourPut200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldContourPut(fieldContourPutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load one ore more GeoObject by id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async geoObject(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeoObject200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.geoObject(objectIds, version, deleted, pageKey, developerPayload, properties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete one or more geo object.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async geoObjectDelete(objectIds: string, version?: string, developerPayload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeoObjectDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.geoObjectDelete(objectIds, version, developerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load a list of objects
         * @param {GeoObjectType} objectType Type of object that should be returned
         * @param {string} processOrderId The id of the process order for which all isolation zones should be returned
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async geoObjectList(objectType: GeoObjectType, processOrderId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeoObject200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.geoObjectList(objectType, processOrderId, version, deleted, pageKey, developerPayload, properties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update one or more geo object.
         * @param {GeoObjectPatchRequest} geoObjectPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async geoObjectPatch(geoObjectPatchRequest: GeoObjectPatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeoObjectPut200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.geoObjectPatch(geoObjectPatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create or replace one or more geo object.
         * @param {GeoObjectPutRequest} geoObjectPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async geoObjectPut(geoObjectPutRequest: GeoObjectPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeoObjectPut200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.geoObjectPut(geoObjectPutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Restore one or more geo object.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async geoObjectUndelete(objectIds: string, version?: string, developerPayload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeoObjectUndelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.geoObjectUndelete(objectIds, version, developerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the id and point for land cadastre map layer entries matching the search query.
         * @param {string} query The search query.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mapLayerLandCadastreSearch(query: string, version?: string, developerPayload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MapLayerLandCadastreSearch200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mapLayerLandCadastreSearch(query, version, developerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the gps track for a track in geoJson format.
         * @param {string} trackId Id of the track for which to return the gps track.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tracksGpsTrack(trackId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivitiesGpsTrack200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tracksGpsTrack(trackId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * Class370Api - factory interface
 * @export
 */
export const Class370ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Class370ApiFp(configuration)
    return {
        /**
         * Returns the gps log for an activity in geoJson format for the selected `datatypes`.         Only points with at least one defined value for the selected `datatypes` are returned.         Currently `time` is the only supported datatype supported, in the future the activities endpoint will provide a         list of available datatypes for each activity.         Currently only every forth point is returned.
         * @param {string} activityId Id of the activity for which to return the gps track.
         * @param {string} dataTypes Comma seperated list of datatypes.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activitiesGpsLog(activityId: string, dataTypes: string, pageKey?: string, options?: any): AxiosPromise<ActivitiesGpsLog200Response> {
            return localVarFp.activitiesGpsLog(activityId, dataTypes, pageKey, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the gps track for an activity in geoJson format.
         * @param {string} activityId Id of the activity for which to return the gps track.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activitiesGpsTrack(activityId: string, options?: any): AxiosPromise<ActivitiesGpsTrack200Response> {
            return localVarFp.activitiesGpsTrack(activityId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete one or more field contours.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldContourDelete(objectIds: string, version?: string, developerPayload?: string, options?: any): AxiosPromise<FieldContourDelete200Response> {
            return localVarFp.fieldContourDelete(objectIds, version, developerPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Load one or more field contours.             If the user does not have `GLOBAL_ALL_FIELDS` permissions only contours for active fields are returned.
         * @param {string} processOrderId The id of the process order for which all activity contours should be returned
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [permissionMode] Enable special permission modes
         * @param {string} [fieldSharingCompanyId] For &#x60;permissionMode&#x3D;fieldSharing&#x60;: The Id of the company for which to load data related to shared fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldContourList(processOrderId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, permissionMode?: string, fieldSharingCompanyId?: string, options?: any): AxiosPromise<FieldContourObjects200Response> {
            return localVarFp.fieldContourList(processOrderId, version, deleted, pageKey, developerPayload, properties, permissionMode, fieldSharingCompanyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Load one or more field contours by field contour id.              If the user does not have `GLOBAL_ALL_FIELDS` permissions only contours for active fields are returned.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [permissionMode] Enable special permission modes
         * @param {string} [fieldSharingCompanyId] For &#x60;permissionMode&#x3D;fieldSharing&#x60;: The Id of the company for which to load data related to shared fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldContourObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, permissionMode?: string, fieldSharingCompanyId?: string, options?: any): AxiosPromise<FieldContourObjects200Response> {
            return localVarFp.fieldContourObjects(objectIds, version, deleted, pageKey, developerPayload, properties, permissionMode, fieldSharingCompanyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update one or more field contours.
         * @param {FieldContourPatchRequest} fieldContourPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldContourPatch(fieldContourPatchRequest: FieldContourPatchRequest, options?: any): AxiosPromise<FieldContourPut200Response> {
            return localVarFp.fieldContourPatch(fieldContourPatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create or replace one or more field contours.
         * @param {FieldContourPutRequest} fieldContourPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldContourPut(fieldContourPutRequest: FieldContourPutRequest, options?: any): AxiosPromise<FieldContourPut200Response> {
            return localVarFp.fieldContourPut(fieldContourPutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Load one ore more GeoObject by id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoObject(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: any): AxiosPromise<GeoObject200Response> {
            return localVarFp.geoObject(objectIds, version, deleted, pageKey, developerPayload, properties, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete one or more geo object.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoObjectDelete(objectIds: string, version?: string, developerPayload?: string, options?: any): AxiosPromise<GeoObjectDelete200Response> {
            return localVarFp.geoObjectDelete(objectIds, version, developerPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Load a list of objects
         * @param {GeoObjectType} objectType Type of object that should be returned
         * @param {string} processOrderId The id of the process order for which all isolation zones should be returned
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoObjectList(objectType: GeoObjectType, processOrderId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: any): AxiosPromise<GeoObject200Response> {
            return localVarFp.geoObjectList(objectType, processOrderId, version, deleted, pageKey, developerPayload, properties, options).then((request) => request(axios, basePath));
        },
        /**
         * Update one or more geo object.
         * @param {GeoObjectPatchRequest} geoObjectPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoObjectPatch(geoObjectPatchRequest: GeoObjectPatchRequest, options?: any): AxiosPromise<GeoObjectPut200Response> {
            return localVarFp.geoObjectPatch(geoObjectPatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create or replace one or more geo object.
         * @param {GeoObjectPutRequest} geoObjectPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoObjectPut(geoObjectPutRequest: GeoObjectPutRequest, options?: any): AxiosPromise<GeoObjectPut200Response> {
            return localVarFp.geoObjectPut(geoObjectPutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Restore one or more geo object.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoObjectUndelete(objectIds: string, version?: string, developerPayload?: string, options?: any): AxiosPromise<GeoObjectUndelete200Response> {
            return localVarFp.geoObjectUndelete(objectIds, version, developerPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the id and point for land cadastre map layer entries matching the search query.
         * @param {string} query The search query.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapLayerLandCadastreSearch(query: string, version?: string, developerPayload?: string, options?: any): AxiosPromise<MapLayerLandCadastreSearch200Response> {
            return localVarFp.mapLayerLandCadastreSearch(query, version, developerPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the gps track for a track in geoJson format.
         * @param {string} trackId Id of the track for which to return the gps track.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tracksGpsTrack(trackId: string, options?: any): AxiosPromise<ActivitiesGpsTrack200Response> {
            return localVarFp.tracksGpsTrack(trackId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for activitiesGpsLog operation in Class370Api.
 * @export
 * @interface Class370ApiActivitiesGpsLogRequest
 */
export interface Class370ApiActivitiesGpsLogRequest {
    /**
     * Id of the activity for which to return the gps track.
     * @type {string}
     * @memberof Class370ApiActivitiesGpsLog
     */
    readonly activityId: string

    /**
     * Comma seperated list of datatypes.
     * @type {string}
     * @memberof Class370ApiActivitiesGpsLog
     */
    readonly dataTypes: string

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof Class370ApiActivitiesGpsLog
     */
    readonly pageKey?: string
}

/**
 * Request parameters for activitiesGpsTrack operation in Class370Api.
 * @export
 * @interface Class370ApiActivitiesGpsTrackRequest
 */
export interface Class370ApiActivitiesGpsTrackRequest {
    /**
     * Id of the activity for which to return the gps track.
     * @type {string}
     * @memberof Class370ApiActivitiesGpsTrack
     */
    readonly activityId: string
}

/**
 * Request parameters for fieldContourDelete operation in Class370Api.
 * @export
 * @interface Class370ApiFieldContourDeleteRequest
 */
export interface Class370ApiFieldContourDeleteRequest {
    /**
     * A comma sperated list of object ids.
     * @type {string}
     * @memberof Class370ApiFieldContourDelete
     */
    readonly objectIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class370ApiFieldContourDelete
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class370ApiFieldContourDelete
     */
    readonly developerPayload?: string
}

/**
 * Request parameters for fieldContourList operation in Class370Api.
 * @export
 * @interface Class370ApiFieldContourListRequest
 */
export interface Class370ApiFieldContourListRequest {
    /**
     * The id of the process order for which all activity contours should be returned
     * @type {string}
     * @memberof Class370ApiFieldContourList
     */
    readonly processOrderId: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class370ApiFieldContourList
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof Class370ApiFieldContourList
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof Class370ApiFieldContourList
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class370ApiFieldContourList
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof Class370ApiFieldContourList
     */
    readonly properties?: string

    /**
     * Enable special permission modes
     * @type {string}
     * @memberof Class370ApiFieldContourList
     */
    readonly permissionMode?: string

    /**
     * For &#x60;permissionMode&#x3D;fieldSharing&#x60;: The Id of the company for which to load data related to shared fields.
     * @type {string}
     * @memberof Class370ApiFieldContourList
     */
    readonly fieldSharingCompanyId?: string
}

/**
 * Request parameters for fieldContourObjects operation in Class370Api.
 * @export
 * @interface Class370ApiFieldContourObjectsRequest
 */
export interface Class370ApiFieldContourObjectsRequest {
    /**
     * Comma seperated list of object ids, use this to request specific objects.
     * @type {string}
     * @memberof Class370ApiFieldContourObjects
     */
    readonly objectIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class370ApiFieldContourObjects
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof Class370ApiFieldContourObjects
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof Class370ApiFieldContourObjects
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class370ApiFieldContourObjects
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof Class370ApiFieldContourObjects
     */
    readonly properties?: string

    /**
     * Enable special permission modes
     * @type {string}
     * @memberof Class370ApiFieldContourObjects
     */
    readonly permissionMode?: string

    /**
     * For &#x60;permissionMode&#x3D;fieldSharing&#x60;: The Id of the company for which to load data related to shared fields.
     * @type {string}
     * @memberof Class370ApiFieldContourObjects
     */
    readonly fieldSharingCompanyId?: string
}

/**
 * Request parameters for fieldContourPatch operation in Class370Api.
 * @export
 * @interface Class370ApiFieldContourPatchRequest
 */
export interface Class370ApiFieldContourPatchRequest {
    /**
     * 
     * @type {FieldContourPatchRequest}
     * @memberof Class370ApiFieldContourPatch
     */
    readonly fieldContourPatchRequest: FieldContourPatchRequest
}

/**
 * Request parameters for fieldContourPut operation in Class370Api.
 * @export
 * @interface Class370ApiFieldContourPutRequest
 */
export interface Class370ApiFieldContourPutRequest {
    /**
     * 
     * @type {FieldContourPutRequest}
     * @memberof Class370ApiFieldContourPut
     */
    readonly fieldContourPutRequest: FieldContourPutRequest
}

/**
 * Request parameters for geoObject operation in Class370Api.
 * @export
 * @interface Class370ApiGeoObjectRequest
 */
export interface Class370ApiGeoObjectRequest {
    /**
     * Comma seperated list of object ids, use this to request specific objects.
     * @type {string}
     * @memberof Class370ApiGeoObject
     */
    readonly objectIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class370ApiGeoObject
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof Class370ApiGeoObject
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof Class370ApiGeoObject
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class370ApiGeoObject
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof Class370ApiGeoObject
     */
    readonly properties?: string
}

/**
 * Request parameters for geoObjectDelete operation in Class370Api.
 * @export
 * @interface Class370ApiGeoObjectDeleteRequest
 */
export interface Class370ApiGeoObjectDeleteRequest {
    /**
     * A comma sperated list of object ids.
     * @type {string}
     * @memberof Class370ApiGeoObjectDelete
     */
    readonly objectIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class370ApiGeoObjectDelete
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class370ApiGeoObjectDelete
     */
    readonly developerPayload?: string
}

/**
 * Request parameters for geoObjectList operation in Class370Api.
 * @export
 * @interface Class370ApiGeoObjectListRequest
 */
export interface Class370ApiGeoObjectListRequest {
    /**
     * Type of object that should be returned
     * @type {GeoObjectType}
     * @memberof Class370ApiGeoObjectList
     */
    readonly objectType: GeoObjectType

    /**
     * The id of the process order for which all isolation zones should be returned
     * @type {string}
     * @memberof Class370ApiGeoObjectList
     */
    readonly processOrderId: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class370ApiGeoObjectList
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof Class370ApiGeoObjectList
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof Class370ApiGeoObjectList
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class370ApiGeoObjectList
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof Class370ApiGeoObjectList
     */
    readonly properties?: string
}

/**
 * Request parameters for geoObjectPatch operation in Class370Api.
 * @export
 * @interface Class370ApiGeoObjectPatchRequest
 */
export interface Class370ApiGeoObjectPatchRequest {
    /**
     * 
     * @type {GeoObjectPatchRequest}
     * @memberof Class370ApiGeoObjectPatch
     */
    readonly geoObjectPatchRequest: GeoObjectPatchRequest
}

/**
 * Request parameters for geoObjectPut operation in Class370Api.
 * @export
 * @interface Class370ApiGeoObjectPutRequest
 */
export interface Class370ApiGeoObjectPutRequest {
    /**
     * 
     * @type {GeoObjectPutRequest}
     * @memberof Class370ApiGeoObjectPut
     */
    readonly geoObjectPutRequest: GeoObjectPutRequest
}

/**
 * Request parameters for geoObjectUndelete operation in Class370Api.
 * @export
 * @interface Class370ApiGeoObjectUndeleteRequest
 */
export interface Class370ApiGeoObjectUndeleteRequest {
    /**
     * A comma sperated list of object ids.
     * @type {string}
     * @memberof Class370ApiGeoObjectUndelete
     */
    readonly objectIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class370ApiGeoObjectUndelete
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class370ApiGeoObjectUndelete
     */
    readonly developerPayload?: string
}

/**
 * Request parameters for mapLayerLandCadastreSearch operation in Class370Api.
 * @export
 * @interface Class370ApiMapLayerLandCadastreSearchRequest
 */
export interface Class370ApiMapLayerLandCadastreSearchRequest {
    /**
     * The search query.
     * @type {string}
     * @memberof Class370ApiMapLayerLandCadastreSearch
     */
    readonly query: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class370ApiMapLayerLandCadastreSearch
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class370ApiMapLayerLandCadastreSearch
     */
    readonly developerPayload?: string
}

/**
 * Request parameters for tracksGpsTrack operation in Class370Api.
 * @export
 * @interface Class370ApiTracksGpsTrackRequest
 */
export interface Class370ApiTracksGpsTrackRequest {
    /**
     * Id of the track for which to return the gps track.
     * @type {string}
     * @memberof Class370ApiTracksGpsTrack
     */
    readonly trackId: string
}

/**
 * Class370Api - object-oriented interface
 * @export
 * @class Class370Api
 * @extends {BaseAPI}
 */
export class Class370Api extends BaseAPI {
    /**
     * Returns the gps log for an activity in geoJson format for the selected `datatypes`.         Only points with at least one defined value for the selected `datatypes` are returned.         Currently `time` is the only supported datatype supported, in the future the activities endpoint will provide a         list of available datatypes for each activity.         Currently only every forth point is returned.
     * @param {Class370ApiActivitiesGpsLogRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class370Api
     */
    public activitiesGpsLog(requestParameters: Class370ApiActivitiesGpsLogRequest, options?: AxiosRequestConfig) {
        return Class370ApiFp(this.configuration).activitiesGpsLog(requestParameters.activityId, requestParameters.dataTypes, requestParameters.pageKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the gps track for an activity in geoJson format.
     * @param {Class370ApiActivitiesGpsTrackRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class370Api
     */
    public activitiesGpsTrack(requestParameters: Class370ApiActivitiesGpsTrackRequest, options?: AxiosRequestConfig) {
        return Class370ApiFp(this.configuration).activitiesGpsTrack(requestParameters.activityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete one or more field contours.
     * @param {Class370ApiFieldContourDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class370Api
     */
    public fieldContourDelete(requestParameters: Class370ApiFieldContourDeleteRequest, options?: AxiosRequestConfig) {
        return Class370ApiFp(this.configuration).fieldContourDelete(requestParameters.objectIds, requestParameters.version, requestParameters.developerPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load one or more field contours.             If the user does not have `GLOBAL_ALL_FIELDS` permissions only contours for active fields are returned.
     * @param {Class370ApiFieldContourListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class370Api
     */
    public fieldContourList(requestParameters: Class370ApiFieldContourListRequest, options?: AxiosRequestConfig) {
        return Class370ApiFp(this.configuration).fieldContourList(requestParameters.processOrderId, requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, requestParameters.permissionMode, requestParameters.fieldSharingCompanyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load one or more field contours by field contour id.              If the user does not have `GLOBAL_ALL_FIELDS` permissions only contours for active fields are returned.
     * @param {Class370ApiFieldContourObjectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class370Api
     */
    public fieldContourObjects(requestParameters: Class370ApiFieldContourObjectsRequest, options?: AxiosRequestConfig) {
        return Class370ApiFp(this.configuration).fieldContourObjects(requestParameters.objectIds, requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, requestParameters.permissionMode, requestParameters.fieldSharingCompanyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update one or more field contours.
     * @param {Class370ApiFieldContourPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class370Api
     */
    public fieldContourPatch(requestParameters: Class370ApiFieldContourPatchRequest, options?: AxiosRequestConfig) {
        return Class370ApiFp(this.configuration).fieldContourPatch(requestParameters.fieldContourPatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create or replace one or more field contours.
     * @param {Class370ApiFieldContourPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class370Api
     */
    public fieldContourPut(requestParameters: Class370ApiFieldContourPutRequest, options?: AxiosRequestConfig) {
        return Class370ApiFp(this.configuration).fieldContourPut(requestParameters.fieldContourPutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load one ore more GeoObject by id.
     * @param {Class370ApiGeoObjectRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class370Api
     */
    public geoObject(requestParameters: Class370ApiGeoObjectRequest, options?: AxiosRequestConfig) {
        return Class370ApiFp(this.configuration).geoObject(requestParameters.objectIds, requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete one or more geo object.
     * @param {Class370ApiGeoObjectDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class370Api
     */
    public geoObjectDelete(requestParameters: Class370ApiGeoObjectDeleteRequest, options?: AxiosRequestConfig) {
        return Class370ApiFp(this.configuration).geoObjectDelete(requestParameters.objectIds, requestParameters.version, requestParameters.developerPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load a list of objects
     * @param {Class370ApiGeoObjectListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class370Api
     */
    public geoObjectList(requestParameters: Class370ApiGeoObjectListRequest, options?: AxiosRequestConfig) {
        return Class370ApiFp(this.configuration).geoObjectList(requestParameters.objectType, requestParameters.processOrderId, requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update one or more geo object.
     * @param {Class370ApiGeoObjectPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class370Api
     */
    public geoObjectPatch(requestParameters: Class370ApiGeoObjectPatchRequest, options?: AxiosRequestConfig) {
        return Class370ApiFp(this.configuration).geoObjectPatch(requestParameters.geoObjectPatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create or replace one or more geo object.
     * @param {Class370ApiGeoObjectPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class370Api
     */
    public geoObjectPut(requestParameters: Class370ApiGeoObjectPutRequest, options?: AxiosRequestConfig) {
        return Class370ApiFp(this.configuration).geoObjectPut(requestParameters.geoObjectPutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Restore one or more geo object.
     * @param {Class370ApiGeoObjectUndeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class370Api
     */
    public geoObjectUndelete(requestParameters: Class370ApiGeoObjectUndeleteRequest, options?: AxiosRequestConfig) {
        return Class370ApiFp(this.configuration).geoObjectUndelete(requestParameters.objectIds, requestParameters.version, requestParameters.developerPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the id and point for land cadastre map layer entries matching the search query.
     * @param {Class370ApiMapLayerLandCadastreSearchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class370Api
     */
    public mapLayerLandCadastreSearch(requestParameters: Class370ApiMapLayerLandCadastreSearchRequest, options?: AxiosRequestConfig) {
        return Class370ApiFp(this.configuration).mapLayerLandCadastreSearch(requestParameters.query, requestParameters.version, requestParameters.developerPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the gps track for a track in geoJson format.
     * @param {Class370ApiTracksGpsTrackRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class370Api
     */
    public tracksGpsTrack(requestParameters: Class370ApiTracksGpsTrackRequest, options?: AxiosRequestConfig) {
        return Class370ApiFp(this.configuration).tracksGpsTrack(requestParameters.trackId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * Class380Api - axios parameter creator
 * @export
 */
export const Class380ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the water-protection-zone map layer entries for the specified area.            Notes:             - Currently only available for Austria.            - Data might require display of copyright notice, see `copyrights` property.            - If too many objects match the bounding box the `status` property will be `tooManyResults`.                The server might still return a partial result in this case. It is up to the client to decide whether to                use the partial result.             - The client should avoid duplicated / overlapping requests as much as possible.             - The client must not send multiple requests to this route at the same time, and should instead wait until                  the previous request has finished or cancel the request before sending a new one.
         * @param {string} bbox Boundingbox in geojson bbox format.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapLayerWaterProtectionZone: async (bbox: string, version?: string, developerPayload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bbox' is not null or undefined
            assertParamExists('mapLayerWaterProtectionZone', 'bbox', bbox)
            const localVarPath = `/map/layer/waterProtectionZone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (bbox !== undefined) {
                localVarQueryParameter['bbox'] = bbox;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Query if geometries intersect with water protection zones.            Notes:             - Currently only available for Austria.            - Queries should not exceed 500 geometries per request.
         * @param {MapLayerWaterProtectionZoneIntersectsRequest} [mapLayerWaterProtectionZoneIntersectsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapLayerWaterProtectionZoneIntersects: async (mapLayerWaterProtectionZoneIntersectsRequest?: MapLayerWaterProtectionZoneIntersectsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/map/layer/waterProtectionZone/intersects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mapLayerWaterProtectionZoneIntersectsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Class380Api - functional programming interface
 * @export
 */
export const Class380ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Class380ApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the water-protection-zone map layer entries for the specified area.            Notes:             - Currently only available for Austria.            - Data might require display of copyright notice, see `copyrights` property.            - If too many objects match the bounding box the `status` property will be `tooManyResults`.                The server might still return a partial result in this case. It is up to the client to decide whether to                use the partial result.             - The client should avoid duplicated / overlapping requests as much as possible.             - The client must not send multiple requests to this route at the same time, and should instead wait until                  the previous request has finished or cancel the request before sending a new one.
         * @param {string} bbox Boundingbox in geojson bbox format.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mapLayerWaterProtectionZone(bbox: string, version?: string, developerPayload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MapLayerWaterProtectionZone200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mapLayerWaterProtectionZone(bbox, version, developerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Query if geometries intersect with water protection zones.            Notes:             - Currently only available for Austria.            - Queries should not exceed 500 geometries per request.
         * @param {MapLayerWaterProtectionZoneIntersectsRequest} [mapLayerWaterProtectionZoneIntersectsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mapLayerWaterProtectionZoneIntersects(mapLayerWaterProtectionZoneIntersectsRequest?: MapLayerWaterProtectionZoneIntersectsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MapLayerWaterProtectionZoneIntersects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mapLayerWaterProtectionZoneIntersects(mapLayerWaterProtectionZoneIntersectsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * Class380Api - factory interface
 * @export
 */
export const Class380ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Class380ApiFp(configuration)
    return {
        /**
         * Returns the water-protection-zone map layer entries for the specified area.            Notes:             - Currently only available for Austria.            - Data might require display of copyright notice, see `copyrights` property.            - If too many objects match the bounding box the `status` property will be `tooManyResults`.                The server might still return a partial result in this case. It is up to the client to decide whether to                use the partial result.             - The client should avoid duplicated / overlapping requests as much as possible.             - The client must not send multiple requests to this route at the same time, and should instead wait until                  the previous request has finished or cancel the request before sending a new one.
         * @param {string} bbox Boundingbox in geojson bbox format.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapLayerWaterProtectionZone(bbox: string, version?: string, developerPayload?: string, options?: any): AxiosPromise<MapLayerWaterProtectionZone200Response> {
            return localVarFp.mapLayerWaterProtectionZone(bbox, version, developerPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Query if geometries intersect with water protection zones.            Notes:             - Currently only available for Austria.            - Queries should not exceed 500 geometries per request.
         * @param {MapLayerWaterProtectionZoneIntersectsRequest} [mapLayerWaterProtectionZoneIntersectsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapLayerWaterProtectionZoneIntersects(mapLayerWaterProtectionZoneIntersectsRequest?: MapLayerWaterProtectionZoneIntersectsRequest, options?: any): AxiosPromise<MapLayerWaterProtectionZoneIntersects200Response> {
            return localVarFp.mapLayerWaterProtectionZoneIntersects(mapLayerWaterProtectionZoneIntersectsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for mapLayerWaterProtectionZone operation in Class380Api.
 * @export
 * @interface Class380ApiMapLayerWaterProtectionZoneRequest
 */
export interface Class380ApiMapLayerWaterProtectionZoneRequest {
    /**
     * Boundingbox in geojson bbox format.
     * @type {string}
     * @memberof Class380ApiMapLayerWaterProtectionZone
     */
    readonly bbox: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class380ApiMapLayerWaterProtectionZone
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class380ApiMapLayerWaterProtectionZone
     */
    readonly developerPayload?: string
}

/**
 * Request parameters for mapLayerWaterProtectionZoneIntersects operation in Class380Api.
 * @export
 * @interface Class380ApiMapLayerWaterProtectionZoneIntersectsRequest
 */
export interface Class380ApiMapLayerWaterProtectionZoneIntersectsRequest {
    /**
     * 
     * @type {MapLayerWaterProtectionZoneIntersectsRequest}
     * @memberof Class380ApiMapLayerWaterProtectionZoneIntersects
     */
    readonly mapLayerWaterProtectionZoneIntersectsRequest?: MapLayerWaterProtectionZoneIntersectsRequest
}

/**
 * Class380Api - object-oriented interface
 * @export
 * @class Class380Api
 * @extends {BaseAPI}
 */
export class Class380Api extends BaseAPI {
    /**
     * Returns the water-protection-zone map layer entries for the specified area.            Notes:             - Currently only available for Austria.            - Data might require display of copyright notice, see `copyrights` property.            - If too many objects match the bounding box the `status` property will be `tooManyResults`.                The server might still return a partial result in this case. It is up to the client to decide whether to                use the partial result.             - The client should avoid duplicated / overlapping requests as much as possible.             - The client must not send multiple requests to this route at the same time, and should instead wait until                  the previous request has finished or cancel the request before sending a new one.
     * @param {Class380ApiMapLayerWaterProtectionZoneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class380Api
     */
    public mapLayerWaterProtectionZone(requestParameters: Class380ApiMapLayerWaterProtectionZoneRequest, options?: AxiosRequestConfig) {
        return Class380ApiFp(this.configuration).mapLayerWaterProtectionZone(requestParameters.bbox, requestParameters.version, requestParameters.developerPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Query if geometries intersect with water protection zones.            Notes:             - Currently only available for Austria.            - Queries should not exceed 500 geometries per request.
     * @param {Class380ApiMapLayerWaterProtectionZoneIntersectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class380Api
     */
    public mapLayerWaterProtectionZoneIntersects(requestParameters: Class380ApiMapLayerWaterProtectionZoneIntersectsRequest = {}, options?: AxiosRequestConfig) {
        return Class380ApiFp(this.configuration).mapLayerWaterProtectionZoneIntersects(requestParameters.mapLayerWaterProtectionZoneIntersectsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * Class390Api - axios parameter creator
 * @export
 */
export const Class390ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the vegetation index graph for a specific fieldId and date range. The field must have a contour defined.
         * @param {string} fieldId Id of field for which to query data.
         * @param {string} startDate Start date of the requested period.
         * @param {string} endDate End date of the requested period.
         * @param {Sen4IndexType} indexType Index type for which to return data.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {Sen4VegetationIndexStatistics} [statistics] Compute historical mean depending on aggregate (D/M/Y) for a given period. Not available for hourly aggregate.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sen4VegetationIndexGraph: async (fieldId: string, startDate: string, endDate: string, indexType: Sen4IndexType, version?: string, developerPayload?: string, statistics?: Sen4VegetationIndexStatistics, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fieldId' is not null or undefined
            assertParamExists('sen4VegetationIndexGraph', 'fieldId', fieldId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('sen4VegetationIndexGraph', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('sen4VegetationIndexGraph', 'endDate', endDate)
            // verify required parameter 'indexType' is not null or undefined
            assertParamExists('sen4VegetationIndexGraph', 'indexType', indexType)
            const localVarPath = `/sen4/vegetationIndexGraph`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (fieldId !== undefined) {
                localVarQueryParameter['fieldId'] = fieldId;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (indexType !== undefined) {
                localVarQueryParameter['indexType'] = indexType;
            }

            if (statistics !== undefined) {
                localVarQueryParameter['statistics'] = statistics;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns weather information for a specific point and date range.                       Performance Note: Performance depends on time range, number /                        type of variables selected and aggregate/statistics type.                        Please test the requests you intend to run for performance and limit time range as needed.                        Requests taking longer than 30 seconds will fail.                         Note: When testing for performance please use different gps points as data is cached in the                          weather backend.
         * @param {number} latitude Latitude of point to query.
         * @param {number} longitude Longitude of point to query.
         * @param {string} startDate Start date of the requested period.
         * @param {string} endDate End date of the requested period.
         * @param {Array<'Temperature2m' | 'Temperature2mMin' | 'Temperature2mMax' | 'Precipitation' | 'WindSpeed10m' | 'SoilTemperatureSurface' | 'SoilMoistureSurface' | 'ShortwaveRadiation' | 'Et0FaoEvapotranspiration' | 'HeatUnit' | 'PrecipitationAccumulated' | 'PrecipitationProbability' | 'WeatherCode' | 'RelativeHumidity2m' | 'SurfacePressure' | 'CloudCover' | 'DirectRadiation'>} variables Weather variables to include in response.                        Note: &#x60;Temperature2mMin&#x60;, &#x60;Temperature2mMax&#x60;, &#x60;HeatUnit&#x60; and &#x60;PrecipitationAccumulated&#x60; not available for hourly aggregate.                         &#x60;DirectRadiation&#x60; not available in daily, weekly and monthly aggregate
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {'Hourly' | 'Daily' | 'Monthly' | 'Yearly'} [aggregate] Data aggregation.
         * @param {'No' | 'MeanY5'} [statistics] Compute historical mean depending on aggregate (D/M/Y) for a given period. Not available for hourly aggregate.
         * @param {number} [tempBaseCrop] Crop-specific base temperature for heat unit calculation in degrees celsius.
         * @param {string} [dateAccumulate] Start date from which to accumulate heat units / accumulated precipitation. If not provided startDate is used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weatherAggregate: async (latitude: number, longitude: number, startDate: string, endDate: string, variables: Array<'Temperature2m' | 'Temperature2mMin' | 'Temperature2mMax' | 'Precipitation' | 'WindSpeed10m' | 'SoilTemperatureSurface' | 'SoilMoistureSurface' | 'ShortwaveRadiation' | 'Et0FaoEvapotranspiration' | 'HeatUnit' | 'PrecipitationAccumulated' | 'PrecipitationProbability' | 'WeatherCode' | 'RelativeHumidity2m' | 'SurfacePressure' | 'CloudCover' | 'DirectRadiation'>, version?: string, developerPayload?: string, aggregate?: 'Hourly' | 'Daily' | 'Monthly' | 'Yearly', statistics?: 'No' | 'MeanY5', tempBaseCrop?: number, dateAccumulate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'latitude' is not null or undefined
            assertParamExists('weatherAggregate', 'latitude', latitude)
            // verify required parameter 'longitude' is not null or undefined
            assertParamExists('weatherAggregate', 'longitude', longitude)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('weatherAggregate', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('weatherAggregate', 'endDate', endDate)
            // verify required parameter 'variables' is not null or undefined
            assertParamExists('weatherAggregate', 'variables', variables)
            const localVarPath = `/weather/aggregate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (latitude !== undefined) {
                localVarQueryParameter['latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['longitude'] = longitude;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (variables) {
                localVarQueryParameter['variables'] = variables.join(COLLECTION_FORMATS.csv);
            }

            if (aggregate !== undefined) {
                localVarQueryParameter['aggregate'] = aggregate;
            }

            if (statistics !== undefined) {
                localVarQueryParameter['statistics'] = statistics;
            }

            if (tempBaseCrop !== undefined) {
                localVarQueryParameter['tempBaseCrop'] = tempBaseCrop;
            }

            if (dateAccumulate !== undefined) {
                localVarQueryParameter['dateAccumulate'] = (dateAccumulate as any instanceof Date) ?
                    (dateAccumulate as any).toISOString().substr(0,10) :
                    dateAccumulate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Class390Api - functional programming interface
 * @export
 */
export const Class390ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Class390ApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the vegetation index graph for a specific fieldId and date range. The field must have a contour defined.
         * @param {string} fieldId Id of field for which to query data.
         * @param {string} startDate Start date of the requested period.
         * @param {string} endDate End date of the requested period.
         * @param {Sen4IndexType} indexType Index type for which to return data.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {Sen4VegetationIndexStatistics} [statistics] Compute historical mean depending on aggregate (D/M/Y) for a given period. Not available for hourly aggregate.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sen4VegetationIndexGraph(fieldId: string, startDate: string, endDate: string, indexType: Sen4IndexType, version?: string, developerPayload?: string, statistics?: Sen4VegetationIndexStatistics, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sen4VegetationIndexGraph200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sen4VegetationIndexGraph(fieldId, startDate, endDate, indexType, version, developerPayload, statistics, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns weather information for a specific point and date range.                       Performance Note: Performance depends on time range, number /                        type of variables selected and aggregate/statistics type.                        Please test the requests you intend to run for performance and limit time range as needed.                        Requests taking longer than 30 seconds will fail.                         Note: When testing for performance please use different gps points as data is cached in the                          weather backend.
         * @param {number} latitude Latitude of point to query.
         * @param {number} longitude Longitude of point to query.
         * @param {string} startDate Start date of the requested period.
         * @param {string} endDate End date of the requested period.
         * @param {Array<'Temperature2m' | 'Temperature2mMin' | 'Temperature2mMax' | 'Precipitation' | 'WindSpeed10m' | 'SoilTemperatureSurface' | 'SoilMoistureSurface' | 'ShortwaveRadiation' | 'Et0FaoEvapotranspiration' | 'HeatUnit' | 'PrecipitationAccumulated' | 'PrecipitationProbability' | 'WeatherCode' | 'RelativeHumidity2m' | 'SurfacePressure' | 'CloudCover' | 'DirectRadiation'>} variables Weather variables to include in response.                        Note: &#x60;Temperature2mMin&#x60;, &#x60;Temperature2mMax&#x60;, &#x60;HeatUnit&#x60; and &#x60;PrecipitationAccumulated&#x60; not available for hourly aggregate.                         &#x60;DirectRadiation&#x60; not available in daily, weekly and monthly aggregate
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {'Hourly' | 'Daily' | 'Monthly' | 'Yearly'} [aggregate] Data aggregation.
         * @param {'No' | 'MeanY5'} [statistics] Compute historical mean depending on aggregate (D/M/Y) for a given period. Not available for hourly aggregate.
         * @param {number} [tempBaseCrop] Crop-specific base temperature for heat unit calculation in degrees celsius.
         * @param {string} [dateAccumulate] Start date from which to accumulate heat units / accumulated precipitation. If not provided startDate is used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async weatherAggregate(latitude: number, longitude: number, startDate: string, endDate: string, variables: Array<'Temperature2m' | 'Temperature2mMin' | 'Temperature2mMax' | 'Precipitation' | 'WindSpeed10m' | 'SoilTemperatureSurface' | 'SoilMoistureSurface' | 'ShortwaveRadiation' | 'Et0FaoEvapotranspiration' | 'HeatUnit' | 'PrecipitationAccumulated' | 'PrecipitationProbability' | 'WeatherCode' | 'RelativeHumidity2m' | 'SurfacePressure' | 'CloudCover' | 'DirectRadiation'>, version?: string, developerPayload?: string, aggregate?: 'Hourly' | 'Daily' | 'Monthly' | 'Yearly', statistics?: 'No' | 'MeanY5', tempBaseCrop?: number, dateAccumulate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WeatherAggregate200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.weatherAggregate(latitude, longitude, startDate, endDate, variables, version, developerPayload, aggregate, statistics, tempBaseCrop, dateAccumulate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * Class390Api - factory interface
 * @export
 */
export const Class390ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Class390ApiFp(configuration)
    return {
        /**
         * Returns the vegetation index graph for a specific fieldId and date range. The field must have a contour defined.
         * @param {string} fieldId Id of field for which to query data.
         * @param {string} startDate Start date of the requested period.
         * @param {string} endDate End date of the requested period.
         * @param {Sen4IndexType} indexType Index type for which to return data.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {Sen4VegetationIndexStatistics} [statistics] Compute historical mean depending on aggregate (D/M/Y) for a given period. Not available for hourly aggregate.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sen4VegetationIndexGraph(fieldId: string, startDate: string, endDate: string, indexType: Sen4IndexType, version?: string, developerPayload?: string, statistics?: Sen4VegetationIndexStatistics, options?: any): AxiosPromise<Sen4VegetationIndexGraph200Response> {
            return localVarFp.sen4VegetationIndexGraph(fieldId, startDate, endDate, indexType, version, developerPayload, statistics, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns weather information for a specific point and date range.                       Performance Note: Performance depends on time range, number /                        type of variables selected and aggregate/statistics type.                        Please test the requests you intend to run for performance and limit time range as needed.                        Requests taking longer than 30 seconds will fail.                         Note: When testing for performance please use different gps points as data is cached in the                          weather backend.
         * @param {number} latitude Latitude of point to query.
         * @param {number} longitude Longitude of point to query.
         * @param {string} startDate Start date of the requested period.
         * @param {string} endDate End date of the requested period.
         * @param {Array<'Temperature2m' | 'Temperature2mMin' | 'Temperature2mMax' | 'Precipitation' | 'WindSpeed10m' | 'SoilTemperatureSurface' | 'SoilMoistureSurface' | 'ShortwaveRadiation' | 'Et0FaoEvapotranspiration' | 'HeatUnit' | 'PrecipitationAccumulated' | 'PrecipitationProbability' | 'WeatherCode' | 'RelativeHumidity2m' | 'SurfacePressure' | 'CloudCover' | 'DirectRadiation'>} variables Weather variables to include in response.                        Note: &#x60;Temperature2mMin&#x60;, &#x60;Temperature2mMax&#x60;, &#x60;HeatUnit&#x60; and &#x60;PrecipitationAccumulated&#x60; not available for hourly aggregate.                         &#x60;DirectRadiation&#x60; not available in daily, weekly and monthly aggregate
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {'Hourly' | 'Daily' | 'Monthly' | 'Yearly'} [aggregate] Data aggregation.
         * @param {'No' | 'MeanY5'} [statistics] Compute historical mean depending on aggregate (D/M/Y) for a given period. Not available for hourly aggregate.
         * @param {number} [tempBaseCrop] Crop-specific base temperature for heat unit calculation in degrees celsius.
         * @param {string} [dateAccumulate] Start date from which to accumulate heat units / accumulated precipitation. If not provided startDate is used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weatherAggregate(latitude: number, longitude: number, startDate: string, endDate: string, variables: Array<'Temperature2m' | 'Temperature2mMin' | 'Temperature2mMax' | 'Precipitation' | 'WindSpeed10m' | 'SoilTemperatureSurface' | 'SoilMoistureSurface' | 'ShortwaveRadiation' | 'Et0FaoEvapotranspiration' | 'HeatUnit' | 'PrecipitationAccumulated' | 'PrecipitationProbability' | 'WeatherCode' | 'RelativeHumidity2m' | 'SurfacePressure' | 'CloudCover' | 'DirectRadiation'>, version?: string, developerPayload?: string, aggregate?: 'Hourly' | 'Daily' | 'Monthly' | 'Yearly', statistics?: 'No' | 'MeanY5', tempBaseCrop?: number, dateAccumulate?: string, options?: any): AxiosPromise<WeatherAggregate200Response> {
            return localVarFp.weatherAggregate(latitude, longitude, startDate, endDate, variables, version, developerPayload, aggregate, statistics, tempBaseCrop, dateAccumulate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for sen4VegetationIndexGraph operation in Class390Api.
 * @export
 * @interface Class390ApiSen4VegetationIndexGraphRequest
 */
export interface Class390ApiSen4VegetationIndexGraphRequest {
    /**
     * Id of field for which to query data.
     * @type {string}
     * @memberof Class390ApiSen4VegetationIndexGraph
     */
    readonly fieldId: string

    /**
     * Start date of the requested period.
     * @type {string}
     * @memberof Class390ApiSen4VegetationIndexGraph
     */
    readonly startDate: string

    /**
     * End date of the requested period.
     * @type {string}
     * @memberof Class390ApiSen4VegetationIndexGraph
     */
    readonly endDate: string

    /**
     * Index type for which to return data.
     * @type {Sen4IndexType}
     * @memberof Class390ApiSen4VegetationIndexGraph
     */
    readonly indexType: Sen4IndexType

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class390ApiSen4VegetationIndexGraph
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class390ApiSen4VegetationIndexGraph
     */
    readonly developerPayload?: string

    /**
     * Compute historical mean depending on aggregate (D/M/Y) for a given period. Not available for hourly aggregate.
     * @type {Sen4VegetationIndexStatistics}
     * @memberof Class390ApiSen4VegetationIndexGraph
     */
    readonly statistics?: Sen4VegetationIndexStatistics
}

/**
 * Request parameters for weatherAggregate operation in Class390Api.
 * @export
 * @interface Class390ApiWeatherAggregateRequest
 */
export interface Class390ApiWeatherAggregateRequest {
    /**
     * Latitude of point to query.
     * @type {number}
     * @memberof Class390ApiWeatherAggregate
     */
    readonly latitude: number

    /**
     * Longitude of point to query.
     * @type {number}
     * @memberof Class390ApiWeatherAggregate
     */
    readonly longitude: number

    /**
     * Start date of the requested period.
     * @type {string}
     * @memberof Class390ApiWeatherAggregate
     */
    readonly startDate: string

    /**
     * End date of the requested period.
     * @type {string}
     * @memberof Class390ApiWeatherAggregate
     */
    readonly endDate: string

    /**
     * Weather variables to include in response.                        Note: &#x60;Temperature2mMin&#x60;, &#x60;Temperature2mMax&#x60;, &#x60;HeatUnit&#x60; and &#x60;PrecipitationAccumulated&#x60; not available for hourly aggregate.                         &#x60;DirectRadiation&#x60; not available in daily, weekly and monthly aggregate
     * @type {Array<'Temperature2m' | 'Temperature2mMin' | 'Temperature2mMax' | 'Precipitation' | 'WindSpeed10m' | 'SoilTemperatureSurface' | 'SoilMoistureSurface' | 'ShortwaveRadiation' | 'Et0FaoEvapotranspiration' | 'HeatUnit' | 'PrecipitationAccumulated' | 'PrecipitationProbability' | 'WeatherCode' | 'RelativeHumidity2m' | 'SurfacePressure' | 'CloudCover' | 'DirectRadiation'>}
     * @memberof Class390ApiWeatherAggregate
     */
    readonly variables: Array<'Temperature2m' | 'Temperature2mMin' | 'Temperature2mMax' | 'Precipitation' | 'WindSpeed10m' | 'SoilTemperatureSurface' | 'SoilMoistureSurface' | 'ShortwaveRadiation' | 'Et0FaoEvapotranspiration' | 'HeatUnit' | 'PrecipitationAccumulated' | 'PrecipitationProbability' | 'WeatherCode' | 'RelativeHumidity2m' | 'SurfacePressure' | 'CloudCover' | 'DirectRadiation'>

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class390ApiWeatherAggregate
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class390ApiWeatherAggregate
     */
    readonly developerPayload?: string

    /**
     * Data aggregation.
     * @type {'Hourly' | 'Daily' | 'Monthly' | 'Yearly'}
     * @memberof Class390ApiWeatherAggregate
     */
    readonly aggregate?: 'Hourly' | 'Daily' | 'Monthly' | 'Yearly'

    /**
     * Compute historical mean depending on aggregate (D/M/Y) for a given period. Not available for hourly aggregate.
     * @type {'No' | 'MeanY5'}
     * @memberof Class390ApiWeatherAggregate
     */
    readonly statistics?: 'No' | 'MeanY5'

    /**
     * Crop-specific base temperature for heat unit calculation in degrees celsius.
     * @type {number}
     * @memberof Class390ApiWeatherAggregate
     */
    readonly tempBaseCrop?: number

    /**
     * Start date from which to accumulate heat units / accumulated precipitation. If not provided startDate is used.
     * @type {string}
     * @memberof Class390ApiWeatherAggregate
     */
    readonly dateAccumulate?: string
}

/**
 * Class390Api - object-oriented interface
 * @export
 * @class Class390Api
 * @extends {BaseAPI}
 */
export class Class390Api extends BaseAPI {
    /**
     * Returns the vegetation index graph for a specific fieldId and date range. The field must have a contour defined.
     * @param {Class390ApiSen4VegetationIndexGraphRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class390Api
     */
    public sen4VegetationIndexGraph(requestParameters: Class390ApiSen4VegetationIndexGraphRequest, options?: AxiosRequestConfig) {
        return Class390ApiFp(this.configuration).sen4VegetationIndexGraph(requestParameters.fieldId, requestParameters.startDate, requestParameters.endDate, requestParameters.indexType, requestParameters.version, requestParameters.developerPayload, requestParameters.statistics, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns weather information for a specific point and date range.                       Performance Note: Performance depends on time range, number /                        type of variables selected and aggregate/statistics type.                        Please test the requests you intend to run for performance and limit time range as needed.                        Requests taking longer than 30 seconds will fail.                         Note: When testing for performance please use different gps points as data is cached in the                          weather backend.
     * @param {Class390ApiWeatherAggregateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class390Api
     */
    public weatherAggregate(requestParameters: Class390ApiWeatherAggregateRequest, options?: AxiosRequestConfig) {
        return Class390ApiFp(this.configuration).weatherAggregate(requestParameters.latitude, requestParameters.longitude, requestParameters.startDate, requestParameters.endDate, requestParameters.variables, requestParameters.version, requestParameters.developerPayload, requestParameters.aggregate, requestParameters.statistics, requestParameters.tempBaseCrop, requestParameters.dateAccumulate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * Class400Api - axios parameter creator
 * @export
 */
export const Class400ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete one or more datura drone assessment.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daturaDroneAssessmentDelete: async (objectIds: string, version?: string, developerPayload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectIds' is not null or undefined
            assertParamExists('daturaDroneAssessmentDelete', 'objectIds', objectIds)
            const localVarPath = `/datura/droneAssessments/{objectIds}`
                .replace(`{${"objectIds"}}`, encodeURIComponent(String(objectIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load all datura drone assessments for a `processOrderId`.
         * @param {string} processOrderId The id of the process order for which all datura drone assessments should be returned
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daturaDroneAssessmentList: async (processOrderId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processOrderId' is not null or undefined
            assertParamExists('daturaDroneAssessmentList', 'processOrderId', processOrderId)
            const localVarPath = `/datura/droneAssessments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }

            if (processOrderId !== undefined) {
                localVarQueryParameter['processOrderId'] = processOrderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load one ore more datura drone assessments by id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daturaDroneAssessmentObjects: async (objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectIds' is not null or undefined
            assertParamExists('daturaDroneAssessmentObjects', 'objectIds', objectIds)
            const localVarPath = `/datura/droneAssessments/{objectIds}`
                .replace(`{${"objectIds"}}`, encodeURIComponent(String(objectIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update one or more datura drone assessment.
         * @param {DaturaDroneAssessmentPatchRequest} daturaDroneAssessmentPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daturaDroneAssessmentPatch: async (daturaDroneAssessmentPatchRequest: DaturaDroneAssessmentPatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'daturaDroneAssessmentPatchRequest' is not null or undefined
            assertParamExists('daturaDroneAssessmentPatch', 'daturaDroneAssessmentPatchRequest', daturaDroneAssessmentPatchRequest)
            const localVarPath = `/datura/droneAssessments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(daturaDroneAssessmentPatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load all datura import jobs for a `processOrderId`.
         * @param {string} processOrderId The id of the process order for which all datura import jobs should be returned
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daturaImportJobList: async (processOrderId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processOrderId' is not null or undefined
            assertParamExists('daturaImportJobList', 'processOrderId', processOrderId)
            const localVarPath = `/datura/importJobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }

            if (processOrderId !== undefined) {
                localVarQueryParameter['processOrderId'] = processOrderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load one ore more datura import jobs by job id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daturaImportJobObjects: async (objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectIds' is not null or undefined
            assertParamExists('daturaImportJobObjects', 'objectIds', objectIds)
            const localVarPath = `/datura/importJobs/{objectIds}`
                .replace(`{${"objectIds"}}`, encodeURIComponent(String(objectIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update one or more datura import jobs. Internal use only.
         * @param {DaturaImportJobPatchRequest} daturaImportJobPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daturaImportJobPatch: async (daturaImportJobPatchRequest: DaturaImportJobPatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'daturaImportJobPatchRequest' is not null or undefined
            assertParamExists('daturaImportJobPatch', 'daturaImportJobPatchRequest', daturaImportJobPatchRequest)
            const localVarPath = `/datura/importJobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokAPIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(daturaImportJobPatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create one or more import job results (drone assessments). Internal use only.
         * @param {DaturaImportJobResultsPutRequest} daturaImportJobResultsPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daturaImportJobResultsPut: async (daturaImportJobResultsPutRequest: DaturaImportJobResultsPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'daturaImportJobResultsPutRequest' is not null or undefined
            assertParamExists('daturaImportJobResultsPut', 'daturaImportJobResultsPutRequest', daturaImportJobResultsPutRequest)
            const localVarPath = `/datura/importJobs/results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokAPIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(daturaImportJobResultsPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Begins a new datura import job, returns the import job including urls for file upload.              Note that `id` must be a new unique id, it is not possible to modify existing import jobs.
         * @param {DaturaImportJobStartRequest} [daturaImportJobStartRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daturaImportJobStart: async (daturaImportJobStartRequest?: DaturaImportJobStartRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/datura/importJobs/start`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(daturaImportJobStartRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload endpoint for chunked file uploads. Note that concurrent requests to this endpoint with the same uploadId are _not_ possible, uploads must be sequential.                  Chunks should be multiples of 256 KB. Send chunks in order, starting at the beginning of the file at position 0.                For the next chunk use the upper value of the `Range` header from the response of the previous chunk.                Repeat until all chunks have been uploaded.                  *Note:* Upload urls are valid for a limited time only (currently one week). Inactive incomplete uploads will be deleted after that time.
         * @param {string} uploadId The id of the upload
         * @param {string} contentLength Number of bytes to upload with this request, 0 to query current status.
         * @param {string} contentRange Either \&quot;bytes &#x60;firstByteOfChunk&#x60;-&#x60;lastByteOfChunk&#x60;|&#x60;combinedUploadSize&#x60;\&quot; for uploading chunks or \&quot;bytes *|&#x60;combinedUploadSize&#x60;\&quot; to query the status of an upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile: async (uploadId: string, contentLength: string, contentRange: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadId' is not null or undefined
            assertParamExists('uploadFile', 'uploadId', uploadId)
            // verify required parameter 'contentLength' is not null or undefined
            assertParamExists('uploadFile', 'contentLength', contentLength)
            // verify required parameter 'contentRange' is not null or undefined
            assertParamExists('uploadFile', 'contentRange', contentRange)
            const localVarPath = `/upload/file/{uploadId}`
                .replace(`{${"uploadId"}}`, encodeURIComponent(String(uploadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contentLength != null) {
                localVarHeaderParameter['Content-Length'] = String(contentLength);
            }

            if (contentRange != null) {
                localVarHeaderParameter['Content-Range'] = String(contentRange);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Class400Api - functional programming interface
 * @export
 */
export const Class400ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Class400ApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete one or more datura drone assessment.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async daturaDroneAssessmentDelete(objectIds: string, version?: string, developerPayload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DaturaDroneAssessmentDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.daturaDroneAssessmentDelete(objectIds, version, developerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load all datura drone assessments for a `processOrderId`.
         * @param {string} processOrderId The id of the process order for which all datura drone assessments should be returned
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async daturaDroneAssessmentList(processOrderId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DaturaDroneAssessmentObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.daturaDroneAssessmentList(processOrderId, version, deleted, pageKey, developerPayload, properties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load one ore more datura drone assessments by id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async daturaDroneAssessmentObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DaturaDroneAssessmentObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.daturaDroneAssessmentObjects(objectIds, version, deleted, pageKey, developerPayload, properties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update one or more datura drone assessment.
         * @param {DaturaDroneAssessmentPatchRequest} daturaDroneAssessmentPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async daturaDroneAssessmentPatch(daturaDroneAssessmentPatchRequest: DaturaDroneAssessmentPatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DaturaDroneAssessmentPatch200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.daturaDroneAssessmentPatch(daturaDroneAssessmentPatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load all datura import jobs for a `processOrderId`.
         * @param {string} processOrderId The id of the process order for which all datura import jobs should be returned
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async daturaImportJobList(processOrderId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DaturaImportJobObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.daturaImportJobList(processOrderId, version, deleted, pageKey, developerPayload, properties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load one ore more datura import jobs by job id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async daturaImportJobObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DaturaImportJobObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.daturaImportJobObjects(objectIds, version, deleted, pageKey, developerPayload, properties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update one or more datura import jobs. Internal use only.
         * @param {DaturaImportJobPatchRequest} daturaImportJobPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async daturaImportJobPatch(daturaImportJobPatchRequest: DaturaImportJobPatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DaturaImportJobPatch200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.daturaImportJobPatch(daturaImportJobPatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create one or more import job results (drone assessments). Internal use only.
         * @param {DaturaImportJobResultsPutRequest} daturaImportJobResultsPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async daturaImportJobResultsPut(daturaImportJobResultsPutRequest: DaturaImportJobResultsPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DaturaDroneAssessmentPatch200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.daturaImportJobResultsPut(daturaImportJobResultsPutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Begins a new datura import job, returns the import job including urls for file upload.              Note that `id` must be a new unique id, it is not possible to modify existing import jobs.
         * @param {DaturaImportJobStartRequest} [daturaImportJobStartRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async daturaImportJobStart(daturaImportJobStartRequest?: DaturaImportJobStartRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DaturaImportJobStart200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.daturaImportJobStart(daturaImportJobStartRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Upload endpoint for chunked file uploads. Note that concurrent requests to this endpoint with the same uploadId are _not_ possible, uploads must be sequential.                  Chunks should be multiples of 256 KB. Send chunks in order, starting at the beginning of the file at position 0.                For the next chunk use the upper value of the `Range` header from the response of the previous chunk.                Repeat until all chunks have been uploaded.                  *Note:* Upload urls are valid for a limited time only (currently one week). Inactive incomplete uploads will be deleted after that time.
         * @param {string} uploadId The id of the upload
         * @param {string} contentLength Number of bytes to upload with this request, 0 to query current status.
         * @param {string} contentRange Either \&quot;bytes &#x60;firstByteOfChunk&#x60;-&#x60;lastByteOfChunk&#x60;|&#x60;combinedUploadSize&#x60;\&quot; for uploading chunks or \&quot;bytes *|&#x60;combinedUploadSize&#x60;\&quot; to query the status of an upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFile(uploadId: string, contentLength: string, contentRange: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityImportStoreMappings200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFile(uploadId, contentLength, contentRange, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * Class400Api - factory interface
 * @export
 */
export const Class400ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Class400ApiFp(configuration)
    return {
        /**
         * Delete one or more datura drone assessment.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daturaDroneAssessmentDelete(objectIds: string, version?: string, developerPayload?: string, options?: any): AxiosPromise<DaturaDroneAssessmentDelete200Response> {
            return localVarFp.daturaDroneAssessmentDelete(objectIds, version, developerPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Load all datura drone assessments for a `processOrderId`.
         * @param {string} processOrderId The id of the process order for which all datura drone assessments should be returned
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daturaDroneAssessmentList(processOrderId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: any): AxiosPromise<DaturaDroneAssessmentObjects200Response> {
            return localVarFp.daturaDroneAssessmentList(processOrderId, version, deleted, pageKey, developerPayload, properties, options).then((request) => request(axios, basePath));
        },
        /**
         * Load one ore more datura drone assessments by id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daturaDroneAssessmentObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: any): AxiosPromise<DaturaDroneAssessmentObjects200Response> {
            return localVarFp.daturaDroneAssessmentObjects(objectIds, version, deleted, pageKey, developerPayload, properties, options).then((request) => request(axios, basePath));
        },
        /**
         * Update one or more datura drone assessment.
         * @param {DaturaDroneAssessmentPatchRequest} daturaDroneAssessmentPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daturaDroneAssessmentPatch(daturaDroneAssessmentPatchRequest: DaturaDroneAssessmentPatchRequest, options?: any): AxiosPromise<DaturaDroneAssessmentPatch200Response> {
            return localVarFp.daturaDroneAssessmentPatch(daturaDroneAssessmentPatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Load all datura import jobs for a `processOrderId`.
         * @param {string} processOrderId The id of the process order for which all datura import jobs should be returned
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daturaImportJobList(processOrderId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: any): AxiosPromise<DaturaImportJobObjects200Response> {
            return localVarFp.daturaImportJobList(processOrderId, version, deleted, pageKey, developerPayload, properties, options).then((request) => request(axios, basePath));
        },
        /**
         * Load one ore more datura import jobs by job id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daturaImportJobObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: any): AxiosPromise<DaturaImportJobObjects200Response> {
            return localVarFp.daturaImportJobObjects(objectIds, version, deleted, pageKey, developerPayload, properties, options).then((request) => request(axios, basePath));
        },
        /**
         * Update one or more datura import jobs. Internal use only.
         * @param {DaturaImportJobPatchRequest} daturaImportJobPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daturaImportJobPatch(daturaImportJobPatchRequest: DaturaImportJobPatchRequest, options?: any): AxiosPromise<DaturaImportJobPatch200Response> {
            return localVarFp.daturaImportJobPatch(daturaImportJobPatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create one or more import job results (drone assessments). Internal use only.
         * @param {DaturaImportJobResultsPutRequest} daturaImportJobResultsPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daturaImportJobResultsPut(daturaImportJobResultsPutRequest: DaturaImportJobResultsPutRequest, options?: any): AxiosPromise<DaturaDroneAssessmentPatch200Response> {
            return localVarFp.daturaImportJobResultsPut(daturaImportJobResultsPutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Begins a new datura import job, returns the import job including urls for file upload.              Note that `id` must be a new unique id, it is not possible to modify existing import jobs.
         * @param {DaturaImportJobStartRequest} [daturaImportJobStartRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daturaImportJobStart(daturaImportJobStartRequest?: DaturaImportJobStartRequest, options?: any): AxiosPromise<DaturaImportJobStart200Response> {
            return localVarFp.daturaImportJobStart(daturaImportJobStartRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload endpoint for chunked file uploads. Note that concurrent requests to this endpoint with the same uploadId are _not_ possible, uploads must be sequential.                  Chunks should be multiples of 256 KB. Send chunks in order, starting at the beginning of the file at position 0.                For the next chunk use the upper value of the `Range` header from the response of the previous chunk.                Repeat until all chunks have been uploaded.                  *Note:* Upload urls are valid for a limited time only (currently one week). Inactive incomplete uploads will be deleted after that time.
         * @param {string} uploadId The id of the upload
         * @param {string} contentLength Number of bytes to upload with this request, 0 to query current status.
         * @param {string} contentRange Either \&quot;bytes &#x60;firstByteOfChunk&#x60;-&#x60;lastByteOfChunk&#x60;|&#x60;combinedUploadSize&#x60;\&quot; for uploading chunks or \&quot;bytes *|&#x60;combinedUploadSize&#x60;\&quot; to query the status of an upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(uploadId: string, contentLength: string, contentRange: string, options?: any): AxiosPromise<ActivityImportStoreMappings200Response> {
            return localVarFp.uploadFile(uploadId, contentLength, contentRange, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for daturaDroneAssessmentDelete operation in Class400Api.
 * @export
 * @interface Class400ApiDaturaDroneAssessmentDeleteRequest
 */
export interface Class400ApiDaturaDroneAssessmentDeleteRequest {
    /**
     * A comma sperated list of object ids.
     * @type {string}
     * @memberof Class400ApiDaturaDroneAssessmentDelete
     */
    readonly objectIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class400ApiDaturaDroneAssessmentDelete
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class400ApiDaturaDroneAssessmentDelete
     */
    readonly developerPayload?: string
}

/**
 * Request parameters for daturaDroneAssessmentList operation in Class400Api.
 * @export
 * @interface Class400ApiDaturaDroneAssessmentListRequest
 */
export interface Class400ApiDaturaDroneAssessmentListRequest {
    /**
     * The id of the process order for which all datura drone assessments should be returned
     * @type {string}
     * @memberof Class400ApiDaturaDroneAssessmentList
     */
    readonly processOrderId: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class400ApiDaturaDroneAssessmentList
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof Class400ApiDaturaDroneAssessmentList
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof Class400ApiDaturaDroneAssessmentList
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class400ApiDaturaDroneAssessmentList
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof Class400ApiDaturaDroneAssessmentList
     */
    readonly properties?: string
}

/**
 * Request parameters for daturaDroneAssessmentObjects operation in Class400Api.
 * @export
 * @interface Class400ApiDaturaDroneAssessmentObjectsRequest
 */
export interface Class400ApiDaturaDroneAssessmentObjectsRequest {
    /**
     * Comma seperated list of object ids, use this to request specific objects.
     * @type {string}
     * @memberof Class400ApiDaturaDroneAssessmentObjects
     */
    readonly objectIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class400ApiDaturaDroneAssessmentObjects
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof Class400ApiDaturaDroneAssessmentObjects
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof Class400ApiDaturaDroneAssessmentObjects
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class400ApiDaturaDroneAssessmentObjects
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof Class400ApiDaturaDroneAssessmentObjects
     */
    readonly properties?: string
}

/**
 * Request parameters for daturaDroneAssessmentPatch operation in Class400Api.
 * @export
 * @interface Class400ApiDaturaDroneAssessmentPatchRequest
 */
export interface Class400ApiDaturaDroneAssessmentPatchRequest {
    /**
     * 
     * @type {DaturaDroneAssessmentPatchRequest}
     * @memberof Class400ApiDaturaDroneAssessmentPatch
     */
    readonly daturaDroneAssessmentPatchRequest: DaturaDroneAssessmentPatchRequest
}

/**
 * Request parameters for daturaImportJobList operation in Class400Api.
 * @export
 * @interface Class400ApiDaturaImportJobListRequest
 */
export interface Class400ApiDaturaImportJobListRequest {
    /**
     * The id of the process order for which all datura import jobs should be returned
     * @type {string}
     * @memberof Class400ApiDaturaImportJobList
     */
    readonly processOrderId: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class400ApiDaturaImportJobList
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof Class400ApiDaturaImportJobList
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof Class400ApiDaturaImportJobList
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class400ApiDaturaImportJobList
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof Class400ApiDaturaImportJobList
     */
    readonly properties?: string
}

/**
 * Request parameters for daturaImportJobObjects operation in Class400Api.
 * @export
 * @interface Class400ApiDaturaImportJobObjectsRequest
 */
export interface Class400ApiDaturaImportJobObjectsRequest {
    /**
     * Comma seperated list of object ids, use this to request specific objects.
     * @type {string}
     * @memberof Class400ApiDaturaImportJobObjects
     */
    readonly objectIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Class400ApiDaturaImportJobObjects
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof Class400ApiDaturaImportJobObjects
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof Class400ApiDaturaImportJobObjects
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Class400ApiDaturaImportJobObjects
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof Class400ApiDaturaImportJobObjects
     */
    readonly properties?: string
}

/**
 * Request parameters for daturaImportJobPatch operation in Class400Api.
 * @export
 * @interface Class400ApiDaturaImportJobPatchRequest
 */
export interface Class400ApiDaturaImportJobPatchRequest {
    /**
     * 
     * @type {DaturaImportJobPatchRequest}
     * @memberof Class400ApiDaturaImportJobPatch
     */
    readonly daturaImportJobPatchRequest: DaturaImportJobPatchRequest
}

/**
 * Request parameters for daturaImportJobResultsPut operation in Class400Api.
 * @export
 * @interface Class400ApiDaturaImportJobResultsPutRequest
 */
export interface Class400ApiDaturaImportJobResultsPutRequest {
    /**
     * 
     * @type {DaturaImportJobResultsPutRequest}
     * @memberof Class400ApiDaturaImportJobResultsPut
     */
    readonly daturaImportJobResultsPutRequest: DaturaImportJobResultsPutRequest
}

/**
 * Request parameters for daturaImportJobStart operation in Class400Api.
 * @export
 * @interface Class400ApiDaturaImportJobStartRequest
 */
export interface Class400ApiDaturaImportJobStartRequest {
    /**
     * 
     * @type {DaturaImportJobStartRequest}
     * @memberof Class400ApiDaturaImportJobStart
     */
    readonly daturaImportJobStartRequest?: DaturaImportJobStartRequest
}

/**
 * Request parameters for uploadFile operation in Class400Api.
 * @export
 * @interface Class400ApiUploadFileRequest
 */
export interface Class400ApiUploadFileRequest {
    /**
     * The id of the upload
     * @type {string}
     * @memberof Class400ApiUploadFile
     */
    readonly uploadId: string

    /**
     * Number of bytes to upload with this request, 0 to query current status.
     * @type {string}
     * @memberof Class400ApiUploadFile
     */
    readonly contentLength: string

    /**
     * Either \&quot;bytes &#x60;firstByteOfChunk&#x60;-&#x60;lastByteOfChunk&#x60;|&#x60;combinedUploadSize&#x60;\&quot; for uploading chunks or \&quot;bytes *|&#x60;combinedUploadSize&#x60;\&quot; to query the status of an upload.
     * @type {string}
     * @memberof Class400ApiUploadFile
     */
    readonly contentRange: string
}

/**
 * Class400Api - object-oriented interface
 * @export
 * @class Class400Api
 * @extends {BaseAPI}
 */
export class Class400Api extends BaseAPI {
    /**
     * Delete one or more datura drone assessment.
     * @param {Class400ApiDaturaDroneAssessmentDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class400Api
     */
    public daturaDroneAssessmentDelete(requestParameters: Class400ApiDaturaDroneAssessmentDeleteRequest, options?: AxiosRequestConfig) {
        return Class400ApiFp(this.configuration).daturaDroneAssessmentDelete(requestParameters.objectIds, requestParameters.version, requestParameters.developerPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load all datura drone assessments for a `processOrderId`.
     * @param {Class400ApiDaturaDroneAssessmentListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class400Api
     */
    public daturaDroneAssessmentList(requestParameters: Class400ApiDaturaDroneAssessmentListRequest, options?: AxiosRequestConfig) {
        return Class400ApiFp(this.configuration).daturaDroneAssessmentList(requestParameters.processOrderId, requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load one ore more datura drone assessments by id.
     * @param {Class400ApiDaturaDroneAssessmentObjectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class400Api
     */
    public daturaDroneAssessmentObjects(requestParameters: Class400ApiDaturaDroneAssessmentObjectsRequest, options?: AxiosRequestConfig) {
        return Class400ApiFp(this.configuration).daturaDroneAssessmentObjects(requestParameters.objectIds, requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update one or more datura drone assessment.
     * @param {Class400ApiDaturaDroneAssessmentPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class400Api
     */
    public daturaDroneAssessmentPatch(requestParameters: Class400ApiDaturaDroneAssessmentPatchRequest, options?: AxiosRequestConfig) {
        return Class400ApiFp(this.configuration).daturaDroneAssessmentPatch(requestParameters.daturaDroneAssessmentPatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load all datura import jobs for a `processOrderId`.
     * @param {Class400ApiDaturaImportJobListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class400Api
     */
    public daturaImportJobList(requestParameters: Class400ApiDaturaImportJobListRequest, options?: AxiosRequestConfig) {
        return Class400ApiFp(this.configuration).daturaImportJobList(requestParameters.processOrderId, requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load one ore more datura import jobs by job id.
     * @param {Class400ApiDaturaImportJobObjectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class400Api
     */
    public daturaImportJobObjects(requestParameters: Class400ApiDaturaImportJobObjectsRequest, options?: AxiosRequestConfig) {
        return Class400ApiFp(this.configuration).daturaImportJobObjects(requestParameters.objectIds, requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update one or more datura import jobs. Internal use only.
     * @param {Class400ApiDaturaImportJobPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class400Api
     */
    public daturaImportJobPatch(requestParameters: Class400ApiDaturaImportJobPatchRequest, options?: AxiosRequestConfig) {
        return Class400ApiFp(this.configuration).daturaImportJobPatch(requestParameters.daturaImportJobPatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create one or more import job results (drone assessments). Internal use only.
     * @param {Class400ApiDaturaImportJobResultsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class400Api
     */
    public daturaImportJobResultsPut(requestParameters: Class400ApiDaturaImportJobResultsPutRequest, options?: AxiosRequestConfig) {
        return Class400ApiFp(this.configuration).daturaImportJobResultsPut(requestParameters.daturaImportJobResultsPutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Begins a new datura import job, returns the import job including urls for file upload.              Note that `id` must be a new unique id, it is not possible to modify existing import jobs.
     * @param {Class400ApiDaturaImportJobStartRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class400Api
     */
    public daturaImportJobStart(requestParameters: Class400ApiDaturaImportJobStartRequest = {}, options?: AxiosRequestConfig) {
        return Class400ApiFp(this.configuration).daturaImportJobStart(requestParameters.daturaImportJobStartRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload endpoint for chunked file uploads. Note that concurrent requests to this endpoint with the same uploadId are _not_ possible, uploads must be sequential.                  Chunks should be multiples of 256 KB. Send chunks in order, starting at the beginning of the file at position 0.                For the next chunk use the upper value of the `Range` header from the response of the previous chunk.                Repeat until all chunks have been uploaded.                  *Note:* Upload urls are valid for a limited time only (currently one week). Inactive incomplete uploads will be deleted after that time.
     * @param {Class400ApiUploadFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class400Api
     */
    public uploadFile(requestParameters: Class400ApiUploadFileRequest, options?: AxiosRequestConfig) {
        return Class400ApiFp(this.configuration).uploadFile(requestParameters.uploadId, requestParameters.contentLength, requestParameters.contentRange, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CnhApi - axios parameter creator
 * @export
 */
export const CnhApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the current Cnh connection status.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interfacesCnhConnectionStatus: async (version?: string, developerPayload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/external/cnh/connectionStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Starts CNH task download process. Tasks are downloaded as ISOXML files for later import.          Returns the same response as `/activitiesImport/status`.         To poll the current status of the download process use `/activitiesImport/status`.         Note that a user is not allowed to start a new download while a previous download initiated by this user is          still in progress. If a download is already in progress an error response with the errorCode `inProgress` is          returned.            To cancel a download that is already in progress use `/activitiesImport/reset`         
         * @param {InterfacesCnhDownloadTasksRequest} [interfacesCnhDownloadTasksRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interfacesCnhDownloadTasks: async (interfacesCnhDownloadTasksRequest?: InterfacesCnhDownloadTasksRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/external/cnh/downloadTasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(interfacesCnhDownloadTasksRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Complete CNH OAuth2 login using supplied code. If successfull the resulting refresh token is              stored for the current user allowing the user to perform CNH API requests.
         * @param {InterfacesCnhLoginRequest} [interfacesCnhLoginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interfacesCnhLogin: async (interfacesCnhLoginRequest?: InterfacesCnhLoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/external/cnh/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(interfacesCnhLoginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the CNH authentication information linked to the current users account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interfacesCnhLogout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/external/cnh/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all companies, growers, farmers and fields in an hirarchial list.                        In case the authorization fails (no linked account, token expired, ...)                        `errorCode` `externalApiAuthorization` is returned.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interfacesCnhOverview: async (version?: string, developerPayload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/external/cnh/overview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all tasks for a company. To get the companyId call `/external/cnh/overview`.                        Note that the same task id can be returned multiple times with different fieldIds.                        In case the authorization fails (no linked account, token expired, ...)                        `errorCode` `externalApiAuthorization` is returned.
         * @param {string} companyId The id of the CNH company for which all tasks should be returned.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interfacesCnhTasks: async (companyId: string, version?: string, developerPayload?: string, pageKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('interfacesCnhTasks', 'companyId', companyId)
            const localVarPath = `/external/cnh/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CnhApi - functional programming interface
 * @export
 */
export const CnhApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CnhApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the current Cnh connection status.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async interfacesCnhConnectionStatus(version?: string, developerPayload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InterfacesCnhConnectionStatus200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.interfacesCnhConnectionStatus(version, developerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Starts CNH task download process. Tasks are downloaded as ISOXML files for later import.          Returns the same response as `/activitiesImport/status`.         To poll the current status of the download process use `/activitiesImport/status`.         Note that a user is not allowed to start a new download while a previous download initiated by this user is          still in progress. If a download is already in progress an error response with the errorCode `inProgress` is          returned.            To cancel a download that is already in progress use `/activitiesImport/reset`         
         * @param {InterfacesCnhDownloadTasksRequest} [interfacesCnhDownloadTasksRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async interfacesCnhDownloadTasks(interfacesCnhDownloadTasksRequest?: InterfacesCnhDownloadTasksRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InterfacesCnhDownloadTasks200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.interfacesCnhDownloadTasks(interfacesCnhDownloadTasksRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Complete CNH OAuth2 login using supplied code. If successfull the resulting refresh token is              stored for the current user allowing the user to perform CNH API requests.
         * @param {InterfacesCnhLoginRequest} [interfacesCnhLoginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async interfacesCnhLogin(interfacesCnhLoginRequest?: InterfacesCnhLoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityImportStoreMappings200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.interfacesCnhLogin(interfacesCnhLoginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes the CNH authentication information linked to the current users account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async interfacesCnhLogout(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityImportStoreMappings200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.interfacesCnhLogout(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all companies, growers, farmers and fields in an hirarchial list.                        In case the authorization fails (no linked account, token expired, ...)                        `errorCode` `externalApiAuthorization` is returned.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async interfacesCnhOverview(version?: string, developerPayload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InterfacesCnhOverview200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.interfacesCnhOverview(version, developerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all tasks for a company. To get the companyId call `/external/cnh/overview`.                        Note that the same task id can be returned multiple times with different fieldIds.                        In case the authorization fails (no linked account, token expired, ...)                        `errorCode` `externalApiAuthorization` is returned.
         * @param {string} companyId The id of the CNH company for which all tasks should be returned.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async interfacesCnhTasks(companyId: string, version?: string, developerPayload?: string, pageKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InterfacesCnhTasks200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.interfacesCnhTasks(companyId, version, developerPayload, pageKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CnhApi - factory interface
 * @export
 */
export const CnhApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CnhApiFp(configuration)
    return {
        /**
         * Returns the current Cnh connection status.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interfacesCnhConnectionStatus(version?: string, developerPayload?: string, options?: any): AxiosPromise<InterfacesCnhConnectionStatus200Response> {
            return localVarFp.interfacesCnhConnectionStatus(version, developerPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Starts CNH task download process. Tasks are downloaded as ISOXML files for later import.          Returns the same response as `/activitiesImport/status`.         To poll the current status of the download process use `/activitiesImport/status`.         Note that a user is not allowed to start a new download while a previous download initiated by this user is          still in progress. If a download is already in progress an error response with the errorCode `inProgress` is          returned.            To cancel a download that is already in progress use `/activitiesImport/reset`         
         * @param {InterfacesCnhDownloadTasksRequest} [interfacesCnhDownloadTasksRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interfacesCnhDownloadTasks(interfacesCnhDownloadTasksRequest?: InterfacesCnhDownloadTasksRequest, options?: any): AxiosPromise<InterfacesCnhDownloadTasks200Response> {
            return localVarFp.interfacesCnhDownloadTasks(interfacesCnhDownloadTasksRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Complete CNH OAuth2 login using supplied code. If successfull the resulting refresh token is              stored for the current user allowing the user to perform CNH API requests.
         * @param {InterfacesCnhLoginRequest} [interfacesCnhLoginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interfacesCnhLogin(interfacesCnhLoginRequest?: InterfacesCnhLoginRequest, options?: any): AxiosPromise<ActivityImportStoreMappings200Response> {
            return localVarFp.interfacesCnhLogin(interfacesCnhLoginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the CNH authentication information linked to the current users account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interfacesCnhLogout(options?: any): AxiosPromise<ActivityImportStoreMappings200Response> {
            return localVarFp.interfacesCnhLogout(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all companies, growers, farmers and fields in an hirarchial list.                        In case the authorization fails (no linked account, token expired, ...)                        `errorCode` `externalApiAuthorization` is returned.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interfacesCnhOverview(version?: string, developerPayload?: string, options?: any): AxiosPromise<InterfacesCnhOverview200Response> {
            return localVarFp.interfacesCnhOverview(version, developerPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all tasks for a company. To get the companyId call `/external/cnh/overview`.                        Note that the same task id can be returned multiple times with different fieldIds.                        In case the authorization fails (no linked account, token expired, ...)                        `errorCode` `externalApiAuthorization` is returned.
         * @param {string} companyId The id of the CNH company for which all tasks should be returned.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interfacesCnhTasks(companyId: string, version?: string, developerPayload?: string, pageKey?: string, options?: any): AxiosPromise<InterfacesCnhTasks200Response> {
            return localVarFp.interfacesCnhTasks(companyId, version, developerPayload, pageKey, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for interfacesCnhConnectionStatus operation in CnhApi.
 * @export
 * @interface CnhApiInterfacesCnhConnectionStatusRequest
 */
export interface CnhApiInterfacesCnhConnectionStatusRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof CnhApiInterfacesCnhConnectionStatus
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof CnhApiInterfacesCnhConnectionStatus
     */
    readonly developerPayload?: string
}

/**
 * Request parameters for interfacesCnhDownloadTasks operation in CnhApi.
 * @export
 * @interface CnhApiInterfacesCnhDownloadTasksRequest
 */
export interface CnhApiInterfacesCnhDownloadTasksRequest {
    /**
     * 
     * @type {InterfacesCnhDownloadTasksRequest}
     * @memberof CnhApiInterfacesCnhDownloadTasks
     */
    readonly interfacesCnhDownloadTasksRequest?: InterfacesCnhDownloadTasksRequest
}

/**
 * Request parameters for interfacesCnhLogin operation in CnhApi.
 * @export
 * @interface CnhApiInterfacesCnhLoginRequest
 */
export interface CnhApiInterfacesCnhLoginRequest {
    /**
     * 
     * @type {InterfacesCnhLoginRequest}
     * @memberof CnhApiInterfacesCnhLogin
     */
    readonly interfacesCnhLoginRequest?: InterfacesCnhLoginRequest
}

/**
 * Request parameters for interfacesCnhOverview operation in CnhApi.
 * @export
 * @interface CnhApiInterfacesCnhOverviewRequest
 */
export interface CnhApiInterfacesCnhOverviewRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof CnhApiInterfacesCnhOverview
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof CnhApiInterfacesCnhOverview
     */
    readonly developerPayload?: string
}

/**
 * Request parameters for interfacesCnhTasks operation in CnhApi.
 * @export
 * @interface CnhApiInterfacesCnhTasksRequest
 */
export interface CnhApiInterfacesCnhTasksRequest {
    /**
     * The id of the CNH company for which all tasks should be returned.
     * @type {string}
     * @memberof CnhApiInterfacesCnhTasks
     */
    readonly companyId: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof CnhApiInterfacesCnhTasks
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof CnhApiInterfacesCnhTasks
     */
    readonly developerPayload?: string

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof CnhApiInterfacesCnhTasks
     */
    readonly pageKey?: string
}

/**
 * CnhApi - object-oriented interface
 * @export
 * @class CnhApi
 * @extends {BaseAPI}
 */
export class CnhApi extends BaseAPI {
    /**
     * Returns the current Cnh connection status.
     * @param {CnhApiInterfacesCnhConnectionStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CnhApi
     */
    public interfacesCnhConnectionStatus(requestParameters: CnhApiInterfacesCnhConnectionStatusRequest = {}, options?: AxiosRequestConfig) {
        return CnhApiFp(this.configuration).interfacesCnhConnectionStatus(requestParameters.version, requestParameters.developerPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Starts CNH task download process. Tasks are downloaded as ISOXML files for later import.          Returns the same response as `/activitiesImport/status`.         To poll the current status of the download process use `/activitiesImport/status`.         Note that a user is not allowed to start a new download while a previous download initiated by this user is          still in progress. If a download is already in progress an error response with the errorCode `inProgress` is          returned.            To cancel a download that is already in progress use `/activitiesImport/reset`         
     * @param {CnhApiInterfacesCnhDownloadTasksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CnhApi
     */
    public interfacesCnhDownloadTasks(requestParameters: CnhApiInterfacesCnhDownloadTasksRequest = {}, options?: AxiosRequestConfig) {
        return CnhApiFp(this.configuration).interfacesCnhDownloadTasks(requestParameters.interfacesCnhDownloadTasksRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Complete CNH OAuth2 login using supplied code. If successfull the resulting refresh token is              stored for the current user allowing the user to perform CNH API requests.
     * @param {CnhApiInterfacesCnhLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CnhApi
     */
    public interfacesCnhLogin(requestParameters: CnhApiInterfacesCnhLoginRequest = {}, options?: AxiosRequestConfig) {
        return CnhApiFp(this.configuration).interfacesCnhLogin(requestParameters.interfacesCnhLoginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the CNH authentication information linked to the current users account.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CnhApi
     */
    public interfacesCnhLogout(options?: AxiosRequestConfig) {
        return CnhApiFp(this.configuration).interfacesCnhLogout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all companies, growers, farmers and fields in an hirarchial list.                        In case the authorization fails (no linked account, token expired, ...)                        `errorCode` `externalApiAuthorization` is returned.
     * @param {CnhApiInterfacesCnhOverviewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CnhApi
     */
    public interfacesCnhOverview(requestParameters: CnhApiInterfacesCnhOverviewRequest = {}, options?: AxiosRequestConfig) {
        return CnhApiFp(this.configuration).interfacesCnhOverview(requestParameters.version, requestParameters.developerPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all tasks for a company. To get the companyId call `/external/cnh/overview`.                        Note that the same task id can be returned multiple times with different fieldIds.                        In case the authorization fails (no linked account, token expired, ...)                        `errorCode` `externalApiAuthorization` is returned.
     * @param {CnhApiInterfacesCnhTasksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CnhApi
     */
    public interfacesCnhTasks(requestParameters: CnhApiInterfacesCnhTasksRequest, options?: AxiosRequestConfig) {
        return CnhApiFp(this.configuration).interfacesCnhTasks(requestParameters.companyId, requestParameters.version, requestParameters.developerPayload, requestParameters.pageKey, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CultivationPlanApi - axios parameter creator
 * @export
 */
export const CultivationPlanApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Load austrian cultivation plan data by field id. Note: currently only returns expected yield,            for use with the terrazo API.            Will eventually replace the legacy cultivation plan API and return all cultivation plan data.
         * @param {string} fieldIds A comma seperated list of field ids for which to return cultivation plan data.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cultivationPlanAtList: async (fieldIds: string, version?: string, developerPayload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fieldIds' is not null or undefined
            assertParamExists('cultivationPlanAtList', 'fieldIds', fieldIds)
            const localVarPath = `/cultivationPlan/At/{fieldIds}`
                .replace(`{${"fieldIds"}}`, encodeURIComponent(String(fieldIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CultivationPlanApi - functional programming interface
 * @export
 */
export const CultivationPlanApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CultivationPlanApiAxiosParamCreator(configuration)
    return {
        /**
         * Load austrian cultivation plan data by field id. Note: currently only returns expected yield,            for use with the terrazo API.            Will eventually replace the legacy cultivation plan API and return all cultivation plan data.
         * @param {string} fieldIds A comma seperated list of field ids for which to return cultivation plan data.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cultivationPlanAtList(fieldIds: string, version?: string, developerPayload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CultivationPlanAtList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cultivationPlanAtList(fieldIds, version, developerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CultivationPlanApi - factory interface
 * @export
 */
export const CultivationPlanApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CultivationPlanApiFp(configuration)
    return {
        /**
         * Load austrian cultivation plan data by field id. Note: currently only returns expected yield,            for use with the terrazo API.            Will eventually replace the legacy cultivation plan API and return all cultivation plan data.
         * @param {string} fieldIds A comma seperated list of field ids for which to return cultivation plan data.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cultivationPlanAtList(fieldIds: string, version?: string, developerPayload?: string, options?: any): AxiosPromise<CultivationPlanAtList200Response> {
            return localVarFp.cultivationPlanAtList(fieldIds, version, developerPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for cultivationPlanAtList operation in CultivationPlanApi.
 * @export
 * @interface CultivationPlanApiCultivationPlanAtListRequest
 */
export interface CultivationPlanApiCultivationPlanAtListRequest {
    /**
     * A comma seperated list of field ids for which to return cultivation plan data.
     * @type {string}
     * @memberof CultivationPlanApiCultivationPlanAtList
     */
    readonly fieldIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof CultivationPlanApiCultivationPlanAtList
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof CultivationPlanApiCultivationPlanAtList
     */
    readonly developerPayload?: string
}

/**
 * CultivationPlanApi - object-oriented interface
 * @export
 * @class CultivationPlanApi
 * @extends {BaseAPI}
 */
export class CultivationPlanApi extends BaseAPI {
    /**
     * Load austrian cultivation plan data by field id. Note: currently only returns expected yield,            for use with the terrazo API.            Will eventually replace the legacy cultivation plan API and return all cultivation plan data.
     * @param {CultivationPlanApiCultivationPlanAtListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CultivationPlanApi
     */
    public cultivationPlanAtList(requestParameters: CultivationPlanApiCultivationPlanAtListRequest, options?: AxiosRequestConfig) {
        return CultivationPlanApiFp(this.configuration).cultivationPlanAtList(requestParameters.fieldIds, requestParameters.version, requestParameters.developerPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DaturaApi - axios parameter creator
 * @export
 */
export const DaturaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete one or more datura drone assessment.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daturaDroneAssessmentDelete: async (objectIds: string, version?: string, developerPayload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectIds' is not null or undefined
            assertParamExists('daturaDroneAssessmentDelete', 'objectIds', objectIds)
            const localVarPath = `/datura/droneAssessments/{objectIds}`
                .replace(`{${"objectIds"}}`, encodeURIComponent(String(objectIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load all datura drone assessments for a `processOrderId`.
         * @param {string} processOrderId The id of the process order for which all datura drone assessments should be returned
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daturaDroneAssessmentList: async (processOrderId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processOrderId' is not null or undefined
            assertParamExists('daturaDroneAssessmentList', 'processOrderId', processOrderId)
            const localVarPath = `/datura/droneAssessments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }

            if (processOrderId !== undefined) {
                localVarQueryParameter['processOrderId'] = processOrderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load one ore more datura drone assessments by id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daturaDroneAssessmentObjects: async (objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectIds' is not null or undefined
            assertParamExists('daturaDroneAssessmentObjects', 'objectIds', objectIds)
            const localVarPath = `/datura/droneAssessments/{objectIds}`
                .replace(`{${"objectIds"}}`, encodeURIComponent(String(objectIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update one or more datura drone assessment.
         * @param {DaturaDroneAssessmentPatchRequest} daturaDroneAssessmentPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daturaDroneAssessmentPatch: async (daturaDroneAssessmentPatchRequest: DaturaDroneAssessmentPatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'daturaDroneAssessmentPatchRequest' is not null or undefined
            assertParamExists('daturaDroneAssessmentPatch', 'daturaDroneAssessmentPatchRequest', daturaDroneAssessmentPatchRequest)
            const localVarPath = `/datura/droneAssessments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(daturaDroneAssessmentPatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load all datura import jobs for a `processOrderId`.
         * @param {string} processOrderId The id of the process order for which all datura import jobs should be returned
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daturaImportJobList: async (processOrderId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processOrderId' is not null or undefined
            assertParamExists('daturaImportJobList', 'processOrderId', processOrderId)
            const localVarPath = `/datura/importJobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }

            if (processOrderId !== undefined) {
                localVarQueryParameter['processOrderId'] = processOrderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load one ore more datura import jobs by job id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daturaImportJobObjects: async (objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectIds' is not null or undefined
            assertParamExists('daturaImportJobObjects', 'objectIds', objectIds)
            const localVarPath = `/datura/importJobs/{objectIds}`
                .replace(`{${"objectIds"}}`, encodeURIComponent(String(objectIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update one or more datura import jobs. Internal use only.
         * @param {DaturaImportJobPatchRequest} daturaImportJobPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daturaImportJobPatch: async (daturaImportJobPatchRequest: DaturaImportJobPatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'daturaImportJobPatchRequest' is not null or undefined
            assertParamExists('daturaImportJobPatch', 'daturaImportJobPatchRequest', daturaImportJobPatchRequest)
            const localVarPath = `/datura/importJobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokAPIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(daturaImportJobPatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create one or more import job results (drone assessments). Internal use only.
         * @param {DaturaImportJobResultsPutRequest} daturaImportJobResultsPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daturaImportJobResultsPut: async (daturaImportJobResultsPutRequest: DaturaImportJobResultsPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'daturaImportJobResultsPutRequest' is not null or undefined
            assertParamExists('daturaImportJobResultsPut', 'daturaImportJobResultsPutRequest', daturaImportJobResultsPutRequest)
            const localVarPath = `/datura/importJobs/results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokAPIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(daturaImportJobResultsPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Begins a new datura import job, returns the import job including urls for file upload.              Note that `id` must be a new unique id, it is not possible to modify existing import jobs.
         * @param {DaturaImportJobStartRequest} [daturaImportJobStartRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daturaImportJobStart: async (daturaImportJobStartRequest?: DaturaImportJobStartRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/datura/importJobs/start`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(daturaImportJobStartRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DaturaApi - functional programming interface
 * @export
 */
export const DaturaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DaturaApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete one or more datura drone assessment.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async daturaDroneAssessmentDelete(objectIds: string, version?: string, developerPayload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DaturaDroneAssessmentDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.daturaDroneAssessmentDelete(objectIds, version, developerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load all datura drone assessments for a `processOrderId`.
         * @param {string} processOrderId The id of the process order for which all datura drone assessments should be returned
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async daturaDroneAssessmentList(processOrderId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DaturaDroneAssessmentObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.daturaDroneAssessmentList(processOrderId, version, deleted, pageKey, developerPayload, properties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load one ore more datura drone assessments by id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async daturaDroneAssessmentObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DaturaDroneAssessmentObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.daturaDroneAssessmentObjects(objectIds, version, deleted, pageKey, developerPayload, properties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update one or more datura drone assessment.
         * @param {DaturaDroneAssessmentPatchRequest} daturaDroneAssessmentPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async daturaDroneAssessmentPatch(daturaDroneAssessmentPatchRequest: DaturaDroneAssessmentPatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DaturaDroneAssessmentPatch200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.daturaDroneAssessmentPatch(daturaDroneAssessmentPatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load all datura import jobs for a `processOrderId`.
         * @param {string} processOrderId The id of the process order for which all datura import jobs should be returned
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async daturaImportJobList(processOrderId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DaturaImportJobObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.daturaImportJobList(processOrderId, version, deleted, pageKey, developerPayload, properties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load one ore more datura import jobs by job id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async daturaImportJobObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DaturaImportJobObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.daturaImportJobObjects(objectIds, version, deleted, pageKey, developerPayload, properties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update one or more datura import jobs. Internal use only.
         * @param {DaturaImportJobPatchRequest} daturaImportJobPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async daturaImportJobPatch(daturaImportJobPatchRequest: DaturaImportJobPatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DaturaImportJobPatch200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.daturaImportJobPatch(daturaImportJobPatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create one or more import job results (drone assessments). Internal use only.
         * @param {DaturaImportJobResultsPutRequest} daturaImportJobResultsPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async daturaImportJobResultsPut(daturaImportJobResultsPutRequest: DaturaImportJobResultsPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DaturaDroneAssessmentPatch200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.daturaImportJobResultsPut(daturaImportJobResultsPutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Begins a new datura import job, returns the import job including urls for file upload.              Note that `id` must be a new unique id, it is not possible to modify existing import jobs.
         * @param {DaturaImportJobStartRequest} [daturaImportJobStartRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async daturaImportJobStart(daturaImportJobStartRequest?: DaturaImportJobStartRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DaturaImportJobStart200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.daturaImportJobStart(daturaImportJobStartRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DaturaApi - factory interface
 * @export
 */
export const DaturaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DaturaApiFp(configuration)
    return {
        /**
         * Delete one or more datura drone assessment.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daturaDroneAssessmentDelete(objectIds: string, version?: string, developerPayload?: string, options?: any): AxiosPromise<DaturaDroneAssessmentDelete200Response> {
            return localVarFp.daturaDroneAssessmentDelete(objectIds, version, developerPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Load all datura drone assessments for a `processOrderId`.
         * @param {string} processOrderId The id of the process order for which all datura drone assessments should be returned
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daturaDroneAssessmentList(processOrderId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: any): AxiosPromise<DaturaDroneAssessmentObjects200Response> {
            return localVarFp.daturaDroneAssessmentList(processOrderId, version, deleted, pageKey, developerPayload, properties, options).then((request) => request(axios, basePath));
        },
        /**
         * Load one ore more datura drone assessments by id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daturaDroneAssessmentObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: any): AxiosPromise<DaturaDroneAssessmentObjects200Response> {
            return localVarFp.daturaDroneAssessmentObjects(objectIds, version, deleted, pageKey, developerPayload, properties, options).then((request) => request(axios, basePath));
        },
        /**
         * Update one or more datura drone assessment.
         * @param {DaturaDroneAssessmentPatchRequest} daturaDroneAssessmentPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daturaDroneAssessmentPatch(daturaDroneAssessmentPatchRequest: DaturaDroneAssessmentPatchRequest, options?: any): AxiosPromise<DaturaDroneAssessmentPatch200Response> {
            return localVarFp.daturaDroneAssessmentPatch(daturaDroneAssessmentPatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Load all datura import jobs for a `processOrderId`.
         * @param {string} processOrderId The id of the process order for which all datura import jobs should be returned
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daturaImportJobList(processOrderId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: any): AxiosPromise<DaturaImportJobObjects200Response> {
            return localVarFp.daturaImportJobList(processOrderId, version, deleted, pageKey, developerPayload, properties, options).then((request) => request(axios, basePath));
        },
        /**
         * Load one ore more datura import jobs by job id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daturaImportJobObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: any): AxiosPromise<DaturaImportJobObjects200Response> {
            return localVarFp.daturaImportJobObjects(objectIds, version, deleted, pageKey, developerPayload, properties, options).then((request) => request(axios, basePath));
        },
        /**
         * Update one or more datura import jobs. Internal use only.
         * @param {DaturaImportJobPatchRequest} daturaImportJobPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daturaImportJobPatch(daturaImportJobPatchRequest: DaturaImportJobPatchRequest, options?: any): AxiosPromise<DaturaImportJobPatch200Response> {
            return localVarFp.daturaImportJobPatch(daturaImportJobPatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create one or more import job results (drone assessments). Internal use only.
         * @param {DaturaImportJobResultsPutRequest} daturaImportJobResultsPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daturaImportJobResultsPut(daturaImportJobResultsPutRequest: DaturaImportJobResultsPutRequest, options?: any): AxiosPromise<DaturaDroneAssessmentPatch200Response> {
            return localVarFp.daturaImportJobResultsPut(daturaImportJobResultsPutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Begins a new datura import job, returns the import job including urls for file upload.              Note that `id` must be a new unique id, it is not possible to modify existing import jobs.
         * @param {DaturaImportJobStartRequest} [daturaImportJobStartRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daturaImportJobStart(daturaImportJobStartRequest?: DaturaImportJobStartRequest, options?: any): AxiosPromise<DaturaImportJobStart200Response> {
            return localVarFp.daturaImportJobStart(daturaImportJobStartRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for daturaDroneAssessmentDelete operation in DaturaApi.
 * @export
 * @interface DaturaApiDaturaDroneAssessmentDeleteRequest
 */
export interface DaturaApiDaturaDroneAssessmentDeleteRequest {
    /**
     * A comma sperated list of object ids.
     * @type {string}
     * @memberof DaturaApiDaturaDroneAssessmentDelete
     */
    readonly objectIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof DaturaApiDaturaDroneAssessmentDelete
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof DaturaApiDaturaDroneAssessmentDelete
     */
    readonly developerPayload?: string
}

/**
 * Request parameters for daturaDroneAssessmentList operation in DaturaApi.
 * @export
 * @interface DaturaApiDaturaDroneAssessmentListRequest
 */
export interface DaturaApiDaturaDroneAssessmentListRequest {
    /**
     * The id of the process order for which all datura drone assessments should be returned
     * @type {string}
     * @memberof DaturaApiDaturaDroneAssessmentList
     */
    readonly processOrderId: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof DaturaApiDaturaDroneAssessmentList
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof DaturaApiDaturaDroneAssessmentList
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof DaturaApiDaturaDroneAssessmentList
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof DaturaApiDaturaDroneAssessmentList
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof DaturaApiDaturaDroneAssessmentList
     */
    readonly properties?: string
}

/**
 * Request parameters for daturaDroneAssessmentObjects operation in DaturaApi.
 * @export
 * @interface DaturaApiDaturaDroneAssessmentObjectsRequest
 */
export interface DaturaApiDaturaDroneAssessmentObjectsRequest {
    /**
     * Comma seperated list of object ids, use this to request specific objects.
     * @type {string}
     * @memberof DaturaApiDaturaDroneAssessmentObjects
     */
    readonly objectIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof DaturaApiDaturaDroneAssessmentObjects
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof DaturaApiDaturaDroneAssessmentObjects
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof DaturaApiDaturaDroneAssessmentObjects
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof DaturaApiDaturaDroneAssessmentObjects
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof DaturaApiDaturaDroneAssessmentObjects
     */
    readonly properties?: string
}

/**
 * Request parameters for daturaDroneAssessmentPatch operation in DaturaApi.
 * @export
 * @interface DaturaApiDaturaDroneAssessmentPatchRequest
 */
export interface DaturaApiDaturaDroneAssessmentPatchRequest {
    /**
     * 
     * @type {DaturaDroneAssessmentPatchRequest}
     * @memberof DaturaApiDaturaDroneAssessmentPatch
     */
    readonly daturaDroneAssessmentPatchRequest: DaturaDroneAssessmentPatchRequest
}

/**
 * Request parameters for daturaImportJobList operation in DaturaApi.
 * @export
 * @interface DaturaApiDaturaImportJobListRequest
 */
export interface DaturaApiDaturaImportJobListRequest {
    /**
     * The id of the process order for which all datura import jobs should be returned
     * @type {string}
     * @memberof DaturaApiDaturaImportJobList
     */
    readonly processOrderId: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof DaturaApiDaturaImportJobList
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof DaturaApiDaturaImportJobList
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof DaturaApiDaturaImportJobList
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof DaturaApiDaturaImportJobList
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof DaturaApiDaturaImportJobList
     */
    readonly properties?: string
}

/**
 * Request parameters for daturaImportJobObjects operation in DaturaApi.
 * @export
 * @interface DaturaApiDaturaImportJobObjectsRequest
 */
export interface DaturaApiDaturaImportJobObjectsRequest {
    /**
     * Comma seperated list of object ids, use this to request specific objects.
     * @type {string}
     * @memberof DaturaApiDaturaImportJobObjects
     */
    readonly objectIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof DaturaApiDaturaImportJobObjects
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof DaturaApiDaturaImportJobObjects
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof DaturaApiDaturaImportJobObjects
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof DaturaApiDaturaImportJobObjects
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof DaturaApiDaturaImportJobObjects
     */
    readonly properties?: string
}

/**
 * Request parameters for daturaImportJobPatch operation in DaturaApi.
 * @export
 * @interface DaturaApiDaturaImportJobPatchRequest
 */
export interface DaturaApiDaturaImportJobPatchRequest {
    /**
     * 
     * @type {DaturaImportJobPatchRequest}
     * @memberof DaturaApiDaturaImportJobPatch
     */
    readonly daturaImportJobPatchRequest: DaturaImportJobPatchRequest
}

/**
 * Request parameters for daturaImportJobResultsPut operation in DaturaApi.
 * @export
 * @interface DaturaApiDaturaImportJobResultsPutRequest
 */
export interface DaturaApiDaturaImportJobResultsPutRequest {
    /**
     * 
     * @type {DaturaImportJobResultsPutRequest}
     * @memberof DaturaApiDaturaImportJobResultsPut
     */
    readonly daturaImportJobResultsPutRequest: DaturaImportJobResultsPutRequest
}

/**
 * Request parameters for daturaImportJobStart operation in DaturaApi.
 * @export
 * @interface DaturaApiDaturaImportJobStartRequest
 */
export interface DaturaApiDaturaImportJobStartRequest {
    /**
     * 
     * @type {DaturaImportJobStartRequest}
     * @memberof DaturaApiDaturaImportJobStart
     */
    readonly daturaImportJobStartRequest?: DaturaImportJobStartRequest
}

/**
 * DaturaApi - object-oriented interface
 * @export
 * @class DaturaApi
 * @extends {BaseAPI}
 */
export class DaturaApi extends BaseAPI {
    /**
     * Delete one or more datura drone assessment.
     * @param {DaturaApiDaturaDroneAssessmentDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DaturaApi
     */
    public daturaDroneAssessmentDelete(requestParameters: DaturaApiDaturaDroneAssessmentDeleteRequest, options?: AxiosRequestConfig) {
        return DaturaApiFp(this.configuration).daturaDroneAssessmentDelete(requestParameters.objectIds, requestParameters.version, requestParameters.developerPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load all datura drone assessments for a `processOrderId`.
     * @param {DaturaApiDaturaDroneAssessmentListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DaturaApi
     */
    public daturaDroneAssessmentList(requestParameters: DaturaApiDaturaDroneAssessmentListRequest, options?: AxiosRequestConfig) {
        return DaturaApiFp(this.configuration).daturaDroneAssessmentList(requestParameters.processOrderId, requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load one ore more datura drone assessments by id.
     * @param {DaturaApiDaturaDroneAssessmentObjectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DaturaApi
     */
    public daturaDroneAssessmentObjects(requestParameters: DaturaApiDaturaDroneAssessmentObjectsRequest, options?: AxiosRequestConfig) {
        return DaturaApiFp(this.configuration).daturaDroneAssessmentObjects(requestParameters.objectIds, requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update one or more datura drone assessment.
     * @param {DaturaApiDaturaDroneAssessmentPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DaturaApi
     */
    public daturaDroneAssessmentPatch(requestParameters: DaturaApiDaturaDroneAssessmentPatchRequest, options?: AxiosRequestConfig) {
        return DaturaApiFp(this.configuration).daturaDroneAssessmentPatch(requestParameters.daturaDroneAssessmentPatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load all datura import jobs for a `processOrderId`.
     * @param {DaturaApiDaturaImportJobListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DaturaApi
     */
    public daturaImportJobList(requestParameters: DaturaApiDaturaImportJobListRequest, options?: AxiosRequestConfig) {
        return DaturaApiFp(this.configuration).daturaImportJobList(requestParameters.processOrderId, requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load one ore more datura import jobs by job id.
     * @param {DaturaApiDaturaImportJobObjectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DaturaApi
     */
    public daturaImportJobObjects(requestParameters: DaturaApiDaturaImportJobObjectsRequest, options?: AxiosRequestConfig) {
        return DaturaApiFp(this.configuration).daturaImportJobObjects(requestParameters.objectIds, requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update one or more datura import jobs. Internal use only.
     * @param {DaturaApiDaturaImportJobPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DaturaApi
     */
    public daturaImportJobPatch(requestParameters: DaturaApiDaturaImportJobPatchRequest, options?: AxiosRequestConfig) {
        return DaturaApiFp(this.configuration).daturaImportJobPatch(requestParameters.daturaImportJobPatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create one or more import job results (drone assessments). Internal use only.
     * @param {DaturaApiDaturaImportJobResultsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DaturaApi
     */
    public daturaImportJobResultsPut(requestParameters: DaturaApiDaturaImportJobResultsPutRequest, options?: AxiosRequestConfig) {
        return DaturaApiFp(this.configuration).daturaImportJobResultsPut(requestParameters.daturaImportJobResultsPutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Begins a new datura import job, returns the import job including urls for file upload.              Note that `id` must be a new unique id, it is not possible to modify existing import jobs.
     * @param {DaturaApiDaturaImportJobStartRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DaturaApi
     */
    public daturaImportJobStart(requestParameters: DaturaApiDaturaImportJobStartRequest = {}, options?: AxiosRequestConfig) {
        return DaturaApiFp(this.configuration).daturaImportJobStart(requestParameters.daturaImportJobStartRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EquipmentApi - axios parameter creator
 * @export
 */
export const EquipmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete one or more equipment.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentDelete: async (objectIds: string, version?: string, developerPayload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectIds' is not null or undefined
            assertParamExists('equipmentDelete', 'objectIds', objectIds)
            const localVarPath = `/equipment/{objectIds}`
                .replace(`{${"objectIds"}}`, encodeURIComponent(String(objectIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load a list of objects
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [companyId] The id of the company for which all equipment should be returned,              if not set or null global equipment are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentList: async (version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, companyId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/equipment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load one ore more equipment by equipment id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentObjects: async (objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectIds' is not null or undefined
            assertParamExists('equipmentObjects', 'objectIds', objectIds)
            const localVarPath = `/equipment/{objectIds}`
                .replace(`{${"objectIds"}}`, encodeURIComponent(String(objectIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update equipment.
         * @param {EquipmentPatchRequest} equipmentPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentPatch: async (equipmentPatchRequest: EquipmentPatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'equipmentPatchRequest' is not null or undefined
            assertParamExists('equipmentPatch', 'equipmentPatchRequest', equipmentPatchRequest)
            const localVarPath = `/equipment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(equipmentPatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create or update equipment.
         * @param {EquipmentPutRequest} equipmentPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentPut: async (equipmentPutRequest: EquipmentPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'equipmentPutRequest' is not null or undefined
            assertParamExists('equipmentPut', 'equipmentPutRequest', equipmentPutRequest)
            const localVarPath = `/equipment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(equipmentPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Restore one or more equipment.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentUndelete: async (objectIds: string, version?: string, developerPayload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectIds' is not null or undefined
            assertParamExists('equipmentUndelete', 'objectIds', objectIds)
            const localVarPath = `/equipment/undelete/{objectIds}`
                .replace(`{${"objectIds"}}`, encodeURIComponent(String(objectIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EquipmentApi - functional programming interface
 * @export
 */
export const EquipmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EquipmentApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete one or more equipment.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equipmentDelete(objectIds: string, version?: string, developerPayload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equipmentDelete(objectIds, version, developerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load a list of objects
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [companyId] The id of the company for which all equipment should be returned,              if not set or null global equipment are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equipmentList(version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, companyId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equipmentList(version, deleted, pageKey, developerPayload, properties, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load one ore more equipment by equipment id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equipmentObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equipmentObjects(objectIds, version, deleted, pageKey, developerPayload, properties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update equipment.
         * @param {EquipmentPatchRequest} equipmentPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equipmentPatch(equipmentPatchRequest: EquipmentPatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentPut200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equipmentPatch(equipmentPatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create or update equipment.
         * @param {EquipmentPutRequest} equipmentPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equipmentPut(equipmentPutRequest: EquipmentPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentPut200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equipmentPut(equipmentPutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Restore one or more equipment.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equipmentUndelete(objectIds: string, version?: string, developerPayload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentUndelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equipmentUndelete(objectIds, version, developerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EquipmentApi - factory interface
 * @export
 */
export const EquipmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EquipmentApiFp(configuration)
    return {
        /**
         * Delete one or more equipment.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentDelete(objectIds: string, version?: string, developerPayload?: string, options?: any): AxiosPromise<EquipmentDelete200Response> {
            return localVarFp.equipmentDelete(objectIds, version, developerPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Load a list of objects
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [companyId] The id of the company for which all equipment should be returned,              if not set or null global equipment are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentList(version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, companyId?: string, options?: any): AxiosPromise<EquipmentObjects200Response> {
            return localVarFp.equipmentList(version, deleted, pageKey, developerPayload, properties, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Load one ore more equipment by equipment id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: any): AxiosPromise<EquipmentObjects200Response> {
            return localVarFp.equipmentObjects(objectIds, version, deleted, pageKey, developerPayload, properties, options).then((request) => request(axios, basePath));
        },
        /**
         * Update equipment.
         * @param {EquipmentPatchRequest} equipmentPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentPatch(equipmentPatchRequest: EquipmentPatchRequest, options?: any): AxiosPromise<EquipmentPut200Response> {
            return localVarFp.equipmentPatch(equipmentPatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create or update equipment.
         * @param {EquipmentPutRequest} equipmentPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentPut(equipmentPutRequest: EquipmentPutRequest, options?: any): AxiosPromise<EquipmentPut200Response> {
            return localVarFp.equipmentPut(equipmentPutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Restore one or more equipment.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentUndelete(objectIds: string, version?: string, developerPayload?: string, options?: any): AxiosPromise<EquipmentUndelete200Response> {
            return localVarFp.equipmentUndelete(objectIds, version, developerPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for equipmentDelete operation in EquipmentApi.
 * @export
 * @interface EquipmentApiEquipmentDeleteRequest
 */
export interface EquipmentApiEquipmentDeleteRequest {
    /**
     * A comma sperated list of object ids.
     * @type {string}
     * @memberof EquipmentApiEquipmentDelete
     */
    readonly objectIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof EquipmentApiEquipmentDelete
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof EquipmentApiEquipmentDelete
     */
    readonly developerPayload?: string
}

/**
 * Request parameters for equipmentList operation in EquipmentApi.
 * @export
 * @interface EquipmentApiEquipmentListRequest
 */
export interface EquipmentApiEquipmentListRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof EquipmentApiEquipmentList
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof EquipmentApiEquipmentList
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof EquipmentApiEquipmentList
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof EquipmentApiEquipmentList
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof EquipmentApiEquipmentList
     */
    readonly properties?: string

    /**
     * The id of the company for which all equipment should be returned,              if not set or null global equipment are returned.
     * @type {string}
     * @memberof EquipmentApiEquipmentList
     */
    readonly companyId?: string
}

/**
 * Request parameters for equipmentObjects operation in EquipmentApi.
 * @export
 * @interface EquipmentApiEquipmentObjectsRequest
 */
export interface EquipmentApiEquipmentObjectsRequest {
    /**
     * Comma seperated list of object ids, use this to request specific objects.
     * @type {string}
     * @memberof EquipmentApiEquipmentObjects
     */
    readonly objectIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof EquipmentApiEquipmentObjects
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof EquipmentApiEquipmentObjects
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof EquipmentApiEquipmentObjects
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof EquipmentApiEquipmentObjects
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof EquipmentApiEquipmentObjects
     */
    readonly properties?: string
}

/**
 * Request parameters for equipmentPatch operation in EquipmentApi.
 * @export
 * @interface EquipmentApiEquipmentPatchRequest
 */
export interface EquipmentApiEquipmentPatchRequest {
    /**
     * 
     * @type {EquipmentPatchRequest}
     * @memberof EquipmentApiEquipmentPatch
     */
    readonly equipmentPatchRequest: EquipmentPatchRequest
}

/**
 * Request parameters for equipmentPut operation in EquipmentApi.
 * @export
 * @interface EquipmentApiEquipmentPutRequest
 */
export interface EquipmentApiEquipmentPutRequest {
    /**
     * 
     * @type {EquipmentPutRequest}
     * @memberof EquipmentApiEquipmentPut
     */
    readonly equipmentPutRequest: EquipmentPutRequest
}

/**
 * Request parameters for equipmentUndelete operation in EquipmentApi.
 * @export
 * @interface EquipmentApiEquipmentUndeleteRequest
 */
export interface EquipmentApiEquipmentUndeleteRequest {
    /**
     * A comma sperated list of object ids.
     * @type {string}
     * @memberof EquipmentApiEquipmentUndelete
     */
    readonly objectIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof EquipmentApiEquipmentUndelete
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof EquipmentApiEquipmentUndelete
     */
    readonly developerPayload?: string
}

/**
 * EquipmentApi - object-oriented interface
 * @export
 * @class EquipmentApi
 * @extends {BaseAPI}
 */
export class EquipmentApi extends BaseAPI {
    /**
     * Delete one or more equipment.
     * @param {EquipmentApiEquipmentDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public equipmentDelete(requestParameters: EquipmentApiEquipmentDeleteRequest, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).equipmentDelete(requestParameters.objectIds, requestParameters.version, requestParameters.developerPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load a list of objects
     * @param {EquipmentApiEquipmentListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public equipmentList(requestParameters: EquipmentApiEquipmentListRequest = {}, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).equipmentList(requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, requestParameters.companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load one ore more equipment by equipment id.
     * @param {EquipmentApiEquipmentObjectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public equipmentObjects(requestParameters: EquipmentApiEquipmentObjectsRequest, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).equipmentObjects(requestParameters.objectIds, requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update equipment.
     * @param {EquipmentApiEquipmentPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public equipmentPatch(requestParameters: EquipmentApiEquipmentPatchRequest, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).equipmentPatch(requestParameters.equipmentPatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create or update equipment.
     * @param {EquipmentApiEquipmentPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public equipmentPut(requestParameters: EquipmentApiEquipmentPutRequest, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).equipmentPut(requestParameters.equipmentPutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Restore one or more equipment.
     * @param {EquipmentApiEquipmentUndeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public equipmentUndelete(requestParameters: EquipmentApiEquipmentUndeleteRequest, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).equipmentUndelete(requestParameters.objectIds, requestParameters.version, requestParameters.developerPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FertilizationPlanApi - axios parameter creator
 * @export
 */
export const FertilizationPlanApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Load fertilization plan data by field id. Note: currently only returns limited data,            for use with the terrazo API.            Will eventually replace the legacy fertilization plan API and return all fertilization plan data.
         * @param {string} fieldIds A comma seperated list of field ids for which to return fertilization plan data.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fertilizationPlanFieldBasedList: async (fieldIds: string, version?: string, developerPayload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fieldIds' is not null or undefined
            assertParamExists('fertilizationPlanFieldBasedList', 'fieldIds', fieldIds)
            const localVarPath = `/fertilizationPlan/fieldBased/{fieldIds}`
                .replace(`{${"fieldIds"}}`, encodeURIComponent(String(fieldIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FertilizationPlanApi - functional programming interface
 * @export
 */
export const FertilizationPlanApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FertilizationPlanApiAxiosParamCreator(configuration)
    return {
        /**
         * Load fertilization plan data by field id. Note: currently only returns limited data,            for use with the terrazo API.            Will eventually replace the legacy fertilization plan API and return all fertilization plan data.
         * @param {string} fieldIds A comma seperated list of field ids for which to return fertilization plan data.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fertilizationPlanFieldBasedList(fieldIds: string, version?: string, developerPayload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FertilizationPlanFieldBasedList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fertilizationPlanFieldBasedList(fieldIds, version, developerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FertilizationPlanApi - factory interface
 * @export
 */
export const FertilizationPlanApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FertilizationPlanApiFp(configuration)
    return {
        /**
         * Load fertilization plan data by field id. Note: currently only returns limited data,            for use with the terrazo API.            Will eventually replace the legacy fertilization plan API and return all fertilization plan data.
         * @param {string} fieldIds A comma seperated list of field ids for which to return fertilization plan data.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fertilizationPlanFieldBasedList(fieldIds: string, version?: string, developerPayload?: string, options?: any): AxiosPromise<FertilizationPlanFieldBasedList200Response> {
            return localVarFp.fertilizationPlanFieldBasedList(fieldIds, version, developerPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for fertilizationPlanFieldBasedList operation in FertilizationPlanApi.
 * @export
 * @interface FertilizationPlanApiFertilizationPlanFieldBasedListRequest
 */
export interface FertilizationPlanApiFertilizationPlanFieldBasedListRequest {
    /**
     * A comma seperated list of field ids for which to return fertilization plan data.
     * @type {string}
     * @memberof FertilizationPlanApiFertilizationPlanFieldBasedList
     */
    readonly fieldIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof FertilizationPlanApiFertilizationPlanFieldBasedList
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof FertilizationPlanApiFertilizationPlanFieldBasedList
     */
    readonly developerPayload?: string
}

/**
 * FertilizationPlanApi - object-oriented interface
 * @export
 * @class FertilizationPlanApi
 * @extends {BaseAPI}
 */
export class FertilizationPlanApi extends BaseAPI {
    /**
     * Load fertilization plan data by field id. Note: currently only returns limited data,            for use with the terrazo API.            Will eventually replace the legacy fertilization plan API and return all fertilization plan data.
     * @param {FertilizationPlanApiFertilizationPlanFieldBasedListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FertilizationPlanApi
     */
    public fertilizationPlanFieldBasedList(requestParameters: FertilizationPlanApiFertilizationPlanFieldBasedListRequest, options?: AxiosRequestConfig) {
        return FertilizationPlanApiFp(this.configuration).fertilizationPlanFieldBasedList(requestParameters.fieldIds, requestParameters.version, requestParameters.developerPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FieldsApi - axios parameter creator
 * @export
 */
export const FieldsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete one or more field contours.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldContourDelete: async (objectIds: string, version?: string, developerPayload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectIds' is not null or undefined
            assertParamExists('fieldContourDelete', 'objectIds', objectIds)
            const localVarPath = `/fieldContours/{objectIds}`
                .replace(`{${"objectIds"}}`, encodeURIComponent(String(objectIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load one or more field contours.             If the user does not have `GLOBAL_ALL_FIELDS` permissions only contours for active fields are returned.
         * @param {string} processOrderId The id of the process order for which all activity contours should be returned
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [permissionMode] Enable special permission modes
         * @param {string} [fieldSharingCompanyId] For &#x60;permissionMode&#x3D;fieldSharing&#x60;: The Id of the company for which to load data related to shared fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldContourList: async (processOrderId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, permissionMode?: string, fieldSharingCompanyId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processOrderId' is not null or undefined
            assertParamExists('fieldContourList', 'processOrderId', processOrderId)
            const localVarPath = `/fieldContours`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }

            if (processOrderId !== undefined) {
                localVarQueryParameter['processOrderId'] = processOrderId;
            }

            if (permissionMode !== undefined) {
                localVarQueryParameter['permissionMode'] = permissionMode;
            }

            if (fieldSharingCompanyId !== undefined) {
                localVarQueryParameter['fieldSharingCompanyId'] = fieldSharingCompanyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update one or more field contours.
         * @param {FieldContourPatchRequest} fieldContourPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldContourPatch: async (fieldContourPatchRequest: FieldContourPatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fieldContourPatchRequest' is not null or undefined
            assertParamExists('fieldContourPatch', 'fieldContourPatchRequest', fieldContourPatchRequest)
            const localVarPath = `/fieldContours`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fieldContourPatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create or replace one or more field contours.
         * @param {FieldContourPutRequest} fieldContourPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldContourPut: async (fieldContourPutRequest: FieldContourPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fieldContourPutRequest' is not null or undefined
            assertParamExists('fieldContourPut', 'fieldContourPutRequest', fieldContourPutRequest)
            const localVarPath = `/fieldContours`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fieldContourPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FieldsApi - functional programming interface
 * @export
 */
export const FieldsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FieldsApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete one or more field contours.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldContourDelete(objectIds: string, version?: string, developerPayload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldContourDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldContourDelete(objectIds, version, developerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load one or more field contours.             If the user does not have `GLOBAL_ALL_FIELDS` permissions only contours for active fields are returned.
         * @param {string} processOrderId The id of the process order for which all activity contours should be returned
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [permissionMode] Enable special permission modes
         * @param {string} [fieldSharingCompanyId] For &#x60;permissionMode&#x3D;fieldSharing&#x60;: The Id of the company for which to load data related to shared fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldContourList(processOrderId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, permissionMode?: string, fieldSharingCompanyId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldContourObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldContourList(processOrderId, version, deleted, pageKey, developerPayload, properties, permissionMode, fieldSharingCompanyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update one or more field contours.
         * @param {FieldContourPatchRequest} fieldContourPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldContourPatch(fieldContourPatchRequest: FieldContourPatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldContourPut200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldContourPatch(fieldContourPatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create or replace one or more field contours.
         * @param {FieldContourPutRequest} fieldContourPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldContourPut(fieldContourPutRequest: FieldContourPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldContourPut200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldContourPut(fieldContourPutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FieldsApi - factory interface
 * @export
 */
export const FieldsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FieldsApiFp(configuration)
    return {
        /**
         * Delete one or more field contours.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldContourDelete(objectIds: string, version?: string, developerPayload?: string, options?: any): AxiosPromise<FieldContourDelete200Response> {
            return localVarFp.fieldContourDelete(objectIds, version, developerPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Load one or more field contours.             If the user does not have `GLOBAL_ALL_FIELDS` permissions only contours for active fields are returned.
         * @param {string} processOrderId The id of the process order for which all activity contours should be returned
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [permissionMode] Enable special permission modes
         * @param {string} [fieldSharingCompanyId] For &#x60;permissionMode&#x3D;fieldSharing&#x60;: The Id of the company for which to load data related to shared fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldContourList(processOrderId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, permissionMode?: string, fieldSharingCompanyId?: string, options?: any): AxiosPromise<FieldContourObjects200Response> {
            return localVarFp.fieldContourList(processOrderId, version, deleted, pageKey, developerPayload, properties, permissionMode, fieldSharingCompanyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update one or more field contours.
         * @param {FieldContourPatchRequest} fieldContourPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldContourPatch(fieldContourPatchRequest: FieldContourPatchRequest, options?: any): AxiosPromise<FieldContourPut200Response> {
            return localVarFp.fieldContourPatch(fieldContourPatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create or replace one or more field contours.
         * @param {FieldContourPutRequest} fieldContourPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldContourPut(fieldContourPutRequest: FieldContourPutRequest, options?: any): AxiosPromise<FieldContourPut200Response> {
            return localVarFp.fieldContourPut(fieldContourPutRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for fieldContourDelete operation in FieldsApi.
 * @export
 * @interface FieldsApiFieldContourDeleteRequest
 */
export interface FieldsApiFieldContourDeleteRequest {
    /**
     * A comma sperated list of object ids.
     * @type {string}
     * @memberof FieldsApiFieldContourDelete
     */
    readonly objectIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof FieldsApiFieldContourDelete
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof FieldsApiFieldContourDelete
     */
    readonly developerPayload?: string
}

/**
 * Request parameters for fieldContourList operation in FieldsApi.
 * @export
 * @interface FieldsApiFieldContourListRequest
 */
export interface FieldsApiFieldContourListRequest {
    /**
     * The id of the process order for which all activity contours should be returned
     * @type {string}
     * @memberof FieldsApiFieldContourList
     */
    readonly processOrderId: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof FieldsApiFieldContourList
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof FieldsApiFieldContourList
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof FieldsApiFieldContourList
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof FieldsApiFieldContourList
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof FieldsApiFieldContourList
     */
    readonly properties?: string

    /**
     * Enable special permission modes
     * @type {string}
     * @memberof FieldsApiFieldContourList
     */
    readonly permissionMode?: string

    /**
     * For &#x60;permissionMode&#x3D;fieldSharing&#x60;: The Id of the company for which to load data related to shared fields.
     * @type {string}
     * @memberof FieldsApiFieldContourList
     */
    readonly fieldSharingCompanyId?: string
}

/**
 * Request parameters for fieldContourPatch operation in FieldsApi.
 * @export
 * @interface FieldsApiFieldContourPatchRequest
 */
export interface FieldsApiFieldContourPatchRequest {
    /**
     * 
     * @type {FieldContourPatchRequest}
     * @memberof FieldsApiFieldContourPatch
     */
    readonly fieldContourPatchRequest: FieldContourPatchRequest
}

/**
 * Request parameters for fieldContourPut operation in FieldsApi.
 * @export
 * @interface FieldsApiFieldContourPutRequest
 */
export interface FieldsApiFieldContourPutRequest {
    /**
     * 
     * @type {FieldContourPutRequest}
     * @memberof FieldsApiFieldContourPut
     */
    readonly fieldContourPutRequest: FieldContourPutRequest
}

/**
 * FieldsApi - object-oriented interface
 * @export
 * @class FieldsApi
 * @extends {BaseAPI}
 */
export class FieldsApi extends BaseAPI {
    /**
     * Delete one or more field contours.
     * @param {FieldsApiFieldContourDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldsApi
     */
    public fieldContourDelete(requestParameters: FieldsApiFieldContourDeleteRequest, options?: AxiosRequestConfig) {
        return FieldsApiFp(this.configuration).fieldContourDelete(requestParameters.objectIds, requestParameters.version, requestParameters.developerPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load one or more field contours.             If the user does not have `GLOBAL_ALL_FIELDS` permissions only contours for active fields are returned.
     * @param {FieldsApiFieldContourListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldsApi
     */
    public fieldContourList(requestParameters: FieldsApiFieldContourListRequest, options?: AxiosRequestConfig) {
        return FieldsApiFp(this.configuration).fieldContourList(requestParameters.processOrderId, requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, requestParameters.permissionMode, requestParameters.fieldSharingCompanyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update one or more field contours.
     * @param {FieldsApiFieldContourPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldsApi
     */
    public fieldContourPatch(requestParameters: FieldsApiFieldContourPatchRequest, options?: AxiosRequestConfig) {
        return FieldsApiFp(this.configuration).fieldContourPatch(requestParameters.fieldContourPatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create or replace one or more field contours.
     * @param {FieldsApiFieldContourPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldsApi
     */
    public fieldContourPut(requestParameters: FieldsApiFieldContourPutRequest, options?: AxiosRequestConfig) {
        return FieldsApiFp(this.configuration).fieldContourPut(requestParameters.fieldContourPutRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FutureApi - axios parameter creator
 * @export
 */
export const FutureApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete one or more equipment.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentDelete: async (objectIds: string, version?: string, developerPayload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectIds' is not null or undefined
            assertParamExists('equipmentDelete', 'objectIds', objectIds)
            const localVarPath = `/equipment/{objectIds}`
                .replace(`{${"objectIds"}}`, encodeURIComponent(String(objectIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update equipment.
         * @param {EquipmentPatchRequest} equipmentPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentPatch: async (equipmentPatchRequest: EquipmentPatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'equipmentPatchRequest' is not null or undefined
            assertParamExists('equipmentPatch', 'equipmentPatchRequest', equipmentPatchRequest)
            const localVarPath = `/equipment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(equipmentPatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create or update equipment.
         * @param {EquipmentPutRequest} equipmentPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentPut: async (equipmentPutRequest: EquipmentPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'equipmentPutRequest' is not null or undefined
            assertParamExists('equipmentPut', 'equipmentPutRequest', equipmentPutRequest)
            const localVarPath = `/equipment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(equipmentPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Restore one or more equipment.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentUndelete: async (objectIds: string, version?: string, developerPayload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectIds' is not null or undefined
            assertParamExists('equipmentUndelete', 'objectIds', objectIds)
            const localVarPath = `/equipment/undelete/{objectIds}`
                .replace(`{${"objectIds"}}`, encodeURIComponent(String(objectIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FutureApi - functional programming interface
 * @export
 */
export const FutureApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FutureApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete one or more equipment.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equipmentDelete(objectIds: string, version?: string, developerPayload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equipmentDelete(objectIds, version, developerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update equipment.
         * @param {EquipmentPatchRequest} equipmentPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equipmentPatch(equipmentPatchRequest: EquipmentPatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentPut200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equipmentPatch(equipmentPatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create or update equipment.
         * @param {EquipmentPutRequest} equipmentPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equipmentPut(equipmentPutRequest: EquipmentPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentPut200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equipmentPut(equipmentPutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Restore one or more equipment.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equipmentUndelete(objectIds: string, version?: string, developerPayload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentUndelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equipmentUndelete(objectIds, version, developerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FutureApi - factory interface
 * @export
 */
export const FutureApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FutureApiFp(configuration)
    return {
        /**
         * Delete one or more equipment.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentDelete(objectIds: string, version?: string, developerPayload?: string, options?: any): AxiosPromise<EquipmentDelete200Response> {
            return localVarFp.equipmentDelete(objectIds, version, developerPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Update equipment.
         * @param {EquipmentPatchRequest} equipmentPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentPatch(equipmentPatchRequest: EquipmentPatchRequest, options?: any): AxiosPromise<EquipmentPut200Response> {
            return localVarFp.equipmentPatch(equipmentPatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create or update equipment.
         * @param {EquipmentPutRequest} equipmentPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentPut(equipmentPutRequest: EquipmentPutRequest, options?: any): AxiosPromise<EquipmentPut200Response> {
            return localVarFp.equipmentPut(equipmentPutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Restore one or more equipment.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentUndelete(objectIds: string, version?: string, developerPayload?: string, options?: any): AxiosPromise<EquipmentUndelete200Response> {
            return localVarFp.equipmentUndelete(objectIds, version, developerPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for equipmentDelete operation in FutureApi.
 * @export
 * @interface FutureApiEquipmentDeleteRequest
 */
export interface FutureApiEquipmentDeleteRequest {
    /**
     * A comma sperated list of object ids.
     * @type {string}
     * @memberof FutureApiEquipmentDelete
     */
    readonly objectIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof FutureApiEquipmentDelete
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof FutureApiEquipmentDelete
     */
    readonly developerPayload?: string
}

/**
 * Request parameters for equipmentPatch operation in FutureApi.
 * @export
 * @interface FutureApiEquipmentPatchRequest
 */
export interface FutureApiEquipmentPatchRequest {
    /**
     * 
     * @type {EquipmentPatchRequest}
     * @memberof FutureApiEquipmentPatch
     */
    readonly equipmentPatchRequest: EquipmentPatchRequest
}

/**
 * Request parameters for equipmentPut operation in FutureApi.
 * @export
 * @interface FutureApiEquipmentPutRequest
 */
export interface FutureApiEquipmentPutRequest {
    /**
     * 
     * @type {EquipmentPutRequest}
     * @memberof FutureApiEquipmentPut
     */
    readonly equipmentPutRequest: EquipmentPutRequest
}

/**
 * Request parameters for equipmentUndelete operation in FutureApi.
 * @export
 * @interface FutureApiEquipmentUndeleteRequest
 */
export interface FutureApiEquipmentUndeleteRequest {
    /**
     * A comma sperated list of object ids.
     * @type {string}
     * @memberof FutureApiEquipmentUndelete
     */
    readonly objectIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof FutureApiEquipmentUndelete
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof FutureApiEquipmentUndelete
     */
    readonly developerPayload?: string
}

/**
 * FutureApi - object-oriented interface
 * @export
 * @class FutureApi
 * @extends {BaseAPI}
 */
export class FutureApi extends BaseAPI {
    /**
     * Delete one or more equipment.
     * @param {FutureApiEquipmentDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FutureApi
     */
    public equipmentDelete(requestParameters: FutureApiEquipmentDeleteRequest, options?: AxiosRequestConfig) {
        return FutureApiFp(this.configuration).equipmentDelete(requestParameters.objectIds, requestParameters.version, requestParameters.developerPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update equipment.
     * @param {FutureApiEquipmentPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FutureApi
     */
    public equipmentPatch(requestParameters: FutureApiEquipmentPatchRequest, options?: AxiosRequestConfig) {
        return FutureApiFp(this.configuration).equipmentPatch(requestParameters.equipmentPatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create or update equipment.
     * @param {FutureApiEquipmentPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FutureApi
     */
    public equipmentPut(requestParameters: FutureApiEquipmentPutRequest, options?: AxiosRequestConfig) {
        return FutureApiFp(this.configuration).equipmentPut(requestParameters.equipmentPutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Restore one or more equipment.
     * @param {FutureApiEquipmentUndeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FutureApi
     */
    public equipmentUndelete(requestParameters: FutureApiEquipmentUndeleteRequest, options?: AxiosRequestConfig) {
        return FutureApiFp(this.configuration).equipmentUndelete(requestParameters.objectIds, requestParameters.version, requestParameters.developerPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MapApi - axios parameter creator
 * @export
 */
export const MapApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Load one ore more GeoObject by id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoObject: async (objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectIds' is not null or undefined
            assertParamExists('geoObject', 'objectIds', objectIds)
            const localVarPath = `/map/geo/objects/{objectIds}`
                .replace(`{${"objectIds"}}`, encodeURIComponent(String(objectIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete one or more geo object.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoObjectDelete: async (objectIds: string, version?: string, developerPayload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectIds' is not null or undefined
            assertParamExists('geoObjectDelete', 'objectIds', objectIds)
            const localVarPath = `/map/geo/objects/{objectIds}`
                .replace(`{${"objectIds"}}`, encodeURIComponent(String(objectIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load a list of objects
         * @param {GeoObjectType} objectType Type of object that should be returned
         * @param {string} processOrderId The id of the process order for which all isolation zones should be returned
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoObjectList: async (objectType: GeoObjectType, processOrderId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectType' is not null or undefined
            assertParamExists('geoObjectList', 'objectType', objectType)
            // verify required parameter 'processOrderId' is not null or undefined
            assertParamExists('geoObjectList', 'processOrderId', processOrderId)
            const localVarPath = `/map/geo/objects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }

            if (objectType !== undefined) {
                localVarQueryParameter['objectType'] = objectType;
            }

            if (processOrderId !== undefined) {
                localVarQueryParameter['processOrderId'] = processOrderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update one or more geo object.
         * @param {GeoObjectPatchRequest} geoObjectPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoObjectPatch: async (geoObjectPatchRequest: GeoObjectPatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'geoObjectPatchRequest' is not null or undefined
            assertParamExists('geoObjectPatch', 'geoObjectPatchRequest', geoObjectPatchRequest)
            const localVarPath = `/map/geo/objects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(geoObjectPatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create or replace one or more geo object.
         * @param {GeoObjectPutRequest} geoObjectPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoObjectPut: async (geoObjectPutRequest: GeoObjectPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'geoObjectPutRequest' is not null or undefined
            assertParamExists('geoObjectPut', 'geoObjectPutRequest', geoObjectPutRequest)
            const localVarPath = `/map/geo/objects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(geoObjectPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Restore one or more geo object.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoObjectUndelete: async (objectIds: string, version?: string, developerPayload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectIds' is not null or undefined
            assertParamExists('geoObjectUndelete', 'objectIds', objectIds)
            const localVarPath = `/map/geo/objects/undelete/{objectIds}`
                .replace(`{${"objectIds"}}`, encodeURIComponent(String(objectIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the field-utilization map layer entries for the specified area.            Notes:             - Data might require display of copyright notice, see `copyrights` property.            - If too many objects match the bounding box the `status` property will be `tooManyResults`.                The server might still return a partial result in this case. It is up to the client to decide whether to                use the partial result.             - The client should avoid duplicated / overlapping requests as much as possible.             - The client must not send multiple requests to this route at the same time, and should instead wait until                  the previous request has finished or cancel the request before sending a new one.
         * @param {string} bbox Boundingbox in geojson bbox format.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapLayerFieldUtilization: async (bbox: string, version?: string, developerPayload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bbox' is not null or undefined
            assertParamExists('mapLayerFieldUtilization', 'bbox', bbox)
            const localVarPath = `/map/layer/fieldUtilization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (bbox !== undefined) {
                localVarQueryParameter['bbox'] = bbox;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the land cadastre map layer entries for the specified area.            Notes:             - Currently only available for Austria.            - Data might require display of copyright notice, see `copyrights` property.            - If too many objects match the bounding box the `status` property will be `tooManyResults`.                The server might still return a partial result in this case. It is up to the client to decide whether to                use the partial result.             - The client should avoid duplicated / overlapping requests as much as possible.             - The client must not send multiple requests to this route at the same time, and should instead wait until                  the previous request has finished or cancel the request before sending a new one.
         * @param {string} bbox Boundingbox in geojson bbox format.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapLayerLandCadastre: async (bbox: string, version?: string, developerPayload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bbox' is not null or undefined
            assertParamExists('mapLayerLandCadastre', 'bbox', bbox)
            const localVarPath = `/map/layer/landCadastre`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (bbox !== undefined) {
                localVarQueryParameter['bbox'] = bbox;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the id and point for land cadastre map layer entries matching the search query.
         * @param {string} query The search query.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapLayerLandCadastreSearch: async (query: string, version?: string, developerPayload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('mapLayerLandCadastreSearch', 'query', query)
            const localVarPath = `/map/layer/landCadastre/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the water-protection-zone map layer entries for the specified area.            Notes:             - Currently only available for Austria.            - Data might require display of copyright notice, see `copyrights` property.            - If too many objects match the bounding box the `status` property will be `tooManyResults`.                The server might still return a partial result in this case. It is up to the client to decide whether to                use the partial result.             - The client should avoid duplicated / overlapping requests as much as possible.             - The client must not send multiple requests to this route at the same time, and should instead wait until                  the previous request has finished or cancel the request before sending a new one.
         * @param {string} bbox Boundingbox in geojson bbox format.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapLayerWaterProtectionZone: async (bbox: string, version?: string, developerPayload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bbox' is not null or undefined
            assertParamExists('mapLayerWaterProtectionZone', 'bbox', bbox)
            const localVarPath = `/map/layer/waterProtectionZone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (bbox !== undefined) {
                localVarQueryParameter['bbox'] = bbox;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Query if geometries intersect with water protection zones.            Notes:             - Currently only available for Austria.            - Queries should not exceed 500 geometries per request.
         * @param {MapLayerWaterProtectionZoneIntersectsRequest} [mapLayerWaterProtectionZoneIntersectsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapLayerWaterProtectionZoneIntersects: async (mapLayerWaterProtectionZoneIntersectsRequest?: MapLayerWaterProtectionZoneIntersectsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/map/layer/waterProtectionZone/intersects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mapLayerWaterProtectionZoneIntersectsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MapApi - functional programming interface
 * @export
 */
export const MapApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MapApiAxiosParamCreator(configuration)
    return {
        /**
         * Load one ore more GeoObject by id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async geoObject(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeoObject200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.geoObject(objectIds, version, deleted, pageKey, developerPayload, properties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete one or more geo object.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async geoObjectDelete(objectIds: string, version?: string, developerPayload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeoObjectDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.geoObjectDelete(objectIds, version, developerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load a list of objects
         * @param {GeoObjectType} objectType Type of object that should be returned
         * @param {string} processOrderId The id of the process order for which all isolation zones should be returned
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async geoObjectList(objectType: GeoObjectType, processOrderId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeoObject200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.geoObjectList(objectType, processOrderId, version, deleted, pageKey, developerPayload, properties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update one or more geo object.
         * @param {GeoObjectPatchRequest} geoObjectPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async geoObjectPatch(geoObjectPatchRequest: GeoObjectPatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeoObjectPut200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.geoObjectPatch(geoObjectPatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create or replace one or more geo object.
         * @param {GeoObjectPutRequest} geoObjectPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async geoObjectPut(geoObjectPutRequest: GeoObjectPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeoObjectPut200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.geoObjectPut(geoObjectPutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Restore one or more geo object.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async geoObjectUndelete(objectIds: string, version?: string, developerPayload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeoObjectUndelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.geoObjectUndelete(objectIds, version, developerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the field-utilization map layer entries for the specified area.            Notes:             - Data might require display of copyright notice, see `copyrights` property.            - If too many objects match the bounding box the `status` property will be `tooManyResults`.                The server might still return a partial result in this case. It is up to the client to decide whether to                use the partial result.             - The client should avoid duplicated / overlapping requests as much as possible.             - The client must not send multiple requests to this route at the same time, and should instead wait until                  the previous request has finished or cancel the request before sending a new one.
         * @param {string} bbox Boundingbox in geojson bbox format.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mapLayerFieldUtilization(bbox: string, version?: string, developerPayload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MapLayerFieldUtilization200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mapLayerFieldUtilization(bbox, version, developerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the land cadastre map layer entries for the specified area.            Notes:             - Currently only available for Austria.            - Data might require display of copyright notice, see `copyrights` property.            - If too many objects match the bounding box the `status` property will be `tooManyResults`.                The server might still return a partial result in this case. It is up to the client to decide whether to                use the partial result.             - The client should avoid duplicated / overlapping requests as much as possible.             - The client must not send multiple requests to this route at the same time, and should instead wait until                  the previous request has finished or cancel the request before sending a new one.
         * @param {string} bbox Boundingbox in geojson bbox format.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mapLayerLandCadastre(bbox: string, version?: string, developerPayload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MapLayerLandCadastre200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mapLayerLandCadastre(bbox, version, developerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the id and point for land cadastre map layer entries matching the search query.
         * @param {string} query The search query.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mapLayerLandCadastreSearch(query: string, version?: string, developerPayload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MapLayerLandCadastreSearch200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mapLayerLandCadastreSearch(query, version, developerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the water-protection-zone map layer entries for the specified area.            Notes:             - Currently only available for Austria.            - Data might require display of copyright notice, see `copyrights` property.            - If too many objects match the bounding box the `status` property will be `tooManyResults`.                The server might still return a partial result in this case. It is up to the client to decide whether to                use the partial result.             - The client should avoid duplicated / overlapping requests as much as possible.             - The client must not send multiple requests to this route at the same time, and should instead wait until                  the previous request has finished or cancel the request before sending a new one.
         * @param {string} bbox Boundingbox in geojson bbox format.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mapLayerWaterProtectionZone(bbox: string, version?: string, developerPayload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MapLayerWaterProtectionZone200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mapLayerWaterProtectionZone(bbox, version, developerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Query if geometries intersect with water protection zones.            Notes:             - Currently only available for Austria.            - Queries should not exceed 500 geometries per request.
         * @param {MapLayerWaterProtectionZoneIntersectsRequest} [mapLayerWaterProtectionZoneIntersectsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mapLayerWaterProtectionZoneIntersects(mapLayerWaterProtectionZoneIntersectsRequest?: MapLayerWaterProtectionZoneIntersectsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MapLayerWaterProtectionZoneIntersects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mapLayerWaterProtectionZoneIntersects(mapLayerWaterProtectionZoneIntersectsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MapApi - factory interface
 * @export
 */
export const MapApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MapApiFp(configuration)
    return {
        /**
         * Load one ore more GeoObject by id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoObject(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: any): AxiosPromise<GeoObject200Response> {
            return localVarFp.geoObject(objectIds, version, deleted, pageKey, developerPayload, properties, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete one or more geo object.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoObjectDelete(objectIds: string, version?: string, developerPayload?: string, options?: any): AxiosPromise<GeoObjectDelete200Response> {
            return localVarFp.geoObjectDelete(objectIds, version, developerPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Load a list of objects
         * @param {GeoObjectType} objectType Type of object that should be returned
         * @param {string} processOrderId The id of the process order for which all isolation zones should be returned
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoObjectList(objectType: GeoObjectType, processOrderId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: any): AxiosPromise<GeoObject200Response> {
            return localVarFp.geoObjectList(objectType, processOrderId, version, deleted, pageKey, developerPayload, properties, options).then((request) => request(axios, basePath));
        },
        /**
         * Update one or more geo object.
         * @param {GeoObjectPatchRequest} geoObjectPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoObjectPatch(geoObjectPatchRequest: GeoObjectPatchRequest, options?: any): AxiosPromise<GeoObjectPut200Response> {
            return localVarFp.geoObjectPatch(geoObjectPatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create or replace one or more geo object.
         * @param {GeoObjectPutRequest} geoObjectPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoObjectPut(geoObjectPutRequest: GeoObjectPutRequest, options?: any): AxiosPromise<GeoObjectPut200Response> {
            return localVarFp.geoObjectPut(geoObjectPutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Restore one or more geo object.
         * @param {string} objectIds A comma sperated list of object ids.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoObjectUndelete(objectIds: string, version?: string, developerPayload?: string, options?: any): AxiosPromise<GeoObjectUndelete200Response> {
            return localVarFp.geoObjectUndelete(objectIds, version, developerPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the field-utilization map layer entries for the specified area.            Notes:             - Data might require display of copyright notice, see `copyrights` property.            - If too many objects match the bounding box the `status` property will be `tooManyResults`.                The server might still return a partial result in this case. It is up to the client to decide whether to                use the partial result.             - The client should avoid duplicated / overlapping requests as much as possible.             - The client must not send multiple requests to this route at the same time, and should instead wait until                  the previous request has finished or cancel the request before sending a new one.
         * @param {string} bbox Boundingbox in geojson bbox format.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapLayerFieldUtilization(bbox: string, version?: string, developerPayload?: string, options?: any): AxiosPromise<MapLayerFieldUtilization200Response> {
            return localVarFp.mapLayerFieldUtilization(bbox, version, developerPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the land cadastre map layer entries for the specified area.            Notes:             - Currently only available for Austria.            - Data might require display of copyright notice, see `copyrights` property.            - If too many objects match the bounding box the `status` property will be `tooManyResults`.                The server might still return a partial result in this case. It is up to the client to decide whether to                use the partial result.             - The client should avoid duplicated / overlapping requests as much as possible.             - The client must not send multiple requests to this route at the same time, and should instead wait until                  the previous request has finished or cancel the request before sending a new one.
         * @param {string} bbox Boundingbox in geojson bbox format.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapLayerLandCadastre(bbox: string, version?: string, developerPayload?: string, options?: any): AxiosPromise<MapLayerLandCadastre200Response> {
            return localVarFp.mapLayerLandCadastre(bbox, version, developerPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the id and point for land cadastre map layer entries matching the search query.
         * @param {string} query The search query.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapLayerLandCadastreSearch(query: string, version?: string, developerPayload?: string, options?: any): AxiosPromise<MapLayerLandCadastreSearch200Response> {
            return localVarFp.mapLayerLandCadastreSearch(query, version, developerPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the water-protection-zone map layer entries for the specified area.            Notes:             - Currently only available for Austria.            - Data might require display of copyright notice, see `copyrights` property.            - If too many objects match the bounding box the `status` property will be `tooManyResults`.                The server might still return a partial result in this case. It is up to the client to decide whether to                use the partial result.             - The client should avoid duplicated / overlapping requests as much as possible.             - The client must not send multiple requests to this route at the same time, and should instead wait until                  the previous request has finished or cancel the request before sending a new one.
         * @param {string} bbox Boundingbox in geojson bbox format.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapLayerWaterProtectionZone(bbox: string, version?: string, developerPayload?: string, options?: any): AxiosPromise<MapLayerWaterProtectionZone200Response> {
            return localVarFp.mapLayerWaterProtectionZone(bbox, version, developerPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Query if geometries intersect with water protection zones.            Notes:             - Currently only available for Austria.            - Queries should not exceed 500 geometries per request.
         * @param {MapLayerWaterProtectionZoneIntersectsRequest} [mapLayerWaterProtectionZoneIntersectsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapLayerWaterProtectionZoneIntersects(mapLayerWaterProtectionZoneIntersectsRequest?: MapLayerWaterProtectionZoneIntersectsRequest, options?: any): AxiosPromise<MapLayerWaterProtectionZoneIntersects200Response> {
            return localVarFp.mapLayerWaterProtectionZoneIntersects(mapLayerWaterProtectionZoneIntersectsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for geoObject operation in MapApi.
 * @export
 * @interface MapApiGeoObjectRequest
 */
export interface MapApiGeoObjectRequest {
    /**
     * Comma seperated list of object ids, use this to request specific objects.
     * @type {string}
     * @memberof MapApiGeoObject
     */
    readonly objectIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof MapApiGeoObject
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof MapApiGeoObject
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof MapApiGeoObject
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof MapApiGeoObject
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof MapApiGeoObject
     */
    readonly properties?: string
}

/**
 * Request parameters for geoObjectDelete operation in MapApi.
 * @export
 * @interface MapApiGeoObjectDeleteRequest
 */
export interface MapApiGeoObjectDeleteRequest {
    /**
     * A comma sperated list of object ids.
     * @type {string}
     * @memberof MapApiGeoObjectDelete
     */
    readonly objectIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof MapApiGeoObjectDelete
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof MapApiGeoObjectDelete
     */
    readonly developerPayload?: string
}

/**
 * Request parameters for geoObjectList operation in MapApi.
 * @export
 * @interface MapApiGeoObjectListRequest
 */
export interface MapApiGeoObjectListRequest {
    /**
     * Type of object that should be returned
     * @type {GeoObjectType}
     * @memberof MapApiGeoObjectList
     */
    readonly objectType: GeoObjectType

    /**
     * The id of the process order for which all isolation zones should be returned
     * @type {string}
     * @memberof MapApiGeoObjectList
     */
    readonly processOrderId: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof MapApiGeoObjectList
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof MapApiGeoObjectList
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof MapApiGeoObjectList
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof MapApiGeoObjectList
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof MapApiGeoObjectList
     */
    readonly properties?: string
}

/**
 * Request parameters for geoObjectPatch operation in MapApi.
 * @export
 * @interface MapApiGeoObjectPatchRequest
 */
export interface MapApiGeoObjectPatchRequest {
    /**
     * 
     * @type {GeoObjectPatchRequest}
     * @memberof MapApiGeoObjectPatch
     */
    readonly geoObjectPatchRequest: GeoObjectPatchRequest
}

/**
 * Request parameters for geoObjectPut operation in MapApi.
 * @export
 * @interface MapApiGeoObjectPutRequest
 */
export interface MapApiGeoObjectPutRequest {
    /**
     * 
     * @type {GeoObjectPutRequest}
     * @memberof MapApiGeoObjectPut
     */
    readonly geoObjectPutRequest: GeoObjectPutRequest
}

/**
 * Request parameters for geoObjectUndelete operation in MapApi.
 * @export
 * @interface MapApiGeoObjectUndeleteRequest
 */
export interface MapApiGeoObjectUndeleteRequest {
    /**
     * A comma sperated list of object ids.
     * @type {string}
     * @memberof MapApiGeoObjectUndelete
     */
    readonly objectIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof MapApiGeoObjectUndelete
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof MapApiGeoObjectUndelete
     */
    readonly developerPayload?: string
}

/**
 * Request parameters for mapLayerFieldUtilization operation in MapApi.
 * @export
 * @interface MapApiMapLayerFieldUtilizationRequest
 */
export interface MapApiMapLayerFieldUtilizationRequest {
    /**
     * Boundingbox in geojson bbox format.
     * @type {string}
     * @memberof MapApiMapLayerFieldUtilization
     */
    readonly bbox: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof MapApiMapLayerFieldUtilization
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof MapApiMapLayerFieldUtilization
     */
    readonly developerPayload?: string
}

/**
 * Request parameters for mapLayerLandCadastre operation in MapApi.
 * @export
 * @interface MapApiMapLayerLandCadastreRequest
 */
export interface MapApiMapLayerLandCadastreRequest {
    /**
     * Boundingbox in geojson bbox format.
     * @type {string}
     * @memberof MapApiMapLayerLandCadastre
     */
    readonly bbox: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof MapApiMapLayerLandCadastre
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof MapApiMapLayerLandCadastre
     */
    readonly developerPayload?: string
}

/**
 * Request parameters for mapLayerLandCadastreSearch operation in MapApi.
 * @export
 * @interface MapApiMapLayerLandCadastreSearchRequest
 */
export interface MapApiMapLayerLandCadastreSearchRequest {
    /**
     * The search query.
     * @type {string}
     * @memberof MapApiMapLayerLandCadastreSearch
     */
    readonly query: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof MapApiMapLayerLandCadastreSearch
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof MapApiMapLayerLandCadastreSearch
     */
    readonly developerPayload?: string
}

/**
 * Request parameters for mapLayerWaterProtectionZone operation in MapApi.
 * @export
 * @interface MapApiMapLayerWaterProtectionZoneRequest
 */
export interface MapApiMapLayerWaterProtectionZoneRequest {
    /**
     * Boundingbox in geojson bbox format.
     * @type {string}
     * @memberof MapApiMapLayerWaterProtectionZone
     */
    readonly bbox: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof MapApiMapLayerWaterProtectionZone
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof MapApiMapLayerWaterProtectionZone
     */
    readonly developerPayload?: string
}

/**
 * Request parameters for mapLayerWaterProtectionZoneIntersects operation in MapApi.
 * @export
 * @interface MapApiMapLayerWaterProtectionZoneIntersectsRequest
 */
export interface MapApiMapLayerWaterProtectionZoneIntersectsRequest {
    /**
     * 
     * @type {MapLayerWaterProtectionZoneIntersectsRequest}
     * @memberof MapApiMapLayerWaterProtectionZoneIntersects
     */
    readonly mapLayerWaterProtectionZoneIntersectsRequest?: MapLayerWaterProtectionZoneIntersectsRequest
}

/**
 * MapApi - object-oriented interface
 * @export
 * @class MapApi
 * @extends {BaseAPI}
 */
export class MapApi extends BaseAPI {
    /**
     * Load one ore more GeoObject by id.
     * @param {MapApiGeoObjectRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MapApi
     */
    public geoObject(requestParameters: MapApiGeoObjectRequest, options?: AxiosRequestConfig) {
        return MapApiFp(this.configuration).geoObject(requestParameters.objectIds, requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete one or more geo object.
     * @param {MapApiGeoObjectDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MapApi
     */
    public geoObjectDelete(requestParameters: MapApiGeoObjectDeleteRequest, options?: AxiosRequestConfig) {
        return MapApiFp(this.configuration).geoObjectDelete(requestParameters.objectIds, requestParameters.version, requestParameters.developerPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load a list of objects
     * @param {MapApiGeoObjectListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MapApi
     */
    public geoObjectList(requestParameters: MapApiGeoObjectListRequest, options?: AxiosRequestConfig) {
        return MapApiFp(this.configuration).geoObjectList(requestParameters.objectType, requestParameters.processOrderId, requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update one or more geo object.
     * @param {MapApiGeoObjectPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MapApi
     */
    public geoObjectPatch(requestParameters: MapApiGeoObjectPatchRequest, options?: AxiosRequestConfig) {
        return MapApiFp(this.configuration).geoObjectPatch(requestParameters.geoObjectPatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create or replace one or more geo object.
     * @param {MapApiGeoObjectPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MapApi
     */
    public geoObjectPut(requestParameters: MapApiGeoObjectPutRequest, options?: AxiosRequestConfig) {
        return MapApiFp(this.configuration).geoObjectPut(requestParameters.geoObjectPutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Restore one or more geo object.
     * @param {MapApiGeoObjectUndeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MapApi
     */
    public geoObjectUndelete(requestParameters: MapApiGeoObjectUndeleteRequest, options?: AxiosRequestConfig) {
        return MapApiFp(this.configuration).geoObjectUndelete(requestParameters.objectIds, requestParameters.version, requestParameters.developerPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the field-utilization map layer entries for the specified area.            Notes:             - Data might require display of copyright notice, see `copyrights` property.            - If too many objects match the bounding box the `status` property will be `tooManyResults`.                The server might still return a partial result in this case. It is up to the client to decide whether to                use the partial result.             - The client should avoid duplicated / overlapping requests as much as possible.             - The client must not send multiple requests to this route at the same time, and should instead wait until                  the previous request has finished or cancel the request before sending a new one.
     * @param {MapApiMapLayerFieldUtilizationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MapApi
     */
    public mapLayerFieldUtilization(requestParameters: MapApiMapLayerFieldUtilizationRequest, options?: AxiosRequestConfig) {
        return MapApiFp(this.configuration).mapLayerFieldUtilization(requestParameters.bbox, requestParameters.version, requestParameters.developerPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the land cadastre map layer entries for the specified area.            Notes:             - Currently only available for Austria.            - Data might require display of copyright notice, see `copyrights` property.            - If too many objects match the bounding box the `status` property will be `tooManyResults`.                The server might still return a partial result in this case. It is up to the client to decide whether to                use the partial result.             - The client should avoid duplicated / overlapping requests as much as possible.             - The client must not send multiple requests to this route at the same time, and should instead wait until                  the previous request has finished or cancel the request before sending a new one.
     * @param {MapApiMapLayerLandCadastreRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MapApi
     */
    public mapLayerLandCadastre(requestParameters: MapApiMapLayerLandCadastreRequest, options?: AxiosRequestConfig) {
        return MapApiFp(this.configuration).mapLayerLandCadastre(requestParameters.bbox, requestParameters.version, requestParameters.developerPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the id and point for land cadastre map layer entries matching the search query.
     * @param {MapApiMapLayerLandCadastreSearchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MapApi
     */
    public mapLayerLandCadastreSearch(requestParameters: MapApiMapLayerLandCadastreSearchRequest, options?: AxiosRequestConfig) {
        return MapApiFp(this.configuration).mapLayerLandCadastreSearch(requestParameters.query, requestParameters.version, requestParameters.developerPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the water-protection-zone map layer entries for the specified area.            Notes:             - Currently only available for Austria.            - Data might require display of copyright notice, see `copyrights` property.            - If too many objects match the bounding box the `status` property will be `tooManyResults`.                The server might still return a partial result in this case. It is up to the client to decide whether to                use the partial result.             - The client should avoid duplicated / overlapping requests as much as possible.             - The client must not send multiple requests to this route at the same time, and should instead wait until                  the previous request has finished or cancel the request before sending a new one.
     * @param {MapApiMapLayerWaterProtectionZoneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MapApi
     */
    public mapLayerWaterProtectionZone(requestParameters: MapApiMapLayerWaterProtectionZoneRequest, options?: AxiosRequestConfig) {
        return MapApiFp(this.configuration).mapLayerWaterProtectionZone(requestParameters.bbox, requestParameters.version, requestParameters.developerPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Query if geometries intersect with water protection zones.            Notes:             - Currently only available for Austria.            - Queries should not exceed 500 geometries per request.
     * @param {MapApiMapLayerWaterProtectionZoneIntersectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MapApi
     */
    public mapLayerWaterProtectionZoneIntersects(requestParameters: MapApiMapLayerWaterProtectionZoneIntersectsRequest = {}, options?: AxiosRequestConfig) {
        return MapApiFp(this.configuration).mapLayerWaterProtectionZoneIntersects(requestParameters.mapLayerWaterProtectionZoneIntersectsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PestsApi - axios parameter creator
 * @export
 */
export const PestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Load a list of objects
         * @param {string} regionId The id of the region for which all pests should be returned
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pestList: async (regionId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('pestList', 'regionId', regionId)
            const localVarPath = `/pests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }

            if (regionId !== undefined) {
                localVarQueryParameter['regionId'] = regionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load one ore more pests by pest id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pestObjects: async (objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectIds' is not null or undefined
            assertParamExists('pestObjects', 'objectIds', objectIds)
            const localVarPath = `/pests/{objectIds}`
                .replace(`{${"objectIds"}}`, encodeURIComponent(String(objectIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PestsApi - functional programming interface
 * @export
 */
export const PestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PestsApiAxiosParamCreator(configuration)
    return {
        /**
         * Load a list of objects
         * @param {string} regionId The id of the region for which all pests should be returned
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pestList(regionId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PestObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pestList(regionId, version, deleted, pageKey, developerPayload, properties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load one ore more pests by pest id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pestObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PestObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pestObjects(objectIds, version, deleted, pageKey, developerPayload, properties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PestsApi - factory interface
 * @export
 */
export const PestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PestsApiFp(configuration)
    return {
        /**
         * Load a list of objects
         * @param {string} regionId The id of the region for which all pests should be returned
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pestList(regionId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: any): AxiosPromise<PestObjects200Response> {
            return localVarFp.pestList(regionId, version, deleted, pageKey, developerPayload, properties, options).then((request) => request(axios, basePath));
        },
        /**
         * Load one ore more pests by pest id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pestObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: any): AxiosPromise<PestObjects200Response> {
            return localVarFp.pestObjects(objectIds, version, deleted, pageKey, developerPayload, properties, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for pestList operation in PestsApi.
 * @export
 * @interface PestsApiPestListRequest
 */
export interface PestsApiPestListRequest {
    /**
     * The id of the region for which all pests should be returned
     * @type {string}
     * @memberof PestsApiPestList
     */
    readonly regionId: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof PestsApiPestList
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof PestsApiPestList
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof PestsApiPestList
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof PestsApiPestList
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof PestsApiPestList
     */
    readonly properties?: string
}

/**
 * Request parameters for pestObjects operation in PestsApi.
 * @export
 * @interface PestsApiPestObjectsRequest
 */
export interface PestsApiPestObjectsRequest {
    /**
     * Comma seperated list of object ids, use this to request specific objects.
     * @type {string}
     * @memberof PestsApiPestObjects
     */
    readonly objectIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof PestsApiPestObjects
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof PestsApiPestObjects
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof PestsApiPestObjects
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof PestsApiPestObjects
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof PestsApiPestObjects
     */
    readonly properties?: string
}

/**
 * PestsApi - object-oriented interface
 * @export
 * @class PestsApi
 * @extends {BaseAPI}
 */
export class PestsApi extends BaseAPI {
    /**
     * Load a list of objects
     * @param {PestsApiPestListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PestsApi
     */
    public pestList(requestParameters: PestsApiPestListRequest, options?: AxiosRequestConfig) {
        return PestsApiFp(this.configuration).pestList(requestParameters.regionId, requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load one ore more pests by pest id.
     * @param {PestsApiPestObjectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PestsApi
     */
    public pestObjects(requestParameters: PestsApiPestObjectsRequest, options?: AxiosRequestConfig) {
        return PestsApiFp(this.configuration).pestObjects(requestParameters.objectIds, requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PlantVarietiesApi - axios parameter creator
 * @export
 */
export const PlantVarietiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Load a list of objects
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [companyId] The id of the company for which all plant varieties should be returned,              if not set or null global plant varieties for &#x60;regionId&#x60; are returned.             Note: either &#x60;companyId&#x60; or &#x60;regionId&#x60; must be set.
         * @param {string} [regionId] The id of the region for which all global plant varieties should be returned,              if not set or null global plant varieties for &#x60;companyId&#x60; are returned.             Note: either &#x60;companyId&#x60; or &#x60;regionId&#x60; must be set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plantVarietyList: async (version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, companyId?: string, regionId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/plantVarieties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (regionId !== undefined) {
                localVarQueryParameter['regionId'] = regionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load one or more plant varieties by id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plantVarietyObjects: async (objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectIds' is not null or undefined
            assertParamExists('plantVarietyObjects', 'objectIds', objectIds)
            const localVarPath = `/plantVarieties/{objectIds}`
                .replace(`{${"objectIds"}}`, encodeURIComponent(String(objectIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlantVarietiesApi - functional programming interface
 * @export
 */
export const PlantVarietiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlantVarietiesApiAxiosParamCreator(configuration)
    return {
        /**
         * Load a list of objects
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [companyId] The id of the company for which all plant varieties should be returned,              if not set or null global plant varieties for &#x60;regionId&#x60; are returned.             Note: either &#x60;companyId&#x60; or &#x60;regionId&#x60; must be set.
         * @param {string} [regionId] The id of the region for which all global plant varieties should be returned,              if not set or null global plant varieties for &#x60;companyId&#x60; are returned.             Note: either &#x60;companyId&#x60; or &#x60;regionId&#x60; must be set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plantVarietyList(version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, companyId?: string, regionId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlantVarietyObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plantVarietyList(version, deleted, pageKey, developerPayload, properties, companyId, regionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load one or more plant varieties by id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plantVarietyObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlantVarietyObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plantVarietyObjects(objectIds, version, deleted, pageKey, developerPayload, properties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlantVarietiesApi - factory interface
 * @export
 */
export const PlantVarietiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlantVarietiesApiFp(configuration)
    return {
        /**
         * Load a list of objects
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [companyId] The id of the company for which all plant varieties should be returned,              if not set or null global plant varieties for &#x60;regionId&#x60; are returned.             Note: either &#x60;companyId&#x60; or &#x60;regionId&#x60; must be set.
         * @param {string} [regionId] The id of the region for which all global plant varieties should be returned,              if not set or null global plant varieties for &#x60;companyId&#x60; are returned.             Note: either &#x60;companyId&#x60; or &#x60;regionId&#x60; must be set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plantVarietyList(version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, companyId?: string, regionId?: string, options?: any): AxiosPromise<PlantVarietyObjects200Response> {
            return localVarFp.plantVarietyList(version, deleted, pageKey, developerPayload, properties, companyId, regionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Load one or more plant varieties by id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plantVarietyObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: any): AxiosPromise<PlantVarietyObjects200Response> {
            return localVarFp.plantVarietyObjects(objectIds, version, deleted, pageKey, developerPayload, properties, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for plantVarietyList operation in PlantVarietiesApi.
 * @export
 * @interface PlantVarietiesApiPlantVarietyListRequest
 */
export interface PlantVarietiesApiPlantVarietyListRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof PlantVarietiesApiPlantVarietyList
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof PlantVarietiesApiPlantVarietyList
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof PlantVarietiesApiPlantVarietyList
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof PlantVarietiesApiPlantVarietyList
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof PlantVarietiesApiPlantVarietyList
     */
    readonly properties?: string

    /**
     * The id of the company for which all plant varieties should be returned,              if not set or null global plant varieties for &#x60;regionId&#x60; are returned.             Note: either &#x60;companyId&#x60; or &#x60;regionId&#x60; must be set.
     * @type {string}
     * @memberof PlantVarietiesApiPlantVarietyList
     */
    readonly companyId?: string

    /**
     * The id of the region for which all global plant varieties should be returned,              if not set or null global plant varieties for &#x60;companyId&#x60; are returned.             Note: either &#x60;companyId&#x60; or &#x60;regionId&#x60; must be set.
     * @type {string}
     * @memberof PlantVarietiesApiPlantVarietyList
     */
    readonly regionId?: string
}

/**
 * Request parameters for plantVarietyObjects operation in PlantVarietiesApi.
 * @export
 * @interface PlantVarietiesApiPlantVarietyObjectsRequest
 */
export interface PlantVarietiesApiPlantVarietyObjectsRequest {
    /**
     * Comma seperated list of object ids, use this to request specific objects.
     * @type {string}
     * @memberof PlantVarietiesApiPlantVarietyObjects
     */
    readonly objectIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof PlantVarietiesApiPlantVarietyObjects
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof PlantVarietiesApiPlantVarietyObjects
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof PlantVarietiesApiPlantVarietyObjects
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof PlantVarietiesApiPlantVarietyObjects
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof PlantVarietiesApiPlantVarietyObjects
     */
    readonly properties?: string
}

/**
 * PlantVarietiesApi - object-oriented interface
 * @export
 * @class PlantVarietiesApi
 * @extends {BaseAPI}
 */
export class PlantVarietiesApi extends BaseAPI {
    /**
     * Load a list of objects
     * @param {PlantVarietiesApiPlantVarietyListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlantVarietiesApi
     */
    public plantVarietyList(requestParameters: PlantVarietiesApiPlantVarietyListRequest = {}, options?: AxiosRequestConfig) {
        return PlantVarietiesApiFp(this.configuration).plantVarietyList(requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, requestParameters.companyId, requestParameters.regionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load one or more plant varieties by id.
     * @param {PlantVarietiesApiPlantVarietyObjectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlantVarietiesApi
     */
    public plantVarietyObjects(requestParameters: PlantVarietiesApiPlantVarietyObjectsRequest, options?: AxiosRequestConfig) {
        return PlantVarietiesApiFp(this.configuration).plantVarietyObjects(requestParameters.objectIds, requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductCategoriesApi - axios parameter creator
 * @export
 */
export const ProductCategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Load a list of objects
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productCategoryList: async (version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/productCategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load one ore more product categories by id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productCategoryObjects: async (objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectIds' is not null or undefined
            assertParamExists('productCategoryObjects', 'objectIds', objectIds)
            const localVarPath = `/productCategories/{objectIds}`
                .replace(`{${"objectIds"}}`, encodeURIComponent(String(objectIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductCategoriesApi - functional programming interface
 * @export
 */
export const ProductCategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductCategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * Load a list of objects
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productCategoryList(version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductCategoryObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productCategoryList(version, deleted, pageKey, developerPayload, properties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load one ore more product categories by id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productCategoryObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductCategoryObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productCategoryObjects(objectIds, version, deleted, pageKey, developerPayload, properties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductCategoriesApi - factory interface
 * @export
 */
export const ProductCategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductCategoriesApiFp(configuration)
    return {
        /**
         * Load a list of objects
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productCategoryList(version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: any): AxiosPromise<ProductCategoryObjects200Response> {
            return localVarFp.productCategoryList(version, deleted, pageKey, developerPayload, properties, options).then((request) => request(axios, basePath));
        },
        /**
         * Load one ore more product categories by id.
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productCategoryObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: any): AxiosPromise<ProductCategoryObjects200Response> {
            return localVarFp.productCategoryObjects(objectIds, version, deleted, pageKey, developerPayload, properties, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for productCategoryList operation in ProductCategoriesApi.
 * @export
 * @interface ProductCategoriesApiProductCategoryListRequest
 */
export interface ProductCategoriesApiProductCategoryListRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof ProductCategoriesApiProductCategoryList
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof ProductCategoriesApiProductCategoryList
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof ProductCategoriesApiProductCategoryList
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof ProductCategoriesApiProductCategoryList
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof ProductCategoriesApiProductCategoryList
     */
    readonly properties?: string
}

/**
 * Request parameters for productCategoryObjects operation in ProductCategoriesApi.
 * @export
 * @interface ProductCategoriesApiProductCategoryObjectsRequest
 */
export interface ProductCategoriesApiProductCategoryObjectsRequest {
    /**
     * Comma seperated list of object ids, use this to request specific objects.
     * @type {string}
     * @memberof ProductCategoriesApiProductCategoryObjects
     */
    readonly objectIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof ProductCategoriesApiProductCategoryObjects
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof ProductCategoriesApiProductCategoryObjects
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof ProductCategoriesApiProductCategoryObjects
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof ProductCategoriesApiProductCategoryObjects
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof ProductCategoriesApiProductCategoryObjects
     */
    readonly properties?: string
}

/**
 * ProductCategoriesApi - object-oriented interface
 * @export
 * @class ProductCategoriesApi
 * @extends {BaseAPI}
 */
export class ProductCategoriesApi extends BaseAPI {
    /**
     * Load a list of objects
     * @param {ProductCategoriesApiProductCategoryListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoriesApi
     */
    public productCategoryList(requestParameters: ProductCategoriesApiProductCategoryListRequest = {}, options?: AxiosRequestConfig) {
        return ProductCategoriesApiFp(this.configuration).productCategoryList(requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load one ore more product categories by id.
     * @param {ProductCategoriesApiProductCategoryObjectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoriesApi
     */
    public productCategoryObjects(requestParameters: ProductCategoriesApiProductCategoryObjectsRequest, options?: AxiosRequestConfig) {
        return ProductCategoriesApiFp(this.configuration).productCategoryObjects(requestParameters.objectIds, requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns a list of pesticide indications. Either `regionId` or `pesticideId` is required.               Performance note: There is a large and growing number of indications per region, therefore loading all              indications is discouraged.             When ever possible only load the required indications for the pesticide currently in use using the              `pesticideId` parameter.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [regionId] The ID of the region for which pesticide indicaitons should be returned. See note in operation description.
         * @param {string} [pesticideId] A pesticide ID, if set only indications for this pesticide are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productPesticideIndicationList: async (version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, regionId?: string, pesticideId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/products/pesticideIndications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }

            if (regionId !== undefined) {
                localVarQueryParameter['regionId'] = regionId;
            }

            if (pesticideId !== undefined) {
                localVarQueryParameter['pesticideId'] = pesticideId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns a list of pesticide indications. Either `regionId` or `pesticideId` is required.               Performance note: There is a large and growing number of indications per region, therefore loading all              indications is discouraged.             When ever possible only load the required indications for the pesticide currently in use using the              `pesticideId` parameter.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [regionId] The ID of the region for which pesticide indicaitons should be returned. See note in operation description.
         * @param {string} [pesticideId] A pesticide ID, if set only indications for this pesticide are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productPesticideIndicationList(version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, regionId?: string, pesticideId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductPesticideIndicationList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productPesticideIndicationList(version, deleted, pageKey, developerPayload, properties, regionId, pesticideId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductsApiFp(configuration)
    return {
        /**
         * Returns a list of pesticide indications. Either `regionId` or `pesticideId` is required.               Performance note: There is a large and growing number of indications per region, therefore loading all              indications is discouraged.             When ever possible only load the required indications for the pesticide currently in use using the              `pesticideId` parameter.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [regionId] The ID of the region for which pesticide indicaitons should be returned. See note in operation description.
         * @param {string} [pesticideId] A pesticide ID, if set only indications for this pesticide are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productPesticideIndicationList(version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, regionId?: string, pesticideId?: string, options?: any): AxiosPromise<ProductPesticideIndicationList200Response> {
            return localVarFp.productPesticideIndicationList(version, deleted, pageKey, developerPayload, properties, regionId, pesticideId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for productPesticideIndicationList operation in ProductsApi.
 * @export
 * @interface ProductsApiProductPesticideIndicationListRequest
 */
export interface ProductsApiProductPesticideIndicationListRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof ProductsApiProductPesticideIndicationList
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof ProductsApiProductPesticideIndicationList
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof ProductsApiProductPesticideIndicationList
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof ProductsApiProductPesticideIndicationList
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof ProductsApiProductPesticideIndicationList
     */
    readonly properties?: string

    /**
     * The ID of the region for which pesticide indicaitons should be returned. See note in operation description.
     * @type {string}
     * @memberof ProductsApiProductPesticideIndicationList
     */
    readonly regionId?: string

    /**
     * A pesticide ID, if set only indications for this pesticide are returned.
     * @type {string}
     * @memberof ProductsApiProductPesticideIndicationList
     */
    readonly pesticideId?: string
}

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI {
    /**
     * Returns a list of pesticide indications. Either `regionId` or `pesticideId` is required.               Performance note: There is a large and growing number of indications per region, therefore loading all              indications is discouraged.             When ever possible only load the required indications for the pesticide currently in use using the              `pesticideId` parameter.
     * @param {ProductsApiProductPesticideIndicationListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productPesticideIndicationList(requestParameters: ProductsApiProductPesticideIndicationListRequest = {}, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productPesticideIndicationList(requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, requestParameters.regionId, requestParameters.pesticideId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RegionAtApi - axios parameter creator
 * @export
 */
export const RegionAtApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Load austrian cultivation plan data by field id. Note: currently only returns expected yield,            for use with the terrazo API.            Will eventually replace the legacy cultivation plan API and return all cultivation plan data.
         * @param {string} fieldIds A comma seperated list of field ids for which to return cultivation plan data.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cultivationPlanAtList: async (fieldIds: string, version?: string, developerPayload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fieldIds' is not null or undefined
            assertParamExists('cultivationPlanAtList', 'fieldIds', fieldIds)
            const localVarPath = `/cultivationPlan/At/{fieldIds}`
                .replace(`{${"fieldIds"}}`, encodeURIComponent(String(fieldIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegionAtApi - functional programming interface
 * @export
 */
export const RegionAtApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RegionAtApiAxiosParamCreator(configuration)
    return {
        /**
         * Load austrian cultivation plan data by field id. Note: currently only returns expected yield,            for use with the terrazo API.            Will eventually replace the legacy cultivation plan API and return all cultivation plan data.
         * @param {string} fieldIds A comma seperated list of field ids for which to return cultivation plan data.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cultivationPlanAtList(fieldIds: string, version?: string, developerPayload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CultivationPlanAtList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cultivationPlanAtList(fieldIds, version, developerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RegionAtApi - factory interface
 * @export
 */
export const RegionAtApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RegionAtApiFp(configuration)
    return {
        /**
         * Load austrian cultivation plan data by field id. Note: currently only returns expected yield,            for use with the terrazo API.            Will eventually replace the legacy cultivation plan API and return all cultivation plan data.
         * @param {string} fieldIds A comma seperated list of field ids for which to return cultivation plan data.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cultivationPlanAtList(fieldIds: string, version?: string, developerPayload?: string, options?: any): AxiosPromise<CultivationPlanAtList200Response> {
            return localVarFp.cultivationPlanAtList(fieldIds, version, developerPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for cultivationPlanAtList operation in RegionAtApi.
 * @export
 * @interface RegionAtApiCultivationPlanAtListRequest
 */
export interface RegionAtApiCultivationPlanAtListRequest {
    /**
     * A comma seperated list of field ids for which to return cultivation plan data.
     * @type {string}
     * @memberof RegionAtApiCultivationPlanAtList
     */
    readonly fieldIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof RegionAtApiCultivationPlanAtList
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof RegionAtApiCultivationPlanAtList
     */
    readonly developerPayload?: string
}

/**
 * RegionAtApi - object-oriented interface
 * @export
 * @class RegionAtApi
 * @extends {BaseAPI}
 */
export class RegionAtApi extends BaseAPI {
    /**
     * Load austrian cultivation plan data by field id. Note: currently only returns expected yield,            for use with the terrazo API.            Will eventually replace the legacy cultivation plan API and return all cultivation plan data.
     * @param {RegionAtApiCultivationPlanAtListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionAtApi
     */
    public cultivationPlanAtList(requestParameters: RegionAtApiCultivationPlanAtListRequest, options?: AxiosRequestConfig) {
        return RegionAtApiFp(this.configuration).cultivationPlanAtList(requestParameters.fieldIds, requestParameters.version, requestParameters.developerPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RuleCheckApi - axios parameter creator
 * @export
 */
export const RuleCheckApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns a list of pesticide indications. Either `regionId` or `pesticideId` is required.               Performance note: There is a large and growing number of indications per region, therefore loading all              indications is discouraged.             When ever possible only load the required indications for the pesticide currently in use using the              `pesticideId` parameter.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [regionId] The ID of the region for which pesticide indicaitons should be returned. See note in operation description.
         * @param {string} [pesticideId] A pesticide ID, if set only indications for this pesticide are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productPesticideIndicationList: async (version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, regionId?: string, pesticideId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/products/pesticideIndications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }

            if (regionId !== undefined) {
                localVarQueryParameter['regionId'] = regionId;
            }

            if (pesticideId !== undefined) {
                localVarQueryParameter['pesticideId'] = pesticideId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load a list of objects
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ruleList: async (version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rulecheck/rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load a list of objects by id
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ruleObjects: async (objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectIds' is not null or undefined
            assertParamExists('ruleObjects', 'objectIds', objectIds)
            const localVarPath = `/rulecheck/rules/{objectIds}`
                .replace(`{${"objectIds"}}`, encodeURIComponent(String(objectIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load activity rule violations.
         * @param {string} processOrderId The id of the process order for which to get all activity rule violation
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rulecheckActivityRuleViolations: async (processOrderId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processOrderId' is not null or undefined
            assertParamExists('rulecheckActivityRuleViolations', 'processOrderId', processOrderId)
            const localVarPath = `/rulecheck/activityRuleViolations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }

            if (processOrderId !== undefined) {
                localVarQueryParameter['processOrderId'] = processOrderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Dissmisses the activity-rule violation with id `activityRuleViolationId`.              The violation will not be shown again unless the underlying data changes.
         * @param {string} activityRuleViolationId The ID of the activityRuleViolation to dismiss.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rulecheckDismiss: async (activityRuleViolationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityRuleViolationId' is not null or undefined
            assertParamExists('rulecheckDismiss', 'activityRuleViolationId', activityRuleViolationId)
            const localVarPath = `/rulecheck/activityRuleViolations/dismiss/{activityRuleViolationId}`
                .replace(`{${"activityRuleViolationId"}}`, encodeURIComponent(String(activityRuleViolationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Manually trigger a recheck of the rules for a process order and return the results.               Note that rules are automatically rechecked in the background after some time whenever activities are changed,             manually calling recheck is only neccessary if rulecheck results must be shown the user immediately.               Because rulecheck runs are resource intensive, recheck requests should be debounced so that for a              process order only one request is sent at a time. Requests should not be sent while a user is still activly              changing data.               Specifically clients *must* wait at-least five seconds after the last activity change was sent to the server and              at least ten seconds after a previous recheck request before triggering a new recheck.
         * @param {RulecheckRecheckRequest} [rulecheckRecheckRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rulecheckRecheck: async (rulecheckRecheckRequest?: RulecheckRecheckRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rulecheck/recheck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rulecheckRecheckRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RuleCheckApi - functional programming interface
 * @export
 */
export const RuleCheckApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RuleCheckApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns a list of pesticide indications. Either `regionId` or `pesticideId` is required.               Performance note: There is a large and growing number of indications per region, therefore loading all              indications is discouraged.             When ever possible only load the required indications for the pesticide currently in use using the              `pesticideId` parameter.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [regionId] The ID of the region for which pesticide indicaitons should be returned. See note in operation description.
         * @param {string} [pesticideId] A pesticide ID, if set only indications for this pesticide are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productPesticideIndicationList(version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, regionId?: string, pesticideId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductPesticideIndicationList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productPesticideIndicationList(version, deleted, pageKey, developerPayload, properties, regionId, pesticideId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load a list of objects
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ruleList(version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RuleObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ruleList(version, deleted, pageKey, developerPayload, properties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load a list of objects by id
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ruleObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RuleObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ruleObjects(objectIds, version, deleted, pageKey, developerPayload, properties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load activity rule violations.
         * @param {string} processOrderId The id of the process order for which to get all activity rule violation
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rulecheckActivityRuleViolations(processOrderId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RulecheckActivityRuleViolations200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rulecheckActivityRuleViolations(processOrderId, version, deleted, pageKey, developerPayload, properties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Dissmisses the activity-rule violation with id `activityRuleViolationId`.              The violation will not be shown again unless the underlying data changes.
         * @param {string} activityRuleViolationId The ID of the activityRuleViolation to dismiss.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rulecheckDismiss(activityRuleViolationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rulecheckDismiss(activityRuleViolationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Manually trigger a recheck of the rules for a process order and return the results.               Note that rules are automatically rechecked in the background after some time whenever activities are changed,             manually calling recheck is only neccessary if rulecheck results must be shown the user immediately.               Because rulecheck runs are resource intensive, recheck requests should be debounced so that for a              process order only one request is sent at a time. Requests should not be sent while a user is still activly              changing data.               Specifically clients *must* wait at-least five seconds after the last activity change was sent to the server and              at least ten seconds after a previous recheck request before triggering a new recheck.
         * @param {RulecheckRecheckRequest} [rulecheckRecheckRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rulecheckRecheck(rulecheckRecheckRequest?: RulecheckRecheckRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RulecheckRecheck200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rulecheckRecheck(rulecheckRecheckRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RuleCheckApi - factory interface
 * @export
 */
export const RuleCheckApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RuleCheckApiFp(configuration)
    return {
        /**
         * Returns a list of pesticide indications. Either `regionId` or `pesticideId` is required.               Performance note: There is a large and growing number of indications per region, therefore loading all              indications is discouraged.             When ever possible only load the required indications for the pesticide currently in use using the              `pesticideId` parameter.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [regionId] The ID of the region for which pesticide indicaitons should be returned. See note in operation description.
         * @param {string} [pesticideId] A pesticide ID, if set only indications for this pesticide are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productPesticideIndicationList(version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, regionId?: string, pesticideId?: string, options?: any): AxiosPromise<ProductPesticideIndicationList200Response> {
            return localVarFp.productPesticideIndicationList(version, deleted, pageKey, developerPayload, properties, regionId, pesticideId, options).then((request) => request(axios, basePath));
        },
        /**
         * Load a list of objects
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ruleList(version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: any): AxiosPromise<RuleObjects200Response> {
            return localVarFp.ruleList(version, deleted, pageKey, developerPayload, properties, options).then((request) => request(axios, basePath));
        },
        /**
         * Load a list of objects by id
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ruleObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: any): AxiosPromise<RuleObjects200Response> {
            return localVarFp.ruleObjects(objectIds, version, deleted, pageKey, developerPayload, properties, options).then((request) => request(axios, basePath));
        },
        /**
         * Load activity rule violations.
         * @param {string} processOrderId The id of the process order for which to get all activity rule violation
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rulecheckActivityRuleViolations(processOrderId: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: any): AxiosPromise<RulecheckActivityRuleViolations200Response> {
            return localVarFp.rulecheckActivityRuleViolations(processOrderId, version, deleted, pageKey, developerPayload, properties, options).then((request) => request(axios, basePath));
        },
        /**
         * Dissmisses the activity-rule violation with id `activityRuleViolationId`.              The violation will not be shown again unless the underlying data changes.
         * @param {string} activityRuleViolationId The ID of the activityRuleViolation to dismiss.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rulecheckDismiss(activityRuleViolationId: string, options?: any): AxiosPromise<RestResponse> {
            return localVarFp.rulecheckDismiss(activityRuleViolationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Manually trigger a recheck of the rules for a process order and return the results.               Note that rules are automatically rechecked in the background after some time whenever activities are changed,             manually calling recheck is only neccessary if rulecheck results must be shown the user immediately.               Because rulecheck runs are resource intensive, recheck requests should be debounced so that for a              process order only one request is sent at a time. Requests should not be sent while a user is still activly              changing data.               Specifically clients *must* wait at-least five seconds after the last activity change was sent to the server and              at least ten seconds after a previous recheck request before triggering a new recheck.
         * @param {RulecheckRecheckRequest} [rulecheckRecheckRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rulecheckRecheck(rulecheckRecheckRequest?: RulecheckRecheckRequest, options?: any): AxiosPromise<RulecheckRecheck200Response> {
            return localVarFp.rulecheckRecheck(rulecheckRecheckRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for productPesticideIndicationList operation in RuleCheckApi.
 * @export
 * @interface RuleCheckApiProductPesticideIndicationListRequest
 */
export interface RuleCheckApiProductPesticideIndicationListRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof RuleCheckApiProductPesticideIndicationList
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof RuleCheckApiProductPesticideIndicationList
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof RuleCheckApiProductPesticideIndicationList
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof RuleCheckApiProductPesticideIndicationList
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof RuleCheckApiProductPesticideIndicationList
     */
    readonly properties?: string

    /**
     * The ID of the region for which pesticide indicaitons should be returned. See note in operation description.
     * @type {string}
     * @memberof RuleCheckApiProductPesticideIndicationList
     */
    readonly regionId?: string

    /**
     * A pesticide ID, if set only indications for this pesticide are returned.
     * @type {string}
     * @memberof RuleCheckApiProductPesticideIndicationList
     */
    readonly pesticideId?: string
}

/**
 * Request parameters for ruleList operation in RuleCheckApi.
 * @export
 * @interface RuleCheckApiRuleListRequest
 */
export interface RuleCheckApiRuleListRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof RuleCheckApiRuleList
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof RuleCheckApiRuleList
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof RuleCheckApiRuleList
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof RuleCheckApiRuleList
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof RuleCheckApiRuleList
     */
    readonly properties?: string
}

/**
 * Request parameters for ruleObjects operation in RuleCheckApi.
 * @export
 * @interface RuleCheckApiRuleObjectsRequest
 */
export interface RuleCheckApiRuleObjectsRequest {
    /**
     * Comma seperated list of object ids, use this to request specific objects.
     * @type {string}
     * @memberof RuleCheckApiRuleObjects
     */
    readonly objectIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof RuleCheckApiRuleObjects
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof RuleCheckApiRuleObjects
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof RuleCheckApiRuleObjects
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof RuleCheckApiRuleObjects
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof RuleCheckApiRuleObjects
     */
    readonly properties?: string
}

/**
 * Request parameters for rulecheckActivityRuleViolations operation in RuleCheckApi.
 * @export
 * @interface RuleCheckApiRulecheckActivityRuleViolationsRequest
 */
export interface RuleCheckApiRulecheckActivityRuleViolationsRequest {
    /**
     * The id of the process order for which to get all activity rule violation
     * @type {string}
     * @memberof RuleCheckApiRulecheckActivityRuleViolations
     */
    readonly processOrderId: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof RuleCheckApiRulecheckActivityRuleViolations
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof RuleCheckApiRulecheckActivityRuleViolations
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof RuleCheckApiRulecheckActivityRuleViolations
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof RuleCheckApiRulecheckActivityRuleViolations
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof RuleCheckApiRulecheckActivityRuleViolations
     */
    readonly properties?: string
}

/**
 * Request parameters for rulecheckDismiss operation in RuleCheckApi.
 * @export
 * @interface RuleCheckApiRulecheckDismissRequest
 */
export interface RuleCheckApiRulecheckDismissRequest {
    /**
     * The ID of the activityRuleViolation to dismiss.
     * @type {string}
     * @memberof RuleCheckApiRulecheckDismiss
     */
    readonly activityRuleViolationId: string
}

/**
 * Request parameters for rulecheckRecheck operation in RuleCheckApi.
 * @export
 * @interface RuleCheckApiRulecheckRecheckRequest
 */
export interface RuleCheckApiRulecheckRecheckRequest {
    /**
     * 
     * @type {RulecheckRecheckRequest}
     * @memberof RuleCheckApiRulecheckRecheck
     */
    readonly rulecheckRecheckRequest?: RulecheckRecheckRequest
}

/**
 * RuleCheckApi - object-oriented interface
 * @export
 * @class RuleCheckApi
 * @extends {BaseAPI}
 */
export class RuleCheckApi extends BaseAPI {
    /**
     * Returns a list of pesticide indications. Either `regionId` or `pesticideId` is required.               Performance note: There is a large and growing number of indications per region, therefore loading all              indications is discouraged.             When ever possible only load the required indications for the pesticide currently in use using the              `pesticideId` parameter.
     * @param {RuleCheckApiProductPesticideIndicationListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleCheckApi
     */
    public productPesticideIndicationList(requestParameters: RuleCheckApiProductPesticideIndicationListRequest = {}, options?: AxiosRequestConfig) {
        return RuleCheckApiFp(this.configuration).productPesticideIndicationList(requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, requestParameters.regionId, requestParameters.pesticideId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load a list of objects
     * @param {RuleCheckApiRuleListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleCheckApi
     */
    public ruleList(requestParameters: RuleCheckApiRuleListRequest = {}, options?: AxiosRequestConfig) {
        return RuleCheckApiFp(this.configuration).ruleList(requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load a list of objects by id
     * @param {RuleCheckApiRuleObjectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleCheckApi
     */
    public ruleObjects(requestParameters: RuleCheckApiRuleObjectsRequest, options?: AxiosRequestConfig) {
        return RuleCheckApiFp(this.configuration).ruleObjects(requestParameters.objectIds, requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load activity rule violations.
     * @param {RuleCheckApiRulecheckActivityRuleViolationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleCheckApi
     */
    public rulecheckActivityRuleViolations(requestParameters: RuleCheckApiRulecheckActivityRuleViolationsRequest, options?: AxiosRequestConfig) {
        return RuleCheckApiFp(this.configuration).rulecheckActivityRuleViolations(requestParameters.processOrderId, requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Dissmisses the activity-rule violation with id `activityRuleViolationId`.              The violation will not be shown again unless the underlying data changes.
     * @param {RuleCheckApiRulecheckDismissRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleCheckApi
     */
    public rulecheckDismiss(requestParameters: RuleCheckApiRulecheckDismissRequest, options?: AxiosRequestConfig) {
        return RuleCheckApiFp(this.configuration).rulecheckDismiss(requestParameters.activityRuleViolationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Manually trigger a recheck of the rules for a process order and return the results.               Note that rules are automatically rechecked in the background after some time whenever activities are changed,             manually calling recheck is only neccessary if rulecheck results must be shown the user immediately.               Because rulecheck runs are resource intensive, recheck requests should be debounced so that for a              process order only one request is sent at a time. Requests should not be sent while a user is still activly              changing data.               Specifically clients *must* wait at-least five seconds after the last activity change was sent to the server and              at least ten seconds after a previous recheck request before triggering a new recheck.
     * @param {RuleCheckApiRulecheckRecheckRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleCheckApi
     */
    public rulecheckRecheck(requestParameters: RuleCheckApiRulecheckRecheckRequest = {}, options?: AxiosRequestConfig) {
        return RuleCheckApiFp(this.configuration).rulecheckRecheck(requestParameters.rulecheckRecheckRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * Sen4Api - axios parameter creator
 * @export
 */
export const Sen4ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the vegetation index graph for a specific fieldId and date range. The field must have a contour defined.
         * @param {string} fieldId Id of field for which to query data.
         * @param {string} startDate Start date of the requested period.
         * @param {string} endDate End date of the requested period.
         * @param {Sen4IndexType} indexType Index type for which to return data.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {Sen4VegetationIndexStatistics} [statistics] Compute historical mean depending on aggregate (D/M/Y) for a given period. Not available for hourly aggregate.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sen4VegetationIndexGraph: async (fieldId: string, startDate: string, endDate: string, indexType: Sen4IndexType, version?: string, developerPayload?: string, statistics?: Sen4VegetationIndexStatistics, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fieldId' is not null or undefined
            assertParamExists('sen4VegetationIndexGraph', 'fieldId', fieldId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('sen4VegetationIndexGraph', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('sen4VegetationIndexGraph', 'endDate', endDate)
            // verify required parameter 'indexType' is not null or undefined
            assertParamExists('sen4VegetationIndexGraph', 'indexType', indexType)
            const localVarPath = `/sen4/vegetationIndexGraph`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (fieldId !== undefined) {
                localVarQueryParameter['fieldId'] = fieldId;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (indexType !== undefined) {
                localVarQueryParameter['indexType'] = indexType;
            }

            if (statistics !== undefined) {
                localVarQueryParameter['statistics'] = statistics;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Sen4Api - functional programming interface
 * @export
 */
export const Sen4ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Sen4ApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the vegetation index graph for a specific fieldId and date range. The field must have a contour defined.
         * @param {string} fieldId Id of field for which to query data.
         * @param {string} startDate Start date of the requested period.
         * @param {string} endDate End date of the requested period.
         * @param {Sen4IndexType} indexType Index type for which to return data.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {Sen4VegetationIndexStatistics} [statistics] Compute historical mean depending on aggregate (D/M/Y) for a given period. Not available for hourly aggregate.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sen4VegetationIndexGraph(fieldId: string, startDate: string, endDate: string, indexType: Sen4IndexType, version?: string, developerPayload?: string, statistics?: Sen4VegetationIndexStatistics, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sen4VegetationIndexGraph200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sen4VegetationIndexGraph(fieldId, startDate, endDate, indexType, version, developerPayload, statistics, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * Sen4Api - factory interface
 * @export
 */
export const Sen4ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Sen4ApiFp(configuration)
    return {
        /**
         * Returns the vegetation index graph for a specific fieldId and date range. The field must have a contour defined.
         * @param {string} fieldId Id of field for which to query data.
         * @param {string} startDate Start date of the requested period.
         * @param {string} endDate End date of the requested period.
         * @param {Sen4IndexType} indexType Index type for which to return data.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {Sen4VegetationIndexStatistics} [statistics] Compute historical mean depending on aggregate (D/M/Y) for a given period. Not available for hourly aggregate.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sen4VegetationIndexGraph(fieldId: string, startDate: string, endDate: string, indexType: Sen4IndexType, version?: string, developerPayload?: string, statistics?: Sen4VegetationIndexStatistics, options?: any): AxiosPromise<Sen4VegetationIndexGraph200Response> {
            return localVarFp.sen4VegetationIndexGraph(fieldId, startDate, endDate, indexType, version, developerPayload, statistics, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for sen4VegetationIndexGraph operation in Sen4Api.
 * @export
 * @interface Sen4ApiSen4VegetationIndexGraphRequest
 */
export interface Sen4ApiSen4VegetationIndexGraphRequest {
    /**
     * Id of field for which to query data.
     * @type {string}
     * @memberof Sen4ApiSen4VegetationIndexGraph
     */
    readonly fieldId: string

    /**
     * Start date of the requested period.
     * @type {string}
     * @memberof Sen4ApiSen4VegetationIndexGraph
     */
    readonly startDate: string

    /**
     * End date of the requested period.
     * @type {string}
     * @memberof Sen4ApiSen4VegetationIndexGraph
     */
    readonly endDate: string

    /**
     * Index type for which to return data.
     * @type {Sen4IndexType}
     * @memberof Sen4ApiSen4VegetationIndexGraph
     */
    readonly indexType: Sen4IndexType

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof Sen4ApiSen4VegetationIndexGraph
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof Sen4ApiSen4VegetationIndexGraph
     */
    readonly developerPayload?: string

    /**
     * Compute historical mean depending on aggregate (D/M/Y) for a given period. Not available for hourly aggregate.
     * @type {Sen4VegetationIndexStatistics}
     * @memberof Sen4ApiSen4VegetationIndexGraph
     */
    readonly statistics?: Sen4VegetationIndexStatistics
}

/**
 * Sen4Api - object-oriented interface
 * @export
 * @class Sen4Api
 * @extends {BaseAPI}
 */
export class Sen4Api extends BaseAPI {
    /**
     * Returns the vegetation index graph for a specific fieldId and date range. The field must have a contour defined.
     * @param {Sen4ApiSen4VegetationIndexGraphRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Sen4Api
     */
    public sen4VegetationIndexGraph(requestParameters: Sen4ApiSen4VegetationIndexGraphRequest, options?: AxiosRequestConfig) {
        return Sen4ApiFp(this.configuration).sen4VegetationIndexGraph(requestParameters.fieldId, requestParameters.startDate, requestParameters.endDate, requestParameters.indexType, requestParameters.version, requestParameters.developerPayload, requestParameters.statistics, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StockMovementApi - axios parameter creator
 * @export
 */
export const StockMovementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the stock levels for a company at the supplied `timestamp`.
         * @param {string} companyId ID of the company for which to return stock-levels.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {number} [timestamp] Timestamp at which to get stock-levels. If not set, the current stock-levels are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockMovementsStockLevels: async (companyId: string, version?: string, developerPayload?: string, timestamp?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('stockMovementsStockLevels', 'companyId', companyId)
            const localVarPath = `/stockMovements/stockLevels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (timestamp !== undefined) {
                localVarQueryParameter['timestamp'] = timestamp;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StockMovementApi - functional programming interface
 * @export
 */
export const StockMovementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StockMovementApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the stock levels for a company at the supplied `timestamp`.
         * @param {string} companyId ID of the company for which to return stock-levels.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {number} [timestamp] Timestamp at which to get stock-levels. If not set, the current stock-levels are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockMovementsStockLevels(companyId: string, version?: string, developerPayload?: string, timestamp?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockMovementsStockLevels200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockMovementsStockLevels(companyId, version, developerPayload, timestamp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StockMovementApi - factory interface
 * @export
 */
export const StockMovementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StockMovementApiFp(configuration)
    return {
        /**
         * Returns the stock levels for a company at the supplied `timestamp`.
         * @param {string} companyId ID of the company for which to return stock-levels.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {number} [timestamp] Timestamp at which to get stock-levels. If not set, the current stock-levels are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockMovementsStockLevels(companyId: string, version?: string, developerPayload?: string, timestamp?: number, options?: any): AxiosPromise<StockMovementsStockLevels200Response> {
            return localVarFp.stockMovementsStockLevels(companyId, version, developerPayload, timestamp, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for stockMovementsStockLevels operation in StockMovementApi.
 * @export
 * @interface StockMovementApiStockMovementsStockLevelsRequest
 */
export interface StockMovementApiStockMovementsStockLevelsRequest {
    /**
     * ID of the company for which to return stock-levels.
     * @type {string}
     * @memberof StockMovementApiStockMovementsStockLevels
     */
    readonly companyId: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof StockMovementApiStockMovementsStockLevels
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof StockMovementApiStockMovementsStockLevels
     */
    readonly developerPayload?: string

    /**
     * Timestamp at which to get stock-levels. If not set, the current stock-levels are returned.
     * @type {number}
     * @memberof StockMovementApiStockMovementsStockLevels
     */
    readonly timestamp?: number
}

/**
 * StockMovementApi - object-oriented interface
 * @export
 * @class StockMovementApi
 * @extends {BaseAPI}
 */
export class StockMovementApi extends BaseAPI {
    /**
     * Returns the stock levels for a company at the supplied `timestamp`.
     * @param {StockMovementApiStockMovementsStockLevelsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockMovementApi
     */
    public stockMovementsStockLevels(requestParameters: StockMovementApiStockMovementsStockLevelsRequest, options?: AxiosRequestConfig) {
        return StockMovementApiFp(this.configuration).stockMovementsStockLevels(requestParameters.companyId, requestParameters.version, requestParameters.developerPayload, requestParameters.timestamp, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StoragePlacesApi - axios parameter creator
 * @export
 */
export const StoragePlacesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Load a list of objects
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [companyId] The id of the company for which all storage places should be returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storagePlaceList: async (version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, companyId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storagePlaces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load a list of objects by id
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storagePlaceObjects: async (objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectIds' is not null or undefined
            assertParamExists('storagePlaceObjects', 'objectIds', objectIds)
            const localVarPath = `/storagePlaces/{objectIds}`
                .replace(`{${"objectIds"}}`, encodeURIComponent(String(objectIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (pageKey !== undefined) {
                localVarQueryParameter['pageKey'] = pageKey;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (properties !== undefined) {
                localVarQueryParameter['properties'] = properties;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StoragePlacesApi - functional programming interface
 * @export
 */
export const StoragePlacesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StoragePlacesApiAxiosParamCreator(configuration)
    return {
        /**
         * Load a list of objects
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [companyId] The id of the company for which all storage places should be returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storagePlaceList(version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, companyId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoragePlaceObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storagePlaceList(version, deleted, pageKey, developerPayload, properties, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load a list of objects by id
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storagePlaceObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoragePlaceObjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storagePlaceObjects(objectIds, version, deleted, pageKey, developerPayload, properties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StoragePlacesApi - factory interface
 * @export
 */
export const StoragePlacesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StoragePlacesApiFp(configuration)
    return {
        /**
         * Load a list of objects
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {string} [companyId] The id of the company for which all storage places should be returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storagePlaceList(version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, companyId?: string, options?: any): AxiosPromise<StoragePlaceObjects200Response> {
            return localVarFp.storagePlaceList(version, deleted, pageKey, developerPayload, properties, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Load a list of objects by id
         * @param {string} objectIds Comma seperated list of object ids, use this to request specific objects.
         * @param {string} [version] The API Version, set to 2.0.
         * @param {boolean} [deleted] If set to true deleted entries are returned instead of the reuglar ones.
         * @param {string} [pageKey] For pagination put in the pageKey from the previous response.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {string} [properties] Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storagePlaceObjects(objectIds: string, version?: string, deleted?: boolean, pageKey?: string, developerPayload?: string, properties?: string, options?: any): AxiosPromise<StoragePlaceObjects200Response> {
            return localVarFp.storagePlaceObjects(objectIds, version, deleted, pageKey, developerPayload, properties, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for storagePlaceList operation in StoragePlacesApi.
 * @export
 * @interface StoragePlacesApiStoragePlaceListRequest
 */
export interface StoragePlacesApiStoragePlaceListRequest {
    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof StoragePlacesApiStoragePlaceList
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof StoragePlacesApiStoragePlaceList
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof StoragePlacesApiStoragePlaceList
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof StoragePlacesApiStoragePlaceList
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof StoragePlacesApiStoragePlaceList
     */
    readonly properties?: string

    /**
     * The id of the company for which all storage places should be returned
     * @type {string}
     * @memberof StoragePlacesApiStoragePlaceList
     */
    readonly companyId?: string
}

/**
 * Request parameters for storagePlaceObjects operation in StoragePlacesApi.
 * @export
 * @interface StoragePlacesApiStoragePlaceObjectsRequest
 */
export interface StoragePlacesApiStoragePlaceObjectsRequest {
    /**
     * Comma seperated list of object ids, use this to request specific objects.
     * @type {string}
     * @memberof StoragePlacesApiStoragePlaceObjects
     */
    readonly objectIds: string

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof StoragePlacesApiStoragePlaceObjects
     */
    readonly version?: string

    /**
     * If set to true deleted entries are returned instead of the reuglar ones.
     * @type {boolean}
     * @memberof StoragePlacesApiStoragePlaceObjects
     */
    readonly deleted?: boolean

    /**
     * For pagination put in the pageKey from the previous response.
     * @type {string}
     * @memberof StoragePlacesApiStoragePlaceObjects
     */
    readonly pageKey?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof StoragePlacesApiStoragePlaceObjects
     */
    readonly developerPayload?: string

    /**
     * Limit which properties are returned. Default is to return all properties.                    Format is &#x60;presetName+addtional&#x60; or &#x60;presetName-additional&#x60; where additional is a comma seperated                    list of properties. &#x60;presetName&#x60; can be either &#x60;all&#x60; or &#x60;dropdown&#x60;. &#x60;all&#x60; includes all properties,                    &#x60;dropdown&#x60; only includes properties required to display a dropdown list. For most object types                   &#x60;dropdown&#x60; will return only &#x60;id&#x60;, &#x60;name&#x60; and &#x60;tstamp&#x60;.
     * @type {string}
     * @memberof StoragePlacesApiStoragePlaceObjects
     */
    readonly properties?: string
}

/**
 * StoragePlacesApi - object-oriented interface
 * @export
 * @class StoragePlacesApi
 * @extends {BaseAPI}
 */
export class StoragePlacesApi extends BaseAPI {
    /**
     * Load a list of objects
     * @param {StoragePlacesApiStoragePlaceListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoragePlacesApi
     */
    public storagePlaceList(requestParameters: StoragePlacesApiStoragePlaceListRequest = {}, options?: AxiosRequestConfig) {
        return StoragePlacesApiFp(this.configuration).storagePlaceList(requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, requestParameters.companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load a list of objects by id
     * @param {StoragePlacesApiStoragePlaceObjectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoragePlacesApi
     */
    public storagePlaceObjects(requestParameters: StoragePlacesApiStoragePlaceObjectsRequest, options?: AxiosRequestConfig) {
        return StoragePlacesApiFp(this.configuration).storagePlaceObjects(requestParameters.objectIds, requestParameters.version, requestParameters.deleted, requestParameters.pageKey, requestParameters.developerPayload, requestParameters.properties, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TracksApi - axios parameter creator
 * @export
 */
export const TracksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the gps track for a track in geoJson format.
         * @param {string} trackId Id of the track for which to return the gps track.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tracksGpsTrack: async (trackId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trackId' is not null or undefined
            assertParamExists('tracksGpsTrack', 'trackId', trackId)
            const localVarPath = `/tracks/{trackId}/gpsTrack`
                .replace(`{${"trackId"}}`, encodeURIComponent(String(trackId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TracksApi - functional programming interface
 * @export
 */
export const TracksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TracksApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the gps track for a track in geoJson format.
         * @param {string} trackId Id of the track for which to return the gps track.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tracksGpsTrack(trackId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivitiesGpsTrack200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tracksGpsTrack(trackId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TracksApi - factory interface
 * @export
 */
export const TracksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TracksApiFp(configuration)
    return {
        /**
         * Returns the gps track for a track in geoJson format.
         * @param {string} trackId Id of the track for which to return the gps track.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tracksGpsTrack(trackId: string, options?: any): AxiosPromise<ActivitiesGpsTrack200Response> {
            return localVarFp.tracksGpsTrack(trackId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for tracksGpsTrack operation in TracksApi.
 * @export
 * @interface TracksApiTracksGpsTrackRequest
 */
export interface TracksApiTracksGpsTrackRequest {
    /**
     * Id of the track for which to return the gps track.
     * @type {string}
     * @memberof TracksApiTracksGpsTrack
     */
    readonly trackId: string
}

/**
 * TracksApi - object-oriented interface
 * @export
 * @class TracksApi
 * @extends {BaseAPI}
 */
export class TracksApi extends BaseAPI {
    /**
     * Returns the gps track for a track in geoJson format.
     * @param {TracksApiTracksGpsTrackRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TracksApi
     */
    public tracksGpsTrack(requestParameters: TracksApiTracksGpsTrackRequest, options?: AxiosRequestConfig) {
        return TracksApiFp(this.configuration).tracksGpsTrack(requestParameters.trackId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UploadApi - axios parameter creator
 * @export
 */
export const UploadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Upload endpoint for chunked file uploads. Note that concurrent requests to this endpoint with the same uploadId are _not_ possible, uploads must be sequential.                  Chunks should be multiples of 256 KB. Send chunks in order, starting at the beginning of the file at position 0.                For the next chunk use the upper value of the `Range` header from the response of the previous chunk.                Repeat until all chunks have been uploaded.                  *Note:* Upload urls are valid for a limited time only (currently one week). Inactive incomplete uploads will be deleted after that time.
         * @param {string} uploadId The id of the upload
         * @param {string} contentLength Number of bytes to upload with this request, 0 to query current status.
         * @param {string} contentRange Either \&quot;bytes &#x60;firstByteOfChunk&#x60;-&#x60;lastByteOfChunk&#x60;|&#x60;combinedUploadSize&#x60;\&quot; for uploading chunks or \&quot;bytes *|&#x60;combinedUploadSize&#x60;\&quot; to query the status of an upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile: async (uploadId: string, contentLength: string, contentRange: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadId' is not null or undefined
            assertParamExists('uploadFile', 'uploadId', uploadId)
            // verify required parameter 'contentLength' is not null or undefined
            assertParamExists('uploadFile', 'contentLength', contentLength)
            // verify required parameter 'contentRange' is not null or undefined
            assertParamExists('uploadFile', 'contentRange', contentRange)
            const localVarPath = `/upload/file/{uploadId}`
                .replace(`{${"uploadId"}}`, encodeURIComponent(String(uploadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contentLength != null) {
                localVarHeaderParameter['Content-Length'] = String(contentLength);
            }

            if (contentRange != null) {
                localVarHeaderParameter['Content-Range'] = String(contentRange);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UploadApi - functional programming interface
 * @export
 */
export const UploadApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UploadApiAxiosParamCreator(configuration)
    return {
        /**
         * Upload endpoint for chunked file uploads. Note that concurrent requests to this endpoint with the same uploadId are _not_ possible, uploads must be sequential.                  Chunks should be multiples of 256 KB. Send chunks in order, starting at the beginning of the file at position 0.                For the next chunk use the upper value of the `Range` header from the response of the previous chunk.                Repeat until all chunks have been uploaded.                  *Note:* Upload urls are valid for a limited time only (currently one week). Inactive incomplete uploads will be deleted after that time.
         * @param {string} uploadId The id of the upload
         * @param {string} contentLength Number of bytes to upload with this request, 0 to query current status.
         * @param {string} contentRange Either \&quot;bytes &#x60;firstByteOfChunk&#x60;-&#x60;lastByteOfChunk&#x60;|&#x60;combinedUploadSize&#x60;\&quot; for uploading chunks or \&quot;bytes *|&#x60;combinedUploadSize&#x60;\&quot; to query the status of an upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFile(uploadId: string, contentLength: string, contentRange: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityImportStoreMappings200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFile(uploadId, contentLength, contentRange, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UploadApi - factory interface
 * @export
 */
export const UploadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UploadApiFp(configuration)
    return {
        /**
         * Upload endpoint for chunked file uploads. Note that concurrent requests to this endpoint with the same uploadId are _not_ possible, uploads must be sequential.                  Chunks should be multiples of 256 KB. Send chunks in order, starting at the beginning of the file at position 0.                For the next chunk use the upper value of the `Range` header from the response of the previous chunk.                Repeat until all chunks have been uploaded.                  *Note:* Upload urls are valid for a limited time only (currently one week). Inactive incomplete uploads will be deleted after that time.
         * @param {string} uploadId The id of the upload
         * @param {string} contentLength Number of bytes to upload with this request, 0 to query current status.
         * @param {string} contentRange Either \&quot;bytes &#x60;firstByteOfChunk&#x60;-&#x60;lastByteOfChunk&#x60;|&#x60;combinedUploadSize&#x60;\&quot; for uploading chunks or \&quot;bytes *|&#x60;combinedUploadSize&#x60;\&quot; to query the status of an upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(uploadId: string, contentLength: string, contentRange: string, options?: any): AxiosPromise<ActivityImportStoreMappings200Response> {
            return localVarFp.uploadFile(uploadId, contentLength, contentRange, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for uploadFile operation in UploadApi.
 * @export
 * @interface UploadApiUploadFileRequest
 */
export interface UploadApiUploadFileRequest {
    /**
     * The id of the upload
     * @type {string}
     * @memberof UploadApiUploadFile
     */
    readonly uploadId: string

    /**
     * Number of bytes to upload with this request, 0 to query current status.
     * @type {string}
     * @memberof UploadApiUploadFile
     */
    readonly contentLength: string

    /**
     * Either \&quot;bytes &#x60;firstByteOfChunk&#x60;-&#x60;lastByteOfChunk&#x60;|&#x60;combinedUploadSize&#x60;\&quot; for uploading chunks or \&quot;bytes *|&#x60;combinedUploadSize&#x60;\&quot; to query the status of an upload.
     * @type {string}
     * @memberof UploadApiUploadFile
     */
    readonly contentRange: string
}

/**
 * UploadApi - object-oriented interface
 * @export
 * @class UploadApi
 * @extends {BaseAPI}
 */
export class UploadApi extends BaseAPI {
    /**
     * Upload endpoint for chunked file uploads. Note that concurrent requests to this endpoint with the same uploadId are _not_ possible, uploads must be sequential.                  Chunks should be multiples of 256 KB. Send chunks in order, starting at the beginning of the file at position 0.                For the next chunk use the upper value of the `Range` header from the response of the previous chunk.                Repeat until all chunks have been uploaded.                  *Note:* Upload urls are valid for a limited time only (currently one week). Inactive incomplete uploads will be deleted after that time.
     * @param {UploadApiUploadFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public uploadFile(requestParameters: UploadApiUploadFileRequest, options?: AxiosRequestConfig) {
        return UploadApiFp(this.configuration).uploadFile(requestParameters.uploadId, requestParameters.contentLength, requestParameters.contentRange, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WeatherApi - axios parameter creator
 * @export
 */
export const WeatherApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns weather information for a specific point and date range.                       Performance Note: Performance depends on time range, number /                        type of variables selected and aggregate/statistics type.                        Please test the requests you intend to run for performance and limit time range as needed.                        Requests taking longer than 30 seconds will fail.                         Note: When testing for performance please use different gps points as data is cached in the                          weather backend.
         * @param {number} latitude Latitude of point to query.
         * @param {number} longitude Longitude of point to query.
         * @param {string} startDate Start date of the requested period.
         * @param {string} endDate End date of the requested period.
         * @param {Array<'Temperature2m' | 'Temperature2mMin' | 'Temperature2mMax' | 'Precipitation' | 'WindSpeed10m' | 'SoilTemperatureSurface' | 'SoilMoistureSurface' | 'ShortwaveRadiation' | 'Et0FaoEvapotranspiration' | 'HeatUnit' | 'PrecipitationAccumulated' | 'PrecipitationProbability' | 'WeatherCode' | 'RelativeHumidity2m' | 'SurfacePressure' | 'CloudCover' | 'DirectRadiation'>} variables Weather variables to include in response.                        Note: &#x60;Temperature2mMin&#x60;, &#x60;Temperature2mMax&#x60;, &#x60;HeatUnit&#x60; and &#x60;PrecipitationAccumulated&#x60; not available for hourly aggregate.                         &#x60;DirectRadiation&#x60; not available in daily, weekly and monthly aggregate
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {'Hourly' | 'Daily' | 'Monthly' | 'Yearly'} [aggregate] Data aggregation.
         * @param {'No' | 'MeanY5'} [statistics] Compute historical mean depending on aggregate (D/M/Y) for a given period. Not available for hourly aggregate.
         * @param {number} [tempBaseCrop] Crop-specific base temperature for heat unit calculation in degrees celsius.
         * @param {string} [dateAccumulate] Start date from which to accumulate heat units / accumulated precipitation. If not provided startDate is used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weatherAggregate: async (latitude: number, longitude: number, startDate: string, endDate: string, variables: Array<'Temperature2m' | 'Temperature2mMin' | 'Temperature2mMax' | 'Precipitation' | 'WindSpeed10m' | 'SoilTemperatureSurface' | 'SoilMoistureSurface' | 'ShortwaveRadiation' | 'Et0FaoEvapotranspiration' | 'HeatUnit' | 'PrecipitationAccumulated' | 'PrecipitationProbability' | 'WeatherCode' | 'RelativeHumidity2m' | 'SurfacePressure' | 'CloudCover' | 'DirectRadiation'>, version?: string, developerPayload?: string, aggregate?: 'Hourly' | 'Daily' | 'Monthly' | 'Yearly', statistics?: 'No' | 'MeanY5', tempBaseCrop?: number, dateAccumulate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'latitude' is not null or undefined
            assertParamExists('weatherAggregate', 'latitude', latitude)
            // verify required parameter 'longitude' is not null or undefined
            assertParamExists('weatherAggregate', 'longitude', longitude)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('weatherAggregate', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('weatherAggregate', 'endDate', endDate)
            // verify required parameter 'variables' is not null or undefined
            assertParamExists('weatherAggregate', 'variables', variables)
            const localVarPath = `/weather/aggregate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication farmdokJWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (developerPayload !== undefined) {
                localVarQueryParameter['developerPayload'] = developerPayload;
            }

            if (latitude !== undefined) {
                localVarQueryParameter['latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['longitude'] = longitude;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (variables) {
                localVarQueryParameter['variables'] = variables.join(COLLECTION_FORMATS.csv);
            }

            if (aggregate !== undefined) {
                localVarQueryParameter['aggregate'] = aggregate;
            }

            if (statistics !== undefined) {
                localVarQueryParameter['statistics'] = statistics;
            }

            if (tempBaseCrop !== undefined) {
                localVarQueryParameter['tempBaseCrop'] = tempBaseCrop;
            }

            if (dateAccumulate !== undefined) {
                localVarQueryParameter['dateAccumulate'] = (dateAccumulate as any instanceof Date) ?
                    (dateAccumulate as any).toISOString().substr(0,10) :
                    dateAccumulate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WeatherApi - functional programming interface
 * @export
 */
export const WeatherApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WeatherApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns weather information for a specific point and date range.                       Performance Note: Performance depends on time range, number /                        type of variables selected and aggregate/statistics type.                        Please test the requests you intend to run for performance and limit time range as needed.                        Requests taking longer than 30 seconds will fail.                         Note: When testing for performance please use different gps points as data is cached in the                          weather backend.
         * @param {number} latitude Latitude of point to query.
         * @param {number} longitude Longitude of point to query.
         * @param {string} startDate Start date of the requested period.
         * @param {string} endDate End date of the requested period.
         * @param {Array<'Temperature2m' | 'Temperature2mMin' | 'Temperature2mMax' | 'Precipitation' | 'WindSpeed10m' | 'SoilTemperatureSurface' | 'SoilMoistureSurface' | 'ShortwaveRadiation' | 'Et0FaoEvapotranspiration' | 'HeatUnit' | 'PrecipitationAccumulated' | 'PrecipitationProbability' | 'WeatherCode' | 'RelativeHumidity2m' | 'SurfacePressure' | 'CloudCover' | 'DirectRadiation'>} variables Weather variables to include in response.                        Note: &#x60;Temperature2mMin&#x60;, &#x60;Temperature2mMax&#x60;, &#x60;HeatUnit&#x60; and &#x60;PrecipitationAccumulated&#x60; not available for hourly aggregate.                         &#x60;DirectRadiation&#x60; not available in daily, weekly and monthly aggregate
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {'Hourly' | 'Daily' | 'Monthly' | 'Yearly'} [aggregate] Data aggregation.
         * @param {'No' | 'MeanY5'} [statistics] Compute historical mean depending on aggregate (D/M/Y) for a given period. Not available for hourly aggregate.
         * @param {number} [tempBaseCrop] Crop-specific base temperature for heat unit calculation in degrees celsius.
         * @param {string} [dateAccumulate] Start date from which to accumulate heat units / accumulated precipitation. If not provided startDate is used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async weatherAggregate(latitude: number, longitude: number, startDate: string, endDate: string, variables: Array<'Temperature2m' | 'Temperature2mMin' | 'Temperature2mMax' | 'Precipitation' | 'WindSpeed10m' | 'SoilTemperatureSurface' | 'SoilMoistureSurface' | 'ShortwaveRadiation' | 'Et0FaoEvapotranspiration' | 'HeatUnit' | 'PrecipitationAccumulated' | 'PrecipitationProbability' | 'WeatherCode' | 'RelativeHumidity2m' | 'SurfacePressure' | 'CloudCover' | 'DirectRadiation'>, version?: string, developerPayload?: string, aggregate?: 'Hourly' | 'Daily' | 'Monthly' | 'Yearly', statistics?: 'No' | 'MeanY5', tempBaseCrop?: number, dateAccumulate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WeatherAggregate200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.weatherAggregate(latitude, longitude, startDate, endDate, variables, version, developerPayload, aggregate, statistics, tempBaseCrop, dateAccumulate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WeatherApi - factory interface
 * @export
 */
export const WeatherApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WeatherApiFp(configuration)
    return {
        /**
         * Returns weather information for a specific point and date range.                       Performance Note: Performance depends on time range, number /                        type of variables selected and aggregate/statistics type.                        Please test the requests you intend to run for performance and limit time range as needed.                        Requests taking longer than 30 seconds will fail.                         Note: When testing for performance please use different gps points as data is cached in the                          weather backend.
         * @param {number} latitude Latitude of point to query.
         * @param {number} longitude Longitude of point to query.
         * @param {string} startDate Start date of the requested period.
         * @param {string} endDate End date of the requested period.
         * @param {Array<'Temperature2m' | 'Temperature2mMin' | 'Temperature2mMax' | 'Precipitation' | 'WindSpeed10m' | 'SoilTemperatureSurface' | 'SoilMoistureSurface' | 'ShortwaveRadiation' | 'Et0FaoEvapotranspiration' | 'HeatUnit' | 'PrecipitationAccumulated' | 'PrecipitationProbability' | 'WeatherCode' | 'RelativeHumidity2m' | 'SurfacePressure' | 'CloudCover' | 'DirectRadiation'>} variables Weather variables to include in response.                        Note: &#x60;Temperature2mMin&#x60;, &#x60;Temperature2mMax&#x60;, &#x60;HeatUnit&#x60; and &#x60;PrecipitationAccumulated&#x60; not available for hourly aggregate.                         &#x60;DirectRadiation&#x60; not available in daily, weekly and monthly aggregate
         * @param {string} [version] The API Version, set to 2.0.
         * @param {string} [developerPayload] An arbitrary value, if set is returned unchanged in the response.
         * @param {'Hourly' | 'Daily' | 'Monthly' | 'Yearly'} [aggregate] Data aggregation.
         * @param {'No' | 'MeanY5'} [statistics] Compute historical mean depending on aggregate (D/M/Y) for a given period. Not available for hourly aggregate.
         * @param {number} [tempBaseCrop] Crop-specific base temperature for heat unit calculation in degrees celsius.
         * @param {string} [dateAccumulate] Start date from which to accumulate heat units / accumulated precipitation. If not provided startDate is used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weatherAggregate(latitude: number, longitude: number, startDate: string, endDate: string, variables: Array<'Temperature2m' | 'Temperature2mMin' | 'Temperature2mMax' | 'Precipitation' | 'WindSpeed10m' | 'SoilTemperatureSurface' | 'SoilMoistureSurface' | 'ShortwaveRadiation' | 'Et0FaoEvapotranspiration' | 'HeatUnit' | 'PrecipitationAccumulated' | 'PrecipitationProbability' | 'WeatherCode' | 'RelativeHumidity2m' | 'SurfacePressure' | 'CloudCover' | 'DirectRadiation'>, version?: string, developerPayload?: string, aggregate?: 'Hourly' | 'Daily' | 'Monthly' | 'Yearly', statistics?: 'No' | 'MeanY5', tempBaseCrop?: number, dateAccumulate?: string, options?: any): AxiosPromise<WeatherAggregate200Response> {
            return localVarFp.weatherAggregate(latitude, longitude, startDate, endDate, variables, version, developerPayload, aggregate, statistics, tempBaseCrop, dateAccumulate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for weatherAggregate operation in WeatherApi.
 * @export
 * @interface WeatherApiWeatherAggregateRequest
 */
export interface WeatherApiWeatherAggregateRequest {
    /**
     * Latitude of point to query.
     * @type {number}
     * @memberof WeatherApiWeatherAggregate
     */
    readonly latitude: number

    /**
     * Longitude of point to query.
     * @type {number}
     * @memberof WeatherApiWeatherAggregate
     */
    readonly longitude: number

    /**
     * Start date of the requested period.
     * @type {string}
     * @memberof WeatherApiWeatherAggregate
     */
    readonly startDate: string

    /**
     * End date of the requested period.
     * @type {string}
     * @memberof WeatherApiWeatherAggregate
     */
    readonly endDate: string

    /**
     * Weather variables to include in response.                        Note: &#x60;Temperature2mMin&#x60;, &#x60;Temperature2mMax&#x60;, &#x60;HeatUnit&#x60; and &#x60;PrecipitationAccumulated&#x60; not available for hourly aggregate.                         &#x60;DirectRadiation&#x60; not available in daily, weekly and monthly aggregate
     * @type {Array<'Temperature2m' | 'Temperature2mMin' | 'Temperature2mMax' | 'Precipitation' | 'WindSpeed10m' | 'SoilTemperatureSurface' | 'SoilMoistureSurface' | 'ShortwaveRadiation' | 'Et0FaoEvapotranspiration' | 'HeatUnit' | 'PrecipitationAccumulated' | 'PrecipitationProbability' | 'WeatherCode' | 'RelativeHumidity2m' | 'SurfacePressure' | 'CloudCover' | 'DirectRadiation'>}
     * @memberof WeatherApiWeatherAggregate
     */
    readonly variables: Array<'Temperature2m' | 'Temperature2mMin' | 'Temperature2mMax' | 'Precipitation' | 'WindSpeed10m' | 'SoilTemperatureSurface' | 'SoilMoistureSurface' | 'ShortwaveRadiation' | 'Et0FaoEvapotranspiration' | 'HeatUnit' | 'PrecipitationAccumulated' | 'PrecipitationProbability' | 'WeatherCode' | 'RelativeHumidity2m' | 'SurfacePressure' | 'CloudCover' | 'DirectRadiation'>

    /**
     * The API Version, set to 2.0.
     * @type {string}
     * @memberof WeatherApiWeatherAggregate
     */
    readonly version?: string

    /**
     * An arbitrary value, if set is returned unchanged in the response.
     * @type {string}
     * @memberof WeatherApiWeatherAggregate
     */
    readonly developerPayload?: string

    /**
     * Data aggregation.
     * @type {'Hourly' | 'Daily' | 'Monthly' | 'Yearly'}
     * @memberof WeatherApiWeatherAggregate
     */
    readonly aggregate?: 'Hourly' | 'Daily' | 'Monthly' | 'Yearly'

    /**
     * Compute historical mean depending on aggregate (D/M/Y) for a given period. Not available for hourly aggregate.
     * @type {'No' | 'MeanY5'}
     * @memberof WeatherApiWeatherAggregate
     */
    readonly statistics?: 'No' | 'MeanY5'

    /**
     * Crop-specific base temperature for heat unit calculation in degrees celsius.
     * @type {number}
     * @memberof WeatherApiWeatherAggregate
     */
    readonly tempBaseCrop?: number

    /**
     * Start date from which to accumulate heat units / accumulated precipitation. If not provided startDate is used.
     * @type {string}
     * @memberof WeatherApiWeatherAggregate
     */
    readonly dateAccumulate?: string
}

/**
 * WeatherApi - object-oriented interface
 * @export
 * @class WeatherApi
 * @extends {BaseAPI}
 */
export class WeatherApi extends BaseAPI {
    /**
     * Returns weather information for a specific point and date range.                       Performance Note: Performance depends on time range, number /                        type of variables selected and aggregate/statistics type.                        Please test the requests you intend to run for performance and limit time range as needed.                        Requests taking longer than 30 seconds will fail.                         Note: When testing for performance please use different gps points as data is cached in the                          weather backend.
     * @param {WeatherApiWeatherAggregateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeatherApi
     */
    public weatherAggregate(requestParameters: WeatherApiWeatherAggregateRequest, options?: AxiosRequestConfig) {
        return WeatherApiFp(this.configuration).weatherAggregate(requestParameters.latitude, requestParameters.longitude, requestParameters.startDate, requestParameters.endDate, requestParameters.variables, requestParameters.version, requestParameters.developerPayload, requestParameters.aggregate, requestParameters.statistics, requestParameters.tempBaseCrop, requestParameters.dateAccumulate, options).then((request) => request(this.axios, this.basePath));
    }
}


