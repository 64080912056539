
import { PropType, defineComponent } from 'vue';

import FormFieldInput from '@/shared/components/form/FormFieldInput.vue';
import parseNumber from '@/shared/modules/parseNumber';

export default defineComponent({
  name: 'FormFieldInputNumberFormattedDisplayValue',
  components: { FormFieldInput },
  model: {
    prop: 'value',
    event: 'update',
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    value: {
      type: Number as PropType<number | null>,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * Label rendered above or to the left, depending on the variant.
     *
     * If variant is 'no-label' the label can be omitted.
     */
    label: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'horizontal',
      validator: (value: string) =>
        ['horizontal', 'vertical', 'vertical-lg', 'no-label', 'no-label-sm'].includes(value),
    },
    /**
     * If set to `true` or `false` the input will be highlighted with colors and icons, depending on the state.<br>
     * Default `null`.
     */
    state: {
      type: Boolean,
      default: null,
    },
    /**
     * If set to `true` the following props will be overwritten:
     *   - value with `null`
     *   - placeholder with `'(verschiedene)'`
     */
    various: {
      type: Boolean,
      default: false,
    },
    stateMessage: {
      type: String,
      default: null,
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    formatter: {
      type: Function as PropType<(value: number | null) => string>,
      required: true,
    },
    /**
     * the parser should not change precision of the number
     * it should only be used to parse the number from a string. e.g. "1.234 €" -> 1.234
     */
    parser: {
      type: Function as PropType<(value: string | null) => number | null>,
      default: parseNumber,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isInputActive: false,
      validationStateMessage: null as string | null,
      validationState: null as boolean | null,
    };
  },
  computed: {
    displayValue: {
      get(): string {
        if (this.isInputActive) {
          return this.value ? this.value.toString() : '';
        }

        return this.formatter(this.value);
      },
      set(modifiedValue: string) {
        const newValue = this.parser(modifiedValue);
        if (Number.isNaN(newValue)) {
          this.$emit('update', null);
          return;
        }

        this.$emit('update', newValue);
      },
    },
    computedState(): boolean | null {
      return this.validationState ?? this.state;
    },
    computedStateMessage(): string | null {
      return this.validationStateMessage ?? this.stateMessage;
    },
  },
  methods: {
    onBlur() {
      this.isInputActive = false;
      this.$emit('blur');
    },
    onFocus() {
      this.isInputActive = true;
      this.$emit('focus');
    },
  },
});
