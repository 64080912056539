
import { PropType, defineComponent } from 'vue';

import { Violation } from '../types';

export default defineComponent({
  name: 'ModalContentViolation',

  props: {
    violation: {
      type: Object as PropType<Violation>,
      required: true,
    },
  },
});
