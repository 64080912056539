<template>
  <BFormGroup v-show="!hideSlider" label-for="slider-number-input" class="slider-group" label-class="label">
    <div>
      <span class="label" slot="label">
        {{ label }}
      </span>
      <VueSlider
        :tooltip="'none'"
        :value="value"
        :min="minValue"
        :max="maxValue"
        :processStyle="{ backgroundColor: 'var(--primary)' }"
        :dotSize="[20, 20]"
        :interval="interval"
        :silent="true"
        class="pt-4 px-2"
        @change="(value) => onInputUpdate(value)"
      />
      <div class="slider-marks-wrapper">
        <div>{{ minValueFormatted }}</div>
        <div class="input-container">
          <FormFieldInput
            type="text"
            class="form-control--number-input m-0 mt-1"
            name="slider-number-input"
            variant="no-label-sm"
            v-model="valueInternal"
            :formatter="numberFormat"
            @blur="roundValue"
            :state="inputErrorMessage ? false : null"
          />
        </div>
        <div class="text-right">{{ maxValueFormattted }}</div>
      </div>
      <div class="error-container">
        <ErrorUserMessage :error-user-message="inputErrorMessage" class="m-0" />
      </div>
      <p class="additional-information" v-if="additionalInformation != null">{{ additionalInformation }}</p>
    </div>
  </BFormGroup>
</template>

<script>
import numbro from 'numbro';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

import ErrorUserMessage from '@/shared/components/ErrorUserMessage.vue';
import FormFieldInput from '@/shared/components/form/FormFieldInput.vue';

export default {
  name: 'FormSlider',
  components: {
    VueSlider,
    FormFieldInput,
    ErrorUserMessage,
  },
  props: {
    label: {
      type: String,
    },
    value: {
      type: Number,
      default: 0,
    },
    minValue: {
      type: Number,
      default: 0,
    },
    maxValue: {
      type: Number,
      default: 100,
    },
    hideSlider: {
      type: Boolean,
      default: false,
    },
    interval: {
      type: Number,
      default: 1,
    },
    additionalInformation: {
      type: String,
      default: null,
    },
  },
  emits: ['setValue'],
  data() {
    return {
      inputErrorMessage: '',
    };
  },
  computed: {
    minValueFormatted() {
      return numbro(this.minValue).format({ mantissa: 0 });
    },
    maxValueFormattted() {
      return numbro(this.maxValue).format({ mantissa: 0 });
    },

    valueInternal: {
      get() {
        return this.numberFormat(Math.trunc(this.value).toString());
      },
      set(value) {
        let numberValue = 0;
        if (value && value !== '') {
          numberValue = numbro.unformat(value);
        }
        this.onInputUpdate(numberValue);
      },
    },
  },
  methods: {
    onInputUpdate(value) {
      if (value < this.minValue || value > this.maxValue) {
        this.inputErrorMessage = this.$t('Wert muss zwischen {min} und {max} sein', {
          min: this.minValueFormatted,
          max: this.maxValueFormattted,
        });
        return;
      }

      const newValue = Math.min(value, this.maxValue);
      this.$emit('setValue', newValue);
      this.inputErrorMessage = null;
    },
    numberFormat(value) {
      const numberValue = Number(value.replace(/\D/g, ''));
      return numbro(numberValue).format({ mantissa: 0 });
    },
    roundValue() {
      let roundedValue = Math.round(this.value / this.interval) * this.interval;
      roundedValue = roundedValue < this.precision ? this.precision : roundedValue;

      this.$emit('setValue', roundedValue);
    },
  },
};
</script>

<style scoped>
.slider-group {
  display: flex;
  flex-direction: column;
}
::v-deep .slider-group .col {
  flex: 0;
}

::v-deep .label {
  flex: 1;
  min-width: 130px;
  padding-left: 0;
  margin-bottom: 0;
}
.label {
  font-weight: 600;
  font-size: 14px;
}
.slider-marks-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  margin-bottom: var(--spacer_2);
}

.slider-marks-wrapper > div {
  flex: 1;
}

.input-container {
  width: 120px;
}

.error-container {
  font-size: 12px;
  min-height: 18px;
}

.additional-information {
  font-size: 12px;
  color: var(--gray_900);
}
</style>
