<template>
  <div class="form-field-set">
    <FormHeadline
      class="form-field-set__headline"
      :class="{ 'form-field-set__headline--collapsible': collapsible }"
      @click="toggleCollapsed"
    >
      <span v-if="!$slots['header']" class="form-field-set__headline-text">
        {{ headline }}
      </span>
      <span v-else class="form-field-set__headline-text">
        <!--
          @slot
          Rendered in the label. If set it replaces the headline prop.
        -->
        <slot name="header" />
      </span>
      <span v-if="collapsible" class="form-field-set__headline-collapsible-icon">
        <FontAwesomeIcon class="" :icon="contentVisible ? ['far', 'angle-up'] : ['far', 'angle-down']" />
      </span>
    </FormHeadline>
    <BCollapse v-model="contentVisible" class="form-field-set__content">
      <!--
        @slot
        The rendered content inside the BCollapse. Usually a list of form fields.
      -->
      <slot name="default" />
    </BCollapse>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { BCollapse } from 'bootstrap-vue';

import FormHeadline from './FormHeadline.vue';

library.add(faAngleUp, faAngleDown);

/**
 * Use this component to wrap form fields. Has multiple layouts and can be set to collapsible.
 *
 * @category Shared
 * @subcategory Molecules
 * @component
 * @example
 * <div style="min-height: 400px; padding: 10px;">
 *   <FormFieldSet
 *     headline="User"
 *     collapsible
 *   >
 *     <FormFieldInput
 *       label="Firstname"
 *       placeholder="Enter firstname"
 *     />
 *     <FormFieldInput
 *       label="Lastname"
 *       placeholder="Enter lastname"
 *     />
 *   </FormFieldSet>
 *   <FormFieldSet
 *     headline="Additional Data"
 *     collapsible
 *     collapsed
 *   >
 *     <FormFieldInput
 *       label="Tel"
 *       placeholder="Enter number"
 *     />
 *     <FormFieldInput
 *       label="Email"
 *       placeholder="Enter email"
 *     />
 *   </FormFieldSet>
 * </div>
 */
export default {
  name: 'FormFieldSet',
  components: { BCollapse, FontAwesomeIcon, FormHeadline },
  model: {
    prop: 'collapsed',
    event: 'update',
  },
  props: {
    /**
     * Rendered in the label.
     */
    headline: {
      type: String,
      default: null,
    },
    /**
     * Headline will be clickable and can collapse the content if set to `true`.
     */
    collapsible: {
      type: Boolean,
      default: false,
    },
    /**
     * Only works if collapsible is set too.<br>
     * Dynamically defines if the content is collapsed/visible. It is mapped to v-model.
     */
    collapsed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      collapsedInternal: this.collapsed,
    };
  },
  watch: {
    collapsed() {
      this.collapsedInternal = this.collapsed;
    },
  },
  computed: {
    contentVisible: {
      get() {
        if (!this.collapsible) {
          return true;
        }
        return !this.collapsedInternal;
      },
      set(value) {
        this.collapsedInternal = !value;
        /**
         * Content collapsed status. Used to update the v-model.
         *
         * @event FormFieldSet#update
         * @type {Boolean} collapsed
         */
        this.$emit('update', this.collapsedInternal);
      },
    },
  },
  methods: {
    toggleCollapsed() {
      this.contentVisible = this.collapsedInternal;
    },
  },
};
</script>

<style scoped>
.form-field-set {
  display: flow-root;
  margin-top: var(--spacer_2);
  margin-bottom: var(--spacer_2);
}

.form-field-set__headline {
  position: relative;
  margin: 0;
}

.form-field-set__headline--collapsible {
  cursor: pointer;
}

.form-field-set__headline-text {
  display: block;
  margin-right: 1.5em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.form-field-set__headline-collapsible-icon {
  position: absolute;
  right: var(--spacer_3);
  top: 7px;
  font-size: 1rem;
}

.form-field-set__content {
  padding: 0 var(--spacer_3);
}
</style>
