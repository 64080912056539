<template>
  <MultiRootWrapper>
    <table class="table-output table-simple-without-borders">
      <thead>
        <tr class="text-right">
          <th />
          <th v-for="(header, index) in tableHeaders" :key="index">{{ header }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(row, index) in tableRows"
          :key="index"
          class="text-right"
          :class="{
            'border-medium': index !== tableRows.length - 1,
            'border-0': index === tableRows.length - 1,
          }"
        >
          <td class="text-left col-field-name" v-b-tooltip.hover.right :title="row.title">
            {{ row.name }}
          </td>
          <td v-for="(value, index) in row.values" :key="index">
            {{ value }}
          </td>
        </tr>
      </tbody>
    </table>
    <small class="table-hint" v-if="tableHint">
      {{ tableHint }}
    </small>
  </MultiRootWrapper>
</template>

<script>
import { defineComponent } from 'vue';

import MultiRootWrapper from '@/shared/components/MultiRootWrapper.vue';

export default defineComponent({
  name: 'ExportTable',
  components: {
    MultiRootWrapper,
  },
  props: {
    tableHeaders: {
      type: Array,
      required: true,
    },
    tableRows: {
      type: Array,
      required: true,
    },
    tableHint: {
      type: String,
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/sass/mixins/table-simple-without-borders.scss';

.table-output {
  table-layout: auto;
  font-size: 12px;
}

.table-output tr * {
  width: 40px;
}

.table-output tr *:first-child {
  width: calc(100% - 65px - 3 * 40px);
}

.table-output th,
.table-output td {
  padding: 10px;
  text-align: left;

  &:last-child {
    // overrides table-simple-without-borders styles
    padding: 10px !important;
  }
}

.table-hint {
  display: block;
  text-align: right;
  padding-top: 4px;
  font-size: 10px;
  color: var(--gray_600);
  font-weight: 600;
}

.col-field-name {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 125px;
  white-space: nowrap;
}

.table-hint {
  display: block;
  text-align: right;
  padding-top: 4px;
  font-size: 10px;
  color: var(--gray_600);
  font-weight: 600;
}
</style>
