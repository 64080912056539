
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { PropType, defineComponent } from 'vue';

import ButtonLink from '@/shared/components/buttons/ButtonLink.vue';

library.add(faTrashAlt);

export default defineComponent({
  name: 'ProductSelectSection',
  components: {
    ButtonLink,
  },
  props: {
    label: {
      type: String as PropType<string>,
      required: true,
    },
  },
});
