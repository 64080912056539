import {
  ApplicationMapsTerrazoMap200ResponseAllOfDataFeaturesInner,
  ApplicationMapsTerrazoMap200ResponseAllOfDataFeaturesInnerProperties,
} from 'farmdok-rest-api';

import {
  ColorCode,
  ColorConfig,
  CoverageRatio,
  Feature,
  FeatureVegetation,
  Heatmap,
  Properties,
  Vegetation,
} from '@/precision-farming/application-maps/store/baseWorkflowStore/types/Heatmap';

import { FertilizationCerealsZone } from '../types';

export default function toHeatmaps(
  zones: FertilizationCerealsZone[],
  features: ApplicationMapsTerrazoMap200ResponseAllOfDataFeaturesInner[],
  fieldId: string,
): Record<string, Heatmap> {
  return {
    [fieldId]: {
      area: -1,
      coverage_ratio: COVERAGE_RATIO,
      color_codes: toColorCodes(zones),
      color_config: COLOR_CONFIG,
      features: toFeatures(features),
      type: 'FeatureCollection',
      vegetation: VEGETATION,
    },
  };
}

function toColorCodes(zones: FertilizationCerealsZone[]): ColorCode[] {
  return zones.map((zone) => ({
    area: zone.size,
    col: zone.color,
    name: zone.name,
  }));
}

function toFeatures(features: ApplicationMapsTerrazoMap200ResponseAllOfDataFeaturesInner[]): Feature[] {
  return features.map((feature) => ({
    area: -1,
    coverage_ratio: COVERAGE_RATIO,
    geometry: feature.geometry,
    properties: toProperties(feature.properties),
    type: feature.type,
    vegetation: toFeatureVegetation(feature.properties),
  }));
}

function toProperties(properties: ApplicationMapsTerrazoMap200ResponseAllOfDataFeaturesInnerProperties): Properties {
  return {
    fill: properties.fill || '#000000',
    'fill-opacity': 1,
    stroke: properties.fill || '#000000',
    'stroke-opacity': Number(properties['stroke-opacity']),
    'stroke-width': 0,
    value: Number(properties.NDVI),
  };
}

function toFeatureVegetation(
  properties: ApplicationMapsTerrazoMap200ResponseAllOfDataFeaturesInnerProperties,
): FeatureVegetation {
  return {
    type: 'NDVI',
    value: Number(properties.NDVI),
    value_q: -1,
  };
}

const COLOR_CONFIG: ColorConfig = {
  abs_color_scaling: -1,
  color_codes: [],
  max: -1,
  min: -1,
  quantisation_interval: -1,
  smoothing_kernel_size: -1,
};
const COVERAGE_RATIO: CoverageRatio = {
  all: 1,
  cloud: 0,
  shadow: 0,
  snow: 0,
};
const VEGETATION: Vegetation = {
  type: 'NDVI',
  value: 0,
  value_max: 0,
  value_min: 0,
};
