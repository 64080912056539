<template>
  <PageApplicationMaps :workflowKey="workflowKey" :stepCount="3" @onNavigationNext="onNavigationNext">
    <template #headerLogo>
      <HeaderLogo :src="headerLogo.src" :alt="headerLogo.alt" />
    </template>
    <template #steps>
      <SidebarFields
        tableId="sidebar-table-fields-seeding"
        v-show="paginationStep === 1"
        :selected-rows.sync="selectedFields"
        :customColumnDefinition="columnDefinition"
      />
      <SidebarCreateApplicationMapSeeding ref="createApplicationMap" v-if="paginationStep === 2" />
      <SidebarExportSeeding v-show="paginationStep === 3" :workflowKey="workflowKey" />
      <ModalSeedrateConfirmation :thresholdExceeded="seedRatesExceedThresholds" ref="seedRateConfirmationModal" />
    </template>
  </PageApplicationMaps>
</template>
<script>
import { mapGetters, mapState } from 'vuex';

import SidebarFields from '@/fields/components/SidebarFields.vue';
import { WorkflowKey } from '@/precision-farming/application-maps/types';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

import columnsSidebarTableFields from '../../../fields/handsontable/columns/applicationMapsSidebarTableFields';
import PageApplicationMaps from '../PageApplicationMaps.vue';
import HeaderLogo from '../components/HeaderLogo.vue';
import ModalSeedrateConfirmation from './ModalSeedrateConfirmation.vue';
import SidebarCreateApplicationMapSeeding from './SidebarCreateApplicationMapSeeding.vue';
import SidebarExportSeeding from './SidebarExportSeeding.vue';

export default {
  name: 'PageApplicationMapsSeeding',
  components: {
    PageApplicationMaps,
    SidebarFields,
    HeaderLogo,
    SidebarCreateApplicationMapSeeding,
    SidebarExportSeeding,
    ModalSeedrateConfirmation,
  },
  data() {
    return {
      workflowKey: WorkflowKey.SEEDING,
      headerLogo: {
        src: 'https://develop.farmdok.com/assets/img/contracting/saatbau_Logo_Sondervariante_Kompakt_72dpi_RGB_144.png',
        alt: 'Saatbau Logo',
      },
      columnDefinition: columnsSidebarTableFields,
    };
  },
  computed: {
    ...mapState('precisionFarming/applicationMaps/seeding', ['paginationStep']),
    ...mapGetters({
      zones: 'precisionFarming/applicationMaps/seeding/zones',
    }),

    currentCompaniesHaveFeature() {
      return this.$store.getters.currentCompaniesHaveFeature(availableFeatures.FEATURE_APPLICATION_MAPS_SAATBAU_MAIZE);
    },

    selectedFields: {
      get() {
        return this.$store.state.precisionFarming.applicationMaps.seeding.selectedFields;
      },
      set(selected) {
        this.$store.commit('precisionFarming/applicationMaps/seeding/setSelectedFields', selected);
      },
    },

    seedRatesExceedThresholds() {
      const lowerThreshold = 50000;
      const upperThreshold = 150000;
      if (
        this.zones.some(
          (zone) => zone.seedRate < lowerThreshold || (zone.manualSeedRate && zone.manualSeedRate < lowerThreshold),
        )
      ) {
        return 'lower';
      }
      if (
        this.zones.some(
          (zone) => zone.seedRate > upperThreshold || (zone.manualSeedRate && zone.manualSeedRate > upperThreshold),
        )
      ) {
        return 'upper';
      }
      return null;
    },
  },

  methods: {
    async onNavigationNext(callBack) {
      if (this.paginationStep === 2) {
        if (this.seedRatesExceedThresholds) {
          const okay = await this.$refs.seedRateConfirmationModal.show();
          if (okay) {
            this.$store.dispatch(`precisionFarming/applicationMaps/seeding/resetManualSeedrates`);
            return;
          }
        }
      }
      callBack();
    },
  },
};
</script>
