
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle, faCircleNotch } from '@fortawesome/pro-solid-svg-icons';
import { RuleViolationType } from 'farmdok-rest-api';
import { PropType, defineComponent } from 'vue';

import { RuleCheckStatusTypeLoading } from '@/activities/types';

library.add(faCircleExclamation);
library.add(faCircleNotch);
library.add(faCheckCircle);

export default defineComponent({
  name: 'TableFooterRuleCheckStatus',
  props: {
    status: { type: String as PropType<RuleCheckStatusTypeLoading>, default: 'OK' },
    violations: { type: Number, default: 0 },
  },
  computed: {
    statusText(): string {
      switch (this.status) {
        case 'LOADING':
          return this.$t('Live check läuft...') || 'Live check läuft...';
        case RuleViolationType.Violation:
        case RuleViolationType.NotChecked:
          return (
            this.$t('Warnhinweise gefunden: {count}', { count: this.violations }) ||
            `Warnhinweise gefunden: ${this.violations}`
          );
        default:
          return this.$t('Keine Warnhinweise gefunden') || 'Keine Warnhinweise gefunden';
      }
    },
    statusIcon(): string[] {
      switch (this.status) {
        case 'LOADING':
          return ['fas', 'circle-notch'];
        case RuleViolationType.Violation:
        case RuleViolationType.NotChecked:
          return ['far', 'circle-exclamation'];
        default:
          return ['fas', 'check-circle'];
      }
    },
    iconColorClass(): string {
      switch (this.status) {
        case RuleViolationType.Violation:
          return 'text-danger-dark';
        default:
          return 'text-gray-600';
      }
    },
  },
});
